/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useCallback, useState } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSnackbar } from "notistack";
import { useLoaderData, useLocation, useSubmit } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import SelectNodesCard from "./components/SelectNodesCard";
import AnalysisTypeCard from "./components/AnalysisTypeCard";
import AnalysisTargetCard from "./components/AnalysisTargetCard";
import AuthContext from "../../../contexts/AuthContext";
import { analysisTemplateActions } from "../slices/LaunchAnalysisSlices";
import {
  CustomVtuneCmdParser,
  assignObjParamToForm,
  convertClassicAnalysisTypeToTemplateId,
  vtuneCommandAssignment
} from "./utils/AnalysisVtuneParser";

import { checkValidation } from "./LaunchAnalysisValidation";
import { getAnalysisExecution } from "../AnalysisStatus/AnalysisStatus.service";
import LaunchAnalysisDialog from "./components/LaunchAnalysisDialog";
import {
  useGetAnalysesQuery,
  useGetNodeResultQuery
} from "../../../store/wtpkApi";
import ScreenHeader from "../../../components/ScreenHeader/ScreenHeader";
import ParseNodeData from "../../../utils/TableUtils";

const LaunchAnalysisScreen = () => {
  const auth = useContext(AuthContext);
  const [loadedNodes] = useLoaderData();
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formHasError, setFormHasError] = useState(false);
  const submitAnalysis = useSubmit();
  const analysis = useSelector((state) => state.analysis);
  const requestError = useSelector((state) => state.analysis.requestError);
  const executionId = useSelector(
    (state) => state.analysis.analysisExecutionId
  );
  const isLoading = useSelector((state) => state.analysis.loading);
  const customFormInputs = useSelector((state) => state.customAnalysisForms);
  const URLparams = new URLSearchParams(location.search);
  const parameters = URLparams.get("cmd");
  const vTuneCommandLine = useSelector(
    (state) => state.analysis.vTuneCommandLine
  );
  const resetCard = useSelector((state) => state.analysis.resetForm);
  const currentTenantId = useSelector((state) => state.user.current_tenant_id);
  const { data: nodeResultData } = useGetNodeResultQuery(
    URLparams.get("resultId"),
    {
      skip: !URLparams.get("resultId") || !currentTenantId
    }
  );
  const { refetch: refetchAnalyses } = useGetAnalysesQuery(undefined, {
    skip: !currentTenantId
  });

  const getOldAnalysis = useCallback(async () => {
    const activeNodes = ParseNodeData(loadedNodes).filter(
      (node) => node.ping_status === "Online"
    );
    const paramsId =
      URLparams.get("executionId") || nodeResultData?.execution_id;
    if (paramsId) {
      const execution = await getAnalysisExecution(auth.user, paramsId);
      if (execution instanceof Error) {
        enqueueSnackbar(`Could not fetch the analysis: ${execution.message}`, {
          variant: "error"
        });
      }
      if (execution !== undefined) {
        const newAnalysisType = URLparams.get("analysisType");
        if (newAnalysisType && newAnalysisType !== "null") {
          const newTemplateId =
            convertClassicAnalysisTypeToTemplateId(newAnalysisType);
          if (newTemplateId !== execution.template_id) {
            execution.template_id = newTemplateId;
            execution.definition = null;
          }
        }
        return assignObjParamToForm(
          execution,
          dispatch,
          activeNodes,
          enqueueSnackbar
        );
      }
    }
    return null;
  }, [nodeResultData]);

  const setVtuneCommandParams = useCallback(() => {
    return vtuneCommandAssignment(dispatch, parameters);
  }, [parameters]);

  useEffect(() => {
    getOldAnalysis();
    setVtuneCommandParams();
  }, [setVtuneCommandParams, nodeResultData]);

  useEffect(() => {
    if (resetCard)
      dispatch(analysisTemplateActions.setResetAnalysisForm(false));
  }, [resetCard, dispatch]);

  const handleOnSubmit = async () => {
    if (
      await checkValidation(
        analysis,
        customFormInputs,
        CustomVtuneCmdParser(parameters),
        dispatch
      )
    ) {
      dispatch(analysisTemplateActions.toggleLoading());
      setIsModalOpen(true);
      setFormHasError(false);
      submitAnalysis(
        {
          serialized: JSON.stringify({
            analysis,
            customFormInputs,
            vtuneCmdObj: CustomVtuneCmdParser(vTuneCommandLine.value)
          })
        },
        { method: "post", encType: "application/json" }
      );
      refetchAnalyses();
    } else {
      setFormHasError(true);
    }
  };

  return (
    !resetCard && (
      <>
        <LaunchAnalysisDialog
          isLoading={isLoading}
          executionId={executionId}
          isModalOpen={isModalOpen}
          requestError={requestError}
          setIsModalOpen={setIsModalOpen}
        />
        <ScreenHeader
          title="Configure Analysis"
          crumbDefs={[{ label: "Analyses", href: "/analyses" }]}
        />
        <Grid
          container
          spacing={3}
          sx={{
            width: "100%",
            justifyContent: "center",
            marginBottom: "-24px"
          }}
        >
          <Grid item xs={12}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <SelectNodesCard />
              </Grid>
              <Grid item xs={12}>
                <AnalysisTargetCard />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item container rowSpacing={3}>
              <Grid item xs={12}>
                <AnalysisTypeCard />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs
            md={12}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px 0"
            }}
          >
            <Button
              variant="contained"
              size="large"
              aria-label="Start analysis button"
              id="btnLaunchAnalysis"
              startIcon={<PlayArrowIcon />}
              onClick={handleOnSubmit}
            >
              Start Analysis
            </Button>
            <IconButton
              size="small"
              color="primary"
              title="Reset"
              aria-label="Reset button"
              id="btnResetForm"
              sx={{ marginLeft: "20px" }}
              onClick={async () => {
                setFormHasError(false);
                dispatch(analysisTemplateActions.resetAnalyisForm());
                dispatch(analysisTemplateActions.setResetAnalysisForm(true));
              }}
            >
              <RefreshIcon />
            </IconButton>
            <Typography
              sx={{
                fontSize: "14px",
                marginLeft: "20px",
                color: (theme) =>
                  formHasError
                    ? theme.palette.error.main
                    : theme.palette.greyText
              }}
            >
              {formHasError ? (
                <Typography display="flex" alignItems="center">
                  <WarningRoundedIcon color="error" sx={{ mr: 1 }} />
                  <span>
                    The form contains fields that have not been filled in
                    correctly
                  </span>
                </Typography>
              ) : (
                <span>
                  Fields marked with * are required and must be filled out
                </span>
              )}
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  );
};

export default LaunchAnalysisScreen;
