import React from "react";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LandingArt from "../../images/landing-background.png";
import LandingIllustration from "../../images/landing_illustration.png";
import VTLogo from "../../images/VT.png";
import styles from "./LandingStyles";
import "./Landing.css";

const Landing = () => {
  const auth = useAuth();
  const onLoginClick = () => {
    auth.signinRedirect({ state: { location: "/home" } });
  };
  if (auth.isAuthenticated) return <Navigate to="/home" />;
  return (
    <>
      <Box
        height="100vh"
        width="100%"
        className="background-landing-image"
        sx={{
          backgroundImage: ` url(${LandingArt})`
        }}
      />
      <Box position="absolute" top="0" left="0" right="0" zIndex={9999}>
        <Grid container sx={{ minHeight: "100%" }}>
          <Grid
            item
            xl={7}
            md={7}
            sm={0}
            xs={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box
              component="img"
              sx={{
                maxHeight: "75%",
                maxWidth: "75%"
              }}
              className="landing-illustration"
              alt="Landing Illustration"
              src={LandingIllustration}
            />
          </Grid>
          <Grid
            item
            xl={5}
            md={5}
            sm={12}
            xs={12}
            flexDirection="column"
            sx={{
              background: styles.backgroundColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              padding: (theme) => theme.spacing(10)
            }}
          >
            <Stack
              sx={{
                alignItems: "center"
              }}
            >
              <Box
                component="img"
                sx={{
                  maxHeight: "43px",
                  maxWidth: "80px",
                  padding: "56px 80px"
                }}
                className="vt-logo"
                alt="VT logo"
                src={VTLogo}
              />
              <Typography
                mb={0}
                variant="h4"
                align="center"
                sx={[styles.textColor]}
                className="intel-display-font-family"
              >
                {" "}
                Welcome to the Early Access{" "}
              </Typography>
              <Typography
                mb={0}
                variant="h4"
                align="center"
                sx={[styles.textColor]}
                className="intel-display-font-family"
              >
                {" "}
                Performance Profiler
              </Typography>
              {/* <Typography
                mt={3}
                mb={3}
                className="intel-bodytext-font-family"
                sx={styles.textColor}
                variant="body1"
              >
                Lorem ipsum dolor sit amet consectetur. Nam elit eget tellus eu
                nunc nec eu. Hac a arcu malesuada tempor fames venenatis.
              </Typography> */}
            </Stack>
            <Stack mt={3} sx={{ width: "100%", alignItems: "center" }}>
              <Button
                className="intel-bodytext-font-family"
                sx={[styles.landingButton, styles.boldText, styles.textSize]}
                onClick={onLoginClick}
                id="landing-login-btn"
              >
                Log in
              </Button>
              <Typography
                mt={3}
                mb={0}
                className="intel-bodytext-font-family"
                sx={styles.textColor}
                variant="body2"
              >
                If your company is not enrolled, contact your Intel Account
                Manager.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Landing;
