import { List, Stack, Typography } from "@mui/material";
import React from "react";
import { useGetNodeQuery } from "../../../store/wtpkApi";
import ParseNodeData from "../../../utils/TableUtils";
import WTPKObjectDetailsListItem from "../../../components/WTPKObjectDetailsListItem/WTPKObjectDetailsListItem";
import { generateStatusIconWithLabel } from "../../../utils/statusIconGeneration";

const AdditionalNodeDetailsPane = ({ nodeId }) => {
  const { data: nodeData, isLoading } = useGetNodeQuery(nodeId, {
    skip: nodeId === undefined
  });

  const parsedNodeData = isLoading
    ? null
    : ParseNodeData({ nodes: [nodeData.node] });
  const node =
    parsedNodeData && (parsedNodeData?.length > 0 ? parsedNodeData[0] : null);

  return (
    <Stack direction="row" sx={{ pt: 4 }} spacing={4}>
      <Stack>
        <Typography variant="subtitle2">Hardware</Typography>
        <List disableGutters sx={{ p: 0 }}>
          <WTPKObjectDetailsListItem
            fieldName="CPU"
            value={node.cpu_model}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="CPU architecture"
            value={node.cpu_arch}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="PMU"
            value={generateStatusIconWithLabel(
              node.pmu ? "Enabled" : "Not enabled"
            )}
            isLoading={isLoading}
          />
        </List>
        <Typography variant="subtitle2" sx={{ pt: 2 }}>
          System
        </Typography>
        <List disableGutters sx={{ p: 0 }}>
          <WTPKObjectDetailsListItem
            fieldName="Type"
            value={node.node_type}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="Operating System"
            value={node.platform_name}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="OS type"
            value={node.platform_type}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="Kernel"
            value={node.kernel}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="Hypervisor"
            value={node.node_type}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="IP address"
            value={node.ip_address}
            isLoading={isLoading}
          />
        </List>
      </Stack>
      <Stack>
        <Typography variant="subtitle2">Installed drivers</Typography>
        <List disableGutters sx={{ p: 0 }}>
          <WTPKObjectDetailsListItem
            fieldName="VTSSPP"
            value={generateStatusIconWithLabel(
              node.vtsspp_driver_installed ? "Enabled" : "Not enabled"
            )}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="Socwatch"
            value={generateStatusIconWithLabel(
              node.socwatch_driver_installed ? "Enabled" : "Not enabled"
            )}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="sep"
            value={generateStatusIconWithLabel(
              node.sep_driver_installed ? "Enabled" : "Not enabled"
            )}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="pax"
            value={generateStatusIconWithLabel(
              node.pax_driver_installed ? "Enabled" : "Not enabled"
            )}
            isLoading={isLoading}
          />
          <WTPKObjectDetailsListItem
            fieldName="socperf"
            value={generateStatusIconWithLabel(
              node.socperf_driver_installed ? "Enabled" : "Not enabled"
            )}
            isLoading={isLoading}
          />
        </List>
      </Stack>
    </Stack>
  );
};

export default AdditionalNodeDetailsPane;
