import React, { useState, useEffect, useContext } from "react";
import { useLoaderData } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import Stack from "@mui/material/Stack";
import { useSnackbar } from "notistack";
import { ApiGET } from "../../appServices/axiosService";
import ErrorResult from "./Results/ErrorResults";
import AnalysisResult from "./Results/EBPFComponents/EBPFAnalysisResult";
import AnalysisLog from "./Results/VTuneAnalysisLog";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";
import * as AnalysisResponse from "./AnalysisStatus/AnalysisStatus.service";
import { ConvertDateTime } from "../../appServices/resuableMethods";
import AuthContext from "../../contexts/AuthContext";

const apiBaseUrl = `${window.runtime.REACT_APP_API_URL}`;

export const analysisOutputLoader = async (params, auth) => {
  const { nodeId } = params;
  const response = await ApiGET(
    `${apiBaseUrl}/nodeExecution/${nodeId}`,
    auth?.user
  );
  const templateResponse = await ApiGET(
    `${apiBaseUrl}/execution/${response.result.execution_id}`,
    auth?.user
  );

  return [response, templateResponse];
};

const AnalysisOutput = () => {
  const [executionsLoaded, templateResponse] = useLoaderData();
  const [analysisExecutionName, setAnalyisExecutionName] = useState();
  const [outputs, setOutputs] = useState([]);

  const nodeName =
    executionsLoaded?.result?.node?.computer_name ??
    executionsLoaded?.result?.node_id;
  const auth = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [result, setResult] = useState();

  const getLogUrls = async (resultData) => {
    // Retrieve Output Links from the backend
    const outputLink = await AnalysisResponse.getLog(
      auth.user,
      resultData.node_execution_id,
      "stdout"
    );
    const outputData = await axios.get(outputLink?.output_url);
    if (outputData instanceof Error) {
      enqueueSnackbar(
        `Could not fetch the Log details : ${outputData?.message}`,
        { variant: "error" }
      );
    }
    setOutputs(outputData);
  };

  useEffect(() => {
    if (executionsLoaded instanceof Error) {
      enqueueSnackbar(
        `Could not fetch the node execution details : ${executionsLoaded.message}`,
        { variant: "error" }
      );
    }
    if (!(executionsLoaded instanceof Error)) {
      setResult(executionsLoaded.result);
      setAnalyisExecutionName(templateResponse?.execution?.name);
      getLogUrls(executionsLoaded.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    executionsLoaded,
    enqueueSnackbar,
    templateResponse?.execution?.name
  ]);

  const downloadOutput = () => {
    if (result?.status === "FAILED") {
      const element = document.createElement("a");
      const timeStamp = ConvertDateTime(result.date_finished * 1000);
      const filename = `${result.definition.type_id}-${analysisExecutionName}-${result.node.computer_name}-${timeStamp}-log.txt`;
      const file = new Blob([outputs.data], {
        type: "text/plain;charset=utf-8"
      });
      element.href = URL.createObjectURL(file);
      element.download = filename;
      document.body.appendChild(element);
      element.click();
    } else {
      AnalysisResponse.downloadOutput(
        outputs?.data,
        ConvertDateTime(result.date_finished * 1000),
        result.definition.type_id,
        result.node.computer_name,
        analysisExecutionName
      );
    }
  };

  return (
    <>
      <ScreenHeader
        title={nodeName}
        crumbDefs={[
          { label: "Analyses", href: "/analyses" },
          { label: analysisExecutionName, href: ".." },
          { label: "Logs" }
        ]}
        heroButtonDef={{
          label: "DOWNLOAD LOG",
          onClick: downloadOutput,
          variant: "outlined"
        }}
      />
      <Stack sx={{ width: "100%" }} alignItems="stretch">
        {result?.status === "FAILED" && (
          <ErrorResult
            result={result}
            outputs={outputs}
            analysisExecutionName={analysisExecutionName}
          />
        )}
        {result?.status === "COMPLETED" &&
          result?.definition.type_id === "ebpf" && (
            <AnalysisResult
              result={result}
              outputs={outputs}
              analysisExecutionName={analysisExecutionName}
            />
          )}
        {result?.status === "COMPLETED" &&
          result?.definition.type_id.startsWith("vtune") && (
            <AnalysisLog
              result={outputs}
              analysisExecutionName={analysisExecutionName}
            />
          )}
      </Stack>
    </>
  );
};

export default AnalysisOutput;
