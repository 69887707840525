import React from "react";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import WTPKSwitch from "./ReusableFormComponents/WTPKSwitch";
import WTPKInput from "./ReusableFormComponents/WTPKInput";
import { isItInRange } from "../../screens/Analysis/LaunchAnalysis/LaunchAnalysisValidation";

const MemoryAccess = () => {
  const formInputs = useSelector((state) => state.customAnalysisForms.value);
  const validationSamplingInterval = (e, dispatch, action) => {
    isItInRange(
      dispatch,
      e.target.value,
      e.target.id,
      action,
      1,
      1000,
      "Invalid value, please pick a value between 1 and 1000."
    );
  };
  const validationMemoryObjectSize = (e, dispatch, action) => {
    isItInRange(
      dispatch,
      e.target.value,
      e.target.id,
      action,
      1,
      999999999,
      "Invalid value, please pick a value between 1 and 999999999."
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <WTPKInput
          id="sampling_interval"
          label="CPU sampling interval, ms"
          initialValue="1"
          inputType="number"
          min="1"
          max="1000"
          validation={validationSamplingInterval}
        />
      </Grid>
      <Grid item xs={12}>
        <WTPKSwitch
          id="analyze_mem_objects"
          label="Analyze dynamic memory objects"
          defaultChecked={formInputs.analyze_mem_objects || false}
        />
      </Grid>
      <Grid item xs={12}>
        <WTPKInput
          id="mem_object_size_min_thres"
          label="Minimal dynamic memory object size to track, in bytes"
          initialValue={1024}
          inputType="number"
          required
          min="1"
          max="999999999"
          validation={validationMemoryObjectSize}
        />
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <WTPKSwitch
            id="dram_bandwidth_limits"
            label="Evaluate max DRAM bandwidth"
            defaultChecked={formInputs.dram_bandwidth_limits || true}
          />
          <WTPKSwitch
            id="analyze_openmp"
            label="Analyze OpenMP regions"
            defaultChecked={formInputs.analyze_openmp || false}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default MemoryAccess;
