import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SelectNodesTable from "./SelectNodesTable";
import LaunchAnalysisCardHeader from "./LaunchAnalysisCardHeader";

const SelectNodesCard = () => {
  const selectedNodes = useSelector((state) => state.analysis.selectedNodes);
  const missingNodes = useSelector((state) => state.analysis.missingNodes);

  return (
    <Card width="100%">
      <LaunchAnalysisCardHeader
        stepCount="1"
        title="Where"
        subTitle="Select the target to profile."
        missingNodes={missingNodes}
      />
      <CardContent width="100%" sx={{ padding: "0 !important" }}>
        <SelectNodesTable />
        {selectedNodes.isValid != null && !selectedNodes.isValid && (
          <Typography
            color="error"
            sx={{
              position: "relative",
              bottom: "35px",
              paddingLeft: "16px",
              width: "50%"
            }}
          >
            You must select at least 1 node to execute a profiling job against
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default SelectNodesCard;
