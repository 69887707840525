import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useRouteError } from "react-router-dom";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import AuthContext from "../../contexts/AuthContext";

const ErrorScreen = ({
  showHomeButton = true,
  status = "No status code was given",
  message = "No error message was given",
  statusText = "No status text was given"
}) => {
  const auth = useContext(AuthContext);
  const error = useRouteError();
  // eslint-disable-next-line
  // console.error(error);
  return (
    <Box width="100%" py={16}>
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Stack alignItems="center" justifyContent="center">
          <WarningAmberRoundedIcon
            sx={{
              width: "88px",
              height: "88px",
              color: "#BDBDBD"
            }}
          />
          <Typography variant="h6" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
            {error?.status ?? status}
          </Typography>
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          <Typography
            variant="subtitle2"
            sx={{ color: "rgba(0, 0, 0, 0.87)", paddingBottom: "5px" }}
          >
            {error?.data?.message ?? message}
          </Typography>

          <Typography
            variant="body1"
            sx={{ color: "rgba(0, 0, 0, 0.87)", fontSize: "14px" }}
          >
            {error?.statusText ?? statusText}
          </Typography>
        </Stack>
        {showHomeButton &&
          auth?.isAuthenticated &&
          error?.status !== 401 &&
          !error?.data?.didNotAccept && (
            <Button
              href="/home"
              variant="outlined"
              sx={{ padding: "6px 16px" }}
            >
              Back to Home
            </Button>
          )}
        {(!auth.isAuthenticated ||
          error?.status === 401 ||
          error?.data?.didNotAccept) && (
          <Button
            variant="outlined"
            sx={{ padding: "6px 16px" }}
            onClick={() =>
              auth.signinRedirect({
                state: { location: error?.redirect || "/home" }
              })
            }
          >
            Log in
          </Button>
        )}
        {auth?.isAuthenticated && (
          <Button
            sx={{ padding: "4px 5px" }}
            LinkComponent="a"
            href="mailto:vtune-profiler@intel.com"
          >
            Submit Feedback
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default ErrorScreen;
