import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useRevalidator } from "react-router-dom";
import { useSnackbar } from "notistack";
import { UpdateAnalysisExecution } from "../AnalysisStatus/AnalysisStatus.service";
import AuthContext from "../../../contexts/AuthContext";
import { analysisStatusActions } from "../slices/AnalysisSlices";

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
};

const UpdateAnalysisNameModal = (props) => {
  const auth = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const analysisName = useSelector(
    (state) => state.analysisStatus.analysisExecutionName
  );

  const executionId = useSelector(
    (state) => state.analysisStatus.analysisExecutionID
  );

  const [updatedName, setUpdatedName] = useState();
  const [enableButton, setEnableButton] = useState(true);
  const [helpertext, setHelperText] = useState();
  const [isValidString, setIsValidString] = useState(false);
  const dispatch = useDispatch();

  const checkForValidString = (name) => {
    const regEx = /^[A-Za-z0-9_\-\s.,!&?$%#()[\]:]+$/;
    const isValid = regEx.test(name);
    if (!isValid) {
      setIsValidString(!isValid);
      setHelperText(
        "Please enter a valid Analysis execution name.\n You can use only letters, numbers, and special characters (_-.,!&?$%#()[]:)"
      );
    }
    return isValid;
  };

  const UpdateAnalysisExeName = async (e) => {
    e.preventDefault();

    const response = await UpdateAnalysisExecution(
      auth.user,
      executionId,
      updatedName
    );
    if (response instanceof Error) {
      enqueueSnackbar(`Updating the Analysis execution name failed`, {
        variant: "error"
      });
    } else {
      enqueueSnackbar(`Updating the Analysis execution name succeeded`, {
        variant: "success"
      });
      dispatch(
        analysisStatusActions.setSliceKeys({
          analysisExecutionName: updatedName
        })
      );
    }
    props.close();
    setUpdatedName();
    setEnableButton(true);
  };
  const handleDialogButton = () => {
    setEnableButton(true);
    setHelperText("");
    setIsValidString(false);
    props.close();
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleDialogButton}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="draggable-dialog-title"
        sx={{
          cursor: "move"
        }}
      >
        Rename Analysis
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleDialogButton}
        sx={{
          position: "absolute",
          right: "14px",
          top: "12px",
          color: "#0000008F"
        }}
      >
        <CloseIcon />
      </IconButton>
      <form method="PUT" onSubmit={(e) => UpdateAnalysisExeName(e)}>
        <DialogContent
          sx={{ alignItems: "center", pt: 0, minWidth: "400px" }}
          component="div"
        >
          <DialogContentText sx={{ display: "inline-block" }}>
            <Typography
              variant="body2"
              gutterBottom
              fontSize="0.875rem"
              sx={{ display: "inline", mr: "8px" }}
            >
              Current name
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              color="black"
              sx={{ display: "inline" }}
            >
              {analysisName}
            </Typography>
          </DialogContentText>
          <DialogContentText sx={{ pt: 2, minHeight: "84px" }}>
            <TextField
              error={isValidString}
              id="analysisName"
              variant="outlined"
              defaultValue={analysisName}
              sx={{ width: "400px" }}
              size="small"
              label="New name"
              onChange={(e) => {
                setUpdatedName(e.target.value);
                setHelperText("");
                setIsValidString(false);
                if (
                  e.target.value !== analysisName &&
                  checkForValidString(e.target.value)
                ) {
                  setEnableButton(false);
                } else setEnableButton(true);
              }}
              helperText={helpertext}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            pb: 3,
            pt: 0
          }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={handleDialogButton}
            size="small"
          >
            Cancel
          </Button>
          <Button
            disabled={enableButton}
            type="submit"
            variant="contained"
            size="small"
            autoFocus
            color="primary"
          >
            Rename
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateAnalysisNameModal;
