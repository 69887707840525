/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { availableFormInputs } from "../LaunchAnalysis/utils/AnalysisFormCardUtils";

export const initialState = {
  requestError: null,
  loading: false,
  analysisExecutionId: null,
  selectedTemplate: {
    value: null,
    isValid: null,
    statusMessage: null
  },
  selectedNodes: {
    value: [],
    isValid: null,
    statusMessage: null
  },
  where: {
    isValid: null,
    statusMessage: null
  },
  what: {
    isValid: null,
    statusMessage: null
  },
  how: {
    isValid: null,
    statusMessage: null,
    icon: null
  },
  resetForm: false,
  vTuneCommandLine: { value: null, isValid: null, statusMessage: null },
  cmdObj: null,
  analysisTarget: {
    value: null,
    isValid: null,
    statusMessage: null
  },
  targetParameters: Object.fromEntries(
    Object.entries(availableFormInputs).map(([id, value]) => {
      return [
        id,
        Object.fromEntries([
          ["value", ""],
          ["label", value],
          ["isValid", null],
          ["statusMessage", null],
          ["isEnabled", false]
        ])
      ];
    })
  ),

  iptAnalysisName: {
    value: null,
    isValid: null,
    statusMessage: null
  },
  iptAnalysisDuration: {
    value: null,
    isValid: null,
    statusMessage: null
  },
  iptAnalysisDurationUnit: {
    value: "Seconds",
    isValid: null,
    statusMessage: null
  },
  missingNodes: []
};

export const analysisTemplateSlice = createSlice({
  name: "analysisTemplate",
  initialState,
  reducers: {
    setResetAnalysisForm: (state, action) => {
      state.resetForm = action.payload;
    },
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    resetAnalyisForm: () => initialState,
    setAnalysisExecutionId: (state, action) => {
      state.analysisExecutionId = action.payload;
    },
    setValidation: (state, action) => {
      const [keys, isValid, message, icon] = Object.values(action.payload);
      if (Array.isArray(keys)) {
        keys.forEach((key) => {
          state[key].isValid = isValid;
          state[key].statusMessage = message;
          if ("icon" in state[key]) {
            state[key].icon = icon;
          }
        });
      } else {
        state[keys].isValid = isValid;
        state[keys].statusMessage = message;
        if ("icon" in state[keys]) {
          state[keys].icon = icon;
        }
      }
    },
    resetTargetParameterEnabled: (state) => {
      state.targetParameters = Object.fromEntries(
        Object.keys(availableFormInputs).map((id) => {
          return [
            id,
            Object.fromEntries([
              ["value", state.targetParameters[id]?.value ?? ""],
              ["isValid", state.targetParameters[id]?.isValid ?? null],
              [
                "statusMessage",
                state.targetParameters[id]?.statusMessage ?? null
              ],
              ["isEnabled", false]
            ])
          ];
        })
      );
    },
    setTargetParameterValue: (state, action) => {
      const [inputID, valueReceived] = Object.values(action.payload);
      state.targetParameters[inputID].value = valueReceived;
    },
    setAnalysisRequestError: (state, action) => {
      state.requestError = action.payload;
    },
    setAnalysisProp: (state, action) => {
      const [inputID, keyReceived, valueReceived] = Object.values(
        action.payload
      );
      state[inputID][keyReceived] = valueReceived;
    },
    setTargetParameterValidation: (state, action) => {
      const [keys, isValid, message] = Object.values(action.payload);
      if (Array.isArray(keys)) {
        keys.forEach((key) => {
          state.targetParameters[key].isValid = isValid;
          state.targetParameters[key].statusMessage = message;
        });
      } else {
        state.targetParameters[keys].isValid = isValid;
        state.targetParameters[keys].statusMessage = message;
      }
    },
    setTargetParameterIsEnabled: (state, action) => {
      const [inputID, valueReceived] = Object.values(action.payload);
      state.targetParameters[inputID].isEnabled = valueReceived;
    },
    setVTuneCommandObject: (state, action) => {
      state.cmdObj = action.payload;
    },
    setMissingNodes: (state, action) => {
      state.missingNodes = action.payload;
    },
    setSelectedNodes: (state, action) => {
      state.selectedNodes.value = action.payload;
      state.selectedNodes.isValid = action.payload.length !== 0;
    }
  }
});

export const analysisTemplateActions = analysisTemplateSlice.actions;

export default analysisTemplateSlice;
