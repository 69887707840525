import React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const InstallationRequirements = () => {
  return (
    <Accordion
      sx={{
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
        marginTop: (theme) => theme.spacing(2)
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="requirements-header"
      >
        <Typography variant="h6">Requirements</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>Supported Operating Systems:</Typography>
        <List component="ol" sx={{ listStyleType: "disc", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }}>Ubuntu 22</ListItem>
          <ListItem sx={{ display: "list-item" }}>Ubuntu 20</ListItem>
          <ListItem sx={{ display: "list-item" }}>RHEL 9</ListItem>
        </List>
        <Typography sx={{ marginTop: (theme) => theme.spacing(1) }}>
          Supported Chipset:
        </Typography>
        <List
          component="ol"
          sx={{
            listStyleType: "disc",
            pl: 4
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            Intel VTune Profiler supports target nodes running Intel CPUs.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            It is preferred that the target node kernel has access to hardware
            events. For more information on setting this up see the{" "}
            <Link
              href="https://www.intel.com/content/www/us/en/developer/articles/system-requirements/vtune-profiler-system-requirements.html"
              target="_blank"
              rel="noreferrer"
              component="a"
              underline="none"
              color="primary"
            >
              VTune documentation
            </Link>
            .
          </ListItem>
        </List>
        <Typography sx={{ marginTop: (theme) => theme.spacing(1) }}>
          Network:
        </Typography>
        <List
          component="ol"
          sx={{
            listStyleType: "disc",
            pl: 4
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            The target nodes must have outbound access to internet (direct or
            through proxy) on port 443 in order to install the Performance
            Profiler agent, as well as to communicate with the Performance
            Profiler service. Inbound access not required.
          </ListItem>
        </List>
        <Typography sx={{ marginTop: (theme) => theme.spacing(1) }}>
          Security:
        </Typography>
        <List
          component="ol"
          sx={{
            listStyleType: "disc",
            pl: 4
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            Performance Profiler Agent must be installed as sudo/root.
          </ListItem>
        </List>
        <Typography sx={{ marginTop: (theme) => theme.spacing(1) }}>
          Storage:
        </Typography>
        <List
          component="ol"
          sx={{
            listStyleType: "disc",
            pl: 4
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            Target nodes must have 1.5GB of available storage for agent install.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            We recommend additional 5GB of free storage for profiling collection
            data.
          </ListItem>
        </List>
        <Typography sx={{ marginTop: (theme) => theme.spacing(1) }}>
          Installed Components:
        </Typography>
        <List
          component="ol"
          sx={{
            listStyleType: "disc",
            pl: 4
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            Package name: amazon-ssm-agent <br /> Purpose: used to establish
            remote connection to your node. <br /> Storage used: ~100MB <br />{" "}
            Installs into: /usr, /etc, /lib
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Package name: <br />
            <Box
              component="span"
              sx={{ marginLeft: (theme) => theme.spacing(2) }}
            />
            intel-oneapi-common-licensing <br />
            <Box
              component="span"
              sx={{ marginLeft: (theme) => theme.spacing(2) }}
            />
            intel-oneapi-common-vars <br />{" "}
            <Box
              component="span"
              sx={{ marginLeft: (theme) => theme.spacing(2) }}
            />
            intel-oneapi-vtune-eclipse-plugin-vtune <br />
            <Box
              component="span"
              sx={{ marginLeft: (theme) => theme.spacing(2) }}
            />
            intel-oneapi-vtune Purpose: to execute Intel VTune Profiler
            collections. <br />
            Installs into: /opt/intel <br /> Storage used: ~1.5GB
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Package name:wtpk-agent <br />
            Purpose: Used to orchestrate execution and results upload to SaaS.{" "}
            <br />
            Storage used: ~50MB <br />
            Installs into: /var/lib/ppaas
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default InstallationRequirements;
