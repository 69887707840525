import {
  Grid,
  Box,
  Typography,
  Stack,
  Tabs,
  Tab,
  Menu,
  Chip
} from "@mui/material";
import { DataGridPremium, GridCellModes } from "@mui/x-data-grid-premium";
import React, { useState, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { useLoaderData, useRevalidator } from "react-router-dom";
import ColumnDef from "./UsersPaneColumnDef";
import { dataTableHeaderToolbarGetter } from "../../../components/DataTableElements/DataTableHeaderToolbar";
import { ConvertDateTime } from "../../../appServices/resuableMethods";
import { getRoleIdOfName, getRoleNameOfId } from "../../../appServices/role";

import { updateRole } from "../../../appServices/UserManagement.service";

const tabStyle = {
  fontSize: "16px",
  fontWeight: "501"
};

export const formatMemberDataForRow = (item, index) => {
  return {
    id: index,
    userId: item.user_id,
    email: item.member_email,
    userName: item.member_email.split("@")[0],
    typeOfUser: item.member_role.role_name,
    addedOn:
      item?.date_created === null
        ? ""
        : ConvertDateTime(item.date_created * 1000)
  };
};

export const formatInviteDataForRow = (item, index, offset) => {
  return {
    id: index + offset,
    email: item.member_email,
    userName: item.member_email.split("@")[0],
    typeOfUser: getRoleNameOfId(item.member_role_id),
    addedOn: "Invitation pending"
  };
};

const UsersPane = () => {
  const auth = useAuth();
  const accountData = useLoaderData();
  const revalidateLoader = useRevalidator();
  const [panelAnchorEl, setPanelAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownMenuOpen = Boolean(anchorEl);
  const [selectedTableTab, setSelectedTableTab] = useState(0);

  const handleCloseMenuDropdown = () => {
    setAnchorEl(null);
  };

  const userStats = [
    {
      title1: "All users",
      quantity1:
        accountData.invites.items.length + accountData.members.items.length,
      title2: "Administrators",
      quantity2: accountData.members.items.filter(
        (obj) => obj.member_role.role_name === "Admin"
      ).length
    },
    {
      title1: "Active users",
      quantity1: accountData.members.items.length,
      title2: "Viewers",
      quantity2: accountData.members.items.filter(
        (obj) => obj.member_role.role_name === "Viewer"
      ).length
    },
    {
      title1: "Pending",
      quantity1: accountData.invites.items.length,
      title2: "Editors",
      quantity2: accountData.members.items.filter(
        (obj) => obj.member_role.role_name === "Editor"
      ).length
    }
  ];

  const mappedMemberData = accountData.members.items.map(
    formatMemberDataForRow
  );
  const mappedInviteData = accountData.invites.items.map((item, index) => {
    return formatInviteDataForRow(item, index, mappedMemberData.length);
  });

  const getRowDataForSelectedTab = useCallback(() => {
    switch (selectedTableTab) {
      case 1:
        return [...mappedMemberData];
      case 2:
        return [...mappedInviteData];
      default:
        return [...mappedMemberData, ...mappedInviteData];
    }
  }, [selectedTableTab, mappedMemberData, mappedInviteData]);

  const handleSelectedTableTabChange = (_, newValue) => {
    setSelectedTableTab(newValue);
  };

  const processRowUpdate = async (updatedRow, oldRow) => {
    if (updatedRow.typeOfUser !== oldRow.typeOfUser) {
      if (oldRow.addedOn === "Invitation pending") {
        return oldRow;
      }
      await updateRole(
        auth,
        updatedRow.userId,
        getRoleIdOfName(updatedRow.typeOfUser)
      ).finally(() => {
        revalidateLoader.revalidate();
      });
      return updatedRow;
    }
    return updatedRow;
  };

  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable || params.cellMode === "edit") {
      return;
    }

    // Ignore portal
    if (
      event.target.nodeType === 1 &&
      !event.currentTarget.contains(event.target)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit }
        }
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const TabItems = (
    <Tabs
      TabIndicatorProps={{ style: { height: "4px" } }}
      value={selectedTableTab}
      onChange={handleSelectedTableTabChange}
    >
      <Tab id="tabAllUsers" label="All users" value={0} sx={tabStyle} />
      <Tab id="tabActiveUsers" label="Active" value={1} sx={tabStyle} />
      <Tab id="tabAllUsers" label="Requests" value={2} sx={tabStyle} />
    </Tabs>
  );
  return (
    <Box
      display="flex"
      flexGrow={1}
      flexShrink={1}
      flexDirection="column"
      minWidth={0}
      height="100%"
      sx={{
        "& .user-type": {
          color: "#0054AE",
          fontSize: "14px",
          fontWeight: "medium",
          textTransform: "uppercase"
        }
      }}
    >
      <Grid
        container
        sx={{
          marginBottom: (theme) => theme.spacing(2),
          width: "55%",
          border: "solid 8px",
          borderColor: "#F7F7F7"
        }}
      >
        {userStats.map((item) => {
          return (
            <React.Fragment key={item.title1}>
              <Grid
                item
                xs={6}
                sx={{
                  border: "solid 1px",
                  borderColor: "#F7F7F7"
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    paddingX: "16px",
                    paddingY: "4px",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Typography variant="subtitle2">{item.title1}</Typography>
                  <Chip
                    sx={{ marginLeft: "auto" }}
                    size="small"
                    color="secondary"
                    label={item.quantity1}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  border: "solid 1px",
                  borderColor: "#F7F7F7"
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    paddingX: (theme) => theme.spacing(2),
                    paddingY: (theme) => theme.spacing(0.5),
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Typography variant="subtitle2">{item.title2}</Typography>
                  <Chip
                    sx={{ marginLeft: "auto" }}
                    size="small"
                    color="secondary"
                    label={item.quantity2}
                  />
                </Stack>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <DataGridPremium
        columns={ColumnDef}
        rows={getRowDataForSelectedTab()}
        initialState={{
          sorting: {
            sortModel: [{ field: "Email", sort: "desc" }]
          },
          columns: {
            // Hiding this column until it can be completed correctly
            columnVisibilityModel: {
              user: false
            }
          }
        }}
        slots={{
          toolbar: dataTableHeaderToolbarGetter
        }}
        slotProps={{
          toolbar: {
            setPanelAnchorEl,
            disablePageSizeSelector: true,
            tabs: TabItems
          },
          panel: {
            anchorEl: panelAnchorEl
          }
        }}
        disableColumnSelector
        isCellEditable={(params) =>
          params.field === "typeOfUser" &&
          params.row.addedOn !== "Invitation pending"
        }
        hideFooterRowCount
        processRowUpdate={processRowUpdate}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        loading={revalidateLoader.state !== "idle"}
      />
      <Menu
        id="all-nodes-dropdown-options"
        MenuListProps={{
          "aria-labelledby": "all-nodes-dropdown-button"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        anchorEl={anchorEl}
        open={dropdownMenuOpen}
        onClose={handleCloseMenuDropdown}
      />
    </Box>
  );
};

export default UsersPane;
