/* istanbul ignore file */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AuthProvider } from "react-oidc-context";
import { LicenseInfo } from "@mui/x-license";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import axios from "axios";
import { WebStorageStateStore } from "oidc-client-ts";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { reduxStore, persistedStore } from "./store/ReduxStore";
import AuthRedirect from "./AuthRedirect";
import App from "./App";
import globalTheme from "./theme";

LicenseInfo.setLicenseKey(
  "73a9e6edba561867b53cfbb1de498a5dTz04NzMxMixFPTE3NDMxNzMwODYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const onSigninCallback = (user) => {
  const previousLocation = user.state?.location || "/";
  window.location.replace(previousLocation);
};

const endSessionEndpoint = `${window.runtime.REACT_APP_COGNITO_UI}/logout?client_id=${window.runtime.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${window.runtime.REACT_APP_BASE_URL}`;
const oidcConfig = {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: `${window.runtime.REACT_APP_COGNITO_URL}/oauth2_authorize`,
  metadataUrl: `${window.runtime.REACT_APP_COGNITO_URL}/.well-known/openid-configuration`,
  client_id: window.runtime.REACT_APP_COGNITO_CLIENT_ID,
  redirect_uri: `${window.runtime.REACT_APP_BASE_URL}/oauth-callback`,
  scope: window.runtime.REACT_APP_SCOPES,
  responseType: "code",
  revokeTokenTypes: ["refresh_token"],
  automaticSilentRenew: false,
  metadataSeed: {
    end_session_endpoint: `${
      window.runtime.REACT_APP_VTUNE_BASE_URL
    }oauth2/sign_out?rd=${encodeURIComponent(endSessionEndpoint)}`
  },
  onSigninCallback
};

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <PersistGate loading={null} persistor={persistedStore}>
        <AuthProvider {...oidcConfig}>
          <ThemeProvider theme={globalTheme}>
            <AuthRedirect>
              <SnackbarProvider
                autoHideDuration={6000}
                /** action={(snackbarId) => (   // Incompatible with notistack 2.0.8?
                  <IconButton
                    onClick={() => closeSnackbar(snackbarId)}
                    sx={{ color: "#FFF" }}
                  >
                    <CloseIcon />
                  </IconButton>
                )} */
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <App />
              </SnackbarProvider>
            </AuthRedirect>
          </ThemeProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();  We removed reportWebVitals to eliminate unused
// dependencies.  Go here to bring it back:
// https://github.com/intel-innersource/applications.services.watchtowerpeak.monorepo/commits/main/web-mvpui/src/reportWebVitals.js
