import ExploreIcon from "@mui/icons-material/Explore";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import SdStorageIcon from "@mui/icons-material/SdStorage";
import MemoryIcon from "@mui/icons-material/Memory";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HotspotHardwareSampling from "./HotspotHardwareSampling";
import MicroarchitectureExploration from "./MicroarchitectureExploration";
import InputOutputPlatformLevelMetrics from "./InputOutputPlatformLevelMetrics";
import MemoryConsumption from "./MemoryConsumption";
import ThreadingHardwareSampling from "./ThreadingHardwareSampling";
import MemoryAccess from "./MemoryAccess";
import PerformanceSnapshot from "./PerformanceSnapshot";
import ThreadingUserModeSamplingTracing from "./ThreadingUserModeSamplingTracing";

export { HotspotHardwareSampling, MicroarchitectureExploration };

export const CurrentSetOfAnalyzers = {
  bcc_offcputime: {
    type_id: "bcc_offcputime",
    title: "BCC - offcputime",
    description: "Summarize off-CPU time by stack trace",
    homepage: "https://github.com/iovisor/bcc/blob/master/tools/offcputime.py",
    documentation:
      "https://manpages.ubuntu.com/manpages/bionic/en/man8/offcputime-bpfcc.8.html",
    owner: "https://github.com/iovisor",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "profile_system"
    ]
  },

  bcc_biosnoop: {
    type_id: "bcc_biosnoop",
    title: "BCC - biosnoop",
    description:
      "Trace block device I/O and print details including issuing PID.",
    homepage: "https://github.com/iovisor/bcc/blob/master/tools/biosnoop.py",
    documentation:
      "https://manpages.ubuntu.com/manpages/bionic/en/man8/biosnoop-bpfcc.8.html",
    owner: "https://github.com/iovisor",
    targets: ["disk", "profile_system"]
  },

  bcc_tcplife: {
    type_id: "bcc_tcplife",
    title: "BCC - tcplife",
    description: "Trace the lifespan of TCP sessions and summarize.",
    homepage: "https://github.com/iovisor/bcc/blob/master/tools/tcplife.py",
    documentation:
      "https://manpages.ubuntu.com/manpages/bionic/en/man8/tcplife-bpfcc.8.html",
    owner: "https://github.com/iovisor",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "profile_system"
    ]
  },

  vtune_anomaly_detection: {
    type_id: "vtune_anomaly_detection",
    title: "Intel® VTune™ Profiler Anomaly Detection",
    icon: "&#xf83e;",
    description:
      "Identify performance anomalies by profiling critical code at the microsecond level. Anomaly Detection uses Intel Processor Trace technology for fine-grained analysis.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "1.0.1"
  },

  vtune_gpu_hotspots_characterization: {
    type_id: "vtune_gpu_hotspots_characterization",
    title: "Intel® VTune™ Profiler GPU Hotspots Characterization",
    icon: "&#xf729;",
    description:
      "Analyze the most time-consuming GPU kernels, characterize GPU utilization based on GPU hardware metrics, identify performance issues caused by memory latency or inefficient kernel algorithms, and analyze GPU instruction frequency per certain instruction types.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "3.0.1"
  },

  vtune_gpu_hotspots_source_analysis: {
    type_id: "vtune_gpu_hotspots_source_analysis",
    title: "Intel® VTune™ Profiler GPU Hotspots Source Analysis",
    icon: "&#xf729;",
    description:
      "Analyze the most time-consuming GPU kernels, characterize GPU utilization based on GPU hardware metrics, identify performance issues caused by memory latency or inefficient kernel algorithms, and analyze GPU instruction frequency per certain instruction types.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "3.0.1"
  },

  vtune_gpu_offload: {
    type_id: "vtune_gpu_offload",
    title: "Intel® VTune™ Profiler GPU Offload",
    icon: "&#xf729;",
    description:
      "Explore code execution on various CPU and GPU cores on your platform, estimate how your code benefits from offloading to the GPU, and identify whether your application is CPU or GPU bound.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "3.0.0"
  },

  vtune_gpu_rendering: {
    type_id: "vtune_gpu_rendering",
    title: "Intel® VTune™ Profiler GPU Rendering",
    icon: "&#xf013;",
    description:
      "Analyze the CPU/GPU utilization of your code running on the Xen virtualization platform. Explore GPU utilization per GPU engine and GPU hardware metrics that help understand where performance improvements are possible. If applicable, this analysis also detects OpenGL-ES API calls and display them on the timeline. ",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "3.1.1"
  },

  HotspotHardwareSampling: {
    type_id: "vtune_hotspots_hw",
    group_by: "Intel® VTune™ Profiler",
    title: "Hotspots Hardware Event-Based",
    icon: WhatshotIcon,
    component: HotspotHardwareSampling,
    description:
      "Identify the most time consuming functions and lines of source code.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "1.0.0"
  },

  vtune_hotspots_sw: {
    type_id: "vtune_hotspots_sw",
    title: "Intel® VTune™ Profiler Hotspots User-Mode Sampling",
    description:
      "Identify the most time consuming functions and lines of source code.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "1.0.0"
  },

  vtune_hpc_performance: {
    type_id: "vtune_hpc_performance",
    title: "Intel® VTune™ Profiler HPC Performance Characterization",
    icon: "&#xf2db;",
    description:
      "Analyze performance aspects of compute-intensive applications, including CPU and GPU utilization. Get information on OpenMP efficiency, memory access, and vectoriation.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "2.0.1"
  },

  InputOutputPlatformLevelMetrics: {
    type_id: "vtune_io",
    group_by: "Intel® VTune™ Profiler",
    title: "Input and Output",
    icon: SwapHorizIcon,
    component: InputOutputPlatformLevelMetrics,
    description:
      "Analyze utilization of IO subsystems, CPU, and processor busses",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "2.1.0"
  },

  MemoryAccess: {
    type_id: "vtune_memory_access",
    group_by: "Intel® VTune™ Profiler",
    title: "Memory Access",
    icon: SdStorageIcon,
    component: MemoryAccess,
    description:
      "Measure a set of metrics to identify memory access related issues (for example, specific for NUMA architectures). This analysis type is based on the hardware event-based sampling collection.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "1.1.1"
  },

  MemoryConsumption: {
    type_id: "vtune_memory_consumption",
    group_by: "Intel® VTune™ Profiler",
    title: "Memory Consumption",
    icon: SdStorageIcon,
    component: MemoryConsumption,
    description:
      "Analyze memory consumption by your application, its distinct memory objects and their allocation stacks.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "1.0.1"
  },
  PerformanceSnapshot: {
    type_id: "vtune_performance_snapshot",
    group_by: "Intel® VTune™ Profiler",
    title: "Performance Snapshot",
    icon: ExploreIcon,
    component: PerformanceSnapshot,
    no_args: true,
    description:
      "Get a quick snapshot of your application performance and identify next steps for deeper analysis.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "0.0.0"
  },

  vtune_system_overview: {
    type_id: "vtune_system_overview",
    title: "Intel® VTune™ Profiler System Overview",
    icon: "&#xf201;",
    description:
      "Analyze general behaviour of target system to explore resource utilization and identify platform level factors that limit performance.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "3.1.0"
  },

  ThreadingHardwareSampling: {
    type_id: "vtune_threading_hw",
    icon: AltRouteIcon,
    group_by: "Intel® VTune™ Profiler",
    title: "Threading Hardware Event-Based Sampling and Context Switches",
    component: ThreadingHardwareSampling,
    description:
      "Discover how well your application is using parellelism to take advantage of allavailable CPus. Identify and locate synchronization issues causing overhead or idle wait time resulting in lost performance.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "2.0.0"
  },

  ThreadingUserModeSamplingTracing: {
    type_id: "vtune_threading_sw",
    group_by: "Intel® VTune™ Profiler",
    title: "Threading User-Mode Sampling and Tracing",
    icon: AltRouteIcon,
    component: ThreadingUserModeSamplingTracing,
    no_args: true,
    description:
      "Discover how well your application is using parallelism to take advantage of all available CPus. Identify and locate synchronization issues causing overhead or idle wait time resulting in lost performance.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "2.0.0"
  },

  MicroarchitectureExploration: {
    type_id: "vtune_uarch_exploration",
    icon: MemoryIcon,
    group_by: "Intel® VTune™ Profiler",
    title: "Microarchitecture Exploration",
    component: MicroarchitectureExploration,
    description:
      "Analyze CPU microarchitecture bottlenecks affecting the performance of your application. This analysis type is based on the hardware event-based sampling collection.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "1.1.0"
  },

  vtune_vpp: {
    type_id: "vtune_vpp",
    title: "Intel® VTune™ Profiler Platform Profiler",
    icon: "&#xf013;",
    description:
      "Platform Profiler collects coarse-grained, system-level metrics for extended profiling of minutes to hours. Software architects can identify workloads or phases of workloads that use hardware inefficiently and need tuning. Infrastructure architects can see if the current hardware configuration is a good match for most workloads.",
    homepage:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    documentation:
      "https://www.intel.com/content/www/us/en/develop/documentation/vtune-help/top/analyze-performance/algorithm-group/basic-hotspots-analysis.html",
    targets: [
      "attach_by_process_id",
      "attach_by_process_name",
      "start_new_process"
    ],
    vtune_tree_position: "3.1.2"
  },
  "19275d0a-a413-4440-ba25-88f1ceaebf33": {
    template_id: "19275d0a-a413-4440-ba25-88f1ceaebf33",
    name: "Custom Template",
    group_by: "Intel® VTune™ Profiler",
    icon: EditNoteIcon,
    description:
      "Use the Intel® VTune™ Profiler UI to define a custom profiling command",
    type: "vtune",
    vtune_tree_position: "9.9.9",
    definition: {
      type_id: "vtune"
    },
    target: {
      type_id: "",
      app: "",
      app_working_dir: "",
      app_params: "",
      target_process: "",
      target_pid: ""
    }
  }
};

// This will be move to an API till then will use this mapping.
export const reportsByAnalysisType = {
  MemoryConsumption: ["callstacks", "hotspots", "top-down"],
  InputOutputPlatformLevelMetrics: [
    "summary",
    "callstacks",
    "gprof-cc",
    "hotspots",
    "hw-events",
    "top-down",
    "vectspots"
  ],
  HotspotHardwareSampling: [
    "summary",
    "callstacks",
    "gprof-cc",
    "hotspots",
    "hw-events",
    "top-down",
    "vectspots"
  ],
  PerformanceSnapshot: ["summary", "gprof-cc", "hw-events", "vectspots"],
  MicroarchitectureExploration: [
    "summary",
    "callstacks",
    "gprof-cc",
    "hotspots",
    "hw-events",
    "top-down",
    "vectspots"
  ],
  ThreadingHardwareSampling: [
    "summary",
    "callstacks",
    "gprof-cc",
    "hotspots",
    "hw-events",
    "top-down",
    "vectspots"
  ],
  MemoryAccess: [
    "summary",
    "callstacks",
    "gprof-cc",
    "hotspots",
    "hw-events",
    "top-down",
    "vectspots"
  ]
};
