export const availableFormInputs = {
  app: "Application Path",
  app_working_dir: "Working Directory",
  app_params: "Additional Parameters",
  iptTargetProcessType: null,
  checkProcessPID: null
};

export const availableFormInputToolTipContent = {
  app: "The absolute path to the executable you want to spawn.",
  app_working_dir: "The directory to cd into before spawning the process.",
  app_params: "(optional) Parameters to pass to the command being spawned."
};

export const profileTemplates = [
  {
    templateType: ["vtune"],
    id: "start_new_process",
    name: "Profile New Process",
    tooltip:
      "The process or script you specify will be spawned on each target node, as root, and then the profiling job will be executed against that process.",
    defaults: ["app", "app_working_dir", "app_params"]
  },
  {
    templateType: ["ebpf", "vtune"],
    id: "profileExistingProcess",
    tooltip:
      " Specify the process to analyze, by process name or process ID. Best results are achieved when only 1 process matching that name is running on the target node. Will not work if at least 1 node selected is VM type.",
    name: "Profile Existing Process",
    defaults: ["iptTargetProcessType"]
  },
  {
    templateType: ["ebpf"],
    id: "profile_system",
    tooltip: "Runs a system-wide analysis.",
    name: "Profile System",
    defaults: []
  }
];
