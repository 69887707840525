/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  GridCellCheckboxRenderer,
  gridRowSelectionStateSelector,
  useGridSelector
} from "@mui/x-data-grid-premium";

const TreeDataSelectCell = ({ apiRef, params }) => {
  const { rowNode } = params;

  const selectionLookup = useGridSelector(
    apiRef,
    gridRowSelectionStateSelector
  );

  if (rowNode.type !== "group")
    return (
      <GridCellCheckboxRenderer
        {...{
          ...params,
          onClick: (e) => {
            const wasSelected = selectionLookup.includes(rowNode.id);

            // if we're deselecting and the parent is selected, deselect the parent too
            if (wasSelected && selectionLookup.includes(rowNode.parent)) {
              apiRef.current.selectRows([rowNode.id, rowNode.parent], false);
              e.preventDefault();
            } else if (!wasSelected) {
              // if row and all its siblings are now selected, also select the parent
              const siblings = apiRef.current.getRowGroupChildren({
                groupId: rowNode.parent,
                applyFiltering: true,
                applySorting: true
              });
              const shouldSelectParent = siblings?.every(
                (sibling) =>
                  sibling === rowNode.id || selectionLookup.includes(sibling)
              );
              if (shouldSelectParent) {
                apiRef.current.selectRows([rowNode.id, rowNode.parent]);
                e.preventDefault();
              }
            }
          }
        }}
      />
    );

  const children = apiRef.current.getRowGroupChildren({
    groupId: rowNode.id,
    applyFiltering: false,
    applySorting: true
  });

  const indeterminate =
    children?.some((child) => selectionLookup.includes(child)) &&
    children?.some((child) => !selectionLookup.includes(child));

  const checked = children?.every((child) => selectionLookup.includes(child));

  const extraData = {
    ...params,
    disabled: false,
    onClick: (e) => {
      if (rowNode.type === "group") {
        if (children) {
          apiRef.current.selectRows(
            [rowNode.id, ...children],
            indeterminate || !checked
          );
        }
        e.preventDefault();
      }
    },
    indeterminate,
    checked
  };

  return <GridCellCheckboxRenderer {...extraData} />;
};
export default TreeDataSelectCell;
