import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridColumnIcon,
  GridPreferencePanelsValue
} from "@mui/x-data-grid-premium";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/material";
import PageSizeSelector from "./PageSizeSelector";
import { tableSettingsActions } from "../../store/TableSettingsSlice";

const DataTableHeaderToolbar = ({
  optionsCallback,
  optionsEnabled = false,
  showSearch = true,
  setPanelAnchorEl,
  tabs,
  disablePageSizeSelector = false
}) => {
  return (
    <GridToolbarContainer>
      <Stack sx={{ width: "100%" }}>
        <Stack direction="row" justifyContent="flex-end" spacing="20px">
          <Box sx={{ flex: 1 }}>{tabs}</Box>
          {!disablePageSizeSelector && <PageSizeSelector />}
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            disabled={!optionsEnabled}
            sx={{
              display: optionsCallback ? "flex" : "none",
              width: "96px"
            }}
            onClick={optionsCallback}
          >
            Options
          </Button>
        </Stack>
        {showSearch && (
          <Stack direction="row" ref={setPanelAnchorEl}>
            <GridToolbarQuickFilter
              debounceMs={500}
              InputProps={{ disableUnderline: true }}
              sx={{ flex: 1 }}
            />
            <GridToolbarFilterButton
              slotProps={{
                button: {
                  sx: {
                    backgroundColor: "#F5F5F5",
                    color: "#0000008f",
                    borderTop: "1px solid #0000001F",
                    borderRight: "1px solid #0000001F",
                    p: "4px 0px 4px 10px"
                  }
                }
              }}
            />
          </Stack>
        )}
      </Stack>
    </GridToolbarContainer>
  );
};

export const dataTableHeaderToolbarGetter = ({
  showSearch,
  optionsCallback,
  optionsEnabled,
  setPanelAnchorEl,
  tabs,
  disablePageSizeSelector
}) => {
  return (
    <DataTableHeaderToolbar
      optionsCallback={optionsCallback}
      optionsEnabled={optionsEnabled}
      showSearch={showSearch}
      setPanelAnchorEl={setPanelAnchorEl}
      tabs={tabs}
      disablePageSizeSelector={disablePageSizeSelector}
    />
  );
};

export const generateTableManagementMenuItems = (
  handleClose,
  gridApiRef,
  defaultTableState,
  dispatch,
  tableId
) => {
  return [
    <MenuItem
      onClick={() => {
        handleClose();
        gridApiRef.current.showPreferences(GridPreferencePanelsValue.columns);
      }}
    >
      <GridColumnIcon color="primary" /> Manage columns
    </MenuItem>,
    <MenuItem
      onClick={() => {
        handleClose();
        gridApiRef.current.restoreState(defaultTableState);
        const currentState = gridApiRef.current.exportState();
        dispatch(
          tableSettingsActions.saveTableState({
            id: tableId,
            tableState: JSON.stringify(currentState)
          })
        );
      }}
    >
      <RestartAltIcon color="primary" /> Reset view to defaults
    </MenuItem>
  ];
};

export default DataTableHeaderToolbar;
