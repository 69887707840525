import React from "react";
import Grid from "@mui/material/Grid";

import { useSelector } from "react-redux";
import WTPKInput from "./ReusableFormComponents/WTPKInput";
import WTPKSelect from "./ReusableFormComponents/WTPKSelect";
import WTPKSwitch from "./ReusableFormComponents/WTPKSwitch";
import { isItInRange } from "../../screens/Analysis/LaunchAnalysis/LaunchAnalysisValidation";

const HardwareSampling = () => {
  const formInputs = useSelector((state) => state.customAnalysisForms.value);
  const validationSamplingInterval = (e, dispatch, action) => {
    isItInRange(
      dispatch,
      e.target.value,
      e.target.id,
      action,
      1,
      1000,
      "Invalid value, please pick a value between 1 and 1000."
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <WTPKInput
          id="sampling_interval"
          label="CPU sampling interval, ms"
          initialValue="1"
          inputType="number"
          min="1"
          max="1000"
          required
          validation={validationSamplingInterval}
        />
      </Grid>
      <Grid item xs={12}>
        <WTPKSwitch
          id="enable_stack_collection"
          label="Collect stacks"
          defaultChecked={formInputs.enable_stack_collection || true}
        />
      </Grid>
      {formInputs.enable_stack_collection && (
        <Grid item xs={6}>
          <WTPKSelect
            id="stack_size"
            label="Stack size, in bytes"
            options={[
              { optionText: "Unlimited", optionValue: "0" },
              { optionText: "1024", optionValue: "1024" },
              { optionText: "2048", optionValue: "2048" },
              { optionText: "4096", optionValue: "4096" }
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default HardwareSampling;
