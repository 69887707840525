import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth } from "react-oidc-context";
import { useSnackbar } from "notistack";
import { inviteUser } from "../../appServices/UserManagement.service";
import { getRoleIdOfName } from "../../appServices/role";

const InviteUserDialog = (props) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [responseMessage, setResponseMessage] = useState(null);
  const [pending, setPending] = useState(false);
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleInviteUser = async () => {
    if (!pending) {
      setPending(true);
      const response = await inviteUser(auth, email, getRoleIdOfName(role));
      if (response.tenant_invitation) {
        enqueueSnackbar("Invite sent successfully!", { variant: "success" });
        props.close();
      }
      if (response.name === "AxiosError") {
        setResponseMessage(response.response.data);
      } else {
        setResponseMessage(response.name);
      }
      setPending(false);
    }
  };

  const isEmailValid = email.match(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i
  );
  const isEmailDomainAllowed = props.allowedDomains.some((domain) => {
    return email.includes(domain);
  });

  const getEmailHelperText = () => {
    if (email.length > 0) {
      if (!isEmailValid) {
        return "Invalid email address";
      }
      if (!isEmailDomainAllowed) {
        return `Email domain not allowed, valid domains: ${props.allowedDomains}`;
      }
    }
    return `Valid domains: ${props.allowedDomains}`;
  };

  return (
    <Dialog open={props.open} onClose={props.close}>
      <DialogTitle>Invite a user</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Invite a user by email and assign the type of user they are.
        </DialogContentText>
        <FormControl fullWidth sx={{ marginBottom: 2 }} required>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            error={email.length > 0 && (!isEmailValid || !isEmailDomainAllowed)}
            helperText={getEmailHelperText()}
          />
        </FormControl>
        <FormControl fullWidth required>
          <InputLabel id="user-type-select-label">
            Select: Type of user
          </InputLabel>
          <Select
            required
            labelId="user-type-select-label"
            id="user-type-select"
            value={role}
            label="Select: Type of user"
            name="type of user"
            onChange={handleRoleChange}
            fullWidth
          >
            <MenuItem value="Viewer">Viewer</MenuItem>
            <MenuItem value="Editor">Editor</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
          </Select>
        </FormControl>
        {responseMessage && (
          <Typography
            sx={{ mt: 1 }}
            align="center"
            color="error"
            fontWeight="501"
          >
            {responseMessage}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ px: "24px", pt: 0 }}>
        <Button variant="outlined" onClick={props.close}>
          Cancel
        </Button>
        <LoadingButton
          loading={pending}
          variant="contained"
          fullWidth
          onClick={handleInviteUser}
          disabled={
            pending ||
            !(role && email.length > 0 && isEmailValid && isEmailDomainAllowed)
          }
        >
          <span>Invite</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserDialog;
