import React from "react";
import { useDispatch } from "react-redux";
import { Popover, Stack } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import SecurityIcon from "@mui/icons-material/Security";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SignpostIcon from "@mui/icons-material/Signpost";
import { useNavigate } from "react-router-dom";
import PopoverMenuButton from "./PopoverMenuButton";
import { userActions } from "../../../store/UserSlice";

const InformationMenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open, openPopoverAnchor, handlePopoverClose } = props;
  return (
    <Popover
      id="btnAccountSettings"
      open={open}
      anchorEl={openPopoverAnchor}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      slotProps={{ paper: { square: true } }}
    >
      <Stack
        justifyContent="flex-start"
        alignItems="left"
        sx={{ margin: (theme) => theme.spacing(2) }}
      >
        <PopoverMenuButton
          onClick={() => {
            handlePopoverClose();
          }}
          anchorTag
          href="mailto:vtune-profiler@intel.com"
        >
          <ModeCommentIcon sx={{ marginRight: (theme) => theme.spacing(2) }} />{" "}
          Feedback
        </PopoverMenuButton>
        <PopoverMenuButton
          onClick={() => {
            handlePopoverClose();
          }}
          href="userGuide.pdf"
          target="_blank"
        >
          <HelpIcon sx={{ marginRight: (theme) => theme.spacing(2) }} /> User
          guide [pdf]
        </PopoverMenuButton>
        <PopoverMenuButton
          onClick={() => {
            handlePopoverClose();
            navigate("/security");
          }}
          href="/security"
        >
          <SecurityIcon sx={{ marginRight: (theme) => theme.spacing(2) }} />{" "}
          Security
        </PopoverMenuButton>
        <PopoverMenuButton
          onClick={() => {
            handlePopoverClose();
            navigate("information/terms-and-conditions");
          }}
          href="information/terms-and-conditions"
        >
          <AssignmentIcon sx={{ marginRight: (theme) => theme.spacing(2) }} />{" "}
          Terms and Conditions
        </PopoverMenuButton>
        <PopoverMenuButton
          onClick={() => {
            handlePopoverClose();
            navigate("information/data-collection-consent");
          }}
          href="information/data-collection-consent"
        >
          <TaskAltIcon sx={{ marginRight: (theme) => theme.spacing(2) }} /> Data
          collection consent
        </PopoverMenuButton>
        <PopoverMenuButton
          onClick={() => {
            handlePopoverClose();
            dispatch(userActions.startTour());
            navigate("/home");
          }}
        >
          <SignpostIcon sx={{ marginRight: (theme) => theme.spacing(2) }} />{" "}
          View tour
        </PopoverMenuButton>
      </Stack>
    </Popover>
  );
};

export default InformationMenu;
