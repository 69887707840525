import React from "react";
import { useSelector } from "react-redux";
import RecentDataList from "./RecentDataList";
import { useGetAnalysesQuery } from "../../store/wtpkApi";
import { convertDateTime24Hr } from "../../appServices/resuableMethods";

const parseAnalysisData = (
  data,
  analysisHistoryArray,
  onlyMine,
  currentUserEmail
) => {
  if (data !== undefined && !(data instanceof Error)) {
    let result = analysisHistoryArray.map((executionId) => {
      const analysis = data?.items?.find(
        (item) => item.execution_id === executionId
      );
      if (!analysis) {
        return null;
      }
      return {
        id: analysis.execution_id,
        name: analysis.name,
        status: analysis.status,
        date: convertDateTime24Hr(analysis.date_started * 1000),
        url: `/analyses/${analysis.execution_id}`,
        startedBy: analysis.started_by
      };
    });
    result = result.filter((item) => item !== null);

    if (onlyMine) {
      return result.filter((item) => item.startedBy === currentUserEmail);
    }
    return result;
  }
  return [];
};

const RecentlyViewedAnalyses = ({ onlyMine }) => {
  const currentTenantId = useSelector((state) => state.user.current_tenant_id);
  const analysisHistory = useSelector(
    (state) => state.user.viewedAnalysisHistory
  );
  const currentUserEmail = useSelector(
    (state) => state.user.authUser?.profile?.email
  );

  const { data, isSuccess } = useGetAnalysesQuery(undefined, {
    skip: !currentTenantId
  });

  const formattedRowData = parseAnalysisData(
    data,
    analysisHistory ?? [],
    onlyMine,
    currentUserEmail
  );

  return (
    <RecentDataList
      title={
        onlyMine ? "My recently viewed analyses " : "Recently viewed analyses"
      }
      formattedRowData={formattedRowData}
      isLoading={!isSuccess}
    />
  );
};

export default RecentlyViewedAnalyses;
