/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  tableStates: {}
};

export const tableSettingsSlice = createSlice({
  name: "tableSettings",
  initialState,
  reducers: {
    reset: () => initialState,

    saveTableState: (state, action) => {
      const { id, tableState } = action.payload;
      state.tableStates[id] = tableState;
      // console.log("saved table state: ", JSON.parse(state.tableStates[id]));
    }
  }
});

export const tableSettingsActions = tableSettingsSlice.actions;
