import Axios from "axios";
import { json } from "react-router-dom";
import { reduxStore } from "../store/ReduxStore";

const onError = (error) => {
  if (error.request && error.request.status === 401) {
    throw json(
      { message: "Unauthorized" },
      {
        status: error?.request?.status,
        statusText: "Your authentication has expired, please re-login.",
        redirect: window.location.pathname
      }
    );
  }
  if (error.request && error.request.status === 403) {
    throw json(
      { message: "Forbidden" },
      {
        status: error?.request?.status,
        statusText: "You are forbidden from accessing the requested resource."
      }
    );
  }
  if (error.request && error.request.status === 410) {
    throw json(
      { message: "GONE" },
      {
        status: 410,
        statusText:
          "Sorry, the object you requested has been deleted.  Make sure that you have the correct URL and no one has deleted it."
      }
    );
  }
  return error;
};

export const ApiGET = async (url, user, userToggle = false, tenantId = null) =>
  Axios({
    method: "get",
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: encodeURIComponent(user?.access_token),
      "X-Tenant-Id": userToggle
        ? user?.profile["custom:userId"]
        : tenantId || reduxStore.getState().user.current_tenant_id
    }
  })
    .then((response) => {
      return response.data;
    })
    .catch(onError);

// Post Request

export const ApiPost = async (url, user, inputData) =>
  Axios({
    method: "post",
    url,
    data: JSON.stringify(inputData),
    headers: {
      "Content-Type": "application/json",
      Authorization: encodeURIComponent(user?.access_token),
      "X-Tenant-Id": reduxStore.getState().user.current_tenant_id
    }
  })
    .then((response) => {
      return response.data;
    })
    .catch(onError);

// Delete Request
export const ApiDELETE = async (url, user, inputData) => {
  return Axios({
    method: "delete",
    url,
    data: {
      records: inputData
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: encodeURIComponent(user?.access_token),
      "X-Tenant-Id": reduxStore.getState().user.current_tenant_id
    }
  })
    .then((response) => {
      return response.data;
    })
    .catch(onError);
};

export const ApiPut = async (url, user, inputData, userToggle = false) => {
  return Axios({
    method: "put",
    url,
    data: JSON.stringify(inputData),
    headers: {
      "Content-Type": "application/json",
      Authorization: encodeURIComponent(user?.access_token),
      "X-Tenant-Id": userToggle
        ? user?.profile["custom:userId"]
        : reduxStore.getState().user.current_tenant_id
    }
  })
    .then((response) => {
      return response.data;
    })
    .catch(onError);
};
