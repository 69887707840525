import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AuthContext from "../../contexts/AuthContext";
import CodeCopyBox from "./components/CodeCopyBox";
import InstallationRequirements from "./components/InstallationRequirements";
import AutocompleteNodeTagging from "../../components/AutocompleteNodeTagging/AutocompleteNodeTagging";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";

const REGISTER_CONFIG = {
  1: {
    titleName: "Install Agent",
    subTitleName:
      "For each node, open a terminal window and run this command to install the agent."
  },
  2: {
    titleName: "Add tags",
    subTitleName:
      "Tags can help you organize the target nodes you register. Create a new tag or select an existing one."
  },
  3: {
    titleName: "Deploy",
    subTitleName:
      "Deploy the agent for each target node. Run this command (as root or an user with sudo permissions) on each terminal."
  },
  4: {
    titleName: "Node status",
    subTitleName:
      "Once you've run the scripts, it's safe to leave this page. You can check progress in your terminal.  If an installation is successful, the node will appear in the list on the All Nodes page.  If it fails, you may review the log output on the target node for details."
  }
};

const RegisterNodes = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const tenant = useSelector((state) => state.user.current_tenant_id);
  const APP_BASE_URL = `${window.runtime.REACT_APP_BASE_URL}`;
  const [commands, setCommands] = useState([]);
  const [showToast, setShowToast] = useState(false);

  const tags = useSelector((state) => state.nodeTags.registerNodesTags);

  const getTokenExpirationTime = new Date(
    auth.user.expires_at * 1000
  ).toLocaleString(undefined, {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric"
  });

  useEffect(() => {
    const registrationArgs = [`--tenant-id '${tenant}'`];

    if (!window.runtime.REACT_APP_DEFAULT_ENV) {
      registrationArgs.push(
        `--api-endpoint '${window.runtime.REACT_APP_API_URL}'`,
        `--ui-url '${window.runtime.REACT_APP_BASE_URL}'`
      );
    }
    const formattedTagsArray = [];
    tags?.forEach((x) => formattedTagsArray.push(`--tag '${x}'`))?.join(" ");
    const argsPart = registrationArgs.join(" ");
    setCommands([
      {
        command: `curl -o install.sh ${window.runtime.REACT_APP_BASE_URL}/install.sh`,
        id: "commandLine1"
      },
      {
        command: {
          part1: `sudo -E bash ./install.sh ${argsPart}`,
          part2: formattedTagsArray.join(" "),
          part3: `'${auth.user?.access_token}'`
        },
        id: "commandLine2"
      }
    ]);
  }, [setCommands, auth.user, tags, tenant]);

  const getCardHeader = (card) => {
    const { titleName, subTitleName } = REGISTER_CONFIG[card];
    return (
      <CardHeader
        titleTypographyProps={{
          variant: "h6",
          color: "#000000 !important"
        }}
        subheaderTypographyProps={{
          variant: "body1",
          color: "#000000 !important"
        }}
        sx={{ alignItems: "flex-start" }}
        avatar={
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: "12px",
              backgroundColor: "#653171"
            }}
          >
            {card}
          </Avatar>
        }
        title={titleName}
        subheader={<Typography>{subTitleName}</Typography>}
      />
    );
  };

  return (
    <>
      <ScreenHeader
        crumbDefs={[{ label: "Nodes", href: "/nodes" }]}
        title="Register a new node"
        description="VTaaS uses an agent to communicate with your target node and run profiling jobs. To do this, you must
        register a node."
      />
      <Stack>
        <InstallationRequirements />
        <Box sx={{ marginTop: "30px" }}>
          <Grid container>
            <Card
              sx={{
                bgcolor: "#FAFAFA",
                width: "100%",
                padding: "10px"
              }}
              variant="standard"
            >
              {getCardHeader(1)}
              <CardContent sx={{ flex: "1 0 auto", margin: "0 40px" }}>
                <CodeCopyBox
                  command={commands[0]?.command}
                  setShowToast={setShowToast}
                />
              </CardContent>
            </Card>
            <Card
              sx={{
                width: "100%",
                padding: "10px",
                marginTop: "30px"
              }}
              variant="standard"
            >
              {getCardHeader(2)}
              <CardContent sx={{ flex: "1 0 auto", margin: "0 40px" }}>
                <AutocompleteNodeTagging registerNodes />
              </CardContent>
            </Card>
            <Card
              sx={{
                bgcolor: "#FAFAFA",
                width: "100%",
                padding: "10px"
              }}
              variant="standard"
            >
              {getCardHeader(3)}
              <CardContent sx={{ flex: "1 0 auto", margin: "0 40px" }}>
                <Alert
                  severity="info"
                  sx={{
                    marginBottom: "15px"
                  }}
                >
                  The installation script will download and install the packages
                  described above, and then use the attached key to register the
                  target node with Performance Profiler. Once installation is
                  complete, the target node(s) will be available to profile with
                  Performance Profiler.
                </Alert>
                <Alert
                  severity="info"
                  sx={{
                    marginBottom: "15px"
                  }}
                >
                  This command can be reused to register multiple nodes until{" "}
                  {getTokenExpirationTime}. After that time, refresh this page
                  to get a new registration token.
                </Alert>
                <CodeCopyBox
                  command={commands[1]?.command}
                  heightToggle
                  setShowToast={setShowToast}
                />
              </CardContent>
            </Card>
            <Card
              sx={{
                padding: "10px",
                marginTop: "30px",
                width: "100%"
              }}
              variant="standard"
            >
              {getCardHeader(4)}
            </Card>
            <Card
              variant="standard"
              sx={{
                padding: "10px",
                width: "100%",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Button
                variant="outlined"
                onClick={() => navigate("/nodes")}
                id="RegisterNodesAllNodesButton"
                sx={{ fontWeight: "medium" }}
              >
                VIEW ALL NODES
              </Button>
              <Link
                underline="none"
                color="primary"
                href={`${APP_BASE_URL}/userGuide.pdf`}
                target="_blank"
                rel="noreferrer"
                component="a"
                id="RegisterNodesUserGuideLink"
                sx={{ paddingLeft: "20px", fontWeight: "medium" }}
              >
                USER GUIDE
              </Link>
            </Card>
          </Grid>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={showToast}
          key="top-right"
        >
          <Alert severity="info" icon={<ContentCopyIcon />}>
            Command copied to clipboard!
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default RegisterNodes;
