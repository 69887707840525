import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SvgResult from "./SVGResults";
import HtmlResult from "./HTMLResults";

const AnalysisResult = ({ result, outputs }) => {
  const [expanded, setExpanded] = React.useState("panel1");
  const resultsData = outputs.data;

  const htmlScripts = [
    "tcplife",
    "ext4slower",
    "execsnoop",
    "opensnoop",
    "tcpretrans",
    "biosnoop",
    "cachestat",
    "runqlat",
    "syscount",
    "biolatency"
  ];

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h5">Result</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {result.node_execution_id !== undefined && (
            <section>
              {result?.definition.type_id === "ebpf" &&
                htmlScripts.indexOf(result?.definition.script_name) < 0 && (
                  <SvgResult result={result} />
                )}
              {result?.definition.type_id === "ebpf" &&
                htmlScripts.indexOf(result?.definition.script_name) >= 0 && (
                  <HtmlResult result={result} />
                )}
            </section>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h5">Output</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "#FAFAFA"
            }}
          >
            <CardContent>
              <Typography variant="body1" data-testid="Logs">
                {resultsData}
              </Typography>
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AnalysisResult;
