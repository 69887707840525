import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack, Switch, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SpokeIcon from "@mui/icons-material/Spoke";
import RecentlyRunAnalyses from "../../components/RecentDataWidgets/RecentlyRunAnalyses";
import RecentlyRegisteredNodes from "../../components/RecentDataWidgets/RecentlyRegisteredNodes";
import RecentlyViewedAnalyses from "../../components/RecentDataWidgets/RecentlyViewedAnalyses";
import RecentlyUsedNodes from "../../components/RecentDataWidgets/RecentlyUsedNodes";
import { userActions } from "../../store/UserSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const showOnlyMyData = useSelector(
    (state) => state.user.dashboardShowOnlyMine
  );

  const onToggleShowOnlyMyData = () => {
    dispatch(userActions.setDashboardShowOnlyMine(!showOnlyMyData));
  };

  return (
    <Stack sx={{ borderTop: "1px solid var(--grey-300, #E0E0E0)" }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          startIcon={<PlayArrowIcon />}
          sx={{ width: "124px" }}
          href="/analyses"
        >
          Analyses
        </Button>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography
            variant={showOnlyMyData ? "subtitle2" : "body2"}
            component={showOnlyMyData ? "subtitle2" : "body2"}
            sx={{ fontWeight: showOnlyMyData ? "medium" : "normal" }}
          >
            Only mine
          </Typography>
          <Switch
            onChange={onToggleShowOnlyMyData}
            checked={!showOnlyMyData}
            color="default"
          />
          <Typography
            variant={showOnlyMyData ? "body2" : "subtitle2"}
            component={showOnlyMyData ? "body2" : "subtitle2"}
            sx={{ fontWeight: showOnlyMyData ? "normal" : "medium" }}
          >
            All users
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={{ sm: "column", md: "row" }}
        spacing={3}
        sx={{ mb: "16px" }}
      >
        <RecentlyRunAnalyses onlyMine={showOnlyMyData} />
        <RecentlyViewedAnalyses onlyMine={showOnlyMyData} />
      </Stack>
      <Button startIcon={<SpokeIcon />} sx={{ width: "96px" }} href="/nodes">
        Nodes
      </Button>
      <Stack direction={{ sm: "column", md: "row" }} spacing={3}>
        <RecentlyUsedNodes />
        <RecentlyRegisteredNodes />
      </Stack>
    </Stack>
  );
};

export default Dashboard;
