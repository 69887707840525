import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CodeCopyBox = (props) => {
  const copyCommand = (e) => {
    e.preventDefault();
    props.setShowToast(true);
    setTimeout(() => {
      props.setShowToast(false);
    }, "1500");
    if (props.heightToggle) {
      navigator.clipboard.writeText(
        props.command.part2 === ""
          ? `${props.command.part1} ${props.command.part3}`
          : `${props.command.part1} ${props.command.part2} ${props.command.part3}`
      );
    } else {
      navigator.clipboard.writeText(props.command);
    }
  };

  return (
    <Box
      sx={{
        height: props.heightToggle ? "100%" : "auto",
        backgroundColor: "#F5F5F5",
        border: "solid 1px #CCCCCC",
        borderRadius: "5px",
        padding: "5px 0"
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid
          item
          sx={{
            maxWidth: "92%",
            paddingLeft: (theme) => theme.spacing(2),
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography
            variant="p"
            sx={{
              wordWrap: "break-word",
              wordBreak: "break-all",
              fontSize: "14px"
            }}
            id={
              props.heightToggle
                ? "RegisterNodesInstallationCommand"
                : "RegisterNodeDownloadCommand"
            }
          >
            {props.heightToggle ? (
              <>
                {props.command?.part1}{" "}
                <Box
                  component="span"
                  sx={{
                    color: (theme) => theme.palette.primary.main
                  }}
                >
                  {props.command?.part2}{" "}
                </Box>
                {props.command?.part3}
              </>
            ) : (
              props.command
            )}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="copy"
            size="small"
            data-testid="copyIconButton"
            id={
              props.heightToggle
                ? "copyIconButtonForSecondCommand"
                : "copyIconButtonForFirstCommand"
            }
            onClick={(e) => copyCommand(e)}
          >
            <ContentCopyIcon sx={{ width: "20px", height: "20px" }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CodeCopyBox;
