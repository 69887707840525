/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasAcceptedTermsAndConditions: null,
  hasAcceptedDataCollection: null,
  hasError: false
};

export const termsAndConditionsSlice = createSlice({
  name: "termsAndConditions",
  initialState,
  reducers: {
    resetToInitialState: () => initialState,
    setTermsAndConditions: (state, action) => {
      state.hasAcceptedTermsAndConditions = action.payload;
    },
    setDataCollection: (state, action) => {
      state.hasAcceptedDataCollection = action.payload;
    },
    setHasError: (state, action) => {
      state.hasError = action.payload;
    }
  }
});

export const termsAndConditionsActions = termsAndConditionsSlice.actions;
