import React, { useState } from "react";
import { Button, Box, CircularProgress } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ViewAnalysisButton = ({
  executionId,
  disabled = false,
  id = null,
  actionFrom
}) => {
  const [loading, setLoading] = useState(false);
  const handleButtonClick = async () => {
    if (!loading) {
      setLoading(true);
    }
  };
  return (
    <Box sx={{ position: "relative" }}>
      <Button
        id={id ?? `view-execution-${executionId}`}
        variant={actionFrom === "allAnalysis" ? "text" : "contained"}
        disabled={loading || disabled}
        href={`/analyses/${executionId}`}
        onClick={handleButtonClick}
        color="primary"
      >
        {actionFrom === "allAnalysis" ? null : (
          <VisibilityIcon sx={{ mr: 1 }} />
        )}
        View
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          color="primary"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px"
          }}
        />
      )}
    </Box>
  );
};

export default ViewAnalysisButton;
