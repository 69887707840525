/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  useRef
} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { darken, lighten, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { useSelector, useDispatch } from "react-redux";
import {
  ParseKeyToCmdLine,
  customVtuneTemplate
} from "../utils/AnalysisVtuneParser";
import * as APIServices from "../../../../appServices/axiosService";
import AuthContext from "../../../../contexts/AuthContext";
import { analysisTemplateActions } from "../../slices/LaunchAnalysisSlices";
import AnalysisCustomTemplateForm from "./AnalysisCustomTemplateForm";
import LaunchAnalysisCardHeader from "./LaunchAnalysisCardHeader";
import { CurrentSetOfAnalyzers } from "../../../../components/AnalysisTypes/AnalyzersDefinitions";
import { customAnalysisFormsActions } from "../../../../components/AnalysisTypes/Slices/CustomAnalysisForms";
import {
  isItEmpty,
  isItInRange,
  validateCmdLineIsEqual
} from "../LaunchAnalysisValidation";

const apiBaseUrl = window.runtime.REACT_APP_API_URL;
const vtuneBaseUrl = window.runtime.REACT_APP_VTUNE_BASE_URL;

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}));

export const loader = async (auth) => {
  const response = await APIServices.ApiGET(
    `${apiBaseUrl}/template`,
    auth.user
  );
  return response;
};

const AnalysisTypeCard = () => {
  const [openModal, setOpenModal] = useState(false);
  const [activeDurationUnit, setActiveDurationUnit] = useState("Seconds");
  const [durationMaxValue, setDurationMaxValue] = useState(43200);
  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const auth = useContext(AuthContext);
  const tenant = useSelector((state) => state.user.current_tenant_id);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const vTuneCommandLine = useSelector(
    (state) => state.analysis.vTuneCommandLine
  );
  const cmdObj = useSelector((state) => state.analysis.cmdObj);
  const iptAnalysisName = useSelector(
    (state) => state.analysis.iptAnalysisName
  );
  const iptAnalysisDuration = useSelector(
    (state) => state.analysis.iptAnalysisDuration
  );

  const [analysisTemplateOptions, setAnalysisTemplateOptions] = useState([]);
  const selectedAnalysisTemplate = useSelector(
    (state) => state.analysis.selectedTemplate
  );
  const selectedAnalysisTarget = useSelector(
    (state) => state.analysis.analysisTarget
  );

  const onChangeFormInputHandler = (event) => {
    dispatch(
      analysisTemplateActions.setAnalysisProp({
        id: event.target.id,
        keyToUpdate: "value",
        valueReceived: event.target.value
      })
    );
  };

  const getAndSetAnalysisOptions = useCallback(async () => {
    const analysisTypes = [
      customVtuneTemplate,
      ...Object.entries(CurrentSetOfAnalyzers)
        .filter(([, value]) => value.component !== undefined)
        .map(([templateId, templateInfo]) => {
          return {
            template_id: templateId,
            name: templateInfo.title,
            description: templateInfo.description,
            icon: templateInfo?.icon,
            group_by: templateInfo?.group_by,
            type: "vtune",
            definition: {
              type_id: templateInfo.type_id
            },
            vtune_tree_position: templateInfo.vtune_tree_position
          };
        })
      // ...(loadedTemplates?.items ?? [])
    ];
    analysisTypes.sort((a, b) => {
      if (a.type !== b.type) {
        if (a.type === "vtune" && b.type !== "vtune") {
          return -1;
        }
        if (b.type === "vtune") {
          return 1;
        }
      }
      if (a.type === "vtune" && b.type === "vtune") {
        if (a.vtune_tree_position < b.vtune_tree_position) {
          return -1;
        }
        if (a.vtune_tree_position > b.vtune_tree_position) {
          return 1;
        }
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setAnalysisTemplateOptions(analysisTypes);
  }, [auth.user]);

  const validateDuration = (e, durationUnit = activeDurationUnit) => {
    let targetId = "iptAnalysisDuration";
    let targetValue = iptAnalysisDuration.value;
    if (e) {
      targetId = e.target.id;
      targetValue = e.target.value;
    }
    let errMessage = "Invalid value, please pick a number between 1 and 43200";
    let min = 1;
    let max = 43200;
    if (durationUnit === "Hours") {
      errMessage = "Invalid value, please pick a number between 1 and 12";
      min = 1;
      max = 12;
    } else if (durationUnit === "Minutes") {
      errMessage = "Invalid value, please pick a number between 1 and 720";
      min = 1;
      max = 720;
    }
    isItInRange(
      dispatch,
      targetValue,
      targetId,
      analysisTemplateActions.setValidation,
      min,
      max,
      errMessage
    );
  };

  const handleDropdownDuration = (e) => {
    const durationUnit = e.target.value;
    let maxDuration = 12;
    if (durationUnit === "Minutes") {
      maxDuration = 720;
    } else if (durationUnit === "Seconds") {
      maxDuration = 43200;
    }
    setDurationMaxValue(maxDuration);
    setActiveDurationUnit(durationUnit);
    dispatch(
      analysisTemplateActions.setAnalysisProp({
        inputID: "iptAnalysisDurationUnit",
        keyToChange: "value",
        value: durationUnit
      })
    );
    validateDuration("", durationUnit);
  };

  const handleDurationChange = (e) => {
    validateDuration(e);
    if (vTuneCommandLine?.value !== null && cmdObj !== null) {
      const cmdlineChanged = ParseKeyToCmdLine(
        vTuneCommandLine.value,
        "duration",
        e.target.value,
        dispatch
      );
      if (cmdlineChanged !== -1)
        validateCmdLineIsEqual(
          dispatch,
          cmdObj?.lifecycle?.duration,
          e.target.value
        );
    }

    onChangeFormInputHandler(e);
  };

  const setAnalysisDescription = (value) => {
    if (!value || value === null) {
      dispatch(
        analysisTemplateActions.setAnalysisProp({
          inputID: "selectedTemplate",
          keyToChange: "value",
          value: null
        })
      );
      return;
    }
    const templateId = value.template_id;
    const template = analysisTemplateOptions.find(
      (item) => item.template_id === templateId
    );
    if (template) {
      dispatch(customAnalysisFormsActions.resetCustomForm());
      dispatch(
        analysisTemplateActions.setAnalysisProp({
          inputID: "selectedTemplate",
          keyToChange: "value",
          value: template
        })
      );

      if (
        selectedAnalysisTarget.value !== null &&
        template.definition.type_id === "ebpf" &&
        selectedAnalysisTarget.value.id === "start_new_process"
      ) {
        dispatch(
          analysisTemplateActions.setValidation({
            key: "selectedTemplate",
            isValid: false,
            message:
              "Analysis Template is incompatible with Profile Target (What)"
          })
        );
        dispatch(
          analysisTemplateActions.setValidation({
            keyToUpdate: "how",
            isValid: false,
            message: null
          })
        );
      } else {
        dispatch(
          analysisTemplateActions.setValidation({
            keyToUpdate: ["what", "how"],
            isValid: true,
            message: null
          })
        );
        dispatch(
          analysisTemplateActions.setValidation({
            keys: ["analysisTarget", "selectedTemplate"],
            isValid: null,
            message: null
          })
        );
      }
    }
  };

  const showRedirectBtn =
    selectedAnalysisTemplate.value !== null &&
    selectedAnalysisTemplate.value.template_id ===
      "19275d0a-a413-4440-ba25-88f1ceaebf33";
  const showCustomForm =
    selectedAnalysisTemplate.value !== null &&
    selectedAnalysisTemplate.value.template_id in CurrentSetOfAnalyzers &&
    !CurrentSetOfAnalyzers[selectedAnalysisTemplate.value.template_id].no_args;
  useEffect(() => {
    getAndSetAnalysisOptions();
  }, []);

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="vtune-alert-title">
          You are about to navigate away from this page
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="vtune-alert-description">
            If you click &quot;Continue&quot;, you will be navigated to another
            VTune website to configure the analysis. Note that your changes in
            this form will not be transferred.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} alignContent="center">
            <Button
              anchorTag
              href={`${vtuneBaseUrl}editor/${tenant}/ui`}
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Continue
            </Button>
            <Button variant="contained" color="info" onClick={handleClose}>
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Card id="SelectAnalysisTypeCard">
        <LaunchAnalysisCardHeader
          stepCount="3"
          title="How"
          subTitle="Select the type of profiling job."
        />
        <CardContent sx={{ padding: "0 16px" }}>
          <Stack spacing={2} sx={{ margin: "0 24px", maxWidth: "800px" }}>
            <Box id="boxHowAnalysisTemplate">
              <FormControl
                fullWidth
                required
                error={
                  selectedAnalysisTemplate.isValid !== null &&
                  !selectedAnalysisTemplate.isValid
                }
              >
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "100%" }}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="fm-slt-analysis-template"
                      autoHighlight
                      groupBy={(option) => {
                        return option.group_by;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.template_id === value.template_id;
                      }}
                      onChange={(_e, value) => {
                        isItEmpty(
                          dispatch,
                          value,
                          "selectedTemplate",
                          analysisTemplateActions.setValidation
                        );
                        setAnalysisDescription(value);
                      }}
                      options={
                        selectedAnalysisTemplate?.value?.template_id ===
                        "19275d0a-a413-4440-ba25-88f1ceaebf33"
                          ? analysisTemplateOptions
                          : analysisTemplateOptions.slice(0, -1)
                      }
                      value={selectedAnalysisTemplate.value}
                      clearOnEscape
                      renderGroup={(params) => {
                        return (
                          <li key={params.key}>
                            <GroupHeader>{params.group}</GroupHeader>
                            {params.children}
                          </li>
                        );
                      }}
                      getOptionLabel={(option) =>
                        `${option?.group_by} ${
                          option?.name || "Custom Template"
                        }` || ""
                      }
                      renderOption={(props, option) => {
                        const Icon = option.icon;
                        return (
                          <ul {...props} id={option.template_id}>
                            <Stack>
                              <Stack direction="row" alignItems="center">
                                <Icon />
                                <Typography variant="h6" marginLeft={2}>
                                  {option.name || ""}
                                </Typography>
                                {option.name === "Custom Template" && (
                                  <Typography
                                    variant="h6"
                                    fontWeight="501"
                                    color="red"
                                    marginLeft={2}
                                  >
                                    BETA
                                  </Typography>
                                )}
                              </Stack>
                              <Typography variant="subtitle2" marginLeft={5}>
                                {option.description || ""}
                              </Typography>
                            </Stack>
                          </ul>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            selectedAnalysisTemplate.isValid !== null &&
                            !selectedAnalysisTemplate.isValid
                          }
                          name="analysis-template-selector"
                          label={
                            selectedAnalysisTemplate.value === null
                              ? "Analysis template"
                              : ""
                          }
                          inputRef={inputRef}
                          InputLabelProps={{
                            shrink: false, // Disables the label docking to border
                            sx: {
                              color: (theme) =>
                                selectedAnalysisTemplate.value !== null
                                  ? "default"
                                  : theme.palette.darkGreyHeaderText.main,
                              fontSize:
                                selectedAnalysisTemplate.value !== null ||
                                document.activeElement === inputRef.current
                                  ? "1rem"
                                  : "18px",
                              fontWeight:
                                selectedAnalysisTemplate.value !== null
                                  ? "default"
                                  : "500"
                            }
                          }}
                          inputProps={{
                            ...params.inputProps
                          }}
                        />
                      )}
                    />
                  </Box>
                </Stack>

                {selectedAnalysisTemplate.isValid !== null &&
                  !selectedAnalysisTemplate.isValid && (
                    <FormHelperText>
                      {selectedAnalysisTemplate.statusMessage ??
                        "Please select a valid Analysis."}
                    </FormHelperText>
                  )}
              </FormControl>
            </Box>
            {selectedAnalysisTemplate.value !== null && (
              <Typography
                variant="body1"
                marginX={(theme) => theme.spacing(2)}
                whiteSpace="pre-line"
              >
                {selectedAnalysisTemplate.value !== null
                  ? selectedAnalysisTemplate.value.description
                  : ""}
              </Typography>
            )}
            {showRedirectBtn && (
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleClickOpen}
                  >
                    Define Analysis (BETA)
                  </Button>
                </Grid>
              </Grid>
            )}
            <Box id="boxHowAnalysisTemplateInputs">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    size="small"
                    fullWidth
                    required
                    error={
                      iptAnalysisName.isValid !== null &&
                      !iptAnalysisName.isValid
                    }
                  >
                    <InputLabel htmlFor="iptAnalysisName">
                      Analysis Name
                    </InputLabel>
                    <Tooltip
                      placement="top"
                      title="Friendly name to associate with this profiling job."
                      arrow
                    >
                      <OutlinedInput
                        id="iptAnalysisName"
                        onChange={(e) => {
                          isItEmpty(
                            dispatch,
                            e.target.value,
                            e.target.id,
                            analysisTemplateActions.setValidation
                          );

                          onChangeFormInputHandler(e);
                        }}
                        value={
                          iptAnalysisName.value !== null
                            ? iptAnalysisName.value
                            : ""
                        }
                        error={
                          iptAnalysisName.isValid !== null &&
                          !iptAnalysisName.isValid
                        }
                        name="AnalysisName"
                        label="Analysis Name"
                        aria-describedby="iptAnalysisName-helper-text"
                      />
                    </Tooltip>
                    {iptAnalysisName.isValid !== null &&
                      !iptAnalysisName.isValid && (
                        <FormHelperText id="iptAnalysisName-helper-text">
                          {iptAnalysisName.statusMessage
                            ? iptAnalysisName.statusMessage
                            : "Invalid Input"}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" whiteSpace="pre-line">
                    Automatically stop collection after
                  </Typography>
                </Grid>
                <Grid item flex="1">
                  <FormControl
                    size="small"
                    fullWidth
                    error={
                      iptAnalysisDuration.value &&
                      iptAnalysisDuration.isValid !== null &&
                      !iptAnalysisDuration.isValid
                    }
                  >
                    <Tooltip
                      placement="top"
                      title="Time, to execute profiling job."
                      arrow
                    >
                      <OutlinedInput
                        id="iptAnalysisDuration"
                        type="number"
                        onChange={(e) => handleDurationChange(e)}
                        inputProps={{
                          min: 1,
                          max: durationMaxValue
                        }}
                        value={
                          iptAnalysisDuration.value !== null
                            ? iptAnalysisDuration.value
                            : null
                        }
                        name="AnalysisDuration"
                        label=""
                        aria-describedby="iptAnalysisDuration-helper-text"
                      />
                    </Tooltip>
                    {iptAnalysisDuration.value &&
                      iptAnalysisDuration.isValid !== null &&
                      !iptAnalysisDuration.isValid && (
                        <FormHelperText id="iptAnalysisDuration-helper-text">
                          {iptAnalysisDuration.statusMessage
                            ? iptAnalysisDuration.statusMessage
                            : "Invalid Input. Please enter a number between 1 and 43200."}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item>
                  <Select
                    id="iptAnalysisDurationUnit"
                    value={activeDurationUnit}
                    label=""
                    name="AnalysisDurationUnit"
                    onChange={(e) => handleDropdownDuration(e)}
                    sx={{
                      padding: "8.5px 14px",
                      height: "40px"
                    }}
                    size="small"
                  >
                    <MenuItem value="Seconds">Seconds</MenuItem>
                    <MenuItem value="Minutes">Minutes</MenuItem>
                    <MenuItem value="Hours">Hours</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Box>
            {vTuneCommandLine.value &&
              showRedirectBtn &&
              vTuneCommandLine.value.length > 780 && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography mr={1}>VTune Command Line</Typography>
                    {vTuneCommandLine.isValid === false && (
                      <Tooltip
                        placement="top"
                        title={vTuneCommandLine.statusMessage}
                        arrow
                      >
                        <InfoIcon color="primary" fontSize="medium" />
                      </Tooltip>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ overflowWrap: "break-word" }}>
                      {vTuneCommandLine.value
                        .split(",")
                        .join(", ")
                        .split("=")
                        .join("= ")}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
            {vTuneCommandLine.value &&
              showRedirectBtn &&
              vTuneCommandLine.value.length < 780 && (
                <Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1">
                      VTune Command Line
                    </Typography>
                    {vTuneCommandLine.isValid === false && (
                      <Tooltip
                        placement="top"
                        title={vTuneCommandLine.statusMessage}
                        arrow
                      >
                        <InfoIcon color="primary" fontSize="medium" />
                      </Tooltip>
                    )}
                  </Box>

                  <Box
                    padding={(theme) => theme.spacing(2)}
                    border={1}
                    borderColor="#c4c4c4"
                    borderRadius={(theme) => theme.spacing(0.5)}
                  >
                    <Typography
                      variant="body1"
                      marginX={(theme) => theme.spacing(2)}
                      whiteSpace="pre-line"
                    >
                      {vTuneCommandLine.value
                        .split(",")
                        .join(", ")
                        .split("=")
                        .join("= ")}
                    </Typography>
                  </Box>
                </Box>
              )}

            {showCustomForm && (
              <Box>
                <AnalysisCustomTemplateForm
                  templateName={selectedAnalysisTemplate.value.template_id}
                />
              </Box>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default AnalysisTypeCard;
