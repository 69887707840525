import React from "react";
import { useSelector } from "react-redux";
import RecentDataList, { NUM_ROWS_TO_SHOW } from "./RecentDataList";
import { useGetAnalysesQuery, useGetNodesQuery } from "../../store/wtpkApi";
import { convertDateTime24Hr } from "../../appServices/resuableMethods";

const parseData = (nodeData, analysisData) => {
  if (
    nodeData !== undefined &&
    analysisData !== undefined &&
    !(nodeData instanceof Error) &&
    !(analysisData instanceof Error)
  ) {
    // sort the analyses by most recent
    const sortedAnalyses = [...analysisData.items].sort(
      (a, b) => b.date_started - a.date_started
    );

    const recentlyUsedNodeIds = [];

    sortedAnalyses.forEach((analysis) => {
      if (recentlyUsedNodeIds.length <= NUM_ROWS_TO_SHOW) {
        analysis.node_ids.forEach((nodeId) => {
          if (
            recentlyUsedNodeIds.length < NUM_ROWS_TO_SHOW &&
            !recentlyUsedNodeIds.includes(nodeId)
          ) {
            recentlyUsedNodeIds.push(nodeId);
          }
        });
      }
    });

    const parsedNodes = recentlyUsedNodeIds.map((nodeId) => {
      const node = nodeData.nodes.find((item) => item.node_id === nodeId);
      return {
        id: nodeId,
        name: node?.computer_name || nodeId,
        status: node?.ping_status || "Unknown",
        date: node?.registration_date
          ? convertDateTime24Hr(node.registration_date * 1000)
          : "Node data not found",
        url: `/nodes/${nodeId}`
      };
    });
    return parsedNodes;
  }
  return [];
};

const RecentlyUsedNodes = () => {
  const currentTenantId = useSelector((state) => state.user.current_tenant_id);

  const { data: nodeData, isSuccess: nodesLoaded } = useGetNodesQuery(
    undefined,
    {
      skip: !currentTenantId
    }
  );

  const { data: analysisData, isSuccess: analysesLoaded } = useGetAnalysesQuery(
    undefined,
    {
      skip: !currentTenantId
    }
  );

  const formattedRowData =
    nodesLoaded && analysesLoaded ? parseData(nodeData, analysisData) : [];

  return (
    <RecentDataList
      title="Recently used nodes"
      formattedRowData={formattedRowData}
      isLoading={!(nodesLoaded && analysesLoaded)}
    />
  );
};

export default RecentlyUsedNodes;
