import React from "react";
import "../../fonts/intel-fonts.css";

const tourSteps = [
  {
    target: "body",
    placement: "center",
    content: (
      <div className="text-start intel-bodytext-font-family">
        <p>
          Think of Performance Profiler as Intel&reg; VTune&trade; Profiler in
          the cloud.
        </p>
        <p>
          We&apos;ll show you how to register new server targets (on-prem,
          cloud, anywhere) from our SaaS...
        </p>
        <p>How to execute VTune&trade; profiling jobs against these nodes...</p>
        <p>And how to view results from a central location.</p>
        <p>Let&apos;s get started!</p>
      </div>
    ),
    styles: {
      options: { width: "auto" },
      tooltipContainer: { textAlign: "left" }
    },
    disableBeacon: true
  },
  {
    target: "#anchorAllNodes",
    placement: "right",
    content: (
      <div className="text-start intel-bodytext-font-family">
        <p>Manage the servers you want to run profiling against.</p>
        <p>Register/Deregister nodes, add tags for easier tracking!</p>
      </div>
    ),
    styles: {
      options: { width: "auto" },
      tooltipContainer: { textAlign: "left" }
    },
    disableBeacon: true
  },
  {
    target: "#btnCreateAnalysis",
    placement: "right",
    content: (
      <div className="text-start intel-bodytext-font-family">
        <p>
          Configure and run Intel&reg; VTune&trade; Profiler against your nodes.
        </p>
      </div>
    ),
    styles: {
      options: { width: "auto" },
      tooltipContainer: { textAlign: "left" }
    },
    event: "hover"
  },
  {
    target: "#anchorMyAnalyses",
    placement: "right",
    content: (
      <p className="text-start intel-bodytext-font-family">
        View previously executed analyses and their results, and run new ones.
      </p>
    ),
    styles: {
      options: { width: "auto" },
      tooltipContainer: { textAlign: "left" }
    },
    event: "hover"
  },
  {
    target: "#btnInformationMenu",
    placement: "right",
    content: (
      <div className="text-start intel-bodytext-font-family">
        <p>
          Here you will find options to inform us of bugs and feature-requests
          <br /> or read detailed documentation on the Performance Profiler.
        </p>
        <p>Thanks!</p>
      </div>
    ),
    styles: {
      options: { width: "auto" },
      tooltipContainer: { textAlign: "left" }
    },
    event: "hover"
  }
];

export default tourSteps;
