import React from "react";
import { Card, CardContent, Typography, Alert } from "@mui/material";

const ErrorResult = ({ result, outputs, outputDataTest }) => {
  const DefineErrMessage = () => {
    if (result.error !== undefined && result.error !== null) {
      return (
        <Typography variant="body1">
          {result.error.name}: {result.error.description}{" "}
          {result.error.contact_email !== null &&
          result.error.contact_email !== undefined ? (
            <a href={result.error.contact_email}>Submit Ticket.</a>
          ) : null}
        </Typography>
      );
    }
    return null;
  };

  return (
    <>
      <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
        {DefineErrMessage()}
      </Alert>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "#FAFAFA"
        }}
      >
        <CardContent>
          <Typography
            variant="body1"
            gutterBottom
            style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}
          >
            {outputDataTest !== undefined ? outputDataTest.data : outputs.data}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};
export default ErrorResult;
