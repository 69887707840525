import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { analysisTemplateActions } from "../slices/LaunchAnalysisSlices";
import { useGetAnalysesQuery } from "../../../store/wtpkApi";

const ReCreateDialog = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { analysisExecutionId } = props;
  const { data: analysisExecutionResponse } = useGetAnalysesQuery();
  const execution = analysisExecutionResponse?.items.find(
    (item) => item.execution_id === analysisExecutionId
  );

  if (!execution) return null;

  const navigateToCreateAnalysis = () => {
    dispatch(analysisTemplateActions.resetAnalyisForm());
    dispatch(analysisTemplateActions.setResetAnalysisForm(true));
    navigate(`/newAnalysis?executionId=${execution?.execution_id}`);
  };

  // Remove props we don't want to display in the dialog:
  const {
    status,
    date_started: dateStarted,
    date_finished: dateFinished,
    started_by: startedBy,
    ...trimmedExecution
  } = execution;

  return (
    <Dialog
      open={props.open}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      hideBackdrop
    >
      <DialogTitle
        sx={{
          cursor: "move",
          backgroundColor: (theme) => theme.palette.primary.main,
          color: "white"
        }}
      >
        Recreate Analysis
      </DialogTitle>
      <IconButton
        aria-label="icon-close"
        onClick={() => props.handler()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white"
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          minWidth: "350px",
          minHeight: "250px"
        }}
        component="div"
      >
        <DialogContentText>
          <Typography color="black" gutterBottom>
            You are about to be redirected to Create Analysis with these
            parameters pre-loaded. Offline or deregistered nodes will be
            omitted. Press Recreate to proceed.
          </Typography>
          <pre>{JSON.stringify(trimmedExecution, null, 2)}</pre>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={navigateToCreateAnalysis}
          size="small"
          autoFocus
          color="primary"
          aria-label="submit"
        >
          Recreate
        </Button>
        <Button
          variant="contained"
          onClick={() => props.handler()}
          size="small"
          color="info"
          aria-label="visible-close"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReCreateDialog;
