import React from "react";
import { Divider, Typography, Popover, Stack } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../utils/broadcaster";
import PopoverMenuButton from "./PopoverMenuButton";

const AccountMenu = (props) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.current_role);

  const {
    open,
    openAccountPopoverAnchor,
    handleAccountPopoverClose,
    handleInviteUser,
    tenantName
  } = props;
  return (
    <Popover
      id="btnAccountSettings"
      open={open}
      anchorEl={openAccountPopoverAnchor}
      onClose={handleAccountPopoverClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      slotProps={{ paper: { square: true } }}
    >
      <Stack
        justifyContent="flex-start"
        alignItems="left"
        sx={{ margin: (theme) => theme.spacing(2) }}
      >
        <Typography>{auth?.user?.profile?.email}</Typography>
        <Typography>{tenantName}</Typography>
        <Typography>{role.name}</Typography>
        <Divider sx={{ marginY: (theme) => theme.spacing(2) }} />
        <PopoverMenuButton
          onClick={() => {
            handleAccountPopoverClose();
            navigate("/account");
          }}
          href="/account"
        >
          <AccountCircleIcon
            sx={{ marginRight: (theme) => theme.spacing(2) }}
          />{" "}
          {role.hasPermission("canAccessAdminContent")
            ? "Manage Accounts"
            : "My Account"}
        </PopoverMenuButton>
        {role.hasPermission("canAccessAdminContent") && (
          <PopoverMenuButton
            id="btnInviteUser"
            onClick={() => {
              handleInviteUser(true);
              handleAccountPopoverClose();
            }}
          >
            <PersonAddIcon sx={{ marginRight: (theme) => theme.spacing(2) }} />{" "}
            Invite a user
          </PopoverMenuButton>
        )}
        <PopoverMenuButton
          id="btnSignOut"
          onClick={() => {
            logout(auth, dispatch);
          }}
        >
          <ExitToAppOutlinedIcon
            sx={{ marginRight: (theme) => theme.spacing(2) }}
          />{" "}
          Log out
        </PopoverMenuButton>
      </Stack>
    </Popover>
  );
};

export default AccountMenu;
