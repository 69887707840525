import * as AnalysisResponse from "../screens/Analysis/AnalysisStatus/AnalysisStatus.service";
import { analysisStatusActions } from "../screens/Analysis/slices/AnalysisSlices";

export const GetAnalysisExecutionInfo = async (
  analysisExecutionid,
  user,
  dispatch,
  resultTemplateName
) => {
  const analysisStatusResponse =
    await AnalysisResponse.getAnalysisExecutionStatus(
      user,
      analysisExecutionid,
      resultTemplateName
    );
  if (analysisStatusResponse.length > 0) {
    if (
      analysisStatusResponse.find(
        (items) => items.status === "PENDING" || items.status === "RUNNING"
      )
    ) {
      dispatch(
        analysisStatusActions.setSliceKeys({
          analysisStatus: "Analysis Running.....",
          analysisTimestamp: `Started At   ${
            analysisStatusResponse[analysisStatusResponse.length - 1]
              .start_timestamp
          }`
        })
      );
    }
    dispatch(
      analysisStatusActions.setSliceKeys({
        analysisResultsTableData: analysisStatusResponse
      })
    );
  }
  return analysisStatusResponse;
};

export const didJobFail = (status) => {
  return status === "FAILED";
};

export const isCustomVTuneTemplate = (templateID) => {
  // Only custom templates have hyphens in them
  return typeof templateID === "string" && templateID.match(/-/g) !== null;
};
