/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import * as AnalysisUtils from "../utils/AnalysisFormCardUtils";
import AnalysisTargetParametersForm from "./AnalysisTargetParametersForm";
import LaunchAnalysisCardHeader from "./LaunchAnalysisCardHeader";
import { analysisTemplateActions } from "../../slices/LaunchAnalysisSlices";
import { isItEmpty } from "../LaunchAnalysisValidation";

const AnalysisTargetCard = () => {
  const dispatch = useDispatch();
  const analysisTarget = useSelector((state) => state.analysis.analysisTarget);
  const analysisTemplate = useSelector(
    (state) => state.analysis.selectedTemplate
  );
  // const cardFormWhat = useSelector((state) => state.analysis.what);
  const inputRef = useRef(null);

  const onProfileTypeChange = (value) => {
    if (!value || value === null) {
      dispatch(analysisTemplateActions.resetTargetParameterEnabled());
      dispatch(
        analysisTemplateActions.setAnalysisProp({
          inputID: "analysisTarget",
          keyToChange: "value",
          value: null
        })
      );
      return;
    }
    const inputTypeID = value.id;
    const targetProfile = AnalysisUtils.profileTemplates.find(
      (item) => item.id === inputTypeID
    );
    if (targetProfile) {
      dispatch(analysisTemplateActions.resetTargetParameterEnabled());
      dispatch(
        analysisTemplateActions.setAnalysisProp({
          inputID: "analysisTarget",
          keyToChange: "value",
          value: targetProfile
        })
      );
      targetProfile.defaults.forEach((item) => {
        dispatch(
          analysisTemplateActions.setTargetParameterIsEnabled({
            id: item,
            valueReceived: true
          })
        );
      });
      if (
        analysisTemplate.value !== null &&
        targetProfile.id === "start_new_process" &&
        analysisTemplate.value.definition.type_id === "ebpf"
      ) {
        dispatch(
          analysisTemplateActions.setValidation({
            keyToUpdate: "what",
            isValid: false,
            message: null
          })
        );
        dispatch(
          analysisTemplateActions.setValidation({
            keyToUpdate: "analysisTarget",
            isValid: false,
            message:
              "Profile Target is incompatible with Analysis Template (How)"
          })
        );
      } else {
        dispatch(
          analysisTemplateActions.setValidation({
            keyToUpdate: ["what", "how"],
            isValid: true,
            message: null
          })
        );
        dispatch(
          analysisTemplateActions.setValidation({
            keys: ["analysisTarget", "selectedTemplate"],
            isValid: null,
            message: null
          })
        );
      }
    }
  };

  return (
    <Card>
      <LaunchAnalysisCardHeader
        stepCount="2"
        title="What"
        subTitle="Select the target to profile."
      />
      <CardContent sx={{ padding: "0 16px" }}>
        <Box style={{ margin: "0 28px", maxWidth: "800px" }} id="boxWhatTarget">
          <FormControl
            fullWidth
            required
            error={analysisTarget.isValid !== null && !analysisTarget.isValid}
          >
            <Stack direction="row" alignItems="center">
              <Box sx={{ width: "100%" }}>
                <Autocomplete
                  size="small"
                  id="fm-slt-profile-type"
                  autoHighlight
                  onChange={(_e, value) => {
                    isItEmpty(
                      dispatch,
                      value,
                      "analysisTarget",
                      analysisTemplateActions.setValidation
                    );
                    onProfileTypeChange(value);
                  }}
                  options={AnalysisUtils.profileTemplates}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  clearOnEscape
                  value={analysisTarget.value}
                  getOptionLabel={(option) => option?.name || ""}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} id={option.id}>
                        <Box
                          sx={{
                            flexGrow: 1
                          }}
                        >
                          <Typography variant="h6">
                            {option?.name || ""}
                          </Typography>
                          <Typography variant="body2">
                            {option?.tooltip || ""}
                          </Typography>
                        </Box>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        analysisTarget.isValid !== null &&
                        !analysisTarget.isValid
                      }
                      size="small"
                      label={
                        analysisTarget.value === null ? "Profile target" : ""
                      }
                      name="profile-type-selector"
                      inputRef={inputRef}
                      InputLabelProps={{
                        shrink: false, // Disables the label docking to border
                        sx: {
                          color: (theme) =>
                            analysisTarget.value !== null
                              ? "default"
                              : theme.palette.darkGreyHeaderText.main,
                          fontSize:
                            analysisTarget.value !== null ||
                            document.activeElement === inputRef.current
                              ? "1rem"
                              : "18px",
                          fontWeight:
                            analysisTarget.value !== null ? "default" : "500"
                        }
                      }}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true
                      }}
                    />
                  )}
                />
              </Box>
            </Stack>

            {analysisTarget.isValid !== null && !analysisTarget.isValid && (
              <FormHelperText>
                {analysisTarget.statusMessage ??
                  "Please select a valid target."}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box sx={{ margin: "16px 28px 0", display: "flex", maxWidth: "800px" }}>
          <AnalysisTargetParametersForm />
        </Box>
      </CardContent>
    </Card>
  );
};

export default AnalysisTargetCard;
