import { Button, styled } from "@mui/material";

const PopoverMenuButton = styled(Button)(({ theme }) => ({
  variant: "text",
  width: "100%",
  justifyContent: "flex-start",
  color: theme.palette.greyText,
  textTransform: "none"
}));

export default PopoverMenuButton;
