import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import {
  useGetVTuneViewerReadinessQuery,
  useOpenVTuneViewerMutation
} from "../../../store/wtpkApi";

const VTuneResultLoader = () => {
  const location = useLocation();
  const currentTenantId = useSelector((state) => state.user.current_tenant_id);
  const nodeExecutionId = location.pathname.split("/")[2];
  const [openViewer, result] = useOpenVTuneViewerMutation();
  if (result.isUninitialized) {
    openViewer(nodeExecutionId);
  }

  const { data: readinessResponse } = useGetVTuneViewerReadinessQuery(
    nodeExecutionId,
    {
      pollingInterval: 3000
    }
  );

  if (readinessResponse?.is_ready === true) {
    if (result.data?.resource?.result_url)
      window.open(
        `${result.data?.resource?.result_url}?tenantId=${currentTenantId}`,
        "_self"
      );
  }

  return (
    <Box mt={2} pt={3}>
      <Container>
        <Card>
          <CardContent>
            <Typography
              className="lead"
              gutterBottom
              data-testid="VTuneContainer"
            >
              Preparing the analysis results. This may take a few minutes.{" "}
              <br />
              This tab will be automatically redirected to the results view.
              Feel free to continue your work in other tabs while you wait.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default VTuneResultLoader;
