/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  nodeList: [],
  recentAnalysisList: [],
  initialTableLoad: false,
  nodeInformationLoaded: false
};

export const tableDataSlice = createSlice({
  name: "tableData",
  initialState,
  reducers: {
    reset: () => initialState,

    setNodeList: (state, action) => {
      state.nodeList = action.payload;
    },
    setRecentAnalysisList: (state, action) => {
      state.recentAnalysisList = action.payload;
    },
    setInitialTableLoad: (state, action) => {
      state.initialTableLoad = action.payload;
    },
    setNodeInformationLoaded: (state, action) => {
      state.nodeInformationLoaded = action.payload;
    }
  }
});

export const tableDataActions = tableDataSlice.actions;
