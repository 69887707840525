import * as CommonMethods from "../../../appServices/resuableMethods";

const parseAndFilterAnalysisExecutions = (analysisData) => {
  let finalResponse = [];
  if (analysisData?.length > 0) {
    finalResponse = analysisData.map((item) => {
      const container = {};
      container.id = `${item.name}`;
      container.analysis_name = item.name;
      container.execution_id = item.execution_id;
      container.template_id = item.template_id;
      container.started_by = item.started_by;
      container.start_timestamp = CommonMethods.ConvertDateTime(
        item.date_started * 1000
      );
      container.target_count = item.node_ids?.length;
      container.node_ids = item.node_ids;
      container.heirarchy = [item.execution_id];
      container.status = item.status;
      container.profile_target = item.target?.type_id;
      container.profile_app_path = item.target?.app;
      container.aps_report_id = item.aps_report_id;
      container.end_timestamp = item.date_finished
        ? CommonMethods.ConvertDateTime(item.date_finished * 1000)
        : "Still running...";
      return container;
    });

    finalResponse.sort(CommonMethods.customSort);
  }
  return finalResponse;
};

export const generateChildRowsFromAnalysisRow = (analysisRow) => {
  return analysisRow.node_ids.map((nodeId) => {
    return { heirarchy: [analysisRow.execution_id, nodeId] };
  });
};

export const generateChildRowsForAllAnalysisRows = (analysisRows) => {
  return analysisRows.reduce(
    (resultArray, analysisRow) =>
      resultArray.concat(generateChildRowsFromAnalysisRow(analysisRow)),
    []
  );
};

export default parseAndFilterAnalysisExecutions;
