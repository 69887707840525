import React from "react";
import { useSelector } from "react-redux";
import RecentDataList from "./RecentDataList";
import { useGetNodesQuery } from "../../store/wtpkApi";
import { convertDateTime24Hr } from "../../appServices/resuableMethods";

const parseNodeData = (data) => {
  if (data !== undefined && !(data instanceof Error)) {
    /* if (onlyMine) {
      result = data?.items?.filter(
        (item) => item.registered_by === currentUserEmail
      );
    } else {
      result = data.items;
    } */
    const parsedItems = data.nodes.map((item) => {
      return {
        id: item.node_id,
        name: item.computer_name,
        status: item.ping_status,
        date: convertDateTime24Hr(item.registration_date * 1000),
        rawDate: item.registration_date,
        url: `/nodes/${item.node_id}`
      };
    });
    return parsedItems.sort((a, b) => b.rawDate - a.rawDate);
  }
  return [];
};

const RecentlyRegisteredNodes = () => {
  const currentTenantId = useSelector((state) => state.user.current_tenant_id);

  const { data, isSuccess } = useGetNodesQuery(undefined, {
    skip: !currentTenantId
  });

  const formattedRowData = parseNodeData(data);

  return (
    <RecentDataList
      title="Recently registered nodes"
      formattedRowData={formattedRowData}
      isLoading={!isSuccess}
    />
  );
};

export default RecentlyRegisteredNodes;
