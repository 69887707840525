import React from "react";
import { useAuth } from "react-oidc-context";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import styles from "./NoTenantScreenStyles";
import "./NoTenantScreen.css";
import LandingArt from "../../images/vtune-profiler-graphic.svg";
import { logout } from "../../utils/broadcaster";

const NoTenantScreen = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const onLogOutClick = () => {
    logout(auth, dispatch);
  };
  return (
    <Box
      height="100%"
      width="100%"
      sx={{
        background:
          "radial-gradient(48.15% 116.61% at 69.93% 69.65%, #FFFFFF 25.62%, #E9E9E9 100%)"
      }}
    >
      <Box
        position="absolute"
        right="16px"
        bottom="16px"
        top="96px"
        left="80px"
        zIndex={9999}
      >
        <Stack height="100%">
          <Stack>
            <Typography
              mb={0}
              sx={[styles.lightTitle, styles.titleSize, styles.textColor]}
              className="intel-display-font-family"
            >
              403 Error
            </Typography>
            <Typography
              sx={[styles.textColor, styles.subTitleSize, styles.lightTitle]}
              className="intel-display-font-family"
              mb={0}
            >
              You are not a member of any tenant.
            </Typography>
            <Typography
              mt={1}
              mb={0}
              className="intel-bodytext-font-family"
              sx={styles.textSize}
            >
              If your company is enrolled, request an invite from your tenant
              manager.
            </Typography>
            <Typography
              mt={1}
              mb={0}
              className="intel-bodytext-font-family"
              sx={styles.textSize}
            >
              If your company is not enrolled, contact your Intel Account
              Manager.
            </Typography>
          </Stack>
          <Stack
            sx={{
              px: 0,
              pt: 4,
              m: 0,
              justifyContent: "start",
              alignItems: "start"
            }}
          >
            <Button
              className="intel-bodytext-font-family"
              sx={[styles.landingButton, styles.boldText, styles.textSize]}
              onClick={onLogOutClick}
              id="landing-login-btn"
            >
              Log Out
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box
        className="background-notenant-image"
        sx={{
          position: "fixed",
          backgroundImage: `url(${LandingArt})`
        }}
      />
    </Box>
  );
};

export default NoTenantScreen;
