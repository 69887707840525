import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import BarChartIcon from "@mui/icons-material/BarChart";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import {
  DataGridPremium,
  GridActionsCellItem,
  useGridApiRef
} from "@mui/x-data-grid-premium";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import ViewAnalysisButton from "./ViewAnalysisButton";
import { analysisStatusActions } from "../slices/AnalysisSlices";
import DeleteAnalysisDialog from "../components/DeleteAnalysisDialog";
import parseAndFilterAnalysisExecutions, {
  generateChildRowsForAllAnalysisRows,
  generateChildRowsFromAnalysisRow
} from "./AllAnalysis.service";
import UpdateAnalysisNameModal from "../components/UpdateAnalysisNameModal";
import ReCreateDialog from "./ReCreateDialog";
import { columnDef, columnVisibilityDef } from "./AllAnalysisColumnDefs";
import {
  dataTableHeaderToolbarGetter,
  generateTableManagementMenuItems
} from "../../../components/DataTableElements/DataTableHeaderToolbar";
import PaginationButtons from "../../../components/DataTableElements/PaginationButtons";
import {
  getAPSLink,
  getAPSReport
} from "../AnalysisStatus/AnalysisStatus.service";
import ScreenHeader from "../../../components/ScreenHeader/ScreenHeader";
import { tableSettingsActions } from "../../../store/TableSettingsSlice";
import {
  useGetAnalysesQuery,
  getNodeExecutionDetailsForAnalysis,
  useGetNodesQuery
} from "../../../store/wtpkApi";
import { generateNEDictsFromAnalysisNameAndNEs } from "../../../utils/chatParams";
import TreeDataSelectColumn from "../../../components/DataTableElements/TreeDataSelectColumn";
import { analysisTemplateActions } from "../slices/LaunchAnalysisSlices";
import { displayConsistentNodeName } from "../../../appServices/resuableMethods";

const MAX_SELECTED_REPORTS = 6;

const AllAnalysis = () => {
  const auth = useAuth();
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const role = useSelector((state) => state.user.current_role);
  const [selectedAnalysis, setSelectedAnalysis] = useState();
  const userCanCreateAnalyses = role.hasPermission("canCreateAnalyses");
  const userCanDeleteAnalyses = role.hasPermission("canDeleteAnalyses");
  const userCanRenameAnalyses = role.hasPermission("canRenameAnalyses");
  const dispatch = useDispatch();
  const { data: analysisExeResult, isFetching: isFetchingAnalyses } =
    useGetAnalysesQuery(undefined, {
      pollingInterval: 60000
    });
  const { data: nodeData, isFetching: isFetchingNodes } = useGetNodesQuery(
    undefined,
    {
      pollingInterval: 300000
    }
  );
  const isFetching = isFetchingAnalyses || isFetchingNodes;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [execution, setExecution] = useState(null);
  const [showRecreate, setShowRecreate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [panelAnchorEl, setPanelAnchorEl] = useState(null);
  const dropdownMenuOpen = Boolean(anchorEl);
  const [visibleColumns, setVisibleColumns] = useState(columnVisibilityDef);
  const handleRecreateDialog = (show = false) => {
    setShowRecreate(show);
  };
  const handleOpenMenuDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenuDropdown = () => {
    setAnchorEl(null);
  };
  const [showEditModal, setShowEditModal] = useState(false);

  const untoggleableFields = ["__check__", "action"];

  const analysisRows = parseAndFilterAnalysisExecutions(
    analysisExeResult?.items
  );

  const rowData = analysisRows.concat(
    generateChildRowsForAllAnalysisRows(analysisRows)
  );

  const groupingColDef = {
    headerName: "Name",
    valueGetter: (_, row) => {
      if (row.heirarchy.length === 2) {
        const nodeDetails = nodeData?.nodes?.find(
          (node) => node.node_id === row.heirarchy[1]
        );
        return nodeDetails
          ? displayConsistentNodeName(nodeDetails).primary
          : row.heirarchy[1];
      }
      return row.analysis_name;
    },
    flex: 1,
    minWidth: 200
  };

  const getTogglableColumns = (columns) => {
    return columns
      .filter((column) => !untoggleableFields.includes(column.field))
      .map((column) => column.field);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleRowMenuDelete = (e, params) => {
    setOpenDeleteDialog(true);
    dispatch(
      analysisStatusActions.setSliceKeys({
        deletedAnalysis: [params.row.execution_id]
      })
    );
  };

  const handleClose = async () => {
    setOpenDeleteDialog(false);
  };

  const openAPSReport = async (apsReportId) => {
    const apsReportResponse = await getAPSReport(auth.user, apsReportId);
    if (apsReportResponse instanceof Error) {
      enqueueSnackbar(
        `Could not fetch the APS report: ${apsReportResponse.message}`,
        {
          variant: "error"
        }
      );
      return;
    }
    switch (apsReportResponse?.result?.aps_report_status) {
      case "PENDING":
        enqueueSnackbar(
          `APS report is still being generated.  Please try again in another minute or two.`,
          {
            variant: "info"
          }
        );
        break;
      case "READY": {
        const response = await getAPSLink(auth.user, apsReportId);
        if (response instanceof Error) {
          enqueueSnackbar(
            `Could not fetch the log details: ${response.message}`,
            {
              variant: "error"
            }
          );
        }
        if (!(response instanceof Error)) {
          window.open(response.result_url);
          enqueueSnackbar(
            "Your APS report is opening in a new tab.  If it doesn't, check your pop-up blocker.",
            {
              variant: "success"
            }
          );
        }
        break;
      }
      case "FAILED":
        enqueueSnackbar(`Sorry, the APS report generation failed.`, {
          variant: "error"
        });
        break;
      default:
        enqueueSnackbar(
          `Unknown APS report status: ${apsReportResponse.aps_report_status}`,
          {
            variant: "error"
          }
        );
    }
  };

  const allColumnDefs = [
    TreeDataSelectColumn(apiRef),
    ...columnDef,
    {
      field: "action",
      type: "actions",
      hideable: false,
      width: 150,
      align: "right",
      getActions: (params) => {
        if (params.row.heirarchy.length !== 1) {
          return [];
        }
        const actions = [
          <Grid container justifyContent="center" alignItems="center">
            <ViewAnalysisButton
              executionId={params?.id}
              actionFrom="allAnalysis"
            />
          </Grid>,
          <GridActionsCellItem
            icon={
              <BubbleChartIcon
                color={
                  params.row.status === "PENDING" ||
                  params.row.status === "RUNNING"
                    ? "inherit"
                    : "primary"
                }
              />
            }
            title="AI Chat (BETA)"
            component={Link}
            to={`/chat?ae=${encodeURI(
              window.btoa(JSON.stringify([params.row.execution_id]))
            )}`}
            target="_blank"
            disabled={
              params.row.status === "PENDING" || params.row.status === "RUNNING"
            }
          />,
          <GridActionsCellItem
            icon={<BorderColorIcon color="primary" />}
            label="Rename"
            onClick={() => {
              setShowEditModal(true);
              dispatch(
                analysisStatusActions.setSliceKeys({
                  analysisExecutionID: params.row.execution_id,
                  analysisExecutionName: params.row.analysis_name
                })
              );
            }}
            showInMenu={userCanRenameAnalyses}
            sx={{
              display: userCanRenameAnalyses ? null : "none"
            }}
          />,
          <GridActionsCellItem
            label="Recreate"
            icon={<ControlPointDuplicateIcon color="primary" />}
            onClick={async () => {
              setExecution(params.id);
              handleRecreateDialog(true);
            }}
            showInMenu={userCanCreateAnalyses}
            sx={{ display: userCanCreateAnalyses ? null : "none" }}
          />
        ];
        if (params.row.aps_report_id) {
          actions.push(
            <GridActionsCellItem
              icon={<BarChartIcon color="primary" />}
              label="Open APS Report"
              onClick={async () => openAPSReport(params.row.aps_report_id)}
              showInMenu
            />
          );
        }
        actions.push(
          <GridActionsCellItem
            icon={<DeleteIcon color="primary" />}
            label="Delete"
            onClick={(e) => {
              handleRowMenuDelete(e, params, analysisExeResult);
            }}
            showInMenu={userCanDeleteAnalyses}
            sx={{ display: userCanDeleteAnalyses ? null : "none" }}
          />
        );
        return actions;
      }
    }
  ];

  const [columnDefs] = useState([...allColumnDefs]);

  const expansionState = useRef({});
  useEffect(() => {
    apiRef.current.subscribeEvent("rowExpansionChange", (node) => {
      expansionState.current[node.id] = node.childrenExpanded;
    });
  }, [apiRef]);

  const isGroupExpandedByDefault = useCallback(
    (node) => {
      return !!expansionState.current[node.id];
    },
    [expansionState]
  );

  const deleteSelectedAnalysis = () => {
    handleCloseMenuDropdown();
    setOpenDeleteDialog(true);
    dispatch(
      analysisStatusActions.setSliceKeys({
        deletedAnalysis: selectedAnalysis
      })
    );
  };

  // Table saving

  const defaultTableState = {
    sorting: {
      sortModel: [{ field: "start_timestamp", sort: "desc" }]
    },
    columns: {
      columnVisibilityModel: columnVisibilityDef
    },
    pagination: {
      paginationModel: { pageSize: 10, page: 0 }
    }
  };

  const stateFromStore = useSelector(
    (state) => state.tableSettings.tableStates.allAnalyses
  );

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      dispatch(
        tableSettingsActions.saveTableState({
          id: "allAnalyses",
          tableState: JSON.stringify(currentState)
        })
      );
    }
  }, [apiRef, dispatch]);

  useLayoutEffect(() => {
    // handle refresh and navigating away/refreshing
    window.addEventListener("beforeunload", saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener("beforeunload", saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  // Multiselect

  const getSelectedNERows = () =>
    [...apiRef.current.getSelectedRows().values()].filter(
      (row) => row.heirarchy.length > 1
    );

  const getSelectedAERows = () =>
    [...apiRef.current.getSelectedRows().values()].filter(
      (row) => row.heirarchy.length === 1
    );

  const getTotalSelectedNERows = () => {
    // we'll assume for now if the user selects an AE without selected any of its children
    // that they want all of it's children.  This is a workaround until we implement
    // automatic selection for those
    const selectedAERows = getSelectedAERows();
    const selectedNERows = getSelectedNERows();

    // Get the list of all the parent (AEs) of selected NEs so we can skip them
    const parents = new Set();
    selectedNERows.forEach((neRow) => parents.add(neRow.heirarchy[0]));

    // for each AE, if it's not in the parents list, add all of it's children to the NE rows list
    selectedAERows.forEach((aeRow) =>
      parents.has(aeRow.heirarchy[0])
        ? null
        : selectedNERows.splice(
            selectedNERows.length,
            0,
            ...generateChildRowsFromAnalysisRow(aeRow)
          )
    );
    return selectedNERows;
  };

  const shouldDisableMultiSelectAIChat = () => {
    // api ref not initialized yet
    if (!apiRef.current.getSelectedRows) {
      return true;
    }
    const selectedRows = getTotalSelectedNERows();
    const numSelected = selectedRows.length;
    return numSelected === 0 || numSelected > MAX_SELECTED_REPORTS;
  };

  const shouldDisableMultiDelete = () => {
    return !apiRef.current.getSelectedRows || getSelectedAERows().length === 0;
  };

  const getMultiDeleteTooltipTitle = () => {
    if (shouldDisableMultiDelete()) {
      return "Select at least one analysis for deletion. (Deletion of individual node results is not supported.)";
    }
    if (getSelectedNERows().length > 0) {
      return "Note: Deletion of individual node results is not supported.";
    }
    return "";
  };

  const openAIChatForSelected = () => {
    handleCloseMenuDropdown();
    const selectedNERows = getTotalSelectedNERows();
    const promises = [];
    const neDictList = [];

    selectedNERows.forEach(async (neRow) => {
      promises.push(
        getNodeExecutionDetailsForAnalysis(neRow.heirarchy[0], dispatch).then(
          (nodeExecutions) => {
            const nodeExecution = nodeExecutions.find(
              (ne) => ne.node_id === neRow.heirarchy[1]
            );
            const aeName = apiRef.current.getRow(
              neRow.heirarchy[0]
            ).analysis_name;
            neDictList.push(
              generateNEDictsFromAnalysisNameAndNEs(aeName, [nodeExecution])[0]
            );
          }
        )
      );
    });

    Promise.all(promises).then(() =>
      window.open(
        `/chat?ne=${encodeURI(window.btoa(JSON.stringify(neDictList)))}`
      )
    );
  };

  return (
    <>
      <ScreenHeader
        title="Analyses"
        heroButtonDef={
          userCanCreateAnalyses
            ? {
                label: "Configure new analysis",
                href: "/newAnalysis",
                onClick: () => {
                  dispatch(analysisTemplateActions.resetAnalyisForm());
                }
              }
            : null
        }
      />
      <Stack sx={{ height: "100%", minHeight: "400px" }}>
        <DataGridPremium
          apiRef={apiRef}
          pagination
          groupingColDef={groupingColDef}
          data-testid="analysesTable"
          loading={isFetching}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedAnalysis(new Set(newRowSelectionModel));
          }}
          onColumnVisibilityModelChange={(newModel) => {
            if (
              Object.keys(newModel).length === 1 &&
              newModel.analysis_name === true
            ) {
              const updatedColumns = { ...visibleColumns };
              Object.keys(updatedColumns).forEach((key) => {
                updatedColumns[key] = true;
              });
              setVisibleColumns(updatedColumns);
            } else {
              setVisibleColumns({ ...visibleColumns, ...newModel });
            }
          }}
          initialState={
            stateFromStore ? JSON.parse(stateFromStore) : defaultTableState
          }
          slots={{
            toolbar: dataTableHeaderToolbarGetter,
            loadingOverlay: LinearProgress,
            pagination: PaginationButtons
          }}
          slotProps={{
            toolbar: {
              optionsCallback: handleOpenMenuDropdown,
              optionsEnabled: true,
              setPanelAnchorEl
            },
            columnsManagement: {
              getTogglableColumns
            },
            panel: {
              anchorEl: panelAnchorEl
            },
            filterPanel: {
              getTogglableColumns
            }
          }}
          getRowId={(row) =>
            row.execution_id || `${row.heirarchy[0]},${row.heirarchy[1]}`
          }
          columns={columnDefs}
          rows={rowData}
          isGroupExpandedByDefault={isGroupExpandedByDefault}
          treeData
          getTreeDataPath={(row) => row.heirarchy}
          checkboxSelection={userCanDeleteAnalyses}
          sx={{
            paddingBottom: (theme) => theme.spacing(4)
          }}
        />

        <DeleteAnalysisDialog show={openDeleteDialog} close={handleClose} />
        <ReCreateDialog
          open={showRecreate}
          handler={handleRecreateDialog}
          analysisExecutionId={execution}
        />
        <UpdateAnalysisNameModal
          show={showEditModal}
          close={handleEditModalClose}
        />
      </Stack>
      <Menu
        id="all-analysis-dropdown-options"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        anchorEl={anchorEl}
        open={dropdownMenuOpen}
        onClose={handleCloseMenuDropdown}
      >
        <Tooltip
          title={
            shouldDisableMultiSelectAIChat()
              ? `Select between 1 and ${MAX_SELECTED_REPORTS} node results to discuss.`
              : ""
          }
          placement="left"
          arrow
        >
          <span>
            <MenuItem
              sx={{ width: 200 }}
              onClick={() => openAIChatForSelected()}
              disabled={shouldDisableMultiSelectAIChat()}
            >
              <BubbleChartIcon color="primary" /> AI Chat (BETA)
            </MenuItem>
          </span>
        </Tooltip>

        <Tooltip title={getMultiDeleteTooltipTitle()} placement="left" arrow>
          <span>
            <MenuItem
              sx={{
                width: 200,
                display: userCanDeleteAnalyses ? null : "none"
              }}
              onClick={() => deleteSelectedAnalysis()}
              disabled={shouldDisableMultiDelete()}
            >
              <DeleteIcon color="primary" /> Delete Selected
            </MenuItem>
          </span>
        </Tooltip>
        <Divider />
        {generateTableManagementMenuItems(
          handleCloseMenuDropdown,
          apiRef,
          defaultTableState,
          dispatch,
          "AllAnalysis"
        )}
      </Menu>
    </>
  );
};
export default AllAnalysis;
