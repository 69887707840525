import { BroadcastChannel } from "broadcast-channel";
import { ClearLocalStorage } from "../store/ReduxStore";
import { wtpkAPI } from "../store/wtpkApi";

const logoutChannel = new BroadcastChannel("logout");

export const logout = (auth, dispatch) => {
  logoutChannel.postMessage("Logout");
  auth.removeUser();
  dispatch(wtpkAPI.util.resetApiState());
  auth.signoutRedirect({
    client_id: `${window.runtime.REACT_APP_COGNITO_CLIENT_ID}`,
    extraQueryParams: {
      logout_uri: `${
        window.runtime.REACT_APP_VTUNE_BASE_URL
      }oauth2/sign_out?rd=${encodeURIComponent(
        window.runtime.REACT_APP_BASE_URL
      )}`
    }
  });
  ClearLocalStorage(dispatch);
};

export const logoutAllTabs = (auth, dispatch) => {
  logoutChannel.onmessage = () => {
    logout(auth, dispatch);
    logoutChannel.close();
  };
};
