import React from "react";
import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

const LinearProgressWithLabel = (props) => {
  const { currentStepCount, currentStepName, totalStepCount } = props;
  const percentCompleted = (currentStepCount / totalStepCount) * 100;

  return (
    <Box
      sx={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 3
      }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={percentCompleted}
          sx={{ height: 15, borderRadius: 5 }}
        />
        <Typography variant="caption">
          Step {currentStepCount} Of {totalStepCount}
        </Typography>
        <br />
        <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
          {currentStepName}
        </Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
