export const generateDictFromAnalysisStatus = (
  aeName,
  typeId,
  nodeName,
  neId,
  neStatus,
  dateStarted
) => {
  return {
    name: aeName,
    collection_type: typeId,
    target: nodeName,
    node_execution_id: neId,
    status: neStatus,
    date_started: dateStarted
  };
};

export const generateNEDictsFromAnalysisNameAndNEs = (
  analysisExecutionName,
  nodeExecutions
) => {
  return nodeExecutions.map((nodeExecution) => {
    return {
      name: analysisExecutionName,
      collection_type: nodeExecution.definition.type_id,
      target: nodeExecution?.node?.computer_name ?? nodeExecution.node_id,
      node_execution_id: nodeExecution.node_execution_id,
      status: nodeExecution.status,
      date_started: nodeExecution.date_started
    };
  });
};
