import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";
import {
  analysisTemplateSlice,
  analysisTemplateActions
} from "../screens/Analysis/slices/LaunchAnalysisSlices";
import { allNodesTagSlice } from "../screens/AllNodes/slices/AllNodesSlices";
import { userActions, userSlice } from "./UserSlice";
import { analysisStatusSlice } from "../screens/Analysis/slices/AnalysisSlices";
import { tableDataSlice } from "./TableDataSlice";
import { customAnalysisFormsSlice } from "../components/AnalysisTypes/Slices/CustomAnalysisForms";
import { termsAndConditionsSlice } from "../screens/TermsAndConditions/TermsAndConditionsSlice";
import { wtpkAPI } from "./wtpkApi";
import { tableSettingsSlice } from "./TableSettingsSlice";

const persistConfigAnalysis = {
  key: "analysis",
  storage,
  stateReconciler: autoMergeLevel2
};

const persistConfigUser = {
  key: "user",
  storage,
  stateReconciler: autoMergeLevel2
};

const persistConfigTableSettings = {
  key: "tableSettings",
  storage,
  stateReconciler: autoMergeLevel2
};

export const reduxStore = configureStore({
  reducer: {
    [wtpkAPI.reducerPath]: wtpkAPI.reducer,
    analysis: persistReducer(
      persistConfigAnalysis,
      analysisTemplateSlice.reducer
    ),
    nodeTags: allNodesTagSlice.reducer,
    user: persistReducer(persistConfigUser, userSlice.reducer),
    analysisStatus: analysisStatusSlice.reducer,
    tableData: tableDataSlice.reducer,
    tableSettings: persistReducer(
      persistConfigTableSettings,
      tableSettingsSlice.reducer
    ),
    customAnalysisForms: customAnalysisFormsSlice.reducer,
    termsAndConditions: termsAndConditionsSlice.reducer
  },
  middleware: [thunk, wtpkAPI.middleware]
});

export const persistedStore = persistStore(reduxStore);

export const ClearLocalStorage = async (dispatch) => {
  dispatch(analysisTemplateActions.resetAnalyisForm());
  dispatch(analysisTemplateActions.setResetAnalysisForm(true));
  dispatch(userActions.resetToInitialState());
  await persistedStore.purge();
  localStorage.removeItem("persist:analysis");
  localStorage.removeItem("persist:user");
  localStorage.removeItem("persist:tableSettings");
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("oidc.")) {
      localStorage.removeItem(key);
    }
  });
};
