/* istanbul ignore file */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { createTheme } from "@mui/material/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Link as RouterLink } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const colorPalette = {
  primary: {
    main: "#0054AE"
  },
  secondary: {
    main: "#8F5DA2"
  },
  error: {
    main: "#D32F2F"
  },
  warning: {
    main: "#EF6C00"
  },
  info: {
    main: "#0288D1"
  },
  success: {
    main: "#2E7D32"
  },
  greyText: "#6D717D",
  darkGreyHeaderText: {
    main: "#3E4047",
    iconBackground: "#686E71"
  }
};

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, anchorTag, ...other } = props;
  // Map href (MUI) -> to (react-router)
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  if (anchorTag) return <a ref={ref} href={href} {...other} />;
  return <RouterLink ref={ref} to={href} {...other} />;
});

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired
};

const initialTheme = createTheme({
  palette: colorPalette,
  shape: {
    borderRadius: 2
  },
  typography: {
    fontFamily: [
      "IntelOne BodyText",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "IntelOne BodyText";
        src: url("./BodyText/intelone-bodytext-font-family-regular.woff2") format("woff2"), url("./BodyText/intelone-bodytext-font-family-regular.woff") format("woff"), url("./BodyText/intelone-bodytext-font-family-regular.ttf") format("truetype");
        font-weight: normal;
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: "IntelOne BodyText";
        src: url("./BodyText/intelone-bodytext-font-family-italic.woff2") format("woff2"), url("./BodyText/intelone-bodytext-font-family-italic.woff") format("woff"), url("./BodyText/intelone-bodytext-font-family-italic.ttf") format("truetype");
        font-weight: normal;
        font-weight: 400;
        font-style: italic;
      }
      @font-face {
        font-family: "IntelOne BodyText";
        src: url("./BodyText/intelone-bodytext-font-family-light.woff2") format("woff2"), url("./BodyText/intelone-bodytext-font-family-light.woff") format("woff"), url("./BodyText/intelone-bodytext-font-family-light.ttf") format("truetype");
        font-weight: light;
        font-weight: 300;
        font-style: normal;
      }
      @font-face {
        font-family: "IntelOne BodyText";
        src: url("./BodyText/intelone-bodytext-font-family-lightitalic.woff2") format("woff2"), url("./BodyText/intelone-bodytext-font-family-lightitalic.woff") format("woff"), url("./BodyText/intelone-bodytext-font-family-lightitalic.ttf") format("truetype");
        font-weight: light;
        font-weight: 300;
        font-style: italic;
      }
      @font-face {
        font-family: "IntelOne BodyText";
        src: url("./BodyText/intelone-bodytext-font-family-medium.woff2") format("woff2"), url("./BodyText/intelone-bodytext-font-family-medium.woff") format("woff"), url("./BodyText/intelone-bodytext-font-family-medium.ttf") format("truetype");
        font-weight: medium;
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: "IntelOne BodyText";
        src: url("./BodyText/intelone-bodytext-font-family-mediumitalic.woff2") format("woff2"), url("./BodyText/intelone-bodytext-font-family-mediumitalic.woff") format("woff"), url("./BodyText/intelone-bodytext-font-family-mediumitalic.ttf") format("truetype");
        font-weight: medium;
        font-weight: 500;
        font-style: italic;
      }
      @font-face {
        font-family: "IntelOne BodyText";
        src: url("./BodyText/intelone-bodytext-font-family-bold.woff2") format("woff2"), url("./BodyText/intelone-bodytext-font-family-bold.woff") format("woff"), url("./BodyText/intelone-bodytext-font-family-bold.ttf") format("truetype");
        font-weight: bold;
        font-weight: 700;
        font-style: normal;
      }
      @font-face {
        font-family: "IntelOne BodyText";
        src: url("./BodyText/intelone-bodytext-font-family-bolditalic.woff2") format("woff2"), url("./BodyText/intelone-bodytext-font-family-bolditalic.woff") format("woff"), url("./BodyText/intelone-bodytext-font-family-bolditalic.ttf") format("truetype");
        font-weight: bold;
        font-weight: 700;
        font-style: italic;
      }
      @font-face {
        font-family: "IntelOne Display";
        src: url("./Display/intelone-display-font-family-regular.woff2") format("woff2"), url("./Display/intelone-display-font-family-regular.woff") format("woff"), url("./Display/intelone-display-font-family-regular.ttf") format("truetype");
        font-weight: normal;
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: "IntelOne Display";
        src: url("./Display/intelone-display-font-family-light.woff2") format("woff2"), url("./Display/intelone-display-font-family-light.woff") format("woff"), url("./Display/intelone-display-font-family-light.ttf") format("truetype");
        font-weight: light;
        font-weight: 300;
        font-style: normal;
      }
      @font-face {
        font-family: "IntelOne Display";
        src: url("./Display/intelone-display-font-family-medium.woff2") format("woff2"), url("./Display/intelone-display-font-family-medium.woff") format("woff"), url("./Display/intelone-display-font-family-medium.ttf") format("truetype");
        font-weight: medium;
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: "IntelOne Display";
        src: url("./Display/intelone-display-font-family-bold.woff2") format("woff2"), url("./Display/intelone-display-font-family-bold.woff") format("woff"), url("./Display/intelone-display-font-family-bold.ttf") format("truetype");
        font-weight: bold;
        font-weight: 700;
        font-style: normal;
      }
      @font-face {
        font-family: "IntelOne Mono";
        src: url("./Mono/intelone-mono-font-family-regular.woff2") format("woff2"), url("./Mono/intelone-mono-font-family-regular.woff") format("woff"), url("./Mono/intelone-mono-font-family-regular.ttf") format("truetype");
        font-weight: normal;
        font-weight: 400;
        font-style: normal;
      }
      /* Italic */
      @font-face {
        font-family: "IntelOne Mono";
        src: url("./Mono/intelone-mono-font-family-italic.woff2") format("woff2"), url("./Mono/intelone-mono-font-family-italic.woff") format("woff"), url("./Mono/intelone-mono-font-family-italic.ttf") format("truetype");
        font-weight: normal;
        font-weight: 400;
        font-style: italic;
      }
      /* Light */
      @font-face {
        font-family: "IntelOne Mono";
        src: url("./Mono/intelone-mono-font-family-light.woff2") format("woff2"), url("./Mono/intelone-mono-font-family-light.woff") format("woff"), url("./Mono/intelone-mono-font-family-light.ttf") format("truetype");
        font-weight: light;
        font-weight: 300;
        font-style: normal;
      }

      @font-face {
        font-family: "IntelOne Mono";
        src: url("./Mono/intelone-mono-font-family-lightitalic.woff2") format("woff2"), url("./Mono/intelone-mono-font-family-lightitalic.woff") format("woff"), url("./Mono/intelone-mono-font-family-lightitalic.ttf") format("truetype");
        font-weight: light;
        font-weight: 300;
        font-style: italic;
      }

      @font-face {
        font-family: "IntelOne Mono";
        src: url("./Mono/intelone-mono-font-family-medium.woff2") format("woff2"), url("./Mono/intelone-mono-font-family-medium.woff") format("woff"), url("./Mono/intelone-mono-font-family-medium.ttf") format("truetype");
        font-weight: medium;
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: "IntelOne Mono";
        src: url("./Mono/intelone-mono-font-family-mediumitalic.woff2") format("woff2"), url("./Mono/intelone-mono-font-family-mediumitalic.woff") format("woff"), url("./Mono/intelone-mono-font-family-mediumitalic.ttf") format("truetype");
        font-weight: medium;
        font-weight: 500;
        font-style: italic;
      }
      @font-face {
        font-family: "IntelOne Mono";
        src: url("./Mono/intelone-mono-font-family-bold.woff2") format("woff2"), url("./Mono/intelone-mono-font-family-bold.woff") format("woff"), url("./Mono/intelone-mono-font-family-bold.ttf") format("truetype");
        font-weight: bold;
        font-weight: 700;
        font-style: normal;
      }
      @font-face {
        font-family: "IntelOne Mono";
        src: url("./Mono/intelone-mono-font-family-bolditalic.woff2") format("woff2"), url("./Mono/intelone-mono-font-family-bolditalic.woff") format("woff"), url("./Mono/intelone-mono-font-family-bolditalic.ttf") format("truetype");
        font-weight: bold;
        font-weight: 700;
        font-style: italic;
      }
      @font-face {
        font-family: "Intel Clear";
        src: url("./Clear/IntelClear-Regular-webfont.woff") format("woff"), url("./Clear/IntelClear-Regular-webfont.ttf") format("truetype");
        font-weight: normal;
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: "Intel Clear";
        src: url("./Clear/IntelClear-Light-webfont.woff") format("woff"), url("./Clear/IntelClear-Light-webfont.ttf") format("truetype");
        font-weight: light;
        font-weight: 300;
        font-style: normal;
      }
      @font-face {
        font-family: "Intel Clear";
        src: url("./Clear/IntelClear-Bold-webfont.woff") format("woff"), url("./Clear/IntelClear-Bold-webfont.ttf") format("truetype");
        font-weight: bold;
        font-weight: 700;
        font-style: normal;
      }
      `
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...((ownerState.variant === "contained" && {
            boxShadow:
              "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)"
          }) ||
            (ownerState.variant === "outlined" && {
              borderWidth: "2px"
            }))
        })
      }
    },
    MuiCard: {
      defaultProps: {
        square: true,
        variant: "outlined"
      },
      styleOverrides: {
        root: {
          border: 0,
          backgroundColor: "#FAFAFA"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "0px"
        },
        title: {
          textTransform: "uppercase",
          fontSize: "14px",
          color: colorPalette.greyText,
          fontWeight: "medium"
        },
        avatar: {
          marginRight: "8px"
        }
      }
    },
    MuiDataGrid: {
      defaultProps: {
        autoHeight: true,
        disableAggregation: true,
        pagination: true,
        disableRowSelectionOnClick: true,
        slots: {
          openFilterButtonIcon: FilterAltIcon,
          moreActionsIcon: MoreHorizIcon
        },
        localeText: {
          toolbarFilters: ""
        },
        pageSizeOptions: [10, 50, 100, 500] // { value: -1, label: "All" }] --removing All because it's bugged
      },
      styleOverrides: {
        root: {
          border: "none"
        },
        main: {
          borderLeft: "1px solid #0000001F",
          borderRight: "1px solid #0000001F"
        },
        footerContainer: {
          borderLeft: "1px solid #0000001F",
          borderBottom: "1px solid #0000001F",
          borderRight: "1px solid #0000001F"
        },
        columnHeader: {
          height: "56px"
        },
        columnHeaderTitle: {
          fontWeight: "medium"
        },
        cell: {
          display: "flex",
          alignItems: "center"
        },
        toolbarContainer: {
          padding: "0px",
          borderBottom: "1px solid #0000001F"
        },
        toolbarQuickFilter: {
          backgroundColor: "#F5F5F5",
          height: "52px",
          padding: "0px 8px",
          justifyContent: "center",
          borderTop: "1px solid #0000001F",
          borderLeft: "1px solid #0000001F"
        }
      }
    }
  }
});

const globalTheme = createTheme(initialTheme, {
  // Custom colors created with augmentColor go here
  palette: {
    darkerPrimary: initialTheme.palette.augmentColor({
      color: {
        main: "#00377C"
      },
      name: "darkerPrimary"
    }),
    darkerSecondary: initialTheme.palette.augmentColor({
      color: {
        main: "#653171"
      },
      name: "darkerSecondary"
    })
  }
});

export default globalTheme;
