import React from "react";
import { Typography, Box, List, ListItem } from "@mui/material";

const TCLegalText = () => {
  return (
    <Box mx={4}>
      <Typography paragraph>
        This Terms of Service Agreement (<strong>“Agreement”</strong>) is a
        legally binding contract between Intel Corporation, a Delaware
        corporation (<strong>“Intel,” “we,” “us,” “our”</strong>) and you, or
        the company or other legal entity that you represent and warrant you
        have the legal authority to bind, (each, <strong>“you”</strong> or{" "}
        <strong>“your”</strong>) with respect to your use of our services and
        related APIs, websites, and software (collectively the{" "}
        <strong>“Service”</strong>). It is important that you carefully read and
        understand the terms and conditions of this Agreement. BY ACCESSING OR
        USING THE SERVICE OR CLICKING ON THE CHECKBOX TO INDICATE YOUR AGREEMENT
        TO THESE TERMS AND CONDITIONS, YOU AGREE TO BE BOUND BY THE TERMS AND
        CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS AND
        CONDITIONS OF THIS AGREEMENT, OR DO NOT HAVE THE LEGAL AUTHORITY OR
        REQUIRED AGE IN YOUR JURISDICTION TO AGREE TO THEM, DO NOT ACCESS OR USE
        THE SERVICE AND DO NOT CLICK THE CHECKBOX TO INDICATE YOUR AGREEMENT TO
        THESE TERMS AND CONDITIONS.
      </Typography>
      <Typography paragraph>
        For clarity, the Service requires a software agent to be installed on
        the computer system to be monitored (the <strong>“Agent”</strong>). The
        use of the Agent is subject solely to the terms and conditions of the
        end user license agreement bundled with the Agent, however the storage,
        processing and use of data collected by the Agent and received by the
        Service is subject to the terms and conditions of this Agreement.
      </Typography>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        1. Your Account for the Service
      </Typography>
      <Typography paragraph>
        In order to use the Service, you need to register and create an account
        with Intel. You agree to (a) provide accurate, current, and complete
        information and (b) maintain and update your information to keep it
        accurate, current, and complete. You acknowledge that, if any
        information provided by you is inaccurate, not current, or incomplete,
        we may terminate this Agreement and your use of the Service. By
        providing Intel with your email address or other contact information,
        you consent to our use of this information to send you Service-related
        notices and other administrative notices, including any notices required
        by law.
      </Typography>
      <Typography paragraph>
        Personally-identifiable information (“Personal Information”) that you
        submit to Intel through account creation or use of the Services is
        governed by Intel Corporation’s (“Intel”) Privacy Notice, found at
        https://www.intel.com/content/www/us/en/privacy/intel-privacy-notice.html,
        which describes how we collect, process, and disclose your Personal
        Information. By accessing and using the Service, you acknowledge that
        you have read and understand Intel’s Privacy Notice.
      </Typography>
      <Typography paragraph>
        You are solely responsible for any activity that occurs on your account.
        You must keep your account credentials (including username and password)
        secure at all times. You must not share your account with any third
        parties without Intel’s prior written consent. You must notify Intel
        immediately of any breach or suspected breach of security or
        unauthorized use of your account. Intel will not be liable for any
        losses caused by any unauthorized use of your account.
      </Typography>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        2. Your Use of the Service
      </Typography>
      <Typography paragraph>
        You may not use the Service, or assist or encourage any other party, to
        engage in any of the following prohibited activities (either directly or
        through the Agent):
      </Typography>
      <List component="ol" sx={{ listStyleType: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          Submitting to the Service or to Intel, any Personal Information,
          except as necessary for the establishment of your account;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Submitting to the Service or to Intel, any other information that may
          be protected from disclosure by applicable law;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Collecting or harvesting any Personal Information, including account
          names or other information about users from the Service;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Transmitting any trade secret or other material, non-public
          information about any person, company, or entity without the
          authorization to do so;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Publishing, transmitting, distributing, or storing content, material,
          information, or data that (a) is illegal, obscene, defamatory,
          libelous, threatening, harassing, abusive, or hateful or that
          advocates violence or threatens the health of others; (b) is harmful
          to or interferes with the Service or any third party’s networks,
          equipment, applications, services, or websites (e.g., viruses, worms,
          Trojan horses, etc.); (c) infringes, dilutes, misappropriates, or
          otherwise violates any privacy, intellectual property, publicity, or
          other personal rights including, without limitation, copyrights,
          patents, trademarks, trade secrets, or other proprietary information
          (including unauthorized use of domain names); or (d) is fraudulent or
          contains false, deceptive, or misleading statements, claims, or
          representations (such as “phishing”);
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Attempting to disrupt, degrade, impair, or violate the integrity or
          security of the Service or the computers, services, accounts, or
          networks of any other party (including, without limitation, “hacking,”
          “denial of service” attacks, etc.), including any activity that
          typically precedes attempts to breach security such as scanning,
          probing, or other testing or vulnerability assessment activity, or
          engaging in or permitting any network or hosting activity that results
          in the blacklisting or other blockage of Intel internet protocol
          space;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Taking any action that imposes, or may impose, at our sole discretion,
          an unreasonable or disproportionately large load on our
          infrastructure;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Copying, framing, mirroring, distributing or disclosing any part of
          the Service in any medium, including without limitation by any
          automated or non-automated “scraping”;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Permitting any third party to access the Service;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Sublicensing selling, renting, leasing, transferring, assigning, or
          conveying any rights under this Agreement to any third party, except
          as expressly permitted herein;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Using, copying, modifying, creating a derivative work of, reverse
          engineering, decompiling, or otherwise attempting to extract the
          source code of the software underlying the Service or any part
          thereof, unless expressly permitted or required by law, and in any
          case, without providing prior written notice to Intel;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Accessing any content on the Service through any technology or means
          other than those provided or authorized by the Service;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Bypassing measures to prevent or restrict access to the Service,
          including, without limitation, features that prevent or restrict use
          or copying of any content or enforce limitations on use of the Service
          or the content therein;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Using any automated system, including, without limitation, “robots,”
          “spiders,” “offline readers,” etc., to access the Service;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Accessing the Service for purposes of monitoring its availability,
          performance, or functionality;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Executing any form of network monitoring or running a network analyzer
          or packet sniffer or other technology to intercept, decode, mine, or
          display any packets used to communicate between the Service’s servers
          or any data not intended for you;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Using the Service for any commercial solicitation purposes;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Impersonating another person or otherwise misrepresenting your
          affiliation with a person or entity, conducting fraud, hiding or
          attempting to hide your identity;
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Violating any applicable law, statute, ordinance, or regulation, or
          encourage any conduct that could constitute a criminal offense or give
          rise to civil liability; and/or
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Removing any copyright, trademark, or other proprietary rights notices
          contained in or on the Service.
        </ListItem>
      </List>
      <Typography paragraph>
        Improper use of the Service may result in termination of your access to
        and use of the Service, and/or civil or criminal liabilities. You agree
        to use the Service in accordance with all applicable laws.
      </Typography>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        3. Access to the Service; Rights Needed from You; Ownership Rights
      </Typography>
      <List sx={{ listStyleType: "upper-alpha", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Your Access to the Service
          </Box>
          . Subject to the terms and conditions of this Agreement, Intel
          authorizes you to access and use the Service solely for your internal
          business purposes. Intel reserves all rights not expressly granted
          herein in the Service.
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Rights Needed from You
          </Box>
          .
          <List style={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Customer Data</strong>. You expressly grant, and you
              warrant that you have all rights necessary to grant, to Intel, a
              royalty-free, fully paid-up, sublicensable (through multiple tiers
              of sublicensees), transferable, perpetual, irrevocable,
              non-exclusive, worldwide license to use, distribute, modify,
              reproduce, publicly display, publicly perform, and create
              derivative works of any information, data, materials, or content
              you provide to Intel, directly or through the Agent, excluding
              Personal Information (<strong>“Customer Data”</strong>) for the
              purposes of (a) providing the Service, or (b) developing,
              maintaining, supporting, or improving the Service.
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Feedback</strong>. If you provide suggestions, feedback,
              or other input to Intel concerning the functionality and
              performance of the Services, including, without limitation,
              identifying potential errors and improvements (
              <strong>“Feedback”</strong>), then you hereby grant Intel a
              worldwide, non-exclusive, royalty-free, fully paid-up,
              sublicensable (through multiple tiers of sublicensees),
              transferable, perpetual, irrevocable, right and license to
              reproduce, distribute, create derivative works of, publicly
              display, publicly perform, disclose, make, have made, use, sell
              (directly or indirectly), offer for sale or disposition, import,
              dispose of, and otherwise exploit such Feedback for any purpose
              without restriction, provided that Intel shall not attribute such
              Feedback to you, or otherwise refer to or identify you in
              connection with such activities. You will not provide or disclose
              any Feedback to Intel that you do not have the right to provide or
              disclose.
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Resultant Data</strong>. Notwithstanding anything to the
              contrary, Intel may collect and analyze data and other information
              relating to the provision, use, and performance of various aspects
              of the Services and related systems and technologies
              (collectively, <strong>“Resultant Data”</strong>), and Intel will
              be free (during and after the term of this Agreement) to (i) use
              such Resultant Data to improve and enhance the Services and for
              other development, diagnostic, and corrective purposes in
              connection with the Services and other Intel offerings, and (ii)
              disclose such Resultant Data solely in aggregated and anonymized
              or other de-identified form in connection with its business.
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            {" "}
            Our Ownership Rights
          </Box>
          . The Service is owned and operated by Intel and its suppliers, and
          the Service (and any intellectual property and other rights relating
          thereto) is and will remain the property of Intel and its suppliers.
          The Service is protected by U.S. and international copyright,
          trademark, and other laws. The Service may be used solely (a) to the
          extent permitted in this Agreement or (b) as expressly authorized in
          writing by Intel. Use of the Service for any other purpose is strictly
          prohibited. You do not acquire any ownership rights by using the
          Service. The trademarks, logos, and service marks displayed on the
          Service (collectively, the <strong>“Intel Trademarks”</strong>) are
          the registered and unregistered trademarks of Intel and its suppliers,
          and/or other third parties. Nothing contained in this Agreement or the
          Service should be construed as granting, by implication, estoppel, or
          otherwise, any license or right to use any Intel Trademark(s) without
          the express written permission of Intel, its suppliers, or the
          third-party owner of any such Intel Trademark.
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Your Ownership Rights
          </Box>
          . Subject to the rights granted to Intel in this Agreement, as between
          you and Intel, you own and retain all right, title, and interest in
          and to your Customer Data.
        </ListItem>
      </List>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        4. The Service.
      </Typography>
      <Typography paragraph>
        You are authorized to use the Service in accordance with the terms and
        conditions of this Agreement for the term authorized by Intel or until
        otherwise terminated by Intel. Intel does not guarantee that future
        versions of the Service will be made available under the same terms, or
        be provided at all. You acknowledge that the Service may not be complete
        or fully functional and may contain bugs, errors, omissions and other
        problems. Intel provides no warranty, indemnity, support, or maintenance
        with respect to the Service and has no liability to you with regard to
        them.{" "}
      </Typography>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        5. Term & Termination
      </Typography>
      <List sx={{ listStyleType: "upper-alpha", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Term
          </Box>
          . This Agreement is effective from the date on which you first agree
          to its terms, access the Service or submit any Customer Data to Intel,
          whichever is earlier, and it shall remain effective until your rights
          to access the Service expire or this Agreement is terminated in
          accordance with Sections 5.B or 5.C below.{" "}
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Expiration or Termination of Service
          </Box>
          . Intel may immediately terminate this Agreement, and/or your access
          to and use of the Service, or any portion thereof, at any time and for
          any reason, with or without cause or prior notice. Intel may also
          terminate this Agreement immediately if you fail to comply with any
          term or provision of this Agreement. You may terminate this Agreement
          at any time by contacting us and terminating your account. Until you
          terminate your account, you are responsible for any activity by anyone
          using your account. Upon termination of this Agreement by either
          party, your right to use the Service shall immediately cease.
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Service Discontinuation
          </Box>
          . Intel reserves the right at any time to modify or discontinue,
          temporarily or permanently, the Service, or any part or portion
          thereof, to you with or without notice to you. You agree that we shall
          not be liable to you or to any third party for discontinuance of the
          Service, or any part of portion thereof. Nothing in this Agreement
          shall be construed to obligate Intel to maintain and support the
          Service, or any part or portion thereof, during the term of this
          Agreement.
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Survival
          </Box>
          . Upon termination or expiration of this Agreement for any reason, the
          second paragraph of the preamble and Sections 3.B, 3.C, 3.D, 5.D, 6,
          7, 8, 9, 11, and 12 shall survive.
        </ListItem>
      </List>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        6. No Warranties. Disclaimers
      </Typography>
      <Typography paragraph>
        THE SERVICE IS PROVIDED “AS IS” AND “WITH ALL FAULTS” AND INTEL AND ITS
        SUPPLIERS DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR
        IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE,
        NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
        ANY WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF
        PERFORMANCE, OR USAGE OF TRADE.
      </Typography>
      <Typography paragraph>
        INTEL AND ITS SUPPLIERS ALSO DISCLAIM ALL WARRANTIES, REPRESENTATIONS,
        OR GUARANTEES THAT THE SERVICE, OR YOUR USE OF THE SERVICE, WILL BE
        UNINTERRUPTED, COMPLETE, ACCURATE, CURRENT OR UP-TO-DATE, RELIABLE,
        ERROR-FREE, SECURE, OR THAT ANY PROBLEMS WILL BE CORRECTED, OR THAT THE
        SERVICE, OR ANY INFORMATION, DATA, SOFTWARE, OR OTHER MATERIAL
        ACCESSIBLE FROM THE SERVICE, IS FREE OF VIRUSES OR OTHER HARMFUL CODE.
        WE DO NOT WARRANT, REPRESENT, OR MAKE ANY GUARANTEE REGARDING THE USE
        OF, OR THE RESULTS OF THE USE OF, THE SERVICE AND YOU ASSUME ALL
        RESPONSIBILITY AND RISK FOR YOUR USE OF THE SERVICE, AND YOUR RELIANCE
        THEREON.
      </Typography>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        7. Limitation of Liability
      </Typography>
      <Typography paragraph>
        NEITHER INTEL NOR ANY OF ITS SUPPLIERS ARE RESPONSIBLE OR LIABLE FOR ANY
        DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY,
        PUNITIVE OR OTHER INDIRECT DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY
        LOSS OF PROFITS, LOST SAVINGS, OR LOSS OF DATA) OR LIABILITIES UNDER ANY
        CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHER THEORY ARISING OUT OF
        OR RELATING IN ANY MANNER TO THE SERVICE, AND/OR ANY LINKED WEBSITE,
        WHETHER OR NOT INTEL HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH
        DAMAGES OR LIABILITIES.
      </Typography>
      <Typography paragraph>
        SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
        WARRANTIES AND/OR LIABILITIES, SO CERTAIN OF THE ABOVE LIMITATIONS OR
        EXCLUSIONS MAY NOT APPLY TO YOU.
      </Typography>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        8. Indemnification
      </Typography>
      <Typography paragraph>
        You (and also any third party for whom you operate an account on the
        Service) agree to fully indemnify, defend, and hold Intel, and its
        respective suppliers, licensors, agents, successors, and assigns, and
        our and their directors, officers, employees, consultants, and other
        representatives harmless from and against any and all claims, damages,
        losses, costs (including reasonable attorneys’ fees), and other expenses
        that arise directly or indirectly out of or from: (a) your breach of
        this Agreement; (b) any allegation that any Customer Data or other
        materials you submit to us or transmit to the Service infringe,
        misappropriate, or otherwise violate the copyright, patent, trademark,
        trade secret, or other intellectual property or other rights of any
        third party; (c) your activities in connection with the Service or other
        websites to which the Service is linked; (d) your negligence or willful
        misconduct; (e) your use of the results, content, data, or information
        provided via the Service; (f) any service or product offered by you in
        connection with or related to your use of the Service; and/or (g) your
        violation of any laws, rules, regulations, codes, statutes, ordinances,
        or orders of any governmental and quasi-governmental authorities,
        including, without limitation, all regulatory, administrative and
        legislative authorities.
      </Typography>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        9. Choice of Law and Forum
      </Typography>
      <Typography paragraph>
        All disputes arising out of or related to this Agreement, whether based
        on contract, tort, or any other legal or equitable theory, will in all
        respects be governed by, and construed and interpreted under the laws of
        the State of Delaware.{" "}
        <strong>
          The parties agree that the United Nations Convention on Contracts for
          the International Sale of Goods (1980) is specifically excluded from
          and will not apply to this Agreement.
        </strong>{" "}
        All disputes arising out of or related to this Agreement, whether based
        on contract, tort, or any other legal or equitable theory, will be
        subject to the exclusive jurisdiction and venue of the federal or state
        courts located in the State of Delaware.
      </Typography>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        10. U.S. Government Restricted Rights
      </Typography>
      <Typography paragraph>
        The Service shall be deemed “commercial computer software” pursuant to
        DFAR Section 227.7202 and FAR Section 12.212, as applicable. Any use,
        modification, reproduction release, performance, display or disclosure
        of the Service by the U.S. Government will be governed solely by the
        terms of this Agreement and will be prohibited except to the extent
        expressly permitted by the terms of this Agreement.
      </Typography>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        11. Export
      </Typography>
      <Typography paragraph>
        You will not export or re-export, either directly or indirectly, any
        technical data, software, process, product, service, or system obtained
        from Intel or its suppliers, without first complying with the United
        States and all other applicable government laws and regulations
        governing the export, re-export, and import of those items. In
        particular, but without limitation, the Services may not be exported or
        re-exported (a) into any U.S. embargoed countries or (b) to any person
        or entity listed on a denial order published by the U.S. government or
        any other applicable governments. By using the Services, you represent
        and warrant that you are not located in any such country or on any such
        list
      </Typography>
      <Typography paragraph sx={{ fontWeight: "bold" }}>
        12. Miscellaneous
      </Typography>
      <List sx={{ listStyleType: "upper-alpha", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <Typography paragraph>
            <Box component="span" sx={{ textDecoration: "underline" }}>
              Entire Agreement; Relationship of Parties; Assignment; Waiver;
              Notices; Interpretation
            </Box>
            . This Agreement contains the complete and exclusive agreement and
            understanding between the parties concerning the subject matter of
            this Agreement, and supersedes all prior and contemporaneous
            proposals, agreements, understanding, negotiations, representations,
            warranties, conditions, and communications, oral or written, between
            the parties relating to the same subject matter. Each party
            acknowledges and agrees that in entering into this Agreement it has
            not relied on, and will not be entitled to rely on, any oral or
            written representations, warranties, conditions, understanding, or
            communications between the parties that are not expressly set forth
            in this Agreement. The express provisions of this Agreement control
            over any course of performance, course of dealing, or usage of the
            trade inconsistent with any of the provisions of this Agreement. The
            provisions of this Agreement will prevail notwithstanding any
            different, conflicting, or additional provisions that may appear on
            any purchase order, acknowledgement, invoice, or other writing
            issued by either party in connection with this Agreement.
          </Typography>
          <Typography paragraph>
            No agency, partnership, joint venture, employee-employer, or
            franchiser-franchisee relationship is intended or created by this
            Agreement.
          </Typography>
          <Typography paragraph>
            Neither this Agreement nor any right, obligation, or remedy
            hereunder is assignable, transferable, delegable, or sublicensable
            by you except with Intel’s prior written consent, and any attempted
            assignment, transfer, delegation, or sublicense shall be null and
            void. Intel may assign, transfer, or delegate this Agreement or any
            right or obligation or remedy hereunder in its sole discretion.
          </Typography>
          <Typography paragraph>
            No waiver by either party of any breach or default hereunder shall
            be deemed to be a waiver of any preceding or subsequent breach or
            default.
          </Typography>
          <Typography paragraph>
            All notices and consents required or permitted to be given to Intel
            under this Agreement must be in writing and notices will be
            considered delivered (a) when received if delivered by hand, (b) the
            next business day after being sent by pre-paid,
            nationally-recognized, overnight air courier with tracking
            capabilities, and (c) 5 business days after being sent by registered
            or certified airmail, return receipt required, postage prepaid. All
            notices must be addressed as follows:
          </Typography>
          <Typography paragraph marginLeft={5}>
            Intel Corporation <br /> 2200 Mission College Blvd <br />
            Santa Clara, CA 95054 <br />
            Attn: General Counsel <br />
            Reference ID: SATG <br />
            With a copy, which will not be notice, to:
            Intel-Legal-Notices@intel.com
          </Typography>
          <Typography paragraph>
            Notices to you will be sent to the e-mail address you have
            designated on your account. Notice to you shall be deemed given 24
            hours after the e-mail is sent.
          </Typography>
          <Typography paragraph>
            Any heading, caption, or section title contained in this Agreement
            is inserted only as a matter of convenience, and in no way defines
            or explains any section or provision.
          </Typography>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Changes to this Agreement
          </Box>
          . We reserve the right, at any time, with or without cause, to: (a)
          change the terms and conditions of this Agreement; (b) change the
          Service, including eliminating or discontinuing any service or other
          feature of the Service; or (c) deny or terminate your use of and/or
          access to the Service. Any changes we make will be effective
          immediately upon our making such changes available on the Service or
          otherwise providing notice thereof. You agree that your continued use
          of the Service after such changes constitutes your acceptance of such
          changes.
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Severability
          </Box>
          . The parties intend that if a court holds that any provision or part
          of this Agreement is invalid or unenforceable under applicable law,
          the court will modify the provision to the minimum extent necessary to
          make it valid and enforceable, or if it cannot be made valid and
          enforceable, the parties intend that the court will sever and delete
          the provision or part from this Agreement. Any change to or deletion
          of a provision or part of this Agreement under this Section will not
          affect the validity or enforceability of the remainder of this
          Agreement, which will continue in full force and effect.
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Third-Party Links
          </Box>
          . The Service may contain links to other websites for your
          convenience. We do not control the linked websites, or the content
          provided through such websites, and we have not reviewed, in their
          entirety, such websites. Your use of linked websites is subject to the
          privacy practices and terms of use established by the specific linked
          website, and we disclaim all liability for such use. The fact that we
          offer such links does not indicate any approval or endorsement by us
          of any linked website or any material contained on any linked website,
          and we disclaim any such approval or endorsement.
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Compliance with Laws
          </Box>
          . Each party agrees to comply with the laws, rules, and regulations
          applicable to their obligations under this Agreement.
        </ListItem>
      </List>
    </Box>
  );
};

export default TCLegalText;
