import React from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import RevokeButton from "./RevokeButton";
import DataGridSelectCell from "../../../components/DataTableElements/DataGridSelectCell";

const renderSelectCell = (params) => {
  return (
    <DataGridSelectCell
      id={params.id}
      field={params.field}
      value={params.value}
      isEditable={params.isEditable}
      cellMode={params.cellMode}
      colDef={params.colDef}
    />
  );
};

const ColumnDef = [
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 200,
    editable: false,
    hideable: false
  },
  {
    field: "userName",
    headerName: "User",
    flex: 1,
    minWidth: 200,
    editable: false,
    hideable: false
  },
  {
    field: "typeOfUser",
    headerName: "User type",
    flex: 1,
    minWidth: 200,
    editable: true,
    type: "singleSelect",
    valueOptions: ["Admin", "Editor", "Viewer"],
    hideable: false,
    cellClassName: (params) => {
      if (params.row.addedOn !== "Invitation pending") {
        return "user-type";
      }
      return "";
    },
    renderCell: renderSelectCell,
    renderEditCell: renderSelectCell
  },
  {
    field: "addedOn",
    headerName: "Status",
    flex: 1,
    minWidth: 200,
    editable: false,
    hideable: false
  },
  {
    field: "action",
    headerName: "",
    type: "actions",
    sortable: false,
    hideable: false,
    getActions: (params) => {
      const actions = [
        <GridActionsCellItem icon={<RevokeButton userDetails={params.row} />} />
      ];
      return actions;
    }
  }
];

export default ColumnDef;
