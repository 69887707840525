import { ApiGET, ApiDELETE, ApiPut } from "../../../appServices/axiosService";
import * as CommonMethods from "../../../appServices/resuableMethods";

const apiBaseUrl = `${window.runtime.REACT_APP_API_URL}`;

export const getAnalysisExecution = async (user, analysisExecutionid) => {
  const response = await ApiGET(
    `${apiBaseUrl}/execution/${analysisExecutionid}`,
    user
  );
  return response.execution || response;
};

export const getTemplateDetails = async (user) => {
  const templateDetails = await ApiGET(`${apiBaseUrl}/template`, user);
  return templateDetails.items;
};

export const getAnalysisExecutionStatus = async (
  user,
  analysisExecutionid,
  templateName
) => {
  const response = await ApiGET(
    `${apiBaseUrl}/execution/${analysisExecutionid}/nodeExecution`,
    user
  );
  let finalResponse = [];

  finalResponse = response.items.map((item) => {
    const container = {};
    container.nodeListLength = response.items.length;
    container.nodeStatus = item.node?.ping_status || "Inactive";
    if (item.node) {
      container.node_name = item.node.name;
      container.tags = item.node.tags;
    } else {
      container.tags = {};
    }
    const nodeDetails = {
      name: item.node === null ? null : item.node.name,
      computer_name: item.node === null ? null : item.node.computer_name,
      node_id: item.node_id
    };
    container.name_object =
      CommonMethods.displayConsistentNodeName(nodeDetails);

    container.node_id = item.node_id;
    container.start_timestamp = CommonMethods.ConvertDateTime(
      item.date_started * 1000
    );
    if (item.date_finished === null) container.end_timestamp = "n/a";
    else
      container.end_timestamp = CommonMethods.ConvertDateTime(
        item.date_finished * 1000
      );
    container.date_started = item.date_started;
    container.status = item.status;
    container.execution_id = item.execution_id;
    container.node_execution_id = item.node_execution_id;
    container.template_id = templateName;
    container.type = item.definition.type_id;
    container.status_cause = item.status_cause;
    container.error = item.error;
    if (item.step) {
      container.totalStepCount = item.step.substring(2, 3);
      container.currentStepCount = item.step.substring(0, 1);
      container.currentStepName = item.step.substring(4);
    }
    container.definition = item.definition;
    container.lifecycle = item.lifecycle;
    container.target = item.target;
    container.started_by = item.started_by;
    if (item.node != null) {
      container.node = item.node;
      if (item.node.registration_date != null) {
        container.node_registration_date = CommonMethods.ConvertDateTime(
          item.node.registration_date * 1000
        );
      }
    }
    return container;
  });

  return finalResponse.filter((obj) => !(obj && Object.keys(obj).length === 0));
};

export const downloadVtuneResult = async (
  user,
  nodeExecutionID,
  downloadName,
  reportTypeWithFormat
) => {
  const response = await ApiGET(
    `${apiBaseUrl}/nodeExecution/${nodeExecutionID}/result/${reportTypeWithFormat}/link?download_name=${downloadName}`,
    user
  );
  return response;
};

export const getLog = async (user, nodeExecutionID, logID) => {
  const res = await ApiGET(
    `${apiBaseUrl}/nodeExecution/${nodeExecutionID}/output/${logID}/link`,
    user
  );

  if (res.name === "AxiosError" && res.response.status === 404) {
    return null;
  }

  return res;
};

export const downloadOutput = (
  output,
  timeStamp,
  templateName,
  nodeName,
  analysisExecutionName
) => {
  const element = document.createElement("a");
  const timestamp = timeStamp.replace("Completed At   ", "");
  const filename = `${templateName}-${analysisExecutionName}-${nodeName}-${timestamp}-${"stdout"}.txt`;
  const file = new Blob([output], {
    type: "text/plain;charset=utf-8"
  });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
};

export const deleteAnalysisExecution = async (user, analysisExecutionID) => {
  const response = await ApiDELETE(
    `${apiBaseUrl}/execution/${analysisExecutionID}`,
    user
  );
  return response;
};

export const UpdateAnalysisExecution = async (
  user,
  analysisExecutionID,
  executionName
) => {
  const response = await ApiPut(
    `${apiBaseUrl}/execution/${analysisExecutionID}`,
    user,
    { name: executionName }
  );
  return response;
};

export const getAPSReport = async (user, apsReportID) => {
  const response = await ApiGET(`${apiBaseUrl}/apsReport/${apsReportID}`, user);
  return response;
};

export const getAPSLink = async (user, apsReportID) => {
  const response = await ApiGET(
    `${apiBaseUrl}/apsReport/${apsReportID}/link`,
    user
  );
  return response;
};
