import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Link,
  Stack,
  MenuItem,
  Tooltip
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import HelpIcon from "@mui/icons-material/Help";
import SpokeIcon from "@mui/icons-material/Spoke";
import SpokeOutlinedIcon from "@mui/icons-material/SpokeOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";

import { useSelector } from "react-redux";

import VTuneLogo from "../../images/VT.svg";
import SparkTop from "../../images/brand-spark-top.svg";
import SparkBottom from "../../images/brand-spark-bottom.svg";
import Tour from "../Tour/Tour";
import InviteUserDialog from "../InviteUserDialog/InviteUserDialog";
import { useGetTenantQuery } from "../../store/wtpkApi";
import AccountMenu from "./components/AccountMenu";
import InformationMenu from "./components/InformationMenu";

const SideNavBrandedHeader = styled(MenuItem)(() => ({
  height: "64px",
  backgroundImage: `url(${VTuneLogo}), url(${SparkTop})`,
  backgroundPosition: `center, bottom right`,
  backgroundSize: `40px 22px, 32px 24px`,
  backgroundColor: "#001E50",
  backgroundRepeat: "no-repeat",
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  "&:hover": { backgroundColor: "#001E50" }
}));

const UpperSideNavMenuItem = styled(MenuItem)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0
}));

const LowerSideNavMenuItem = styled(MenuItem)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0
}));

const DrawerChevron = styled("div")(() => ({
  height: "38px",
  display: "flex",
  alignItems: "center",
  paddingRight: 0,
  paddingLeft: 0,
  paddingTop: 0,
  paddingBottom: 0
}));

const SideNavBar = ({ open = false, handleDrawerClose, handleDrawerOpen }) => {
  const { pathname } = useLocation();
  const tenantId = useSelector((state) => state.user.current_tenant_id);
  const { data: tenantData, isSuccess: gotTenantData } = useGetTenantQuery(
    tenantId,
    { refetchOnMountOrArgChange: true, skip: !tenantId }
  );

  const allowedEmailDomains = gotTenantData
    ? tenantData.tenant.allowed_email_domains
    : [];
  const tenantName = gotTenantData ? tenantData.tenant.tenant_name : "";

  const upperListItemButtonSx = {
    justifyContent: open ? "initial" : "center",
    py: "14px",
    "&:hover": { backgroundColor: "#00377C" },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#00377C"
    }
  };

  const lowerListItemButtonSx = {
    height: "38px",
    px: "14px",
    justifyContent: open ? "initial" : "center",
    "&:hover": { backgroundColor: "#00377C" },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#00377C"
    }
  };

  const listItemTextSx = {
    display: open ? "block" : "none",
    color: "#ffffff"
  };

  const listItemIconSx = {
    minWidth: open ? "20px !important" : "36px",
    mr: open ? 1 : "auto",
    justifyContent: "center"
  };

  const upperIconSx = {
    color: "#ffffff",
    height: "24px !important",
    width: "24px !important"
  };

  const lowerIconSx = {
    color: "#ffffff",
    height: "20px !important",
    width: "20px !important"
  };

  const tooltipSx = {
    display: "none"
  };

  const [openInformationPopoverAnchor, setOpenInformationPopoverAnchor] =
    useState(null);
  const openInformationPopover = Boolean(openInformationPopoverAnchor);

  const handleInformationPopoverClick = (event) => {
    setOpenInformationPopoverAnchor(event.currentTarget);
  };
  const handleInformationPopoverClose = () => {
    setOpenInformationPopoverAnchor(null);
  };

  const [openAccountPopoverAnchor, setOpenAccountPopoverAnchor] =
    useState(null);
  const openAccountPopover = Boolean(openAccountPopoverAnchor);

  const handleAccountPopoverClick = (event) => {
    setOpenAccountPopoverAnchor(event.currentTarget);
  };
  const handleAccountPopoverClose = () => {
    setOpenAccountPopoverAnchor(null);
  };

  const [inviteUserDialogIsOpen, setInviteUserDialogIsOpen] = useState(false);
  const handleInviteUser = (show = false) => {
    setInviteUserDialogIsOpen(show);
  };

  const closeInviteUserDialog = () => {
    setInviteUserDialogIsOpen(false);
  };

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          backgroundColor: "#0054AE",
          height: "100vh",
          backgroundImage: `url(${SparkBottom})`,
          backgroundPosition: `bottom left`,
          backgroundSize: `32px 24px`,
          backgroundRepeat: "no-repeat"
        }}
      >
        <MenuList disablePadding>
          <SideNavBrandedHeader
            sx={{
              width: open ? "160px" : "64px"
            }}
          >
            <Link sx={{ width: "100%", height: "100%" }} href="/home" />
          </SideNavBrandedHeader>
          <Tooltip title={open ? "" : "Dashboard"} placement="right" arrow>
            <UpperSideNavMenuItem sx={{ width: open ? "160px" : "64px" }}>
              <ListItemButton
                selected={pathname === "/home"}
                id="anchorHome"
                href="/home"
                sx={upperListItemButtonSx}
              >
                <ListItemIcon id="dashboard" sx={listItemIconSx}>
                  {pathname.includes("/home") ? (
                    <SpaceDashboardIcon sx={upperIconSx} />
                  ) : (
                    <SpaceDashboardOutlinedIcon sx={upperIconSx} />
                  )}
                </ListItemIcon>
                <ListItemText primary="Dashboard" sx={listItemTextSx} />
              </ListItemButton>
            </UpperSideNavMenuItem>
          </Tooltip>
          <Tooltip
            title={open ? "" : "Analyses"}
            placement="right"
            arrow
            sx={{ tooltipSx }}
          >
            <UpperSideNavMenuItem sx={{ width: open ? "160px" : "64px" }}>
              <ListItemButton
                selected={
                  pathname.startsWith("/analyses") ||
                  pathname.startsWith("/newAnalysis")
                }
                href="/analyses"
                id="anchorMyAnalyses"
                sx={upperListItemButtonSx}
              >
                <ListItemIcon sx={listItemIconSx}>
                  {pathname.startsWith("/analyses") ||
                  pathname.startsWith("/newAnalysis") ? (
                    <PlayArrowIcon sx={upperIconSx} />
                  ) : (
                    <PlayArrowOutlinedIcon sx={upperIconSx} />
                  )}
                </ListItemIcon>
                <ListItemText primary="Analyses" sx={listItemTextSx} />
              </ListItemButton>
            </UpperSideNavMenuItem>
          </Tooltip>
          <Tooltip
            title={open ? "" : "Nodes"}
            placement="right"
            arrow
            sx={{ tooltipSx }}
          >
            <UpperSideNavMenuItem sx={{ width: open ? "160px" : "64px" }}>
              <ListItemButton
                selected={
                  pathname.startsWith("/allNodes") ||
                  pathname.startsWith("/registerNodes") ||
                  pathname.startsWith("/editNodeTags") ||
                  pathname.startsWith("/nodes")
                }
                href="/nodes"
                id="anchorAllNodes"
                sx={upperListItemButtonSx}
              >
                <ListItemIcon sx={listItemIconSx}>
                  {pathname.includes("Node") ? (
                    <SpokeIcon sx={upperIconSx} />
                  ) : (
                    <SpokeOutlinedIcon sx={upperIconSx} />
                  )}
                </ListItemIcon>
                <ListItemText primary="Nodes" sx={listItemTextSx} />
              </ListItemButton>
            </UpperSideNavMenuItem>
          </Tooltip>
        </MenuList>
        <MenuList sx={{ pb: "16px" }}>
          <Tooltip
            title={open ? "" : "AI Chat (BETA)"}
            placement="right"
            arrow
            sx={{ tooltipSx }}
          >
            <LowerSideNavMenuItem sx={{ display: "block" }}>
              <ListItemButton
                id="btnChat"
                sx={lowerListItemButtonSx}
                href="/chat"
                target="_blank"
              >
                <ListItemIcon sx={listItemIconSx}>
                  <BubbleChartIcon sx={lowerIconSx} />
                </ListItemIcon>
                <ListItemText primary="AI Chat" sx={listItemTextSx} />
                <ListItemText
                  primary="(BETA)"
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontSize: "0.5rem"
                    },
                    ...listItemTextSx
                  }}
                />
              </ListItemButton>
            </LowerSideNavMenuItem>
          </Tooltip>

          <Tooltip
            title={open ? "" : "Information"}
            placement="right"
            arrow
            sx={{ tooltipSx }}
          >
            <LowerSideNavMenuItem sx={{ display: "block" }}>
              <ListItemButton
                id="btnInformationMenu"
                sx={lowerListItemButtonSx}
                onClick={handleInformationPopoverClick}
                selected={
                  openInformationPopover ||
                  pathname === "/security" ||
                  pathname === "/terms-and-conditions" ||
                  pathname === "/data-collection-consent"
                }
              >
                <ListItemIcon sx={listItemIconSx}>
                  <HelpIcon sx={lowerIconSx} />
                </ListItemIcon>
                <ListItemText primary="Information" sx={listItemTextSx} />
              </ListItemButton>
            </LowerSideNavMenuItem>
          </Tooltip>
          <Tooltip
            title={open ? "" : "Account"}
            placement="right"
            arrow
            sx={{ tooltipSx }}
          >
            <LowerSideNavMenuItem sx={{ display: "block" }}>
              <ListItemButton
                id="btnAccountSettings"
                sx={lowerListItemButtonSx}
                onClick={handleAccountPopoverClick}
                selected={openAccountPopover || pathname === "/account"}
              >
                <ListItemIcon sx={listItemIconSx}>
                  <AccountCircleIcon sx={lowerIconSx} />
                </ListItemIcon>
                <ListItemText primary="Account" sx={listItemTextSx} />
              </ListItemButton>
            </LowerSideNavMenuItem>
          </Tooltip>
          <DrawerChevron
            sx={{
              width: open ? "160px" : "64px",
              justifyContent: open ? "flex-end" : "center"
            }}
          >
            <IconButton
              onClick={!open ? handleDrawerOpen : handleDrawerClose}
              aria-label={
                open ? "collapse side nav bar" : "expand side nav bar"
              }
              sx={{
                marginRight: open ? "8px" : "0px",
                "&:hover": { backgroundColor: "#00377C" }
              }}
            >
              {!open ? (
                <LastPageIcon
                  sx={{ color: "#ffffff", height: "20px", width: "20px" }}
                />
              ) : (
                <FirstPageIcon
                  sx={{ color: "#ffffff", height: "20px", width: "20px" }}
                />
              )}
            </IconButton>
          </DrawerChevron>
        </MenuList>
      </Stack>
      <Tour />
      {inviteUserDialogIsOpen && (
        <InviteUserDialog
          open={inviteUserDialogIsOpen}
          close={closeInviteUserDialog}
          allowedDomains={allowedEmailDomains}
        />
      )}
      <InformationMenu
        open={openInformationPopover}
        handlePopoverClose={handleInformationPopoverClose}
        openPopoverAnchor={openInformationPopoverAnchor}
      />
      <AccountMenu
        open={openAccountPopover}
        handleAccountPopoverClose={handleAccountPopoverClose}
        openAccountPopoverAnchor={openAccountPopoverAnchor}
        handleInviteUser={handleInviteUser}
        tenantName={tenantName}
      />
    </>
  );
};

export default SideNavBar;
