import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { json } from "react-router-dom";
import {
  useGetMembershipsQuery,
  useGetMyInvitationsQuery,
  wtpkAPI
} from "../../store/wtpkApi";
import { userActions } from "../../store/UserSlice";
import { termsAndConditionsActions } from "../../screens/TermsAndConditions/TermsAndConditionsSlice";
import { ApiPut } from "../../appServices/axiosService";

const TenantPicker = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const currentTenantId = useSelector((state) => state.user.current_tenant_id);

  const { data: memberships, isSuccess: gotMemberships } =
    useGetMembershipsQuery();
  const { data: invitationList, isSuccess: gotInvitations } =
    useGetMyInvitationsQuery();

  const handleChangeTenant = (_event, newTenantId) => {
    if (newTenantId !== currentTenantId) {
      const membershipObj = memberships.items.find(
        (item) => item.tenant_id === newTenantId
      );
      dispatch(
        userActions.updateTenantIdAndRole({
          currentTenantId: membershipObj.tenant_id,
          currentRoleName: membershipObj.member_role.role_name
        })
      );
      enqueueSnackbar(`Tenant changed to ${membershipObj.tenant_id}`, {
        variant: "success"
      });
    }
  };

  const generateTenantButton = (item) => {
    return <Button key={item.tenant_id}>{item.tenant_id}</Button>;
  };

  const generateTenantRadioButton = (item) => {
    return (
      <FormControlLabel
        value={item.tenant_id}
        control={<Radio />}
        label={item.tenant_id}
      />
    );
  };

  const revokeTermsAndConsent = async () => {
    const input = {
      preferences: {
        terms_accepted: false,
        data_collection_accepted: false
      }
    };
    const response = await ApiPut(
      `${window.runtime.REACT_APP_API_URL}/user/${auth.user.profile["custom:userId"]}`,
      auth.user,
      input,
      true
    );
    if (response && response.name !== "AxiosError") {
      dispatch(
        termsAndConditionsActions.setTermsAndConditions(
          response.user.preferences.terms_accepted
        )
      );
      dispatch(
        termsAndConditionsActions.setDataCollection(
          response.user.preferences.data_collection_accepted
        )
      );
      dispatch(
        wtpkAPI.endpoints.getCustomerInfo.initiate(
          auth?.user?.profile["custom:userId"],
          { subscribe: false, forceRefetch: true }
        )
      );
    } else
      throw json(
        {
          didNotAccept: true,
          message:
            "There was an error in sending your data. Please log out and sign back in."
        },
        {
          status: 403,
          statusText: "You must re-login, to accept the terms and conditions"
        }
      );
    return response;
  };

  return (
    <Stack spacing={1}>
      <FormControl>
        <FormLabel id="tenant-radio-buttons-group-label">
          Choose your Tenant:
        </FormLabel>
        <RadioGroup
          aria-labelledby="tenant-radio-buttons-group-label"
          defaultValue={currentTenantId}
          name="tenant-radio-buttons-group"
          onChange={handleChangeTenant}
        >
          {gotMemberships && memberships.items.map(generateTenantRadioButton)}
        </RadioGroup>
      </FormControl>

      <Typography>
        Your invites (log out and log back in to auto-accept)
      </Typography>
      <ButtonGroup orientation="vertical">
        {gotInvitations && invitationList.items.map(generateTenantButton)}
      </ButtonGroup>
      <Divider />
      <Button
        align="left"
        variant="contained"
        sx={{ width: "200px" }}
        onClick={revokeTermsAndConsent}
      >
        Revoke T&C and DCC
      </Button>
    </Stack>
  );
};

export default TenantPicker;
