import React from "react";
import { LinearProgress } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { columnDef, columnVisibilityDef } from "./NodeResultColumnDef";
import {
  useGetAnalysesQuery,
  useGetNodeResultsOfNodeQuery
} from "../../../store/wtpkApi";
import { dataTableHeaderToolbarGetter } from "../../../components/DataTableElements/DataTableHeaderToolbar";
import PaginationButtons from "../../../components/DataTableElements/PaginationButtons";

const NodeResultTable = ({ nodeId }) => {
  const { data: analysisData, isLoading: analysisDataLoading } =
    useGetAnalysesQuery({
      skip: nodeId === undefined
    });

  const { data: nodeResultData, isLoading: nodeResultDataLoading } =
    useGetNodeResultsOfNodeQuery(nodeId, {
      skip: nodeId === undefined
    });

  const isLoading = analysisDataLoading || nodeResultDataLoading;

  // parse the result data to add the analysis name and remove deleted analyses
  const parsedNodeResultData = isLoading
    ? []
    : nodeResultData?.items
        ?.map((nodeResult) => {
          return {
            analysis_name: analysisData.items.find(
              (analysis) => analysis.execution_id === nodeResult.execution_id
            )?.name,
            ...nodeResult
          };
        })
        .filter((nodeResult) => nodeResult.analysis_name !== undefined);
  // if analysis_name is undefined, the parent analysis was deleted.  Filter these out

  return (
    <DataGridPremium
      rows={parsedNodeResultData}
      columns={columnDef}
      loading={isLoading}
      getRowId={(row) => row.execution_id}
      pagination
      disableRowGrouping
      initialState={{
        sorting: {
          sortModel: [{ field: "date_started", sort: "desc" }]
        },
        columns: {
          columnVisibilityModel: columnVisibilityDef
        },
        density: "compact",
        pagination: {
          paginationModel: { pageSize: 10, page: 0 }
        }
      }}
      slots={{
        toolbar: dataTableHeaderToolbarGetter,
        loadingOverlay: LinearProgress,
        pagination: PaginationButtons
      }}
    />
  );
};

export default NodeResultTable;
