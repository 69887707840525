import React from "react";
import { Box, Chip } from "@mui/material";
import { generateColumnVisibilityDef } from "../../../appServices/resuableMethods";
import generateIconForStatus from "../../../utils/statusIconGeneration";

export const columnDef = [
  {
    field: "analysis_name",

    renderCell: (params) => <Box>{params.value}</Box>,

    headerName: "Analysis Name",
    sortable: true,
    hideable: false,
    filter: true,
    flex: 1,
    minWidth: 200
  },
  {
    field: "execution_id",
    filter: true,
    headerName: "Analysis ID",
    sortable: true,
    width: 320
  },
  {
    field: "template_id",
    headerName: "Analysis Type",
    sortable: true,
    flex: 1,
    minWidth: 270,
    valueGetter: (value) =>
      value === "19275d0a-a413-4440-ba25-88f1ceaebf33"
        ? "Custom VTune Template"
        : value
  },
  {
    field: "start_timestamp",
    headerName: "Start Date",
    type: "dateTime",
    valueGetter: (value) => value && new Date(value),
    sortable: true,
    flex: 0.7,
    minWidth: 190
  },
  {
    field: "end_timestamp",
    headerName: "Completed Date",
    type: "dateTime",
    valueGetter: (value) => value && new Date(value),
    sortable: true,
    flex: 0.7,
    minWidth: 190
  },
  {
    field: "started_by",
    headerName: "Started By",
    editable: false,
    minWidth: 200,
    valueGetter: (_, row) => {
      let userID = row.started_by;
      if (userID != null && userID.startsWith("IntelSSO_")) {
        userID = userID.substring(9);
      }
      return userID ?? "Unknown";
    }
  },
  {
    field: "target_count",
    headerName: "# Nodes",
    sortable: true,
    width: 70,
    align: "center",
    renderCell: (params) => {
      return params.row.heirarchy.length === 2 ? null : (
        <Chip
          size="small"
          color="secondary"
          label={params.value}
          sx={{ minWidth: "17px", height: "17px !important" }}
        />
      );
    }
  },
  {
    field: "profile_target",
    headerName: "Profile Target Type",
    sortable: true,
    width: 190,
    valueGetter: (value) => {
      switch (value) {
        case "start_new_process":
          return "New process";
        case "profile_system":
          return "Whole system";
        case "attach_by_process_name":
          return "Existing process name";
        case "attach_by_process_id":
          return "Existing process ID";
        default:
          return value;
      }
    }
  },
  {
    field: "profile_app_path",
    headerName: "Profile App Path",
    sortable: true,
    width: 200,
    valueGetter: (_, row) => {
      return row.profile_app_path || "N/A";
    }
  },
  {
    field: "status",
    headerName: "Status",
    width: 70,
    sortable: true,
    align: "center",
    renderCell: (params) => {
      return generateIconForStatus(params.value);
    }
  }
];

const fieldsVisibleByDefault = [
  "template_id",
  "start_timestamp",
  "target_count",
  "node_type",
  "status"
];

export const columnVisibilityDef = generateColumnVisibilityDef(
  columnDef,
  fieldsVisibleByDefault
);
