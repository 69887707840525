import React, { useEffect } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { customAnalysisFormsActions } from "../Slices/CustomAnalysisForms";

const WTPKSwitch = ({
  id,
  label,
  defaultChecked = false,
  disabled = false
}) => {
  const currentSwitchValue = useSelector(
    (state) => state.customAnalysisForms.value
  );
  const dispatch = useDispatch();
  const onSwitchFormToggle = (e) => {
    dispatch(
      customAnalysisFormsActions.setFormInput({
        type: "value",
        id: e.target.id,
        valueReceived: e.target.checked
      })
    );
  };

  useEffect(() => {
    dispatch(
      customAnalysisFormsActions.setFormInput({
        type: ["value", "valid", "statusMessage", "required"],
        id,
        valueReceived: [defaultChecked, null, null, false]
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControlLabel
      control={
        <Switch
          name={id}
          id={id}
          data-testid={id}
          onChange={onSwitchFormToggle}
          checked={currentSwitchValue[id] || false}
          disabled={disabled}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label={label}
      labelPlacement="end"
    />
  );
};

export default WTPKSwitch;
