/* eslint-disable no-alert */
/* istanbul ignore file */
import React from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import AuthContext from "./contexts/AuthContext";
import { userActions } from "./store/UserSlice";

const AuthRedirect = ({ children }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  dispatch(userActions.setAuthUser(auth.user));

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthRedirect;
