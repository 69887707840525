/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  selectedNodes: [],
  selectedNodesPreviewState: [],
  selectedTags: [],
  tagsToBeDeleted: [],
  registerNodesTags: [],
  tagEditorNodesTags: [],
  tagDeregistrationModalVisible: false,
  refreshTableValues: false,
  prepareForDeregistration: false,
  rowData: []
};

export const allNodesTagSlice = createSlice({
  name: "myFleetTags",
  initialState,
  reducers: {
    resetToInitialState: () => initialState,
    setSelectedNodes: (state, action) => {
      state.selectedNodes = action.payload;
    },
    setSelectedNodesPreviewState: (state, action) => {
      state.selectedNodesPreviewState = action.payload;
    },
    setRegisterNodesTags: (state, action) => {
      state.registerNodesTags = action.payload;
    },
    setTagEditorNodesTags: (state, action) => {
      state.tagEditorNodesTags = action.payload;
    },
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
    setTagsToBeDeleted: (state, action) => {
      state.tagsToBeDeleted = action.payload;
    },
    setTagDeregistrationModalVisible: (state, action) => {
      state.tagDeregistrationModalVisible = action.payload;
    },
    setRefreshTableValues: (state, action) => {
      state.refreshTableValues = action.payload;
    },
    setPrepareForDeregistration: (state, action) => {
      state.prepareForDeregistration = action.payload;
    },
    setRowData: (state, action) => {
      state.rowData = action.payload;
    }
  }
});

export const allNodesTagActions = allNodesTagSlice.actions;
