/* eslint-disable no-param-reassign */
/* istanbul ignore file */
import React, { useEffect } from "react";
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  json,
  redirect
} from "react-router-dom";
import { CircularProgress, Stack, Typography } from "@mui/material";
import "./App.css";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";

import Home from "./screens/Home/Home";
import Layout from "./components/Layout/Layout";
import AllAnalysis from "./screens/Analysis/AllAnalysis/AllAnalysis";
import AnalysisStatus, {
  analysisStatusLoader
} from "./screens/Analysis/AnalysisStatus/AnalysisStatus";
import ErrorScreen from "./screens/Error/ErrorScreen";
import VTuneResultLoader from "./screens/Analysis/Results/VTuneResultLoader";
import SecurityScreen from "./screens/Security/Security";
import AllNodesScreen from "./screens/AllNodes/AllNodesScreen";
import LaunchAnalysisScreen from "./screens/Analysis/LaunchAnalysis/LaunchAnalysisScreen";
import AnalysisOutput, {
  analysisOutputLoader
} from "./screens/Analysis/AnalysisOutput";
import { loader as SelectNodesTableLoader } from "./screens/Analysis/LaunchAnalysis/components/SelectNodesTable";

import Landing from "./screens/Landing/Landing";
import { loader as AnalysisTemplateLoader } from "./screens/Analysis/LaunchAnalysis/components/AnalysisTypeCard";

import TermsAndConditions, {
  action as TermsAndConditionsAction
} from "./screens/TermsAndConditions/TermsAndConditions";
import DataCollectionConsent, {
  action as DataCollectionAction
} from "./screens/TermsAndConditions/DataCollectionConsent";
import LaunchAnalysisAction from "./screens/Analysis/LaunchAnalysis/LaunchAnalysisAction";
import RegisterNodes from "./screens/RegisterNodes/RegisterNodes";
import NodeTagEditor from "./screens/NodeTagEditor/NodeTagEditor";
import { userActions } from "./store/UserSlice";
import NoTenantScreen from "./screens/Error/NoTenantScreen";
import { parseRoleList } from "./appServices/UserManagement.service";
import Account, { accountLoader } from "./screens/Account/Account";
import ProtectedRoute from "./Authentication/ProtectedRoute";
import AuthRedirect from "./AuthRedirect";
import { logoutAllTabs } from "./utils/broadcaster";
import { Role } from "./appServices/role";
import {
  useGetCustomerInfoQuery,
  useGetMembershipsQuery,
  useGetRolesQuery
} from "./store/wtpkApi";
import TenantPicker from "./components/TenantPicker/TenantPicker";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import ChatLoader from "./components/ChatLoader/ChatLoader";
import NodeDetails from "./screens/NodeDetails/NodeDetails";

const App = () => {
  const currentAuth = useAuth();
  const { data: roleData, isLoading: roleDataIsLoading } = useGetRolesQuery(
    undefined,
    {
      skip: !currentAuth.isAuthenticated
    }
  );
  const { data: membershipData, isLoading: membershipDataIsLoading } =
    useGetMembershipsQuery(undefined, {
      skip: !currentAuth.isAuthenticated
    });
  // Default to the first tenant in the list
  // Currently the plan is to only support one tenant per user
  const { data: customerInfo, isLoading: customerInfoIsLoading } =
    useGetCustomerInfoQuery(currentAuth?.user?.profile["custom:userId"], {
      skip:
        currentAuth?.user?.profile["custom:userId"] === null ||
        currentAuth?.user?.profile["custom:userId"] === undefined
    });

  const dispatch = useDispatch();
  const URLparams = new URLSearchParams(document.location.search);
  const role = useSelector((state) => state.user.current_role);

  useEffect(() => {
    logoutAllTabs(currentAuth, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateTenantMemberInfo = async () => {
    if (!Role.wereRolesUpdated() && roleData) {
      const roleDefs = parseRoleList(roleData);
      Role.updateRoleDefinitions(roleDefs);
    }
    const memberInfo = membershipData; // await getMemberInformation(currentAuth);
    if (memberInfo && memberInfo.name !== "AxiosError") {
      if (memberInfo.items.length === 0) {
        throw redirect("/noTenant");
      }
      const memberships = memberInfo.items.reduce((obj, item) => {
        return {
          ...obj,
          [item.tenant_id]: {
            role_id: item.member_role.role_id,
            role_name: item.member_role.role_name,
            date_created: item.date_created
          }
        };
      }, {});
      dispatch(userActions.setMemberships(memberships));
    }
  };

  const checkAuthentication = async () => {
    if (!currentAuth || !currentAuth?.isAuthenticated) return currentAuth;
    if (
      (customerInfo && !customerInfo?.user?.preferences?.terms_accepted) ||
      (customerInfo &&
        !customerInfo?.user?.preferences?.data_collection_accepted)
    ) {
      throw redirect("/terms-and-conditions");
    }
    await updateTenantMemberInfo(currentAuth);

    return currentAuth;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<Landing />} path="/" />
        <Route exact path="/login" element={<Navigate to="/" />} />
        <Route
          path="/loading"
          element={
            <Stack
              sx={{ width: "100vw", height: "100vh" }}
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h2">Loading...</Typography>
              <CircularProgress color="inherit" size="14rem" />
            </Stack>
          }
        />
        <Route
          errorElement={<ErrorScreen />}
          path="/VTuneResultLoader/:nodeExecutionId"
          element={<VTuneResultLoader />}
        />
        <Route
          errorElement={<ErrorScreen />}
          path="/chat"
          element={<ChatLoader />}
        />
        <Route
          errorElement={<ErrorScreen />}
          path="/chat/:chatID"
          element={<ChatLoader />}
        />
        {
          // chatLoader routes can be removed once backend is updated
        }
        <Route
          errorElement={<ErrorScreen />}
          path="/chatLoader"
          element={<ChatLoader />}
        />
        <Route
          errorElement={<ErrorScreen />}
          path="/chatLoader/:chatID"
          element={<ChatLoader />}
        />
        <Route path="oauth-callback" element={<AuthRedirect />} />
        <Route
          path="terms-and-conditions"
          errorElement={<ErrorScreen />}
          element={<TermsAndConditions />}
          action={async ({ request }) => {
            return TermsAndConditionsAction(request, dispatch, currentAuth);
          }}
        />
        <Route
          path="data-collection-consent"
          errorElement={<ErrorScreen />}
          element={<DataCollectionConsent />}
          action={async ({ request }) => {
            return DataCollectionAction(request, dispatch, currentAuth);
          }}
        />
        <Route
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
          errorElement={<ErrorScreen />}
          loader={async () => {
            await checkAuthentication();
          }}
        >
          {role.hasPermission("canRegisterNodes") && (
            <Route
              element={<RegisterNodes />}
              path="/registerNodes"
              loader={async () => {
                const nodeTableResponse = await SelectNodesTableLoader(
                  currentAuth
                );
                return nodeTableResponse;
              }}
            />
          )}
          <Route
            element={<NodeTagEditor />}
            path="/editNodeTags"
            loader={async () => {
              await checkAuthentication();
              const nodeTableResponse = await SelectNodesTableLoader(
                currentAuth
              );
              return nodeTableResponse;
            }}
          />
          <Route
            path="information/terms-and-conditions"
            errorElement={<ErrorScreen />}
            element={<TermsAndConditions />}
          />
          <Route
            path="information/data-collection-consent"
            errorElement={<ErrorScreen />}
            element={<DataCollectionConsent />}
          />
          <Route
            path="home"
            element={<Home />}
            loader={async () => {
              await checkAuthentication();
            }}
            shouldRevalidate={false}
          />
          <Route
            path="sharedAnalysis"
            exact
            element={
              <Navigate to={`/newAnalysis?cmd=${URLparams.get("cmd")}`} />
            }
          />
          <Route
            exact
            path="newAnalysis"
            element={<LaunchAnalysisScreen />}
            action={async ({ request }) => {
              await checkAuthentication();
              return LaunchAnalysisAction(request, dispatch, currentAuth);
            }}
            loader={async () => {
              const nodeTableResponse = await SelectNodesTableLoader(
                currentAuth
              );
              const analysisTemplateResponse = await AnalysisTemplateLoader(
                currentAuth
              );
              return [nodeTableResponse, analysisTemplateResponse];
            }}
          />
          <Route path="nodes" element={<AllNodesScreen />} />
          <Route path="/nodes/:nodeId" element={<NodeDetails />} />
          <Route path="allNodes" element={<AllNodesScreen />} />
          <Route path="myNotes">
            <Route
              exact
              path="analysis/:executionId"
              element={<AnalysisStatus />}
            />
          </Route>

          <Route path="analyses">
            <Route index element={<AllAnalysis />} />
            <Route path=":executionId">
              <Route
                index
                element={<AnalysisStatus />}
                errorElement={<ErrorScreen />}
                loader={async ({ params }) =>
                  analysisStatusLoader(currentAuth, params, dispatch)
                }
              />
              <Route
                path=":nodeId"
                element={<AnalysisOutput />}
                errorElement={<ErrorScreen />}
                loader={async ({ params }) => {
                  return analysisOutputLoader(params, currentAuth);
                }}
              />
            </Route>
          </Route>

          <Route path="security" element={<SecurityScreen />} />
          <Route path="tenant" element={<TenantPicker />} />

          <Route
            path="/account"
            element={<Account />}
            loader={async () => accountLoader(currentAuth)}
          />
          <Route
            path="timedOut"
            loader={() => {
              throw json(
                { message: "Not Authorized" },
                {
                  status: 401,
                  statusText:
                    "Your login has expired.  Please login again by clicking the 'Login' button."
                }
              );
            }}
          />
          <Route
            path="*"
            loader={() => {
              throw json(
                { message: "Page Not Found" },
                {
                  status: 404,
                  statusText: "The page you were looking for does not exist."
                }
              );
            }}
          />
        </Route>
        <Route
          path="/noTenant"
          errorElement={<NoTenantScreen />}
          loader={() => {
            throw json(
              { message: "NO_TENANT" },
              {
                status: 403,
                statusText: "User is not a member of any tenant"
              }
            );
          }}
        />
      </Route>
    )
  );

  if (
    currentAuth.isLoading ||
    (currentAuth.isAuthenticated &&
      (roleDataIsLoading || membershipDataIsLoading || customerInfoIsLoading))
  ) {
    return <LoadingSpinner />;
  }

  return <RouterProvider router={router} />;
};

export default App;
