import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import AllNodesTable from "./components/AllNodesTable";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";

const AllNodesScreen = () => {
  const role = useSelector((state) => state.user.current_role);
  const allNodesData = useSelector((state) => state.nodeTags.rowData);
  const userCanRegisterNodes = role.hasPermission("canRegisterNodes");
  const registerNodesButtonDef = userCanRegisterNodes
    ? { label: "Register New Node", href: "/registerNodes" }
    : undefined;
  return (
    <>
      <ScreenHeader
        title="Nodes"
        heroButtonDef={registerNodesButtonDef}
        count={allNodesData.length}
      />
      <Box width="100%" height="100%" minHeight="400px">
        <AllNodesTable />
      </Box>
    </>
  );
};

export default AllNodesScreen;
