import React from "react";
import MuiPagination from "@mui/material/Pagination";
import {
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSizeSelector
} from "@mui/x-data-grid-premium";

const Pagination = ({ page, onPageChange, className }) => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  return pageSize === -1 ? null : (
    <MuiPagination
      color="secondary"
      shape="rounded"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      sx={{
        "&>.MuiPagination-ul > li > .MuiPaginationItem-text": {
          color: "#0054AE"
        },
        "&>.MuiPagination-ul .Mui-selected": {
          boxShadow: "0px 2px 3px 0px darkgrey"
        },
        "&>.MuiPagination-ul .Mui-selected.MuiPaginationItem-text": {
          color: "#FFFFFF"
        },
        "&>.MuiPagination-ul > li > .MuiPaginationItem-previousNext": {
          color: "rgba(0, 0, 0, 0.56)"
        }
      }}
    />
  );
};

const PaginationButtons = (props) => {
  return (
    <GridPagination
      labelDisplayedRows={() => ""}
      rowsPerPageOptions={[]}
      ActionsComponent={Pagination}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default PaginationButtons;
