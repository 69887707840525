import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useSnackbar } from "notistack";
import { DataGridPremium, GridActionsCellItem } from "@mui/x-data-grid-premium";
import IconButton from "@mui/material/IconButton";
import BarChartIcon from "@mui/icons-material/BarChart";
import DownloadIcon from "@mui/icons-material/Download";
import {
  useNavigate,
  useParams,
  useRevalidator,
  useLoaderData
} from "react-router-dom";
import {
  Stack,
  Box,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  Button,
  Grid
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AuthContext from "../../../contexts/AuthContext";
import * as AnalysisResponse from "./AnalysisStatus.service";
import { analysisStatusActions } from "../slices/AnalysisSlices";
import {
  CurrentSetOfAnalyzers,
  reportsByAnalysisType
} from "../../../components/AnalysisTypes/AnalyzersDefinitions";
import { columnDef, columnVisibilityDef } from "./AnalysisStatusColumnDef";
import UpdateAnalysisNameModal from "../components/UpdateAnalysisNameModal";
import DeleteAnalysisDialog from "../components/DeleteAnalysisDialog";
import ReCreateDialog from "../AllAnalysis/ReCreateDialog";
import {
  GetAnalysisExecutionInfo,
  isCustomVTuneTemplate
} from "../../../appServices/AnalysisTemplateMethods";

import { reduxStore } from "../../../store/ReduxStore";
import { dataTableHeaderToolbarGetter } from "../../../components/DataTableElements/DataTableHeaderToolbar";
import { getAPSLink } from "./AnalysisStatus.service";
import PaginationButtons from "../../../components/DataTableElements/PaginationButtons";
import APSReportSummary from "./APSReportSummary";
import ScreenHeader from "../../../components/ScreenHeader/ScreenHeader";
import * as CommonMethods from "../../../appServices/resuableMethods";
import {
  generateNEDictsFromAnalysisNameAndNEs,
  generateDictFromAnalysisStatus
} from "../../../utils/chatParams";
import { userActions } from "../../../store/UserSlice";
import { generateStatusIconWithLabel } from "../../../utils/statusIconGeneration";

const PROFILE_TARGET_CONFIG = {
  start_new_process: "New process",
  profile_system: "Whole system",
  attach_by_process_name: "Existing process name",
  attach_by_process_id: "Existing process ID"
};

const getNodesWithPMU = (analysisStatusReponse) => {
  return analysisStatusReponse.filter((item) =>
    item.node?.inventory?.vtune_context?.LinuxPerfCapabilities.includes("cpu:")
  );
};

const getOtherAPSReportStatus = (executionResponse, analysisStatusReponse) => {
  // WRONG_ANALYSIS_TYPE
  if (executionResponse.template_id !== "PerformanceSnapshot") {
    return "WRONG_ANALYSIS_TYPE";
  }

  // ONLY_ONE_NODE
  if (analysisStatusReponse.length === 1) {
    return "ONLY_ONE_NODE";
  }

  // NOT_ENOUGH_PMU_NODES
  const pmuNodes = getNodesWithPMU(analysisStatusReponse);
  if (pmuNodes.length < 2) {
    return "NOT_ENOUGH_PMU_NODES";
  }

  // NOT_ENOUGH_SUCCESSFUL_PMU_NODES
  if (executionResponse.status === "FAILED") {
    return "NOT_ENOUGH_SUCCESSFUL_PMU_NODES";
  }
  if (
    executionResponse.status !== "PENDING" &&
    executionResponse.status !== "RUNNING"
  ) {
    const successfulPMUNodes = pmuNodes.filter(
      (item) => item.status === "COMPLETED"
    );
    if (successfulPMUNodes.length < 1) {
      return "NOT_ENOUGH_SUCCESSFUL_PMU_NODES";
    }
  }
  return "PENDING";
};

const getTemplateName = (templateId, templateDetails) => {
  if (isCustomVTuneTemplate(templateId)) {
    return "Intel® VTune™ Profiler Custom Template";
  }
  if (CurrentSetOfAnalyzers[templateId]) {
    return CurrentSetOfAnalyzers[templateId].title;
  }
  if (templateDetails.length > 0) {
    const result = templateDetails.filter(
      (item) => item.template_id === templateId
    );
    if (result.length > 0) {
      return result[0].name;
    }
  }
  return "N/A";
};

export const analysisStatusLoader = async (auth, params, dispatch) => {
  const executionResponse = await AnalysisResponse.getAnalysisExecution(
    auth.user,
    params.executionId
  );

  if (
    executionResponse.name?.isAxiosError ||
    executionResponse.name === "AxiosError"
  ) {
    const errorDetails = {
      status: executionResponse.response.status,
      statusText: executionResponse.response.statusText,
      data: { message: executionResponse.message }
    };
    throw errorDetails;
  }

  const apsReportId = executionResponse.aps_report_id;
  let apsReport = null;
  if (apsReportId !== null && apsReportId !== undefined) {
    const apsResponse = await AnalysisResponse.getAPSReport(
      auth.user,
      apsReportId
    );
    apsReport = apsResponse.result;
  }
  const responseTemplateDetails = await AnalysisResponse.getTemplateDetails(
    auth.user
  );

  const resultTemplateName = getTemplateName(
    executionResponse.template_id,
    responseTemplateDetails
  );
  const analysisStatusReponse = await GetAnalysisExecutionInfo(
    params.executionId,
    auth.user,
    dispatch,
    resultTemplateName
  );

  dispatch(
    analysisStatusActions.setSliceKeys({
      analysisExecutionid: params.executionId,
      analysisExecutionName: executionResponse.name,
      templateID: executionResponse.template_id,
      aps_report_id: apsReportId,
      dateStarted: executionResponse.date_started,
      dateFinished: executionResponse.date_finished,
      target: executionResponse.target,
      analysisNodes: executionResponse.node_ids,
      startedBy: executionResponse.started_by,
      executionStatus: executionResponse.status,
      aps_report_status:
        apsReport?.aps_report_status ||
        getOtherAPSReportStatus(executionResponse, analysisStatusReponse),
      templateDetails: responseTemplateDetails,
      templateName: resultTemplateName
    })
  );

  return executionResponse;
};

const untoggleableFields = ["__check__", "actions"];

const getTogglableColumns = (columns) => {
  return columns
    .filter((column) => !untoggleableFields.includes(column.field))
    .map((column) => column.field);
};

const AnalysisStatus = () => {
  const auth = useContext(AuthContext);
  const role = useSelector((state) => state.user.current_role);
  const templateID = useSelector((state) => state.analysisStatus.templateID);

  const { executionId } = useParams();
  const revalidator = useRevalidator();

  const analysisTimestamp = useSelector(
    (state) => state.analysisStatus.analysisTimestamp
  );
  const analysisExecutionName = useSelector(
    (state) => state.analysisStatus.analysisExecutionName
  );
  const analysisResultsTableData = useSelector(
    (state) => state.analysisStatus.analysisResultsTableData
  );
  const apsReportId = useSelector(
    (state) => state.analysisStatus.aps_report_id
  );
  const apsReportStatus = useSelector(
    (state) => state.analysisStatus.aps_report_status
  );
  const analysisStartTimestamp = useSelector(
    (state) => state.analysisStatus.dateStarted
  );
  const analysisEndTimestamp = useSelector(
    (state) => state.analysisStatus.dateFinished
  );
  const targetType = useSelector(
    (state) => state.analysisStatus.target?.type_id
  );
  const targetPath = useSelector((state) => state.analysisStatus.target?.app);
  const analysisNodes = useSelector(
    (state) => state.analysisStatus.analysisNodes
  );
  const analysisStartedBy = useSelector(
    (state) => state.analysisStatus.startedBy
  );
  const executionStatus = useSelector(
    (state) => state.analysisStatus.executionStatus
  );
  const analysisTemplateName = useSelector(
    (state) => state.analysisStatus.templateName
  );

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [showRecreateModal, setShowRecreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columnVisibilityDef);
  const executionData = useLoaderData();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (executionId !== null) {
    dispatch(
      analysisStatusActions.setSliceKeys({ analysisExecutionID: executionId })
    );

    dispatch(userActions.userViewedAnalysis(executionId));
  }

  const handleClickMenuDropdown = (event) => {
    setMenuAnchorEl(event?.currentTarget);
  };

  const handleCloseMenuDropdown = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuOpenAIChat = () => {
    window.open(
      `/chat?ne=${encodeURI(
        window.btoa(
          JSON.stringify(
            generateNEDictsFromAnalysisNameAndNEs(
              analysisExecutionName,
              rowData
            )
          )
        )
      )}`,
      "_blank"
    );
  };

  const handleRecreateDialog = async () => {
    setMenuAnchorEl(null);
    setShowRecreateModal(!showRecreateModal);
  };

  useEffect(() => {
    setRowData(analysisResultsTableData);
  }, [analysisResultsTableData]);

  useEffect(() => {
    dispatch(
      analysisStatusActions.setSliceKeys({
        templateName: analysisTemplateName,
        timeStamp: analysisTimestamp
      })
    );
  }, [analysisTemplateName, analysisTimestamp, dispatch]);

  useEffect(() => {
    // This useEffect is used for setInterval
    let interval = "";
    if (rowData.length > 0)
      if (
        rowData.find((e) => e.status === "PENDING" || e.status === "RUNNING") ||
        (apsReportId && apsReportStatus === "PENDING")
      ) {
        interval = setInterval(() => {
          revalidator.revalidate();
        }, 5000);
      }
    return () => {
      clearInterval(interval);
    };
  }, [rowData, apsReportId, apsReportStatus, revalidator]);

  const showEditModalDialog = () => {
    setShowRenameModal(true);
    dispatch(
      analysisStatusActions.setSliceKeys({
        analysisExecutionID: executionId
      })
    );
  };

  const handleDelete = () => {
    const timestamp = analysisTimestamp.replace("Completed At   ", "");
    setShowDeleteModal(true);
    setMenuAnchorEl(null);
    dispatch(
      analysisStatusActions.setSliceKeys({
        analysisExecutionID: executionId,
        analysisTimestamp: timestamp,
        deletedAnalysis: [executionId]
      })
    );
  };

  const handleEditModalClose = () => {
    setShowRenameModal(false);
  };

  const handleDeleteModalClose = (event) => {
    setShowDeleteModal(false);
    if (event.currentTarget.innerText === "CLOSE") {
      navigate("/analyses");
    }
  };

  const fetchResultUrl = async () => {
    const response = await getAPSLink(auth.user, apsReportId);

    if (response instanceof Error) {
      enqueueSnackbar(`Could not fetch the log details: ${response.message}`, {
        variant: "error"
      });
    }
    if (!(response instanceof Error)) {
      window.open(response.result_url);
    }
  };
  const fetchPerformanceSnapshot = (e) => {
    e.preventDefault();
    fetchResultUrl();
    setMenuAnchorEl(null);
  };

  const downloadVTuneResults = async (data, nodeName, reportType, format) => {
    let timestamp = analysisTimestamp.replace("Completed At   ", "");
    if (analysisTimestamp.slice(-2) === "PM")
      timestamp = `${timestamp.substring(0, timestamp.length - 3)}PM`;
    else timestamp = `${timestamp.substring(0, timestamp.length - 3)}AM`;
    let downloadName = `${
      data.type
    }-${reportType}-${nodeName}-${timestamp.replace(", ", "_")}`;
    downloadName = downloadName.replaceAll(" ", "_");
    const response = await AnalysisResponse.downloadVtuneResult(
      auth.user,
      data.node_execution_id,
      downloadName,
      `${reportType}.${format}`
    );

    if (response instanceof Error) {
      enqueueSnackbar(
        `Could not fetch the analysis details: ${response.message}`,
        { variant: "error" }
      );
    } else if (response !== undefined) {
      window.open(response.result_url);
    }
  };

  const downloadVTuneResultSet = (data, nodename) => {
    downloadVTuneResults(data, nodename, "result", "zip");
  };

  const downloadVTuneResultText = (data, nodename, reportType) => {
    downloadVTuneResults(data, nodename, `${reportType}_report`, "txt");
  };

  const allColumnDef = [
    ...columnDef,
    {
      field: "actions",
      type: "actions",
      width: 240,
      getActions: (params) => {
        let actions = [];
        if (
          params.row.status === "COMPLETED" ||
          params.row.status === "FAILED" ||
          params.row.status === "READY"
        ) {
          const nodeName = params.row.name_object.primary
            ? params.row.name_object.primary
            : params.row.name_object.secondary1;
          const downloadIndividualReportActions =
            reportsByAnalysisType[templateID]?.map((report) => {
              return (
                <GridActionsCellItem
                  sx={{ textTransform: "capitalize" }}
                  label={report[0].toUpperCase() + report.slice(1)}
                  id={`btn-analysis-download-${report}`}
                  disabled={params.row.status === "FAILED"}
                  onClick={() =>
                    downloadVTuneResultText(params.row, nodeName, report)
                  }
                  size="small"
                  color="black"
                  title={`Download VTune ${report} report.`}
                  showInMenu
                />
              );
            }) || [];

          if (downloadIndividualReportActions.length > 0) {
            downloadIndividualReportActions.unshift(
              <GridActionsCellItem
                label="Individual reports"
                disabled
                showInMenu
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.6)"
                }}
              />
            );
          }

          const downloadReportActions = [
            <GridActionsCellItem
              label="VTune results (.zip)"
              id="btn-analysis-download"
              disabled={params.row.status === "FAILED"}
              onClick={() => downloadVTuneResultSet(params.row, nodeName)}
              size="small"
              color="rgba(0, 0, 0, 0.87)"
              title="Download VTune results. Useful for loading into a local copy of VTune for Source Code Analysis"
              showInMenu
              sx={{ borderBottom: "2px solid #F7F7F7" }}
            />,
            ...downloadIndividualReportActions
          ];
          const activeDownloads =
            params.row.status !== "FAILED"
              ? downloadReportActions
              : [
                  <IconButton
                    aria-label="View Analysis LogDownload Reports"
                    size="small"
                    color="black"
                    title="Download Reports"
                    disabled
                  >
                    <DownloadIcon />
                  </IconButton>
                ];

          actions = [
            <Grid container justifyContent="center" alignItems="center">
              {params.row.type === "ebpf" && (
                <Box>
                  <Button
                    id="btn-analysis-output"
                    LinkComponent="a"
                    color="primary"
                    size="small"
                    variant="text"
                    href={`/analyses/${params.row.execution_id}/${params.row.node_execution_id}`}
                    title={
                      params.row.status === "COMPLETED"
                        ? "View Analysis Results/Logs"
                        : "View Logs"
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    View Output
                  </Button>
                </Box>
              )}
              {params.row.type.startsWith("vtune") && (
                <Stack direction="row" spacing={0.5}>
                  <Button
                    variant="text"
                    LinkComponent="a"
                    disabled={params.row.status === "FAILED"}
                    href={`/VTuneResultLoader/${params.row.node_execution_id}`}
                    color="primary"
                    size="small"
                    target="_blank"
                    title="Open Analysis Results"
                    id="btn-analysis-results"
                    rel="noreferrer"
                    sx={{ fontWeight: "501" }}
                  >
                    View Result
                  </Button>
                  <IconButton
                    aria-label="AI Chat (BETA)"
                    size="small"
                    color="black"
                    title="AI Chat (BETA)"
                    disabled={
                      params.row.status === "PENDING" ||
                      params.row.status === "RUNNING"
                    }
                    href={`/chat?ne=${encodeURI(
                      window.btoa(
                        JSON.stringify([
                          generateDictFromAnalysisStatus(
                            reduxStore.getState().analysisStatus
                              ?.analysisExecutionName,
                            params.row?.definition?.type_id,
                            params.row.node
                              ? params.row.node.computer_name
                              : params.row.node_id,
                            params.row?.node_execution_id,
                            params.row?.status,
                            params.row?.date_started
                          )
                        ])
                      )
                    )}`}
                    target="_blank"
                  >
                    <BubbleChartIcon />
                  </IconButton>
                  <IconButton
                    aria-label="View Analysis Log"
                    href={`/analyses/${params.row.execution_id}/${params.row.node_execution_id}`}
                    size="small"
                    color="black"
                    title="View Analysis Log"
                    target="_blank"
                  >
                    <InsertDriveFileIcon />
                  </IconButton>
                </Stack>
              )}
              {params.row.status === "READY" && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<BarChartIcon />}
                >
                  View Report
                </Button>
              )}
            </Grid>,
            ...activeDownloads
          ];
        }
        return actions;
      }
    }
  ];
  return (
    <>
      <ScreenHeader
        title={
          <>
            {analysisExecutionName}{" "}
            {role.hasPermission("canRenameAnalyses") && (
              <IconButton
                aria-label="rename"
                size="small"
                color="primary"
                title="Rename the analysis execution"
                onClick={showEditModalDialog}
              >
                <BorderColorIcon />
              </IconButton>
            )}
          </>
        }
        crumbDefs={[
          { label: "Analyses", href: "/analyses" },
          { label: "Analysis Details" }
        ]}
        heroButtonDef={{
          label: "Analysis Options",
          isMenu: true,
          btnAction: handleClickMenuDropdown,
          isOpen: menuOpen
        }}
      />
      <Stack width="100%" alignItems="left">
        <Box sx={{ display: "flex" }}>
          <List disableGutters sx={{ minWidth: "70%", p: 0 }}>
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Analysis ID
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
                {executionId}
              </Typography>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Analysis Type
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
                {analysisTemplateName}
              </Typography>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Start Date
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
                {CommonMethods.ConvertDateTime(analysisStartTimestamp * 1000)}
              </Typography>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Completed Date
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
                {analysisEndTimestamp &&
                  CommonMethods.ConvertDateTime(analysisEndTimestamp * 1000)}
              </Typography>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Started by
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
                {analysisStartedBy}
              </Typography>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Number of nodes
              </Typography>
              <ListItemText>
                <Avatar
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: 10,
                    backgroundColor: "#8F5DA2"
                  }}
                >
                  {analysisNodes?.length}
                </Avatar>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Profile Target Type
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
                {PROFILE_TARGET_CONFIG[targetType]}
              </Typography>
            </ListItem>
            {targetType !== "profile_system" && (
              <ListItem sx={{ p: 0 }}>
                <Typography variant="body2" sx={{ minWidth: "230px" }}>
                  Profile App Path
                </Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
                  {targetPath}
                </Typography>
              </ListItem>
            )}
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Status
              </Typography>
              {executionStatus && generateStatusIconWithLabel(executionStatus)}
            </ListItem>
          </List>
          {apsReportStatus !== "WRONG_ANALYSIS_TYPE" && (
            <APSReportSummary
              apsReportId={apsReportId}
              apsReportStatus={apsReportStatus}
            />
          )}
        </Box>
        <Box sx={{ width: "100%", paddingTop: "30px" }}>
          <DataGridPremium
            columns={allColumnDef}
            rows={rowData}
            autoHeight
            getRowHeight={() => "auto"}
            getRowId={(row) => row.node_execution_id}
            onColumnVisibilityModelChange={(newModel) => {
              if (
                Object.keys(newModel).length === 2 &&
                newModel.name_object === true &&
                newModel.actions === true
              ) {
                const updatedColumns = { ...visibleColumns };
                Object.keys(updatedColumns).forEach((key) => {
                  updatedColumns[key] = true;
                });
                setVisibleColumns(updatedColumns);
              } else {
                setVisibleColumns({ ...visibleColumns, ...newModel });
              }
            }}
            pagination
            slots={{
              toolbar: dataTableHeaderToolbarGetter,
              loadingOverlay: LinearProgress,
              pagination: PaginationButtons,
              moreActionsIcon: DownloadIcon
            }}
            slotProps={{
              toolbar: {
                showSearch: false
              },
              columnsManagement: {
                getTogglableColumns
              }
            }}
            disableDensitySelector
            disableRowSelectionOnClick
            initialState={{
              columns: {
                columnVisibilityModel: columnVisibilityDef
              },
              pagination: {
                paginationModel: { pageSize: 10, page: 0 }
              }
            }}
          />
        </Box>
        <Menu
          id="screen-header-dropdown-options"
          MenuListProps={{
            "aria-labelledby": "screen-header-dropdown-button"
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleCloseMenuDropdown}
        >
          <MenuItem
            disabled={
              executionStatus === "PENDING" || executionStatus === "RUNNING"
            }
            onClick={handleMenuOpenAIChat}
          >
            <ListItemIcon>
              <BubbleChartIcon />
            </ListItemIcon>
            <ListItemText>AI Chat (BETA)</ListItemText>
          </MenuItem>
          {apsReportStatus === "READY" && (
            <MenuItem onClick={fetchPerformanceSnapshot}>
              <ListItemIcon>
                <FindInPageIcon />
              </ListItemIcon>
              <ListItemText>View Application Performance Snapshot</ListItemText>
            </MenuItem>
          )}
          {role.hasPermission("canCreateAnalyses") && (
            <MenuItem onClick={handleRecreateDialog}>
              <ListItemIcon>
                <ControlPointDuplicateIcon />
              </ListItemIcon>
              <ListItemText>Recreate analysis</ListItemText>
            </MenuItem>
          )}
          {role.hasPermission("canDeleteAnalyses") && (
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete analysis</ListItemText>
            </MenuItem>
          )}
        </Menu>
        <DeleteAnalysisDialog
          show={showDeleteModal}
          close={handleDeleteModalClose}
        />
        <ReCreateDialog
          open={showRecreateModal}
          handler={handleRecreateDialog}
          analysisExecutionId={executionData?.execution_id}
        />
        <UpdateAnalysisNameModal
          show={showRenameModal}
          close={handleEditModalClose}
        />
      </Stack>
    </>
  );
};

export default AnalysisStatus;
