/* eslint-disable camelcase */
import EditNoteIcon from "@mui/icons-material/EditNote";
import { analysisTemplateActions } from "../../slices/LaunchAnalysisSlices";
import { profileTemplates } from "./AnalysisFormCardUtils";
import { CurrentSetOfAnalyzers } from "../../../../components/AnalysisTypes/AnalyzersDefinitions";
import { customAnalysisFormsActions } from "../../../../components/AnalysisTypes/Slices/CustomAnalysisForms";

export const customVtuneTemplate = {
  template_id: "19275d0a-a413-4440-ba25-88f1ceaebf33",
  name: "Custom Template",
  group_by: "Intel® VTune™ Profiler",
  icon: EditNoteIcon,
  description:
    "Use the Intel® VTune™ Profiler UI to define a custom profiling command",
  type: "vtune",
  vtune_tree_position: "9.9.9",
  definition: {
    type_id: "vtune"
  },
  target: {
    type_id: "",
    app: "",
    app_working_dir: "",
    app_params: "",
    target_process: "",
    target_pid: ""
  }
};

export const checkForEqualReturnValue = (array, index) => {
  // A function that checks if the indice in the array has a trailing
  // '=' with additional characters. This indicates a value is stored
  // right next to the flag
  const getEqualValue = /(?<==)(.+)/g;
  if (array[index].includes("=") && array[index].match(getEqualValue))
    return [array[index].match(getEqualValue).join(" "), 1];

  return [array[index + 1], 2];
};

export const parseEqualsWithQuotes = (stringArray) => {
  const items = JSON.parse(JSON.stringify(stringArray));
  for (let index = 0; index < items.length; index += 1) {
    // Recreates the commandline arguments in the same way they came in.
    if (
      items[index].includes("=") &&
      items[index][items[index].length - 1] === "="
    ) {
      const result = items[index] + items[index + 1];
      items[index] = result;
      items.splice(index + 1, 1);
    }
  }
  return items;
};

const regex_mapping = {
  definition_arguments: /^-{1,2}(collect)(-with)?$/gm,
  target_process: /^-{1,2}(target-process)($|=[\w.'"]*$){0,1}/gm,
  target_pid: /^-{1,2}(target-pid)($|=[\w.'"]*$){0,1}/gm,
  start_new_process: /(?<!\w|\W)-{2}(?!\w|\W)/gm,
  app: /(?<!\w)-{2}(?!\w)/gm,
  app_working_dir: /-{1,2}app-working-dir={0,1}/gm,
  duration: /^-{1,2}(duration|d)($|=[\w.'"]*$){0,1}/gm
};

export const parseCmdLineToArray = (cmdline) => {
  // Do not strip any quotes to preserve command for accurate parsing
  // Regex parser that will split on spaces or = with trailing spaces
  let command = JSON.parse(JSON.stringify(cmdline));
  if (!command) return null;
  // String comes with double quotes at start and end. Remove those before parsing
  if (
    ["'", '"'].includes(command[0]) &&
    ["'", '"'].includes(command[command.length - 1])
  ) {
    command = command.slice(1);
    command = command.substring(0, command.length - 1);
  }
  const matchExpression = /["'](.+)['"]|([^"\s]+)/gm;
  return command.match(matchExpression);
};

export const ParseKeyToCmdLine = (cmdline, key, value, dispatch) => {
  let cmdArray = parseCmdLineToArray(cmdline);
  if (key in regex_mapping) {
    const cmdIndex = cmdArray.findIndex((cmd) => cmd.match(regex_mapping[key]));
    if (cmdIndex === -1) return false;
    const [, place] = checkForEqualReturnValue(cmdArray, cmdIndex);
    if (place === 2) cmdArray[cmdIndex + 1] = value;
    else {
      const splitString = cmdArray[cmdIndex].split("=");
      splitString[1] = value;
      cmdArray[cmdIndex] = splitString.join("=");
    }
  }
  if (key === "app_params") {
    let cmdIndex = cmdArray.findIndex((cmd) => cmd.match(regex_mapping.app));
    if (cmdIndex === -1) return false;
    cmdIndex += 2;
    cmdArray.splice(cmdIndex, cmdArray.length - cmdIndex);
    cmdArray = cmdArray.concat(value.split(" "));
  }
  const newCmdLine = parseEqualsWithQuotes(cmdArray).join(" ");
  dispatch(
    analysisTemplateActions.setAnalysisProp({
      inputID: "vTuneCommandLine",
      keyReceived: "value",
      valueReceived: newCmdLine
    })
  );
  return newCmdLine;
};

export const CustomVtuneCmdParser = (cmdline) => {
  if (!cmdline) return null;
  const commandList = parseCmdLineToArray(cmdline);

  // All commandlines start with `vtune` so it will be popped out
  const type_id = commandList.shift();

  // Create object to return
  const objToReturn = {
    definition: { type_id },
    target: {
      app_params: ""
    }
  };
  const mutateCommandList = JSON.parse(JSON.stringify(commandList));
  let i = 0;
  while (i < mutateCommandList.length) {
    // different variations of all flags, use custom regex to find them all
    let pauseIteration = false;
    if (
      mutateCommandList[i].match(regex_mapping.definition_arguments) !== null
    ) {
      // Should only find -collect, --collect, or -collect-with
      objToReturn.definition.arguments = `${mutateCommandList[i]} ${
        mutateCommandList[i + 1]
      }`;
      mutateCommandList.splice(i, 2);
      pauseIteration = true;
    } else if (mutateCommandList[i].match(regex_mapping.target_process)) {
      // Matches on target-process even if it follows an equal sign or has leading '-'
      objToReturn.target.type_id = "attach_by_process_name";
      const [value, indicesToRemove] = checkForEqualReturnValue(
        mutateCommandList,
        i
      );
      objToReturn.target.target_process = value;
      mutateCommandList.splice(i, indicesToRemove);
      pauseIteration = true;
    } else if (mutateCommandList[i].match(regex_mapping.target_pid)) {
      // Matches on target-pid even if it follows an equal sign or has leading '-'
      objToReturn.target.type_id = "attach_by_process_id";
      const [value, indicesToRemove] = checkForEqualReturnValue(
        mutateCommandList,
        i
      );
      objToReturn.target.target_pid = value;
      mutateCommandList.splice(i, indicesToRemove);
      pauseIteration = true;
    } else if (mutateCommandList[i].match(regex_mapping.start_new_process)) {
      // Marks the target application, there should only ever be one
      objToReturn.target.type_id = "start_new_process";
      objToReturn.target.app = mutateCommandList[i + 1];
      mutateCommandList.splice(i, 2);
      objToReturn.target.app_params = parseEqualsWithQuotes(
        mutateCommandList.splice(i, mutateCommandList.length - i)
      ).join(" ");
      pauseIteration = true;
    } else if (mutateCommandList[i].match(regex_mapping.app_working_dir)) {
      // From testing and research, -app-working-dir should only exist in the commandline
      const [value, indicesToRemove] = checkForEqualReturnValue(
        mutateCommandList,
        i
      );
      objToReturn.target.app_working_dir = value;
      mutateCommandList.splice(i, indicesToRemove);
      pauseIteration = true;
    } else if (mutateCommandList[i].match(regex_mapping.duration)) {
      // Optional durational value that should overwrite the form input
      // Could exist as -d, --d, -duration, --duration and may have a
      // trailing equal sign
      const [value, indicesToRemove] = checkForEqualReturnValue(
        mutateCommandList,
        i
      );
      objToReturn.lifecycle = {
        type_id: "set_duration",
        duration: value
      };
      mutateCommandList.splice(i, indicesToRemove);
      pauseIteration = true;
    }
    if (!pauseIteration) i += 1;
  }
  if (!objToReturn.target?.type_id)
    objToReturn.target.type_id = "profile_system";

  // Any arguments that were not used are inserted into app_params.
  objToReturn.definition.arguments = `${
    objToReturn.definition.arguments
  } ${parseEqualsWithQuotes(mutateCommandList).join(" ")}`;
  return objToReturn;
};

export const buildCommandLineFromObj = (cmdObj) => {
  const cmdLine = ["vtune"];
  if ("definition" in cmdObj && "arguments" in cmdObj.definition) {
    cmdLine.push(`${cmdObj.definition.arguments}`);
  }
  if (cmdObj?.lifecycle) {
    cmdLine.push(`-duration ${cmdObj?.lifecycle?.duration}`);
  }
  if ("target" in cmdObj) {
    if (cmdObj.target?.app_working_dir) {
      cmdLine.push(`-app-working-dir ${cmdObj.target?.app_working_dir}`);
    }
    if (cmdObj.target?.type_id === "attach_by_process_name") {
      cmdLine.push(`-target-process ${cmdObj.target?.target_process}`);
    }
    if (cmdObj.target?.type_id === "attach_by_process_id") {
      cmdLine.push(`-target-pid ${cmdObj.target?.target_pid}`);
    }
    if (cmdObj.target?.type_id === "start_new_process") {
      cmdLine.push(`-- ${cmdObj.target?.app}`);
      if (cmdObj.target?.app_params) {
        cmdLine.push(`${cmdObj.target?.app_params}`);
      }
    }
  }
  return cmdLine.join(" ");
};

export const assignObjParamToForm = (cmdObj, dispatch, activeNodes = null) => {
  dispatch(analysisTemplateActions.resetAnalyisForm());
  let target = null;
  if (cmdObj?.template_id) {
    if (cmdObj.template_id.startsWith("UNSUPPORTED")) {
      dispatch(
        analysisTemplateActions.setAnalysisProp({
          id: "selectedTemplate",
          keyToUpdate: "value",
          value: null
        })
      );
      dispatch(
        analysisTemplateActions.setValidation({
          key: "selectedTemplate",
          isValid: false,
          message: `Analysis type ${cmdObj?.template_id.substring(
            11
          )} is not supported yet.  Please select another option.`
        })
      );
    } else if (cmdObj.template_id in CurrentSetOfAnalyzers) {
      const savedTemplate = CurrentSetOfAnalyzers[cmdObj.template_id];
      dispatch(
        analysisTemplateActions.setAnalysisProp({
          id: "selectedTemplate",
          keyToUpdate: "value",
          value: {
            template_id: cmdObj?.template_id,
            name: savedTemplate.title,
            description: savedTemplate.description,
            icon: savedTemplate?.icon,
            group_by: savedTemplate?.group_by,
            type: "vtune",
            definition: {
              type_id: savedTemplate.type_id
            },
            vtune_tree_position: savedTemplate.vtune_tree_position
          }
        })
      );
    }

    if (cmdObj?.definition) {
      if (cmdObj?.template_id !== "19275d0a-a413-4440-ba25-88f1ceaebf33") {
        Object.keys(cmdObj.definition)
          .filter(
            (index) => !["type_id", "load_drivers", "arguments"].includes(index)
          )
          .forEach((defKey) => {
            dispatch(
              customAnalysisFormsActions.setFormInput({
                type: "value",
                id: defKey,
                valueReceived: cmdObj.definition[defKey]
              })
            );
          });
      } else {
        dispatch(
          analysisTemplateActions.setAnalysisProp({
            inputID: "vTuneCommandLine",
            keyReceived: "value",
            valueReceived: buildCommandLineFromObj(cmdObj)
          })
        );
      }
    }
  }
  if (cmdObj?.node_ids && !!activeNodes) {
    const isThereANodeInActiveNodes = activeNodes.some((node) =>
      cmdObj.node_ids.includes(node.node_id)
    );
    if (isThereANodeInActiveNodes) {
      dispatch(
        analysisTemplateActions.setAnalysisProp({
          id: "selectedNodes",
          keyToUpdate: "value",
          value: activeNodes.filter((row) =>
            cmdObj.node_ids.includes(row.node_id)
          )
        })
      );
    }
    const nodesThatWereNotFound = cmdObj.node_ids.filter(
      (node_id) => !activeNodes.map((node) => node.node_id).includes(node_id)
    );
    if (nodesThatWereNotFound.length > 0) {
      dispatch(analysisTemplateActions.setMissingNodes(nodesThatWereNotFound));
    }
  }
  if (cmdObj?.name) {
    dispatch(
      analysisTemplateActions.setAnalysisProp({
        id: "iptAnalysisName",
        keyToUpdate: "value",
        value: cmdObj?.name
      })
    );
  }
  if (cmdObj?.lifecycle) {
    dispatch(
      analysisTemplateActions.setAnalysisProp({
        id: "iptAnalysisDuration",
        keyToUpdate: "value",
        value: cmdObj?.lifecycle?.duration
      })
    );
  }
  if (cmdObj?.target) {
    switch (cmdObj?.target?.type_id) {
      case "attach_by_process_name":
        target = profileTemplates.find(
          (item) => item.id === "profileExistingProcess"
        );
        dispatch(
          analysisTemplateActions.setTargetParameterValue({
            id: "checkProcessPID",
            valueReceived: false
          })
        );
        dispatch(
          analysisTemplateActions.setTargetParameterValue({
            inputID: "iptTargetProcessType",
            valueReceived: cmdObj.target?.target_process ?? null
          })
        );
        break;
      case "attach_by_process_id":
        target = profileTemplates.find(
          (item) => item.id === "profileExistingProcess"
        );
        dispatch(
          analysisTemplateActions.setTargetParameterValue({
            id: "checkProcessPID",
            valueReceived: true
          })
        );
        dispatch(
          analysisTemplateActions.setTargetParameterValue({
            inputID: "iptTargetProcessType",
            valueReceived: cmdObj.target?.target_pid ?? null
          })
        );
        break;
      case "start_new_process":
        target = profileTemplates.find(
          (item) => item.id === "start_new_process"
        );
        dispatch(
          analysisTemplateActions.setTargetParameterValue({
            inputID: "app",
            valueReceived: cmdObj.target?.app ?? null
          })
        );
        dispatch(
          analysisTemplateActions.setTargetParameterValue({
            inputID: "app_working_dir",
            valueReceived: cmdObj.target?.app_working_dir ?? null
          })
        );
        dispatch(
          analysisTemplateActions.setTargetParameterValue({
            inputID: "app_params",
            valueReceived: cmdObj.target?.app_params ?? null
          })
        );
        break;
      case "profile_system":
        target = profileTemplates.find((item) => item.id === "profile_system");
        break;
      default:
        break;
    }
  }
  if (target) {
    dispatch(
      analysisTemplateActions.setAnalysisProp({
        id: "analysisTarget",
        keyToUpdate: "value",
        value: target
      })
    );
    target.defaults.forEach((item) => {
      dispatch(
        analysisTemplateActions.setTargetParameterIsEnabled({
          id: item,
          valueReceived: true
        })
      );
    });
  }
  return cmdObj;
};

export const vtuneCommandAssignment = (dispatch, cmdline) => {
  dispatch(
    analysisTemplateActions.setAnalysisProp({
      inputID: "vTuneCommandLine",
      keyReceived: "value",
      valueReceived: cmdline
    })
  );
  if (cmdline && typeof cmdline === "string" && cmdline.length !== 0) {
    const cmdObj = CustomVtuneCmdParser(cmdline);
    dispatch(analysisTemplateActions.setVTuneCommandObject(cmdObj));
    dispatch(
      analysisTemplateActions.setAnalysisProp({
        inputID: "selectedTemplate",
        keyToUpdate: "value",
        value: customVtuneTemplate
      })
    );

    return assignObjParamToForm(cmdObj, dispatch);
  }
  return null;
};

export const convertClassicAnalysisTypeToTemplateId = (analysisType) => {
  switch (analysisType) {
    case "Hotspots":
      return "HotspotHardwareSampling";
    case "MemoryConsumption":
      return "MemoryConsumption";
    case "MicroarchitectureExploration":
      return "MicroarchitectureExploration";
    case "MemoryAccess":
      return "MemoryAccess";
    case "Threading":
      return "ThreadingHardwareSampling";
    case "IO":
      return "InputOutputPlatformLevelMetrics";
    case "HPCPerfChar":
    case "GpuOffload":
    case "GpuComputeMediaHotspots":
    case "FPGAInteraction":
    case "SystemOverview":
    case "GraphicsRendering":
    default:
      return `UNSUPPORTED${analysisType}`;
  }
};
