import { Box, Button, Chip, Stack } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { allNodesTagActions } from "../../AllNodes/slices/AllNodesSlices";

const renderTags = (tags) => {
  return Object.entries(tags).map(([key]) => {
    return (
      <Chip
        size="small"
        sx={{
          marginRight: (theme) => theme.spacing(1),
          marginTop: (theme) => theme.spacing(1)
        }}
        label={<Box>{key}</Box>}
        color="secondary"
        key={key}
      />
    );
  });
};

const NodeTagsPane = ({ nodeData, isLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEditTags = () => {
    dispatch(allNodesTagActions.setSelectedNodes([nodeData]));
    dispatch(allNodesTagActions.setSelectedNodesPreviewState([]));
    dispatch(allNodesTagActions.setTagEditorNodesTags([]));
    navigate("/editNodeTags");
  };
  return (
    <Stack>
      <Box
        sx={{
          pt: 1,
          display: "flex",
          justifyContent: "flex-end",
          width: "100%"
        }}
      >
        <Button startIcon={<EditIcon />} onClick={handleEditTags}>
          Edit Tags
        </Button>
      </Box>
      <Box sx={{ pt: 2 }}>
        {isLoading ? "Loading..." : renderTags(nodeData.node_tags)}
      </Box>
    </Stack>
  );
};

export default NodeTagsPane;
