import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { useDispatch, useSelector } from "react-redux";
import { customAnalysisFormsActions } from "../Slices/CustomAnalysisForms";

const WTPKSelect = ({ id, label, options }) => {
  const currentSelectValue = useSelector(
    (state) => state.customAnalysisForms.value
  );
  const currentSelectStatusMessage = useSelector(
    (state) => state.customAnalysisForms.statusMessage
  );
  const currentSelectValid = useSelector(
    (state) => state.customAnalysisForms.valid
  );
  const dispatch = useDispatch();
  const onSelectFormToggle = (e) => {
    dispatch(
      customAnalysisFormsActions.setFormInput({
        type: "value",
        id: e.target.name,
        valueReceived: e.target.value
      })
    );
  };

  useEffect(() => {
    dispatch(
      customAnalysisFormsActions.setFormInput({
        type: ["value", "valid", "statusMessage", "required"],
        id,
        valueReceived: [options[0].optionValue, null, null, false]
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      fullWidth
      error={currentSelectValid[id] !== null && !currentSelectValid[id]}
      size="small"
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        data-testid={id}
        id={id}
        name={id}
        label={label}
        error={currentSelectValid[id] !== null && !currentSelectValid[id]}
        onChange={onSelectFormToggle}
        aria-describedby={`${id}-helper-text`}
        value={
          currentSelectValue[id]
            ? currentSelectValue[id]
            : options[0].optionValue
        }
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.optionValue} value={option.optionValue}>
              {option.optionText}
            </MenuItem>
          );
        })}
      </Select>
      {currentSelectValid[id] !== null && !currentSelectValid[id] && (
        <FormHelperText id={`${id}-helper-text`}>
          {currentSelectStatusMessage[id]
            ? currentSelectStatusMessage[id]
            : "Invalid Option"}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default WTPKSelect;
