import { Box, Stack } from "@mui/material";
import React from "react";
import Loader from "../../images/loader.svg";

const LoadingSpinner = () => {
  return (
    <Stack
      sx={{ width: "100vw", height: "100vh" }}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          backgroundImage: `url(${Loader})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "6.25rem",
          height: "6.25rem",
          transform: "translate(-50%, -50%)"
        }}
      />
    </Stack>
  );
};

export default LoadingSpinner;
