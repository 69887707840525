import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  value: {},
  valid: {},
  statusMessage: {},
  required: {}
};
export const customAnalysisFormsSlice = createSlice({
  name: "customAnalysisForms",
  initialState,
  reducers: {
    resetCustomForm: () => initialState,
    setValidation: (state, action) => {
      const [keys, isValid, message] = Object.values(action.payload);
      if (Array.isArray(keys)) {
        keys.forEach((key) => {
          state.valid[key] = isValid;
          state.statusMessage[key] = message;
        });
      } else {
        state.valid[keys] = isValid;
        state.statusMessage[keys] = message;
      }
    },
    setFormInput: (state, action) => {
      const [typeToSet, inputID, valueReceived] = Object.values(action.payload);
      if (Array.isArray(typeToSet)) {
        typeToSet.forEach((type, index) => {
          if (
            Array.isArray(valueReceived) &&
            valueReceived.length === typeToSet.length
          ) {
            if (Array.isArray(inputID)) {
              inputID.forEach((id) => {
                state[type][id] = valueReceived[index];
              });
            } else {
              state[type][inputID] = valueReceived[index];
            }
          } else if (Array.isArray(inputID)) {
            inputID.forEach((id) => {
              state[type][id] = valueReceived;
            });
          } else {
            state[type][inputID] = valueReceived;
          }
        });
      } else {
        state[typeToSet][inputID] = valueReceived;
      }
    }
  }
});

export const customAnalysisFormsActions = customAnalysisFormsSlice.actions;
