import React from "react";
import { useSelector } from "react-redux";
import RecentDataList from "./RecentDataList";
import { useGetAnalysesQuery } from "../../store/wtpkApi";
import { convertDateTime24Hr } from "../../appServices/resuableMethods";

const parseAnalysisData = (data, onlyMine, currentUserEmail) => {
  let result;
  if (data !== undefined && !(data instanceof Error)) {
    if (onlyMine) {
      result = data?.items?.filter(
        (item) => item.started_by === currentUserEmail
      );
    } else {
      result = data.items;
    }
    const parsedItems = result.map((item) => {
      return {
        id: item.execution_id,
        name: item.name,
        status: item.status,
        date: convertDateTime24Hr(item.date_started * 1000),
        url: `/analyses/${item.execution_id}`,
        rawDate: item.date_started
      };
    });
    return parsedItems.sort((a, b) => b.rawDate - a.rawDate);
  }
  return [];
};

const RecentlyRunAnalyses = ({ onlyMine }) => {
  const currentTenantId = useSelector((state) => state.user.current_tenant_id);
  const currentUserEmail = useSelector(
    (state) => state.user.authUser?.profile?.email
  );

  const { data, isSuccess } = useGetAnalysesQuery(undefined, {
    skip: !currentTenantId
  });

  const formattedRowData = parseAnalysisData(data, onlyMine, currentUserEmail);

  return (
    <RecentDataList
      title={onlyMine ? "My recently run analyses" : "Recently run analyses"}
      formattedRowData={formattedRowData}
      isLoading={!isSuccess}
    />
  );
};

export default RecentlyRunAnalyses;
