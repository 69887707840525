import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { generateColumnVisibilityDef } from "../../../appServices/resuableMethods";
import generateIconForStatus from "../../../utils/statusIconGeneration";

export const columnDef = [
  {
    field: "name_object",
    headerName: "Node",
    flex: 2,
    minWidth: 200,
    editable: false,
    hideable: false,
    getApplyQuickFilterFn: (filterValue) => {
      return (_, row) => {
        const nodeDetails = `${row.name_object?.primary} ${
          row.name_object?.secondary1
        } ${row.name_object?.secondary2} ${
          row.nodeStatus === "Inactive" ? "Deregistered" : ""
        }`;
        return nodeDetails.toLowerCase().includes(filterValue.toLowerCase());
      };
    },
    valueGetter: (_, row) => {
      return row.name_object.primary;
    },
    renderCell: (params) => {
      const row = params?.row;
      if (!row) return null;
      const { name_object: nameObject, nodeStatus } = row;
      return nameObject?.secondary1 !== "" ? (
        <Tooltip
          title={
            <Box>
              <strong>Additional Identifiers:</strong> <br />
              {nameObject?.secondary1} <br /> {nameObject?.secondary2}
            </Box>
          }
        >
          <Stack
            sx={{ height: "100%" }}
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Typography variant="body2" component="div">
              {nameObject?.primary}
            </Typography>

            {nodeStatus === "Inactive" && (
              <Chip
                label="Deregistered"
                color="error"
                size="small"
                sx={{ marginTop: 1 }}
              />
            )}
          </Stack>
        </Tooltip>
      ) : (
        <Stack
          sx={{ height: "100%" }}
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography variant="body2">{nameObject?.primary}</Typography>
          {nodeStatus === "Inactive" && (
            <Chip label="Deregistered" color="error" size="small" />
          )}
        </Stack>
      );
    }
  },
  {
    field: "node_tags",
    headerName: "Tags",
    flex: 2,
    minWidth: 240,
    editable: false,
    wrapText: true,
    sortComparator: (v1, v2) => {
      const keys1 = Object.entries(v1).map(([key]) => `${key.toLowerCase()}`);
      const keys2 = Object.entries(v2).map(([key]) => `${key.toLowerCase()}`);

      if (keys1.length === 0) return 1;
      if (keys2.length === 0) return -1;

      return keys1.join("").localeCompare(keys2.join(""));
    },
    getApplyQuickFilterFn: (filterValue) => {
      return (_, row) => {
        const nodeDetails = row.node_tags;
        return Object.keys(nodeDetails)
          .toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      };
    },
    renderCell: (params) => {
      const tags = params.row.node_tags;
      if (typeof tags === "object" && tags !== null) {
        const items = Object.entries(tags).map(([key]) => {
          return (
            <Chip
              size="small"
              sx={{
                marginRight: (theme) => theme.spacing(1),
                marginTop: (theme) => theme.spacing(1)
              }}
              label={<Box>{key}</Box>}
              color="secondary"
              key={`${params.row.node_id}-${key}`}
            />
          );
        });

        return (
          <Box
            sx={{
              marginY: (theme) => theme.spacing(1),
              overflow: "hidden"
            }}
          >
            {items}
          </Box>
        );
      }
      return null;
    }
  },
  {
    field: "registration_date",
    headerName: "Registered On",
    flex: 1.3,
    minWidth: 150,
    editable: false,
    type: "dateTime"
  },
  {
    field: "cpu_model",
    headerName: "CPU",
    flex: 1.2,
    minWidth: 100,
    editable: false,
    renderCell: (params) => {
      <Box sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        {params.row.cpu_model}
      </Box>;
    }
  },
  {
    field: "node_type",
    headerName: "Type",
    flex: 0.75,
    minWidth: 100,
    editable: false
  },
  {
    field: "platform_name",
    headerName: "Operating System",
    flex: 1,
    minWidth: 100,
    editable: false
  },
  {
    field: "last_ping_time",
    headerName: "Last Online",
    flex: 1,
    minWidth: 150,
    editable: false,
    type: "dateTime"
  },
  {
    field: "computer_name",
    headerName: "Hostname",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.computer_name ?? "Unknown";
    }
  },
  {
    field: "hypervisor",
    headerName: "Hypervisor",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.hypervisor ?? "Unknown";
    }
  },
  {
    field: "pmu",
    headerName: "PMU",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.pmu ? "enabled" : "disabled";
    }
  },
  {
    field: "vtsspp_driver_installed",
    headerName: "VTSSPP Driver Installed",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.vtsspp_driver_installed ?? "Unknown";
    }
  },
  {
    field: "socwatch_driver_installed",
    headerName: "Socwatch Driver Installed",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.socwatch_driver_installed ?? "Unknown";
    }
  },
  {
    field: "sampling_driver_installed",
    headerName: "Sampling Drivers Installed",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return (
        "sep: ".concat(row?.sep_driver_installed ? "Yes" : "No") +
        " pax: ".concat(row?.pax_driver_installed ? "Yes" : "No") +
        " socperf: ".concat(row?.socperf_driver_installed ? "Yes" : "No")
      );
    },
    renderCell: (params) => {
      return (
        <Stack direction="column" alignItems="left" spacing={1}>
          <Typography variant="body2">
            {"sep: ".concat(params?.row?.sep_driver_installed ? "Yes" : "No")}
          </Typography>
          <Typography variant="body2">
            {"pax: ".concat(params?.row?.pax_driver_installed ? "Yes" : "No")}
          </Typography>
          <Typography variant="body2">
            {"socperf: ".concat(
              params?.row?.socperf_driver_installed ? "Yes" : "No"
            )}
          </Typography>
        </Stack>
      );
    }
  },
  {
    field: "ip_address",
    headerName: "IP Address",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.ip_address ?? "Unknown";
    }
  },
  {
    field: "cpu_arch",
    headerName: "CPU Architecture",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.cpu_arch ?? "Unknown";
    }
  },
  {
    field: "kernel",
    headerName: "Kernel",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.kernel ?? "Unknown";
    }
  },
  {
    field: "platform_type",
    headerName: "OS Type",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.platform_type ?? "Unknown";
    }
  },
  {
    field: "registered_by",
    headerName: "Registered By",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.registered_by ?? "Unknown";
    }
  },
  {
    field: "ping_status",
    headerName: "Status",
    width: 70,
    editable: false,
    align: "center",
    renderCell: (params) => generateIconForStatus(params.row.ping_status)
  }
];

const fieldsVisibleByDefault = [
  "name_object",
  "node_tags",
  "registration_date",
  "cpu_model",
  "node_type",
  "platform_name",
  "last_ping_time",
  "ping_status"
];

export const columnVisibilityDef = generateColumnVisibilityDef(
  columnDef,
  fieldsVisibleByDefault
);
