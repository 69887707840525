import { CurrentSetOfAnalyzers } from "../../../components/AnalysisTypes/AnalyzersDefinitions";
import { analysisTemplateActions } from "../slices/LaunchAnalysisSlices";

import * as APIServices from "../../../appServices/axiosService";

const calculateDuration = (analysis) => {
  let duration = analysis?.iptAnalysisDuration?.value;
  if (analysis?.iptAnalysisDurationUnit?.value === "Hours") {
    duration *= 3600;
  } else if (analysis?.iptAnalysisDurationUnit?.value === "Minutes") {
    duration *= 60;
  }
  return duration;
};

export const buildObjToSend = (analysisForm) => {
  const { analysis, customFormInputs, vtuneCmdObj } = analysisForm;
  let objToSend = vtuneCmdObj ?? {
    target: {
      type_id: null
    }
  };
  objToSend = {
    ...objToSend,
    template_id: analysis.selectedTemplate.value.template_id,
    name: analysis.iptAnalysisName.value,
    definition:
      vtuneCmdObj?.definition ?? analysis.selectedTemplate.value.definition,
    node_ids: analysis.selectedNodes.value.map((item) => item.node_id)
  };
  const targetParametersToStore = Object.fromEntries(
    Object.entries(analysis.targetParameters)
      .filter(([, value]) => value.isEnabled)
      .map(([key, value]) => {
        return [key, value.value];
      })
  );
  switch (analysis.analysisTarget.value.id) {
    case "profileExistingProcess": {
      objToSend.target = {
        ...objToSend.target,
        type_id: analysis.targetParameters.checkProcessPID.value
          ? "attach_by_process_id"
          : "attach_by_process_name",
        ...(analysis.targetParameters.checkProcessPID.value
          ? {
              target_pid: targetParametersToStore.iptTargetProcessType
            }
          : {
              target_process: targetParametersToStore.iptTargetProcessType
            })
      };
      break;
    }
    default:
      objToSend.target = { ...objToSend.target, ...targetParametersToStore };
      objToSend.target.type_id = analysis.analysisTarget.value.id;
      break;
  }
  objToSend.lifecycle = {
    type_id: "set_duration",
    duration: analysis.iptAnalysisDuration.value
      ? calculateDuration(analysis)
      : 43200
  };
  if (
    analysis.selectedTemplate.value.template_id in CurrentSetOfAnalyzers &&
    CurrentSetOfAnalyzers[analysis.selectedTemplate.value.template_id].component
  ) {
    objToSend.definition = {
      ...objToSend.definition,
      ...customFormInputs.value
    };
  }
  return objToSend;
};

const LaunchAnalysisAction = async (request, dispatch, auth) => {
  dispatch(analysisTemplateActions.setAnalysisRequestError(null));
  const apiBaseUrl = window.runtime.REACT_APP_API_URL;
  const formData = await request.formData();
  const analysisForm = JSON.parse(formData.get("serialized"));
  const objToSend = buildObjToSend(analysisForm);
  const response = await APIServices.ApiPost(
    `${apiBaseUrl}/execution`,
    auth.user,
    objToSend
  ).then((data) => {
    if (data?.name && data.name === "AxiosError") {
      dispatch(
        analysisTemplateActions.setAnalysisRequestError({
          data,
          form: objToSend
        })
      );
    } else {
      dispatch(
        analysisTemplateActions.setAnalysisExecutionId(
          data.execution.execution_id
        )
      );
    }

    dispatch(analysisTemplateActions.toggleLoading());
    return data;
  });

  return response;
};

export default LaunchAnalysisAction;
