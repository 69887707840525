import { User } from "oidc-client-ts";

export const getUser = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${window.runtime.REACT_APP_COGNITO_URL}/oauth2_authorize:${window.runtime.REACT_APP_COGNITO_CLIENT_ID}`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
};

export default getUser;
