/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import Box from "@mui/material/Box";
import { CurrentSetOfAnalyzers } from "../../../../components/AnalysisTypes/AnalyzersDefinitions";

const AnalysisCustomTemplateForm = ({ templateName }) => {
  const Component = CurrentSetOfAnalyzers[templateName].component;
  if (Component)
    return (
      <Box id="boxHowCustomTemplate" component="form">
        <Component />
      </Box>
    );

  return <Box display="none">Display None</Box>;
};

export default AnalysisCustomTemplateForm;
