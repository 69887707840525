import {
  sortTags,
  parseNodeType,
  parseModelType,
  displayConsistentNodeName
} from "../appServices/resuableMethods";

const ParseNodeData = (nodeData, filter = false) => {
  try {
    const parsed = nodeData.nodes
      .filter((item) => (filter ? item.ping_status === filter : true))
      .map((item) => {
        return {
          node_id: item.node_id,
          name_object: displayConsistentNodeName(item),
          computer_name: item.computer_name,
          ip_address: item.ip_address,
          platform_name: `${item.platform_name} ${item.platform_version}`,
          platform_type: item.platform_type,
          ping_status: item.ping_status,
          node_tags: sortTags(item.tags),
          registration_date: new Date(item.registration_date * 1000),
          last_ping_time: new Date(item.last_ping_time * 1000),
          cpu_model: item.inventory
            ? parseModelType(item.inventory.cpu_brand)
            : "",
          node_type: item.inventory
            ? parseNodeType(item.inventory.vtune_context.Hypervisor)
            : "",
          cpu_arch: item.inventory?.cpu_arch,
          hypervisor: item.inventory?.vtune_context?.Hypervisor,
          pmu: item.inventory?.vtune_context?.LinuxPerfCapabilities?.includes(
            "cpu:"
          ),
          vtsspp_driver_installed: item.inventory?.vtune_drivers?.vtsspp?.built,
          sep_driver_installed: item.inventory?.vtune_drivers?.sep?.built,
          pax_driver_installed: item.inventory?.vtune_drivers?.pax?.built,
          socperf_driver_installed:
            item.inventory?.vtune_drivers?.socperf?.built,
          socwatch_driver_installed:
            item.inventory?.vtune_drivers?.socwatch?.built,
          cpu: item.inventory?.cpu_brand,
          kernel: item.inventory?.kernel_release,
          os_distribution: item.inventory?.distro_name,
          os_version: item.inventory?.distro_version
        };
      });
    return parsed;
  } catch (error) {
    return error;
  }
};

export default ParseNodeData;
