import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const AnalysisLog = ({ result }) => {
  const resultsData = result?.data;
  let htmlLogstring = [];

  if (
    resultsData &&
    resultsData?.split(/[\r\n]+/).length !== htmlLogstring.length
  ) {
    htmlLogstring = resultsData?.split(/[\r\n]+/);
  }
  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: "#FAFAFA"
      }}
    >
      <CardContent
        data-testid="Logs"
        sx={{
          maxHeight: "70vh",
          overflowY: "auto"
        }}
      >
        {htmlLogstring.map((item) => (
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              whiteSpace: "pre-wrap"
            }}
          >
            {item}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
};

export default AnalysisLog;
