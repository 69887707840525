import {
  Link,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import generateIconForStatus from "../../utils/statusIconGeneration";

export const NUM_ROWS_TO_SHOW = 5;

const generateSkeletons = () => {
  let counter = -1;
  return Array.from({ length: NUM_ROWS_TO_SHOW }, () => {
    counter += 1;
    return (
      <TableRow key={counter} sx={{ "& td": { border: 0 } }}>
        <TableCell>
          <Skeleton width={100} />
        </TableCell>
        <TableCell>
          <Skeleton width={100} />
        </TableCell>
        <TableCell>
          <Skeleton variant="circular" width={22} height={22} />
        </TableCell>
      </TableRow>
    );
  });
};

const RecentDataList = ({ title, subtitle, formattedRowData, isLoading }) => {
  const generateTableRows = () => {
    const itemCount = formattedRowData.length;
    const lastItemIndex =
      itemCount < NUM_ROWS_TO_SHOW ? itemCount - 1 : NUM_ROWS_TO_SHOW - 1;
    const result = formattedRowData.slice(0, lastItemIndex + 1).map((item) => {
      return (
        <TableRow
          key={item.id}
          sx={{ "& td": { border: 0 }, height: "1.875rem" }}
        >
          <TableCell
            title={item.name}
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: "0px",
              fontWeight: "medium"
            }}
          >
            {item.url ? (
              <Link to={`${item.url}`} color="inherit" underline="none">
                {item.name}
              </Link>
            ) : (
              item.name
            )}
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "150px"
            }}
          >
            {item.date}
          </TableCell>
          <TableCell height="1.875rem" width="24px">
            {generateIconForStatus(item.status)}
          </TableCell>
        </TableRow>
      );
    });
    return result;
  };

  return (
    <Stack
      sx={{
        backgroundColor: "white",
        border: "1px",
        px: "16px",
        pt: "12px",
        pb: "8px",
        width: { sm: "100%", md: "calc(50% - 12px)" }
      }}
    >
      <Typography component="subtitle2" fontSize="14px" fontWeight="medium">
        {title}
      </Typography>
      <Typography component="body2" fontSize="14px">
        {subtitle}
      </Typography>
      {!isLoading && formattedRowData?.length === 0 ? (
        <Typography
          component="div"
          width="100%"
          textAlign="center"
          sx={{ pt: "60px" }}
        >
          No data
        </Typography>
      ) : (
        <Table size="small">
          <TableBody>
            {isLoading ? generateSkeletons() : generateTableRows()}
          </TableBody>
        </Table>
      )}
    </Stack>
  );
};

export default RecentDataList;
