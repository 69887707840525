import { ListItem, Typography } from "@mui/material";
import React from "react";

const WTPKObjectDetailsListItem = ({ fieldName, value, isLoading }) => {
  return (
    <ListItem sx={{ p: 0 }}>
      <Typography variant="body2" sx={{ minWidth: "230px" }}>
        {fieldName}
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
        {isLoading ? "Loading..." : value}
      </Typography>
    </ListItem>
  );
};

export default WTPKObjectDetailsListItem;
