import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import {
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector
} from "@mui/x-data-grid-premium";

const getPageSizeOptionValue = (option) => {
  return typeof option === "object" ? option.value : option;
};

const getSmallestPageSize = (pageSizeOptions) => {
  return pageSizeOptions.reduce((smallest, input) => {
    // skip the "ALL" option, it doesn't count for smallest
    if (getPageSizeOptionValue(input) === -1) {
      return smallest;
    }
    if (getPageSizeOptionValue(smallest) === -1) {
      return input;
    }
    return getPageSizeOptionValue(input) < getPageSizeOptionValue(smallest)
      ? input
      : smallest;
  });
};

const PageSizeSelector = () => {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const { pageSizeOptions } = useGridRootProps();

  return rowCount >
    getPageSizeOptionValue(getSmallestPageSize(pageSizeOptions)) ? (
    <Stack direction="row" alignItems="center">
      <Typography
        variant="body"
        sx={{ marginRight: (theme) => theme.spacing(1) }}
      >
        Showing
      </Typography>
      <FormControl variant="standard">
        <Select
          id="pagination-select"
          value={pageSize}
          label={pageSize}
          onChange={(e) => apiRef.current.setPageSize(e.target.value)}
        >
          {pageSizeOptions.map((option) => {
            const val = getPageSizeOptionValue(option);
            return val < rowCount ? (
              <MenuItem value={val} key={val}>
                {typeof option === "object" ? option.label : option}
              </MenuItem>
            ) : null;
          })}
        </Select>
      </FormControl>
      <Typography
        variant="body"
        sx={{ marginLeft: (theme) => theme.spacing(1) }}
      >
        of {rowCount}
      </Typography>
    </Stack>
  ) : null;
};

export default PageSizeSelector;
