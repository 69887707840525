import React, { useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { useDispatch, useSelector } from "react-redux";
import { customAnalysisFormsActions } from "../Slices/CustomAnalysisForms";

const WTPKInput = ({
  id,
  label,
  disabled,
  placeholder,
  initialValue = "",
  inputType = "text",
  min = false,
  max = false,
  required = false,
  pattern = false,
  validation = null
}) => {
  const currentInputValue = useSelector(
    (state) => state.customAnalysisForms.value
  );
  const currentInputStatusMessage = useSelector(
    (state) => state.customAnalysisForms.statusMessage
  );
  const currentInputValid = useSelector(
    (state) => state.customAnalysisForms.valid
  );
  const dispatch = useDispatch();
  const onInputFormToggle = (e) => {
    dispatch(
      customAnalysisFormsActions.setFormInput({
        type: "value",
        id: e.target.id,
        valueReceived: e.target.value
      })
    );
  };

  useEffect(() => {
    dispatch(
      customAnalysisFormsActions.setFormInput({
        type: ["value", "valid", "statusMessage", "required"],
        id,
        valueReceived: [initialValue, null, null, required]
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      size="small"
      fullWidth
      error={currentInputValid[id] !== null && !currentInputValid[id]}
      required={required}
      disabled={disabled}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        type={inputType}
        inputProps={{
          min,
          max
        }}
        error={currentInputValid[id] !== null && !currentInputValid[id]}
        onChange={(e) => {
          if (validation !== null)
            validation(e, dispatch, customAnalysisFormsActions.setValidation);
          onInputFormToggle(e);
        }}
        value={currentInputValue[id] ? currentInputValue[id] : ""}
        placeholder={placeholder}
        pattern={pattern.toString()}
        name={id}
        label={label}
        aria-describedby={`${id}-helper-text`}
      />
      {currentInputValid[id] !== null && !currentInputValid[id] && (
        <FormHelperText id={`${id}-helper-text`}>
          {currentInputStatusMessage[id]
            ? currentInputStatusMessage[id]
            : "Invalid Input"}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default WTPKInput;
