import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Grid,
  IconButton,
  Typography,
  List,
  ListItem
} from "@mui/material";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { allNodesTagActions } from "../slices/AllNodesSlices";
import { useDeleteNodesMutation } from "../../../store/wtpkApi";

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
};

const TagDeregistrationModal = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteNodes] = useDeleteNodesMutation();

  const [showSpinner, setShowSpinner] = useState(false);

  const prepareForDeregistration = useSelector(
    (state) => state.nodeTags.prepareForDeregistration
  );
  const selectedNodeList = useSelector((state) => state.nodeTags.selectedNodes);
  const deregisterNodesModal = useSelector(
    (state) => state.nodeTags.tagDeregistrationModalVisible
  );

  const closeTheDialog = useCallback(async () => {
    dispatch(
      allNodesTagActions.setTagDeregistrationModalVisible(!deregisterNodesModal)
    );
  }, [deregisterNodesModal, dispatch]);

  const handleNodeDeregistration = async () => {
    setShowSpinner(true);
    dispatch(
      allNodesTagActions.setPrepareForDeregistration(!prepareForDeregistration)
    );
    let hasDeregistrationError = false;
    await Promise.all(
      selectedNodeList.map(async (node) => {
        const response = await deleteNodes(node.node_id)
          .unwrap()
          .then((fulfilled) => fulfilled)
          .catch((rejected) => rejected);
        if (response instanceof Error) {
          enqueueSnackbar(
            `Error deregistering node ${node.node_id}: ${response.message}`,
            { variant: "error" }
          );
          hasDeregistrationError = true;
        }
      })
    );
    if (!hasDeregistrationError) {
      closeTheDialog();
      enqueueSnackbar("Node deregistration successful.", {
        variant: "success"
      });
      if (window.location.pathname.startsWith("/nodes/")) {
        window.open("/nodes", "_self");
      }
    }
    setShowSpinner(false);
  };

  useEffect(() => {
    const handleEscapeKeyPress = (e) => {
      if (e.key === "Escape" && deregisterNodesModal) {
        closeTheDialog();
      }
    };

    document.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [closeTheDialog, deregisterNodesModal]);

  return (
    <Dialog
      open={deregisterNodesModal}
      onClose={closeTheDialog}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        sx={{
          cursor: "move",
          backgroundColor: (theme) => theme.palette.error.main,
          color: "white"
        }}
        id="draggable-dialog-title"
      >
        <Grid container justifyContent="space-between">
          <Box>Deregister Nodes</Box>
          <IconButton
            aria-label="close"
            onClick={closeTheDialog}
            sx={{
              color: "white"
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Box sx={{ marginTop: (theme) => theme.spacing(3) }}>
            <Typography>The following nodes will be deregistered:</Typography>
            <List component="ol" sx={{ listStyleType: "disc", pl: 4 }}>
              {selectedNodeList.map((element) => {
                return (
                  <ListItem key={element.node_id} sx={{ display: "list-item" }}>
                    {element.name_object.primary}
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box component="p" sx={{ marginTop: (theme) => theme.spacing(1) }}>
            Confirm deregistration from your account? <br /> <br /> Note: This
            action will remove the reference to the selected nodes from your
            account. No changes will be made to the node itself. The agent will
            not be uninstalled on the target node(s), but you will not be able
            to run new profiling jobs against the node(s) without first
            re-registering.
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="btnModalCancelDeregister"
          variant="contained"
          color="error"
          onClick={closeTheDialog}
          disabled={showSpinner}
          sx={{ color: "white" }}
        >
          Cancel
        </Button>
        <Button
          id="btnModalConfirmDeregister"
          sx={{ marginLeft: (theme) => theme.spacing(3), color: "white" }}
          variant="contained"
          color="error"
          onClick={handleNodeDeregistration}
          disabled={showSpinner}
        >
          {showSpinner ? (
            <>
              Saving Changes...{" "}
              <CircularProgress
                sx={{ marginRight: (theme) => theme.spacing(2) }}
              />
            </>
          ) : (
            "Deregister"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagDeregistrationModal;
