import React from "react";
import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import tourSteps from "./TourSteps";
import { userActions } from "../../store/UserSlice";

const Tour = () => {
  const dispatch = useDispatch();

  const runTour = useSelector((state) => state.user.runTour);
  const stepIndex = useSelector((state) => state.user.tourStepIndex);
  const tourCallback = (data) => {
    const { action, status, type } = data;
    if (status === "finished" || status === "skipped") {
      dispatch(userActions.stopTour());
    } else if (
      action === ACTIONS.NEXT &&
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)
    ) {
      dispatch(userActions.tourNextStep());
    } else if (
      action === ACTIONS.PREV &&
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)
    ) {
      dispatch(userActions.tourPrevStep());
    }
  };

  return (
    <Joyride
      steps={tourSteps}
      run={runTour}
      stepIndex={stepIndex}
      styles={{
        options: {
          primaryColor: "#0068B5"
        }
      }}
      hideCloseButton="false"
      showSkipButton
      continuous
      showProgress
      spotlightPadding={0}
      disableOverlayClose
      disableScrolling
      locale={{ open: "Open the tour", last: "Close", skip: "Skip tour" }}
      callback={tourCallback}
    />
  );
};

export default Tour;
