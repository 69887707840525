import React, { useState } from "react";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { useAuth } from "react-oidc-context";
import { useSearchParams } from "react-router-dom";
import {
  useOpenChatMutation,
  useGetChatReadinessQuery,
  useGetNodeExecutionsOfAnalysisQuery,
  useGetAnalysisQuery
} from "../../store/wtpkApi";
import { generateNEDictsFromAnalysisNameAndNEs } from "../../utils/chatParams";

const ChatLoader = () => {
  const auth = useAuth();
  const userId = auth.user.profile["custom:userId"];
  const [openChat, result] = useOpenChatMutation();
  if (result.isUninitialized) {
    openChat(userId);
  }
  const [containerReady, setContainerReady] = useState(false);

  const { data: readinessResponse } = useGetChatReadinessQuery(userId, {
    pollingInterval: containerReady ? 0 : 1000
  });

  if (!containerReady && readinessResponse?.is_ready === true) {
    setContainerReady(true);
  }

  const [searchParams] = useSearchParams();
  const encodedAEStr = searchParams.get("ae");
  const analysisExecutionIDList = encodedAEStr
    ? JSON.parse(window.atob(decodeURI(encodedAEStr)))
    : [];
  const analysisExecutionID =
    analysisExecutionIDList.length === 1 ? analysisExecutionIDList[0] : null;

  const { data: analysisResponse, isSuccess: analysisSuccess } =
    useGetAnalysisQuery(analysisExecutionID, {
      skip: analysisExecutionID === null
    });
  const { data: nodeExecutionReponse, isSuccess: nodeExecutionSuccess } =
    useGetNodeExecutionsOfAnalysisQuery(analysisExecutionID, {
      skip: analysisExecutionID === null
    });

  if (readinessResponse?.is_ready === true && result.data?.resource?.chat_url) {
    if (encodedAEStr) {
      if (analysisSuccess && nodeExecutionSuccess) {
        const neDictList = generateNEDictsFromAnalysisNameAndNEs(
          analysisResponse.execution.name,
          nodeExecutionReponse.items
        );
        window.open(
          `${result.data.resource.chat_url}?ne=${encodeURI(
            window.btoa(JSON.stringify(neDictList))
          )}`,
          "_self"
        );
      }
    } else {
      const searchString = searchParams.toString();
      window.open(
        searchString
          ? `${result.data.resource.chat_url}?${searchParams.toString()}`
          : `${result.data.resource.chat_url}`,
        "_self"
      );
    }
  }

  return (
    <Box mt={2} pt={3}>
      <Container>
        <Card>
          <CardContent>
            <Typography
              className="lead"
              gutterBottom
              data-testid="ChatContainer"
            >
              Preparing your chat session... <br />
              This tab will be automatically redirected to the chat. Feel free
              to continue your work in other tabs while you wait.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ChatLoader;
