import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import React, { useContext, useRef, useState } from "react";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { useRevalidator } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import {
  revokeInvitation,
  revokeUserAccess
} from "../../../appServices/UserManagement.service";

const PaperComponent = (props) => {
  const draggableRef = useRef(null);
  return (
    <Draggable
      handle="#draggable-dialog-title"
      ref={draggableRef}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} ref={draggableRef} />
    </Draggable>
  );
};

const RevokeButton = (props) => {
  const auth = useContext(AuthContext);
  const { userDetails } = props;
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const revalidateLoader = useRevalidator();

  const revokeType =
    userDetails.addedOn === "Invitation pending" ? "invitation" : "access";

  const dialogTitle =
    revokeType === "invitation" ? "Revoke Invitation" : "Revoke Access";

  const dialogContent =
    revokeType === "invitation" ? (
      <DialogContentText>
        The invitation for <b>{userDetails.email} </b>
        will be revoked. Proceed?
      </DialogContentText>
    ) : (
      <DialogContentText>
        <b>{userDetails.userName} </b>
        will no longer have access to this organization. Proceed?
      </DialogContentText>
    );

  const handleClose = () => {
    setShow(false);
  };

  const handleDialog = () => {
    setShow(true);
  };

  const revoke = async () => {
    let response;
    if (revokeType === "invitation") {
      response = await revokeInvitation(auth, userDetails.email);
    } else {
      response = await revokeUserAccess(auth, userDetails.userId);
    }
    if (!(response instanceof Error)) {
      enqueueSnackbar(`User ${revokeType} revoked successfully`, {
        variant: "success"
      });
    } else {
      enqueueSnackbar(
        `Could not revoke user ${revokeType}: ${response.message}`,
        {
          variant: "error"
        }
      );
    }
    revalidateLoader.revalidate();
    setShow(false);
  };

  return (
    <div>
      <IconButton
        aria-label={dialogTitle}
        size="small"
        color="primary"
        title={dialogTitle}
        onClick={handleDialog}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={show}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="draggable-dialog-title"
          sx={{
            cursor: "move",
            color: "white",
            backgroundColor: (theme) => theme.palette.error.main
          }}
        >
          {dialogTitle}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white"
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            size="small"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            autoFocus
            onClick={revoke}
            color="error"
            sx={{ color: "white" }}
          >
            {dialogTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RevokeButton;
