import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useSnackbar } from "notistack";
import { DataGridPremium, GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useRevalidator, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from "@mui/material";
import { allNodesTagActions } from "../AllNodes/slices/AllNodesSlices";
// import { ApiPut } from "../../appServices/axiosService";
// import AuthContext from "../../contexts/AuthContext";
import AutocompleteNodeTagging from "../../components/AutocompleteNodeTagging/AutocompleteNodeTagging";
// import { dataTableHeaderToolbarGetter } from "../../components/DataTableElements/DataTableHeaderToolbar";
// import PaginationButtons from "../../components/DataTableElements/PaginationButtons";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";
import { useUpdateNodeMutation } from "../../store/wtpkApi";

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
};

const NodeTagEditor = () => {
  // const auth = useContext(AuthContext);
  // const apiBaseUrl = `${window.runtime.REACT_APP_API_URL}`;
  const APP_BASE_URL = `${window.runtime.REACT_APP_BASE_URL}`;
  const revalidateLoader = useRevalidator();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [updateNode, { error, isSuccess }] = useUpdateNodeMutation();

  const dispatch = useDispatch();
  const selectedNodeList = useSelector((state) => state.nodeTags.selectedNodes);
  const tagsToBeAdded = useSelector(
    (state) => state.nodeTags.tagEditorNodesTags
  );
  const selectedNodeListPreviewState = useSelector(
    (state) => state.nodeTags.selectedNodesPreviewState
  );

  const [rowData, setRowData] = useState(
    selectedNodeList.length > 0 ? selectedNodeList : []
  );
  const [sortModel, setSortModel] = useState([
    { field: "name_object", sort: "desc" }
  ]);
  const [savingChanges, setSavingChanges] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);

  useEffect(() => {
    if (isSuccess) navigate(-1);
  }, [error, isSuccess, enqueueSnackbar, navigate]);

  const handleRootCancel = () => {
    if (hasBeenUpdated || tagsToBeAdded.length > 0) {
      setOpenCancelDialog(true);
    } else {
      navigate(-1);
    }
  };

  const handleClose = () => {
    setOpenCancelDialog(false);
  };

  const handleCancel = () => {
    dispatch(allNodesTagActions.setTagEditorNodesTags([]));
    dispatch(allNodesTagActions.setSelectedNodesPreviewState([]));
    dispatch(allNodesTagActions.setSelectedNodes([]));
    setOpenCancelDialog(false);
    navigate(-1);
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const deleteTag = (nodeId, tag) => {
    const updatedNodesArray = selectedNodeListPreviewState.map((item) => {
      if (item.node_id === nodeId) {
        const { [tag]: omittedTag, ...restNodeTags } = item.node_tags;
        return { ...item, node_tags: restNodeTags };
      }
      return item;
    });
    dispatch(
      allNodesTagActions.setSelectedNodesPreviewState(updatedNodesArray)
    );
    setRowData(updatedNodesArray);
    setHasBeenUpdated(true);
  };

  const updateTags = async () => {
    try {
      setSavingChanges(true);
      await Promise.all(
        selectedNodeListPreviewState.map(async (item) => {
          const updatedTagList = { ...item.node_tags };
          tagsToBeAdded.forEach((element) => {
            updatedTagList[element] = "";
          });
          const response = await updateNode({
            nodeId: item.node_id,
            inputData: { tags: updatedTagList }
          });
          if (response?.error) {
            throw response.error;
          }
        })
      );
    } catch (er) {
      enqueueSnackbar(`There was an error updating your tags: ${er.data}`, {
        variant: "error"
      });
      setSavingChanges(false);
    }
  };
  const deleteAllTags = (nodeId) => {
    const updatedNodesArray = selectedNodeListPreviewState.map((item) => {
      if (item.node_id === nodeId) {
        return { ...item, node_tags: [] };
      }
      return item;
    });
    dispatch(
      allNodesTagActions.setSelectedNodesPreviewState(updatedNodesArray)
    );
    setRowData(updatedNodesArray);
    setHasBeenUpdated(true);
  };

  useEffect(() => {
    dispatch(allNodesTagActions.setSelectedNodesPreviewState(selectedNodeList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: "name_object",
      headerName: "Node",
      minWidth: 200,
      maxWidth: 250,
      editable: false,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ overflow: "hidden" }}>
          <Tooltip
            title={
              <Box>
                {params?.row.name_object?.secondary1 !== "" ? (
                  <strong>Additional Identifiers:</strong>
                ) : (
                  ""
                )}{" "}
                <br />
                {params.row.name_object.secondary1} <br />{" "}
                {params.row.name_object.secondary2}
              </Box>
            }
          >
            <Box>{params.row.name_object.primary}</Box>
          </Tooltip>
        </Box>
      ),
      sortComparator: (v1, v2) => v1.primary.localeCompare(v2.primary)
    },
    {
      field: "node_tags",
      headerName: "Tags",
      flex: 1,
      editable: false,
      wrapText: true,
      hideable: false,
      sortComparator: (v1, v2) => {
        const keys1 = Object.entries(v1).map(
          ([key, value]) => `${key.toLowerCase()}: ${value.toLowerCase()}`
        );
        const keys2 = Object.entries(v2).map(
          ([key, value]) => `${key.toLowerCase()}: ${value.toLowerCase()}`
        );

        if (keys1.length === 0) return 1;
        if (keys2.length === 0) return -1;

        return keys1.join("").localeCompare(keys2.join(""));
      },
      renderCell: (params) => {
        const tagList = params.row.node_tags;
        if (
          typeof tagList === "object" &&
          tagList !== null &&
          Object.keys(tagList).length !== 0
        ) {
          const items = Object.entries(tagList).map(([key]) => {
            return (
              <Chip
                size="small"
                sx={{
                  marginRight: (theme) => theme.spacing(1),
                  marginTop: (theme) => theme.spacing(1)
                }}
                label={<Box>{key}</Box>}
                color="secondary"
                key={`${params.row.node_id}-${key}`}
                onDelete={() => deleteTag(params.row.node_id, key)}
              />
            );
          });

          return (
            <Box
              sx={{
                marginTop: (theme) => theme.spacing(1),
                marginBottom: (theme) => theme.spacing(2),
                overflow: "hidden"
              }}
            >
              {items}
            </Box>
          );
        }
        return (
          <Typography
            variant="p"
            sx={{
              marginY: (theme) => theme.spacing(2)
            }}
          >
            There are no tags associated with this node
          </Typography>
        );
      }
    },
    {
      field: "action",
      type: "actions",
      hideable: false,
      width: 50,
      align: "right",
      getActions: (params) => {
        const tagList = params.row.node_tags;
        const actions = [];
        if (
          typeof tagList === "object" &&
          tagList !== null &&
          Object.keys(tagList).length !== 0
        ) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon color="default" />}
              label="Rename"
              onClick={() => deleteAllTags(params.row.node_id)}
            />
          );
        } else {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon color="disabled" />}
              label="Rename"
            />
          );
        }
        return actions;
      }
    }
  ];

  return (
    <>
      <ScreenHeader
        title="Add or remove tags from selected nodes"
        crumbDefs={[{ label: "Nodes", href: "/nodes" }]}
        description="Add tags to label or group all of your selected nodes. You can also
      remove unwanted tags individually."
      />
      <Stack>
        <Stack
          sx={{
            background: "#F5F5F5",
            marginTop: "15px",
            padding: "10px"
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "medium" }}>
            Selected nodes
          </Typography>
          <div style={{ height: "320px", width: "100%" }}>
            <DataGridPremium
              autoHeight={false}
              rows={rowData}
              columns={columns}
              loading={revalidateLoader.state !== "idle"}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              disableRowSelectionOnClick
              disableColumnSelector
              disableColumnFilter
              disableColumnPinning
              disableColumnReorder
              disableRowGrouping
              hideFooterPagination
              hideFooter
              getRowHeight={() => "auto"}
              sx={{
                "&>.MuiDataGrid-main": {
                  border: 0
                },
                "&>.MuiDataGrid-main .MuiDataGrid-columnHeader": {
                  background: "#F5F5F5"
                },
                "&>.MuiDataGrid-main .MuiDataGrid-scrollbarFiller": {
                  background: "#F5F5F5"
                }
              }}
              localeText={{
                noRowsLabel: (
                  <Typography variant="h6">
                    There are no nodes selected. Please return to the{" "}
                    <Link
                      underline="none"
                      color="primary"
                      href={`${APP_BASE_URL}/nodes`}
                      component="a"
                    >
                      all nodes table
                    </Link>{" "}
                    and select nodes to edit tags on.
                  </Typography>
                )
              }}
              slotProps={{}}
              getRowId={(row) => row.node_id}
            />
          </div>
        </Stack>

        <Typography
          variant="h6"
          sx={{
            marginTop: (theme) => theme.spacing(3),
            fontWeight: "medium"
          }}
        >
          Add tags to nodes
        </Typography>
        <Typography variant="body1">
          Tags are optional, but are useful for organizing target nodes which
          you register with Performance Profiler. <br /> Select from an existing
          tag below, or create a new tag.
        </Typography>
        <Box sx={{ marginBottom: (theme) => theme.spacing(1) }} />
        <AutocompleteNodeTagging registerNodes={false} />
        <Stack
          direction="row"
          spacing={2}
          sx={{ marginTop: (theme) => theme.spacing(4) }}
        >
          <Button
            variant="contained"
            onClick={() => updateTags()}
            disabled={
              savingChanges || (!hasBeenUpdated && tagsToBeAdded.length < 1)
            }
          >
            {savingChanges ? (
              <>
                Saving Changes...{" "}
                <CircularProgress
                  size="30px"
                  sx={{ marginLeft: (theme) => theme.spacing(1) }}
                />
              </>
            ) : (
              "Save Changes"
            )}
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleRootCancel()}
            disabled={savingChanges}
          >
            Cancel
          </Button>
        </Stack>
        <Dialog
          open={openCancelDialog}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Unsaved Changes
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have made changes that have not been saved. If you continue,
              these changes will be lost. Do you wish to proceed without saving?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Go Back
            </Button>
            <Button onClick={handleCancel}>Continue</Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
};

export default NodeTagEditor;
