import React from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { ConvertDateTime } from "../../../appServices/resuableMethods";

const userDataStyle = {
  fontSize: "12px",
  fontWeight: "501"
};

const userDataLabelStyle = {
  fontSize: "12px",
  minWidth: "95px",
  minHeight: "20px"
};

const YourProfilePane = ({ email, tenantName }) => {
  const role = useSelector((state) => state.user.current_role);
  const memberships = useSelector((state) => state.user.memberships);
  const currentTenantId = useSelector((state) => state.user.current_tenant_id);
  const memberSinceDate = memberships[currentTenantId]?.date_created;

  return (
    <Stack padding={0} spacing={4}>
      <Stack>
        <Typography variant="h5" paddingBottom={1}>
          Me
        </Typography>
        <Stack direction="row" spacing={1} paddingTop="4px">
          <Typography sx={userDataLabelStyle}>Email</Typography>
          <Typography sx={userDataStyle}>{email}</Typography>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h5" paddingBottom={1}>
          Organization
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography sx={userDataLabelStyle}>Name</Typography>
          <Typography sx={userDataStyle}>{tenantName}</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography sx={userDataLabelStyle}>Role</Typography>
          <Typography sx={userDataStyle}>{role.name}</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography sx={userDataLabelStyle}>Member since</Typography>
          <Typography sx={userDataStyle}>
            {memberSinceDate
              ? ConvertDateTime(memberSinceDate * 1000)
              : "Unknown"}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default YourProfilePane;
