import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  List,
  ListItem,
  Stack,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";
import {
  ConvertDateTime,
  displayConsistentNodeName
} from "../../appServices/resuableMethods";
import { useGetNodeQuery } from "../../store/wtpkApi";
import { generateStatusIconWithLabel } from "../../utils/statusIconGeneration";
import NodeResultTable from "./components/NodeResultTable";
import AdditionalNodeDetailsPane from "./components/AdditionalNodeDetailsPane";
import NodeTagsPane from "./components/NodeTagsPane";
import ParseNodeData from "../../utils/TableUtils";
import NodeOptionsMenu from "./components/NodeOptionsMenu";

const NodeDetails = () => {
  const nodeId = window.location.pathname.split("/")[2];
  const { data, isUninitialized, isLoading } = useGetNodeQuery(nodeId, {
    skip: nodeId === undefined
  });
  const loading = isLoading || isUninitialized;
  const nodeData = loading ? null : ParseNodeData({ nodes: [data.node] })[0];
  const role = useSelector((state) => state.user.current_role);

  const userCanUseActionMenu =
    role.hasPermission("canDeregisterNodes") ||
    role.hasPermission("canEditNodeTags") ||
    role.hasPermission("canCreateAnalyses");

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleClickMenuDropdown = (event) => {
    setMenuAnchorEl(event?.currentTarget);
  };

  const handleCloseMenuDropdown = () => {
    setMenuAnchorEl(null);
  };

  const [activeTab, setActiveTab] = useState(0);
  const handleActiveTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const generateTabContent = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return <NodeResultTable nodeId={nodeId} />;
      case 1:
        return <AdditionalNodeDetailsPane nodeId={nodeId} />;
      case 2:
        return <NodeTagsPane nodeData={nodeData} isLoading={loading} />;
      default:
        return <NodeResultTable nodeId={nodeId} />;
    }
  };

  const nodeNameObj = loading
    ? { primary: "Loading..." }
    : displayConsistentNodeName(data.node);

  return (
    <>
      <ScreenHeader
        title={nodeNameObj.primary}
        crumbDefs={[
          { label: "Nodes", href: "/nodes" },
          { label: "Node Details" }
        ]}
        heroButtonDef={
          userCanUseActionMenu
            ? {
                label: "Node Options",
                isMenu: true,
                btnAction: handleClickMenuDropdown,
                isOpen: menuOpen
              }
            : undefined
        }
      />
      <Stack width="100%" alignItems="left">
        <Box sx={{ display: "flex" }}>
          <List disableGutters sx={{ minWidth: "70%", p: 0 }}>
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Registered on
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
                {loading
                  ? "Loading..."
                  : ConvertDateTime(nodeData.registration_date * 1000)}
              </Typography>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Last online
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
                {loading
                  ? "Loading..."
                  : ConvertDateTime(nodeData.last_ping_time * 1000)}
              </Typography>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ minWidth: "230px" }}>
                Status
              </Typography>
              {loading
                ? "Loading..."
                : generateStatusIconWithLabel(nodeData.ping_status)}
            </ListItem>
          </List>
        </Box>
        <Tabs
          TabIndicatorProps={{ style: { height: "4px" } }}
          value={activeTab}
          onChange={handleActiveTabChange}
          sx={{ pt: "30px" }}
        >
          <Tab id="tabNodeResults" label="Node Results" value={0} />
          <Tab id="tabNodeDetails" label="Details" value={1} />
          <Tab id="tabNodeTags" label="Tags" value={2} />
        </Tabs>
        {generateTabContent(activeTab)}
      </Stack>
      <NodeOptionsMenu
        nodeData={nodeData}
        isLoading={loading}
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleCloseMenuDropdown}
      />
    </>
  );
};

export default NodeDetails;
