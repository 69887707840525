import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigation } from "react-router-dom";
import SideNavBar from "../SideNavBar/SideNavBar";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const openDrawerWidth = "160px";
const closedDrawerWidth = "64px";

const openedMixin = (theme) => ({
  width: openDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden",
  border: "none"
});

const closedMixin = (theme) => ({
  width: closedDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: 50 // theme.transitions.duration.shortest
  }),
  overflowX: "hidden",
  border: "none"
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: openDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const Layout = () => {
  const navigation = useNavigation();
  const globalTheme = useTheme();
  const location = useLocation();
  const mainGutterWidth = location.pathname.startsWith("/home") ? 0 : 6;
  const [open, setOpen] = useState(true);
  const pagesWithWhiteBackgrounds = [
    "editNodeTags",
    "admin",
    "analyses",
    "allNodes",
    "nodes",
    "registerNodes",
    "account",
    "newAnalysis"
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (
    navigation.state === "loading" &&
    !location.pathname.includes("/newAnalysis")
  ) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={open}>
        <SideNavBar
          open={open}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
        />
      </Drawer>
      <Box
        component="main"
        bgcolor={
          pagesWithWhiteBackgrounds.some((path) =>
            location.pathname.includes(path)
          )
            ? "white"
            : "#F7F7F7"
        }
        height="100%"
        minHeight={
          location.pathname.startsWith("/home") ? "100vh" : "calc(100vh - 48px)"
        } // -48px to fit the py:3 in the sx prop
        display="flex"
        flexDirection="column"
        width={
          open
            ? `calc(100% - ${openDrawerWidth} - ${globalTheme.spacing(
                mainGutterWidth * 2
              )})`
            : `calc(100% - ${closedDrawerWidth} - ${globalTheme.spacing(
                mainGutterWidth * 2
              )})`
        }
        sx={{
          flexGrow: 4,
          py: location.pathname.startsWith("/home") ? 0 : 3,
          px: mainGutterWidth
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
