import React from "react";
import Grid from "@mui/material/Grid";
import WTPKInput from "./ReusableFormComponents/WTPKInput";
import { isItInRange } from "../../screens/Analysis/LaunchAnalysis/LaunchAnalysisValidation";

export const Name = "MemoryConsumption";
const MemoryConsumption = () => {
  const validationMemoryObjectSize = (e, dispatch, action) => {
    isItInRange(
      dispatch,
      e.target.value,
      e.target.id,
      action,
      1,
      999999999,
      "Invalid value, please pick a value between 1 and 999999999."
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WTPKInput
          id="mem_object_size_min_thres"
          label="Minimal dynamic memory object size to track, in bytes"
          initialValue="32"
          required
          min="1"
          max="999999999"
          inputType="number"
          validation={validationMemoryObjectSize}
        />
      </Grid>
    </Grid>
  );
};
export default MemoryConsumption;
