import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Button, Chip, Link, Stack, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/**
ScreenHeader takes in the following props:

  title (required): The title of the screen

  crumbDefs (optional): An array of breadcrumb definitions to be displayed left to right.
    Each breadcrumb definition is an object with the following properties: 
    - label: The text to display for the breadcrumb
    - href: The URL to navigate to when the breadcrumb is clicked

  heroButtonDef (optional): A hero button definition with the following properties 
    - label: The text to display on the hero button
    - href: The URL to navigate to when the hero button is clicked
    - onClick: The function to call when the hero button is clicked 
    - isMenu: A boolean indicating whether the hero button should be a menu button
    - variant: The variant of the hero button (i.e. contained or outlined)
  
  description (optional): Descriptive paragraph text above the screen content
*/

const ScreenHeader = ({
  crumbDefs = [],
  title,
  heroButtonDef,
  description,
  count
}) => {
  const dropdownMenuOpen = Boolean(heroButtonDef?.isOpen);
  const heroButton =
    heroButtonDef &&
    (heroButtonDef.isMenu ? (
      <Button
        id="screen-header-dropdown-button"
        aria-controls={
          dropdownMenuOpen ? "screen-header-dropdown-options" : undefined
        }
        aria-haspopup="true"
        aria-expanded={dropdownMenuOpen ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={heroButtonDef.btnAction}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <span>{heroButtonDef.label}</span>
      </Button>
    ) : (
      <Button
        variant={heroButtonDef.variant || "contained"}
        href={heroButtonDef.href}
        onClick={heroButtonDef.onClick}
      >
        {heroButtonDef.label}
      </Button>
    ));

  return (
    <Stack sx={!description && { marginBottom: 4 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ "min-height": "16px" }}
      >
        {crumbDefs &&
          crumbDefs.length > 0 &&
          crumbDefs.map((crumbDef) => {
            return crumbDef.href === undefined ? (
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "medium",
                  fontSize: "small"
                }}
              >
                {crumbDef.label}
              </Typography>
            ) : (
              <Link
                href={crumbDef.href}
                underline="none"
                sx={{
                  display: "flex",
                  textTransform: "uppercase",
                  fontWeight: "medium",
                  fontSize: "small"
                }}
              >
                {crumbDef.label}
              </Link>
            );
          })}
      </Breadcrumbs>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">
          {title}{" "}
          {count && (
            <Chip
              label={count}
              sx={{ bgcolor: "#653171", color: "white" }}
              size="medium"
            />
          )}
        </Typography>
        {heroButton}
      </Stack>
      {description && (
        <Typography sx={{ marginTop: "16px", maxWidth: "800px" }}>
          {description}
        </Typography>
      )}
    </Stack>
  );
};

export default ScreenHeader;
