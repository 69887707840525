/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import { Box, Grid, Typography, Card, CardContent, Link } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./Security.css";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";

const SecurityScreen = () => {
  const [nodeRegistrationClicked, setNodeRegistrationClicked] = useState(false);
  const [analysisSectionClicked, setAnalysisSectionClicked] = useState(false);
  const [malwareProtectionClicked, setMalwareProtectionClicked] =
    useState(false);
  const [dataStorageClicked, setDataStorageClicked] = useState(false);
  const APP_BASE_URL = `${window.runtime.REACT_APP_BASE_URL}`;
  const expandCollapseIconStyle = { verticalAlign: "middle" };

  return (
    <>
      <ScreenHeader
        title="Security"
        crumbDefs={[{ label: "Information" }]}
        description="Ensuring the security of the Intel Performance Profiler
        product is a joint effort between Intel and you, the customer.
        This guide provides important security aspects and best
        practices for maintaining a secure environment."
      />
      <Grid container spacing={2}>
        <Grid item xs={12} xl={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <Link
                  href="#intel-performance-profiler-product-security-overview"
                  color="primary"
                  underline="none"
                  component="a"
                >
                  <ExpandMoreIcon
                    fontSize="large"
                    color="primary"
                    sx={expandCollapseIconStyle}
                  />
                  Security Overview
                </Link>
              </Typography>
              <Typography
                variant="h6"
                sx={{ marginLeft: (theme) => theme.spacing(4) }}
              >
                <Link
                  href="#preamble"
                  color="primary"
                  underline="none"
                  component="a"
                >
                  Preamble
                </Link>
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  marginLeft: (theme) => theme.spacing(3)
                }}
                onClick={() =>
                  setNodeRegistrationClicked(!nodeRegistrationClicked)
                }
                color="primary"
              >
                {nodeRegistrationClicked ? (
                  <ExpandMoreIcon
                    fontSize="large"
                    color="primary"
                    sx={expandCollapseIconStyle}
                    aria-label="NodeRegistrationExpandMoreIcon"
                  />
                ) : (
                  <ChevronRightIcon
                    fontSize="large"
                    color="primary"
                    sx={expandCollapseIconStyle}
                    aria-label="NodeRegistrationChevronRightIcon"
                  />
                )}
                Node Registration
              </Typography>
              {nodeRegistrationClicked ? (
                <>
                  <Typography
                    variant="h6"
                    sx={{ marginLeft: (theme) => theme.spacing(10) }}
                  >
                    <Link
                      href="#1-1-node-registration"
                      color="primary"
                      underline="none"
                      component="a"
                    >
                      1.1 Node Registration
                    </Link>
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ marginLeft: (theme) => theme.spacing(10) }}
                  >
                    <Link
                      href="#1-2-kernel-drivers"
                      color="primary"
                      underline="none"
                      component="a"
                    >
                      1.2 Kernel Drivers
                    </Link>
                  </Typography>
                </>
              ) : null}

              <Typography
                variant="h6"
                sx={{ marginLeft: (theme) => theme.spacing(3) }}
                color="primary"
                onClick={() =>
                  setAnalysisSectionClicked(!analysisSectionClicked)
                }
              >
                {analysisSectionClicked ? (
                  <ExpandMoreIcon
                    fontSize="large"
                    color="primary"
                    sx={expandCollapseIconStyle}
                    aria-label="AnalysisSelectionExpandMoreIcon"
                  />
                ) : (
                  <ChevronRightIcon
                    fontSize="large"
                    color="primary"
                    sx={expandCollapseIconStyle}
                    aria-label="AnalysisSelectionChevronRightIcon"
                  />
                )}
                Analysis Selection
              </Typography>
              {analysisSectionClicked ? (
                <Typography
                  variant="h6"
                  sx={{ marginLeft: (theme) => theme.spacing(10) }}
                >
                  <Link
                    href="#2-1-vtune-profiler-os-command-execution"
                    color="primary"
                    underline="none"
                    component="a"
                  >
                    2.1 Intel® VTune™ Profiler OS command execution
                  </Link>
                </Typography>
              ) : null}

              <Typography
                variant="h6"
                color="primary"
                sx={{ marginLeft: (theme) => theme.spacing(3) }}
                onClick={() =>
                  setMalwareProtectionClicked(!malwareProtectionClicked)
                }
              >
                {malwareProtectionClicked ? (
                  <ExpandMoreIcon
                    fontSize="large"
                    color="primary"
                    sx={expandCollapseIconStyle}
                    aria-label="MalwareProtectionExpandMoreIcon"
                  />
                ) : (
                  <ChevronRightIcon
                    fontSize="large"
                    color="primary"
                    sx={expandCollapseIconStyle}
                    aria-label="MalwareProtectionChevronRightIcon"
                  />
                )}
                Malware Protection
              </Typography>
              {malwareProtectionClicked ? (
                <Typography
                  variant="h6"
                  sx={{ marginLeft: (theme) => theme.spacing(10) }}
                >
                  <Link
                    href="#3-1-virus-scanning"
                    color="primary"
                    underline="none"
                    component="a"
                  >
                    3.1 Virus Scanning
                  </Link>
                </Typography>
              ) : null}

              <Typography
                color="primary"
                variant="h6"
                sx={{ marginLeft: (theme) => theme.spacing(3) }}
                onClick={() => setDataStorageClicked(!dataStorageClicked)}
              >
                {dataStorageClicked ? (
                  <ExpandMoreIcon
                    fontSize="large"
                    color="primary"
                    sx={expandCollapseIconStyle}
                    aria-label="DataStorageExpandMoreIcon"
                  />
                ) : (
                  <ChevronRightIcon
                    fontSize="large"
                    color="primary"
                    sx={expandCollapseIconStyle}
                    aria-label="DataStorageChevronRightIcon"
                  />
                )}
                Data Storage
              </Typography>
              {dataStorageClicked ? (
                <Typography
                  sx={{ marginLeft: (theme) => theme.spacing(10) }}
                  variant="h6"
                >
                  <Link
                    href="#4-1-secure-storage"
                    color="primary"
                    underline="none"
                    component="a"
                  >
                    4.1 Secure Storage
                  </Link>
                </Typography>
              ) : null}

              <Typography
                variant="h6"
                sx={{ marginLeft: (theme) => theme.spacing(4) }}
              >
                <Link
                  href="#conclusion"
                  color="primary"
                  underline="none"
                  component="a"
                >
                  Conclusion
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12} xl={9}>
          <Card>
            <CardContent>
              <Box>
                <Typography
                  variant="h5"
                  id="section-1-node-registration-and-software-installation"
                >
                  Section 1: Node Registration and Software Installation
                </Typography>
                <Typography variant="h6" id="1-1-node-registration">
                  1.1 Node Registration
                </Typography>
                <Typography paragraph>
                  When registering a node, Intel supplies a script you can use
                  to install the Intel Performance Profiler agent. The
                  installation process includes installing the Intel® VTune™
                  Profiler package, Amazon SSM package, a custom Performance
                  Profiler agent package, and the OS prerequisites for each. For
                  example Intel® VTune™ Profiler requires prerequisites such as
                  kernel headers for the installation process. These
                  prerequisite packages are obtained from the repository
                  specified in the node&#39;s (apt/yum) configuration, and any
                  unneeded installation package artifacts are deleted after
                  successful installation.
                </Typography>
                <Typography variant="h6" id="1-2-kernel-drivers">
                  1.2 Kernel Drivers
                </Typography>
                <Typography paragraph>
                  During installation, Intel installs sampling drivers kernel
                  drivers on the registered nodes and inserts them into the
                  running kernel. The drivers remain loaded for the duration
                  that a node is registered with Performance Profiler, and allow
                  Intel® VTune™ Profiler to conduct hardware event-based
                  sampling of your workload. See{" "}
                  <Link
                    href="https://www.intel.com/content/www/us/en/docs/vtune-profiler/user-guide/2023-1/sep-driver.html"
                    target="_blank"
                    rel="noreferrer"
                    component="a"
                    underline="none"
                    color="primary"
                  >
                    here
                  </Link>{" "}
                  for additional information on driver installation.
                </Typography>
                <Typography
                  variant="h5"
                  id="section-2-job-selection-and-execution"
                  sx={{ marginTop: (theme) => theme.spacing(5) }}
                >
                  Section 2: Job Selection and Execution
                </Typography>
                <Typography
                  variant="h6"
                  id="2-1-vtune-profiler-os-command-execution"
                >
                  2.1 Intel® VTune™ Profiler OS command execution
                </Typography>
                <Typography paragraph>
                  The Intel Performance Profiler SaaS solution extends the
                  functionality of Intel® VTune™ Profiler by allowing job
                  execution of multiple nodes from a central location. The power
                  and thus potential risks from Intel® VTune™ Profiler still
                  apply, such as the ability for Intel® VTune™ Profiler to
                  attach to or spawn any OS process on the target nodes.
                  Although Intel implements filtering and validation measures to
                  protect against known malicious processes, users are
                  ultimately responsible for assessing the risk of any process
                  they choose to profile via Intel® VTune™ Profiler and Intel
                  Performance Profiler. Additional documentation on securely
                  using Intel Performance Profiler and Intel® VTune™ Profiler
                  can be found{" "}
                  <Link
                    href="https://www.intel.com/content/www/us/en/docs/vtune-profiler/user-guide/2023-1/security-best-practices.html"
                    target="_blank"
                    rel="noreferrer"
                    component="a"
                    underline="none"
                    color="primary"
                  >
                    here
                  </Link>
                  .
                </Typography>
                <Typography
                  variant="h5"
                  id="section-3-malware-protection"
                  sx={{ marginTop: (theme) => theme.spacing(5) }}
                >
                  Section 3: Malware Protection
                </Typography>
                <Typography variant="h6" id="3-1-virus-scanning">
                  3.1 Virus Scanning
                </Typography>
                <Typography paragraph>
                  To maintain the security of your data in Intel Performance
                  Profiler, all Intel® VTune™ Profiler results are scanned for
                  viruses before being uploaded to Intel Performance Profiler.
                  You will be notified of any security findings.
                </Typography>
                <Typography
                  id="section-4-data-storage"
                  variant="h5"
                  sx={{ marginTop: (theme) => theme.spacing(5) }}
                >
                  Section 4: Data Storage
                </Typography>
                <Typography variant="h6" id="4-1-secure-storage">
                  4.1 Secure Storage
                </Typography>
                <Typography paragraph>
                  Results data from executed jobs are uploaded to Intel
                  Performance Profiler, to enable centralized view of profiling
                  results across multiple nodes. All stored data in Performance
                  Profiler is encrypted in transit and at rest. Once the upload
                  of results from a node to Performance Profiler is complete,
                  local results on the node are deleted. 4.2 Data Privacy &amp;
                  Management: You can delete any of their artifacts from Intel
                  Performance Profiler at any time, including node registration
                  data, job execution data, and logs of historical activity. You
                  can also stop, start, upgrade, or uninstall all agent
                  components. Detailed instructions can be found in the Intel
                  Performance Profiler user guide{" "}
                  <Link
                    href={`${APP_BASE_URL}/userGuide.pdf`}
                    target="_blank"
                    rel="noreferrer"
                    component="a"
                    underline="none"
                    color="primary"
                  >
                    here
                  </Link>
                  .
                </Typography>
                <Typography
                  variant="h5"
                  id="conclusion"
                  sx={{ marginTop: (theme) => theme.spacing(5) }}
                >
                  Conclusion
                </Typography>
                <Typography paragraph>
                  The security of your data is Intel&#39;s highest priority. If
                  you have any further inquiries, feel free to contact us at{" "}
                  <Link
                    href="mailto:vtune-profiler@intel.com"
                    component="a"
                    underline="none"
                    color="primary"
                  >
                    Performance Profiler Security
                  </Link>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SecurityScreen;
