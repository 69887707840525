import React from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";

import WTPKSwitch from "./ReusableFormComponents/WTPKSwitch";

export const Name = "InputOutputPlatformLevelMetrics";

const InputOutputPlatformLevelMetrics = () => {
  const formInputs = useSelector((state) => state.customAnalysisForms.value);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel component="legend">
            Select platform-level metrics:
          </FormLabel>
          <FormGroup>
            <WTPKSwitch
              id="mmio"
              label="Locate MMIO accesses"
              defaultChecked={formInputs.mmio || false}
            />
            <WTPKSwitch
              id="collect_memory_bandwidth"
              label="Analyze memory bandwidth"
              defaultChecked={formInputs.collect_memory_bandwidth || true}
            />
            <WTPKSwitch
              id="dram_bandwidth_limits"
              label="Evaluate max DRAM bandwidth"
              defaultChecked={formInputs.dram_bandwidth_limits || true}
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel component="legend">Select API to profile:</FormLabel>
          <FormGroup>
            <WTPKSwitch
              id="dpdk"
              label="DPDK"
              defaultChecked={formInputs.dpdk || false}
            />
            <WTPKSwitch
              id="spdk"
              label="SPDK"
              defaultChecked={formInputs.spdk || false}
            />
            <WTPKSwitch
              id="kernel_stack"
              label="Kernel IO"
              defaultChecked={formInputs.kernel_stack || false}
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default InputOutputPlatformLevelMetrics;
