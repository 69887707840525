/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Tooltip, styled } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(14)
  }
}));

const InfoTip = ({ content, placement, size, color = "primary" }) => {
  return (
    <HtmlTooltip placement={placement} title={content} arrow>
      <InfoOutlinedIcon fontSize={size} color={color} />
    </HtmlTooltip>
  );
};

export default InfoTip;
