import React from "react";
import { CardHeader, Typography, Alert, Avatar } from "@mui/material";

const LaunchAnalysisCardHeader = (props) => {
  const { stepCount, title, subTitle, missingNodes } = props;
  return (
    <CardHeader
      titleTypographyProps={{
        variant: "h6",
        color: "#000000 !important",
        textTransform: "none !important"
      }}
      subheaderTypographyProps={{
        variant: "body1",
        color: "#000000 !important"
      }}
      sx={{ alignItems: "flex-start", padding: "10px" }}
      avatar={
        <Avatar
          sx={{
            width: 26,
            height: 26,
            fontSize: "12px",
            backgroundColor: "#653171"
          }}
        >
          {stepCount}
        </Avatar>
      }
      title={title}
      subheader={
        <>
          <Typography>{subTitle}</Typography>
          {missingNodes !== undefined && missingNodes.length > 0 && (
            <Alert severity="warning">
              Nodes with these IDs were not available for selection:
              {`  ${missingNodes.join(", ")}`}
            </Alert>
          )}
        </>
      }
    />
  );
};

export default LaunchAnalysisCardHeader;
