import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React, { useContext, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import AuthContext from "../../../contexts/AuthContext";
import { getAnalysisExecution } from "../AnalysisStatus/AnalysisStatus.service";
import { ConvertDateTime } from "../../../appServices/resuableMethods";
import {
  useDeleteAnalysisMutation,
  useGetAnalysesQuery
} from "../../../store/wtpkApi";

const PaperComponent = (props) => {
  const draggableRef = useRef(null);
  return (
    <Draggable
      handle="#draggable-dialog-title"
      ref={draggableRef}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} ref={draggableRef} />
    </Draggable>
  );
};

const checkForDeletedRecord = async (executionId, auth, retries = 10) => {
  try {
    await getAnalysisExecution(auth.user, executionId);
  } catch (e) {
    if (e.status === 410) return true;
  }
  if (retries > 0) return checkForDeletedRecord(executionId, auth, retries - 1);
  return true;
};

const DeleteAnalysisDialog = (props) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const deletedAnalysesIds = useSelector(
    (state) => state.analysisStatus?.deletedAnalysis
  );
  const { data: analysisExecutionResponse } = useGetAnalysesQuery();
  const [deleteAnalysis] = useDeleteAnalysisMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [deletionStarted, setDeletionStarted] = useState(false);

  // Reset the dialog after it closes
  useEffect(() => {
    if (props.show === false) {
      setIsLoading(false);
      setDeletionStarted(false);
    }
  }, [props.show]);

  // No need to render if there's no data:
  if (
    !deletedAnalysesIds ||
    deletedAnalysesIds.length === 0 ||
    !analysisExecutionResponse
  ) {
    return null;
  }
  const analysesIdSet = new Set(deletedAnalysesIds);

  const deletedAnalyses = analysisExecutionResponse?.items.filter((exe) =>
    analysesIdSet.has(exe.execution_id)
  );

  const onAnalysisStatusPage = location.pathname.startsWith("/analyses/");

  const handleDeleteAnalysis = async () => {
    setIsLoading(true);
    setDeletionStarted(true);

    let count = 0;
    const result = deletedAnalyses.map((a) => a.execution_id);

    const response = await deleteAnalysis(result)
      .unwrap()
      .then((fulfilled) => fulfilled)
      .catch((rejected) => rejected);
    if (response instanceof Error) {
      props.close();
      enqueueSnackbar(`Analysis deletion failed. ${response.message}`, {
        variant: "error"
      });
    } else {
      result.map(async (exe) => {
        if (await checkForDeletedRecord(exe, auth)) {
          count += 1;
        }
        if (count >= result.length) {
          if (onAnalysisStatusPage) {
            navigate("/analyses");
          } else {
            setIsLoading(false);
          }
          props.close();
        }
      });
    }
  };

  const sxDialogDim = {
    minHeight: isLoading && deletionStarted ? "250px" : "100px"
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      props.close(event, reason);
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="draggable-dialog-title"
        sx={{
          cursor: "move",
          backgroundColor: (theme) =>
            !isLoading && deletionStarted
              ? theme.palette.success.main
              : theme.palette.error.main,
          color: "white"
        }}
      >
        {deletedAnalyses.length > 1 ? "Delete Analyses" : "Delete Analysis"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white"
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          marginTop: (theme) => theme.spacing(3),
          width: "400px",
          ...sxDialogDim
        }}
        component="div"
      >
        {isLoading && deletionStarted && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <CircularProgress size="12rem" />
          </Box>
        )}
        {!isLoading && deletionStarted && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <CheckCircleIcon sx={{ fontSize: "14rem" }} color="success" />
          </Box>
        )}
        {!isLoading && deletionStarted && (
          <DialogContentText>
            The analyses have been successfully deleted. You may now close this
            window.
          </DialogContentText>
        )}
        {!deletionStarted && (
          <div>
            {deletedAnalyses.length === 1 && (
              <DialogContentText>
                Are you sure you want to delete this analysis?
                <br />
                <strong>{deletedAnalyses[0].name} </strong> which ran on{" "}
                <strong>
                  {ConvertDateTime(deletedAnalyses[0].date_started * 1000)}
                </strong>
                .
              </DialogContentText>
            )}
            {deletedAnalyses.length > 1 && (
              <DialogContentText>
                Are you sure you want to delete these analyses?
                <ul>
                  {deletedAnalyses.map((exe) => {
                    return (
                      <li>
                        <strong>{exe.name} </strong> created on{" "}
                        <strong>
                          {ConvertDateTime(exe.date_started * 1000)}
                        </strong>
                      </li>
                    );
                  })}
                </ul>
              </DialogContentText>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!isLoading && !deletionStarted && (
          <Button
            variant="contained"
            onClick={handleDeleteAnalysis}
            size="small"
            autoFocus
            color={!isLoading && deletionStarted ? "success" : "error"}
            sx={{ color: "white" }}
          >
            Yes
          </Button>
        )}

        {!isLoading && deletionStarted ? (
          <Button
            disabled={isLoading}
            variant="contained"
            onClick={handleClose}
            size="small"
            color="success"
            sx={{ color: "white" }}
          >
            Close
          </Button>
        ) : (
          <Button
            disabled={isLoading}
            variant="contained"
            onClick={handleClose}
            size="small"
            color="error"
            sx={{ color: "white" }}
          >
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAnalysisDialog;
