import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { useLoaderData } from "react-router-dom";
import { allNodesTagActions } from "../../screens/AllNodes/slices/AllNodesSlices";

const AutocompleteNodeTagging = (props) => {
  const loadedNodes = useLoaderData();
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [tagsString, setTagsString] = useState("");
  const [tagCounts, setTagCounts] = useState([]);
  const [formattedUniqueTagList, setFormattedUniqueTagList] = useState([]);
  const [tagInputInvalid, setTagInputInvalid] = useState(false);
  const [containsDuplicateTag, setContainsDuplicateTag] = useState(false);

  const selectedNodeList = useSelector((state) => state.nodeTags.selectedNodes);

  const filterOptions = (options) => {
    const lastCommaIndex = tagsString.lastIndexOf(",");
    const filterValue =
      lastCommaIndex === -1
        ? tagsString.trim()
        : tagsString.substring(lastCommaIndex + 1).trim();
    return options.filter((option) =>
      option.toLowerCase().includes(filterValue.toLowerCase())
    );
  };

  const handleOptionSelected = (_, newValue) => {
    if (newValue) {
      const lastCommaIndex = tagsString.lastIndexOf(",");
      if (lastCommaIndex === -1) {
        setTagsString(`${newValue}, `);
      } else {
        setTagsString(
          `${tagsString.substring(0, lastCommaIndex + 1)} ${newValue}, `
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tagInputInvalid) {
      const separatedTags = tagsString.toLowerCase().split(",");
      setTags([
        ...new Set(
          tags.concat(
            separatedTags.map((tag) => tag.trim()).filter((tag) => tag !== "")
          )
        )
      ]);
      setTagsString("");
    }
  };

  const handleTagValidation = useCallback(() => {
    const tagRegex = /^[A-Za-z0-9,./+\-@\s]+$/;
    const whiteSpaceRegex = /^\s*$/;
    const nameRegex = /(.*\s*\bname\b\s*.*)$/;
    const modifiedTagInput = tagsString.toLowerCase().trim();
    setTagInputInvalid(
      tagRegex.test(modifiedTagInput) &&
        !whiteSpaceRegex.test(modifiedTagInput) &&
        !nameRegex.test(modifiedTagInput)
    );
  }, [tagsString]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    props.registerNodes
      ? dispatch(allNodesTagActions.setRegisterNodesTags(tags))
      : dispatch(allNodesTagActions.setTagEditorNodesTags(tags));
  }, [tags, dispatch, props.registerNodes]);

  useEffect(() => {
    setTagCounts([]);
    const tagObject = {};
    loadedNodes.nodes.forEach((item) => {
      const nodeTags = item.tags;
      Object.keys(nodeTags).forEach((tagKey) => {
        const modifiedTagKey = tagKey.toLowerCase();
        if (modifiedTagKey) {
          tagObject[modifiedTagKey] = (tagObject[modifiedTagKey] || 0) + 1;
        }
      });
    });

    const sortedKeys = Object.keys(tagObject).sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
    );
    const sortedTagListAlphbetically = {};
    sortedKeys.forEach((key) => {
      sortedTagListAlphbetically[key] = tagObject[key];
    });

    setFormattedUniqueTagList(Object.keys(sortedTagListAlphbetically));
    const sortedTagCountsArray = Object.entries(tagObject).sort(
      ([, countA], [, countB]) => countB - countA
    );
    setTagCounts(sortedTagCountsArray);
  }, [loadedNodes]);

  useEffect(() => {
    handleTagValidation();
  }, [handleTagValidation]);

  useEffect(() => {
    if (tags.length > 0 && !props.registerNodes) {
      setContainsDuplicateTag(
        selectedNodeList.some((obj) =>
          Object.keys(obj.node_tags).some((key) => tags.includes(key))
        )
      );
    } else {
      setContainsDuplicateTag(false);
    }
  }, [selectedNodeList, tags, props.registerNodes]);

  return (
    <>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={7.5} md={9.5} xl={10.3}>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Autocomplete
              value={null}
              inputValue={tagsString}
              onInputChange={(e) => setTagsString(e.target.value)}
              options={formattedUniqueTagList}
              getOptionLabel={(option) => option}
              filterOptions={filterOptions}
              onChange={handleOptionSelected}
              id="NodeTagsAutocompleteDropdownList"
              disabled={!props.registerNodes && selectedNodeList.length < 1}
              size="small"
              freeSolo={!tagInputInvalid && tagsString.length !== 0?.length}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line
                  {...params}
                  label="Add new comma-separated tags here"
                  error={!tagInputInvalid && tagsString.length !== 0}
                  helperText={
                    !tagInputInvalid && tagsString.length !== 0
                      ? "Please enter a valid tag. The following word is not allowed: name. Accepted characters are: letters, numbers, '/', '+', '-', and '@'."
                      : ""
                  }
                  id="NodesAutocompleteTextField"
                />
              )}
              blurOnSelect={false}
              clearOnBlur={false}
            />
          </form>
        </Grid>
        <Grid item xs={4} md={2.3} xl={1.5}>
          <Button
            variant="outlined"
            id="NodesAddTagsButton"
            fullWidth
            type="submit"
            onClick={(e) => handleSubmit(e)}
            disabled={!tagInputInvalid}
            sx={{
              marginLeft: (theme) => theme.spacing(2.4),
              height: "100%",
              maxHeight: "42px",
              whiteSpace: "nowrap"
            }}
          >
            Add Tag(s)
          </Button>
        </Grid>
      </Grid>
      <Grid
        xs={9.5}
        md={10.5}
        xl={11.5}
        sx={{ marginTop: (theme) => theme.spacing(2), width: "100%" }}
      >
        <Typography variant="p">
          ...or select from the most popular tags
        </Typography>
        <br />
        <Stack
          sx={{
            width: "100%",
            maxHeight: "24px",
            overflow: "hidden",
            flexWrap: "wrap",
            marginTop: (theme) => theme.spacing(1)
          }}
          spacing={1}
          direction="row"
        >
          {tagCounts.map((tag) => {
            return (
              <Chip
                label={tag[0]}
                variant="outlined"
                color={tags.includes(tag[0]) ? "secondary" : "default"}
                disabled={!props.registerNodes && selectedNodeList.length < 1}
                id={tag[0]}
                key={tag[0]}
                size="small"
                onClick={() => setTags([...new Set([...tags, tag[0]])])}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  flexGrow: 1,
                  minWidth: 0,
                  textOverflow: "ellipsis",
                  borderWidth: "2px"
                }}
              />
            );
          })}
        </Stack>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: "medium", marginTop: (theme) => theme.spacing(2) }}
        >
          Tags to add to selected node(s)
        </Typography>
        <Grid
          sx={{
            width: "100%",
            flexWrap: "wrap"
          }}
          container
          spacing={1}
        >
          {containsDuplicateTag ? (
            <Grid item xs={12}>
              <Alert severity="info">
                Tags that already exist on selected nodes will not be duplicated
                on those nodes.
              </Alert>
            </Grid>
          ) : null}

          {tags.map((tag) => {
            return (
              <Grid item key={tag}>
                <Chip
                  label={tag}
                  color="secondary"
                  id={tag}
                  size="small"
                  onDelete={() =>
                    setTags(tags.filter((tagItem) => tagItem !== tag))
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default AutocompleteNodeTagging;
