import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddchartIcon from "@mui/icons-material/Addchart";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { analysisTemplateActions } from "../../slices/LaunchAnalysisSlices";
import ViewAnalysisButton from "../../AllAnalysis/ViewAnalysisButton";

const LaunchAnalysisDialog = (props) => {
  const { isLoading, executionId, isModalOpen, requestError, setIsModalOpen } =
    props;
  const dispatch = useDispatch();
  return (
    <Dialog
      open={isModalOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Launching Analysis</DialogTitle>
      <DialogContent
        dividers
        sx={{
          paddingY: 4
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minHeight="250px"
        >
          {isLoading && <CircularProgress size="12rem" />}
          {!isLoading && executionId !== null && (
            <>
              <CheckCircleIcon sx={{ fontSize: "14rem" }} color="success" />
              <DialogContentText id="alert-dialog-description">
                Successfully Launched Analysis!
              </DialogContentText>
            </>
          )}

          {!isLoading && requestError !== null && (
            <>
              <CancelIcon sx={{ fontSize: "14rem" }} color="error" />
              <DialogContentText id="alert-dialog-description">
                There was an error launching your Analysis.{" "}
                {requestError?.data?.message ?? ""}. Will print out your request
                payload. Please contact a Performance Profiler for additional
                assistance.
              </DialogContentText>
              <pre>{JSON.stringify(requestError?.form, null, 2)}</pre>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} alignContent="center">
          <Button
            size="medium"
            variant="contained"
            id="btnNewAnalysis"
            startIcon={<AddchartIcon />}
            disabled={isLoading}
            onClick={async () => {
              setIsModalOpen(false);
              dispatch(analysisTemplateActions.resetAnalyisForm());
              dispatch(analysisTemplateActions.setResetAnalysisForm(true));
            }}
          >
            New Analysis
          </Button>
          {requestError === null && (
            <ViewAnalysisButton
              executionId={executionId}
              size="medium"
              id="btnViewAnalysis"
              disabled={isLoading || executionId === null}
            />
          )}
          <Button
            size="medium"
            variant="contained"
            color="info"
            startIcon={<CloseIcon />}
            id="btnCloseLaunchAnalysisModal"
            disabled={isLoading}
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default LaunchAnalysisDialog;
