import Stack from "@mui/material/Stack";
import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getAPSLink } from "./AnalysisStatus.service";
import AuthContext from "../../../contexts/AuthContext";
import SparkRight from "../../../images/brand-spark-right.svg";

const APSReportSummary = (props) => {
  const { apsReportStatus, apsReportId } = props;
  const auth = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const fetchResultUrl = async () => {
    const response = await getAPSLink(auth.user, apsReportId);

    if (response instanceof Error) {
      enqueueSnackbar(`Could not fetch the log details: ${response.message}`, {
        variant: "error"
      });
    }
    if (!(response instanceof Error)) {
      window.open(response.result_url);
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    fetchResultUrl();
  };
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#eeeeee"
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#0054AE"
    }
  }));
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: "#E0EAF5",
        backgroundImage: `url(${SparkRight})`,
        backgroundPosition: `bottom right`,
        backgroundSize: `32px 24px`,
        backgroundRepeat: "no-repeat",
        borderRadius: `8px 8px 0 8px`,
        padding: `26px`,
        maxWidth: `378px`,
        boxSizing: `border-box`
      }}
    >
      <Typography variant="h5" color="primary.dark">
        Application Performance Snapshot
      </Typography>
      <Typography variant="body2" color="primary.dark" sx={{ pt: 1, pb: 1 }}>
        See an aggregated summary of performance snapshots across all nodes.
      </Typography>
      {apsReportStatus === "PENDING" && (
        <Box
          sx={{
            flexDirection: "column",
            display: "flex",
            width: "100%",
            textAlign: "center"
          }}
        >
          <Typography variant="body2" sx={{ p: 1 }}>
            Processing report
          </Typography>
          <BorderLinearProgress variant="indeterminate" />
        </Box>
      )}
      {apsReportStatus === "FAILED" && (
        <Box
          color="error"
          sx={{
            p: 1,
            border: "solid 1px",
            borderColor: (theme) => theme.palette.error.main,
            flexDirection: "row",
            display: "flex"
          }}
        >
          <InfoOutlinedIcon fontSize="12px" color="error" />
          <Typography
            variant="body2"
            color={(theme) => theme.palette.error.main}
            sx={{ ml: 1 }}
          >
            Report generation failed. Sorry about that.
          </Typography>
        </Box>
      )}
      {apsReportStatus === "NOT_ENOUGH_PMU_NODES" && (
        <Box
          color="error"
          sx={{
            p: 1,
            border: "solid 1px",
            borderColor: (theme) => theme.palette.error.main,
            flexDirection: "row",
            display: "flex"
          }}
        >
          <InfoOutlinedIcon fontSize="12px" color="error" />
          <Typography
            variant="body2"
            color={(theme) => theme.palette.error.main}
            sx={{ ml: 1 }}
          >
            Enable PMUs on at least 2 nodes to generate a report.
          </Typography>
        </Box>
      )}
      {apsReportStatus === "NOT_ENOUGH_SUCCESSFUL_PMU_NODES" && (
        <Box
          color="error"
          sx={{
            p: 1,
            border: "solid 1px",
            borderColor: (theme) => theme.palette.error.main,
            flexDirection: "row",
            display: "flex"
          }}
        >
          <InfoOutlinedIcon fontSize="12px" color="error" />
          <Typography
            variant="body2"
            color={(theme) => theme.palette.error.main}
            sx={{ ml: 1 }}
          >
            At least 2 nodes with PMUs enabled must succeed to generate a
            report.
          </Typography>
        </Box>
      )}
      {apsReportStatus === "ONLY_ONE_NODE" && (
        <Box
          color="info"
          sx={{
            p: 1,
            border: "solid 1px",
            borderColor: (theme) => theme.palette.info.main,
            flexDirection: "row",
            display: "flex"
          }}
        >
          <InfoOutlinedIcon fontSize="12px" color="info" />
          <Typography variant="body2" color="#014361" sx={{ ml: 1 }}>
            Run a Performance Snapshot on 2 or more nodes with PMUs enabled and
            we will generate an aggregated summary.
          </Typography>
        </Box>
      )}
      {apsReportStatus === "READY" && (
        <Button
          variant="contained"
          LinkComponent="a"
          color="primary"
          size="small"
          target="_blank"
          id="btn-analysis-results"
          rel="noreferrer"
          onClick={handleClick}
          title="View report"
          sx={{ m: 1 }}
        >
          View Report
        </Button>
      )}
    </Stack>
  );
};

export default APSReportSummary;
