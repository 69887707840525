/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Menu,
  MenuItem,
  LinearProgress,
  Tooltip
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  DataGridPremium,
  GridActionsCellItem,
  useGridApiRef
} from "@mui/x-data-grid-premium";
import { useDispatch, useSelector } from "react-redux";
import { allNodesTagActions } from "../slices/AllNodesSlices";
import ParseNodeData from "../../../utils/TableUtils";
import "./styles.css";
import TagDeregistrationModal from "./TagDeregistrationModal";
import { columnDef, columnVisibilityDef } from "./AllNodesColumnDef";
import {
  dataTableHeaderToolbarGetter,
  generateTableManagementMenuItems
} from "../../../components/DataTableElements/DataTableHeaderToolbar";
import PaginationButtons from "../../../components/DataTableElements/PaginationButtons";
import { analysisTemplateActions } from "../../Analysis/slices/LaunchAnalysisSlices";
import { tableSettingsActions } from "../../../store/TableSettingsSlice";
import { useGetNodesQuery } from "../../../store/wtpkApi";

const untoggleableFields = ["__check__"];

const getTogglableColumns = (cols) => {
  return cols
    .filter((column) => !untoggleableFields.includes(column.field))
    .map((column) => column.field);
};

const AllNodesTable = () => {
  const apiRef = useGridApiRef();
  const { data: loadedNodes, isFetching } = useGetNodesQuery(undefined, {
    pollingInterval: 60000
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.current_role);
  const userCanUseActionMenu =
    role.hasPermission("canDeregisterNodes") ||
    role.hasPermission("canEditNodeTags");

  const deregisterNodesModal = useSelector(
    (state) => state.nodeTags.tagDeregistrationModalVisible
  );
  const activateDeregistration = useSelector(
    (state) => state.nodeTags.prepareForDeregistration
  );

  const rowData = useSelector((state) => state.nodeTags.rowData);
  const [sortModel, setSortModel] = useState([
    { field: "registration_date", sort: "desc" }
  ]);
  const sortModelRef = useRef(sortModel);
  const [selectedRows, setSelectedRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [panelAnchorEl, setPanelAnchorEl] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(columnVisibilityDef);
  const dropdownMenuOpen = Boolean(anchorEl);
  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const handleOpenMenuDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenuDropdown = () => {
    setAnchorEl(null);
  };

  const toggleDeregisterModal = () => {
    dispatch(
      allNodesTagActions.setTagDeregistrationModalVisible(!deregisterNodesModal)
    );
  };

  const editTagforSelectedNode = async (e, row) => {
    dispatch(allNodesTagActions.setSelectedNodes(row));
    dispatch(allNodesTagActions.setSelectedNodesPreviewState([]));
    dispatch(allNodesTagActions.setTagEditorNodesTags([]));
    handleCloseMenuDropdown();
    navigate("/editNodeTags");
  };

  const deregisterSelectedNode = async (e, row) => {
    dispatch(allNodesTagActions.setSelectedNodes(row));
    handleCloseMenuDropdown();
    toggleDeregisterModal();
  };

  const createAnalysis = async (e, rows) => {
    dispatch(analysisTemplateActions.resetAnalyisForm());
    dispatch(analysisTemplateActions.setSelectedNodes(rows));
    handleCloseMenuDropdown();
    navigate("/newAnalysis");
  };

  const getSelectedNodes = async () => {
    const apiResponse = rowData.filter((item) =>
      selectedRows.includes(item.node_id)
    );
    if (apiResponse.length > 0) {
      editTagforSelectedNode("", apiResponse);
    }
  };

  const getSelectedNodesAndCreateAnalysis = async () => {
    const apiResponse = rowData.filter((item) =>
      selectedRows.includes(item.node_id)
    );
    if (apiResponse.length > 0) {
      createAnalysis("", apiResponse);
    }
  };

  const prepareForDeregistration = async () => {
    const apiResponse = rowData.filter((item) =>
      selectedRows.includes(item.node_id)
    );
    if (apiResponse.length > 0) {
      deregisterSelectedNode("", apiResponse);
    }
  };

  const sortTagKeysAlphabetically = (tags, sortOrder) => {
    const sortedKeys = Object.keys(tags).sort((a, b) =>
      a.localeCompare(b, undefined, { sensitivity: "base" })
    );
    const sortedTags = {};

    if (sortOrder === "desc") {
      sortedKeys.reverse();
    }

    sortedKeys.forEach((key) => {
      sortedTags[key] = tags[key];
    });
    return sortedTags;
  };

  const setTableData = useCallback(() => {
    const parsedData = ParseNodeData(loadedNodes ?? { nodes: [] });
    if (JSON.stringify(parsedData) !== JSON.stringify(rowData)) {
      dispatch(allNodesTagActions.setRowData(parsedData));
      return parsedData;
    }
    return rowData;
  }, [dispatch, loadedNodes, rowData]);

  useEffect(() => {
    setTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedNodes]);

  useEffect(() => {
    prepareForDeregistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateDeregistration]);

  // TODO: Move custom sort function to the column definition,
  //       then we'll no longer need to control the DataGrid's sortModel.
  //       This will allow us to load the sort model from the table state.
  useEffect(() => {
    sortModelRef.current = sortModel;
    if (
      sortModelRef.current?.length > 0 &&
      sortModelRef.current[0]?.field === "node_tags"
    ) {
      const sortOrder = sortModelRef.current[0].sort;
      const sortedRowsData = rowData.map((row) => ({
        ...row,
        node_tags: sortTagKeysAlphabetically(row.node_tags, sortOrder)
      }));
      dispatch(allNodesTagActions.setRowData(sortedRowsData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortModel]);

  const AllcolumnDef = [
    ...columnDef,
    {
      field: "actions",
      type: "actions",
      width: 20,
      hideable: false,
      align: "left",
      getActions: (params) => {
        const isDisabled = params.row.ping_status === "Inactive";
        const createAnalysisMenu = !isDisabled ? (
          <GridActionsCellItem
            icon={<AddCircleOutlineIcon />}
            label="Create analysis"
            showInMenu
            onClick={(e) => createAnalysis(e, [params.row])}
          />
        ) : (
          <GridActionsCellItem
            icon={
              <Tooltip title="Not available for inactive nodes" placement="top">
                <AddCircleOutlineIcon />
              </Tooltip>
            }
            label="Create analysis"
            showInMenu
            sx={{ opacity: "0.25", display: "none" }} // remove display none once the functionality is completed
          />
        );

        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            showInMenu
            onClick={() => navigate(`/nodes/${params.row.node_id}`)}
          />,
          createAnalysisMenu,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit tags"
            showInMenu
            onClick={(e) => editTagforSelectedNode(e, [params.row])}
          />,
          <GridActionsCellItem
            icon={<DeleteForeverIcon />}
            label="Deregister node"
            showInMenu
            onClick={(e) => deregisterSelectedNode(e, [params.row])}
          />
        ];
      }
    }
  ];

  const selectedActiveNodes = (rows) => {
    const selectedIDs = new Set(rows);
    return rowData
      .filter((row) => selectedIDs.has(row.node_id)) // filter the selected row data from rowData.
      .map((rowItem) => {
        return rowItem.ping_status === "Inactive"; // returns true if any of the selected node is inactive
      }) // output ex: [false, true, false, ..]
      .every(
        (val) => val === false // checks if each value is false in the above array
      ); // returns false if the above array has a true(inactive node) in selected nodes.
  };

  // Table state saving

  const defaultTableState = {
    columns: {
      columnVisibilityModel: columnVisibilityDef
    },
    pagination: {
      paginationModel: { pageSize: 10, page: 0 }
    }
  };

  const stateFromStore = useSelector(
    (state) => state.tableSettings.tableStates.AllNodes
  );

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      dispatch(
        tableSettingsActions.saveTableState({
          id: "AllNodes",
          tableState: JSON.stringify(currentState)
        })
      );
    }
  }, [apiRef, dispatch]);

  useLayoutEffect(() => {
    // handle refresh and navigating away/refreshing
    window.addEventListener("beforeunload", saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener("beforeunload", saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  return (
    <>
      <Box sx={{ height: "calc(100% - 88px)" }}>
        <DataGridPremium
          pagination
          apiRef={apiRef}
          rows={rowData}
          onColumnVisibilityModelChange={(newModel) => {
            if (
              Object.keys(newModel).length === 1 &&
              newModel.name_object === true
            ) {
              const updatedColumns = { ...visibleColumns };
              Object.keys(updatedColumns).forEach((key) => {
                updatedColumns[key] = true;
              });
              setVisibleColumns(updatedColumns);
            } else {
              setVisibleColumns({ ...visibleColumns, ...newModel });
            }
          }}
          columns={AllcolumnDef}
          loading={isFetching}
          slots={{
            loadingOverlay: LinearProgress,
            toolbar: dataTableHeaderToolbarGetter,
            pagination: PaginationButtons
          }}
          getRowHeight={() => "auto"}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          checkboxSelection={userCanUseActionMenu}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
          slotProps={{
            toolbar: {
              optionsCallback: userCanUseActionMenu
                ? handleOpenMenuDropdown
                : null,
              optionsEnabled: true,
              setPanelAnchorEl
            },
            columnsManagement: {
              getTogglableColumns
            },
            panel: {
              anchorEl: panelAnchorEl
            }
          }}
          getRowId={(row) => row.node_id}
          selectionModel={selectedRows}
          initialState={
            stateFromStore ? JSON.parse(stateFromStore) : defaultTableState
          }
        />
      </Box>
      <Menu
        id="all-nodes-dropdown-options"
        MenuListProps={{
          "aria-labelledby": "all-nodes-dropdown-button"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        anchorEl={anchorEl}
        open={dropdownMenuOpen}
        onClose={handleCloseMenuDropdown}
      >
        <Tooltip
          title={
            !selectedActiveNodes(selectedRows)
              ? "Select only online nodes to proceed."
              : ""
          }
          placement="left"
          arrow
        >
          <span>
            <MenuItem
              onClick={() => getSelectedNodesAndCreateAnalysis()}
              disabled={!selectedActiveNodes(selectedRows)}
            >
              <AddCircleOutlineIcon color="primary" /> Create analysis from
              selected nodes
            </MenuItem>
          </span>
        </Tooltip>
        {role.hasPermission("canEditNodeTags") && (
          <MenuItem
            onClick={() => getSelectedNodes()}
            disabled={selectedRows.length === 0}
          >
            <EditNoteIcon color="primary" /> Edit Tags of Selected Nodes
          </MenuItem>
        )}
        {role.hasPermission("canDeregisterNodes") && (
          <MenuItem
            onClick={() => prepareForDeregistration()}
            disabled={selectedRows.length === 0}
            sx={{ pointerEvents: "auto" }}
          >
            <DeleteIcon color="primary" /> Deregister Selected Nodes
          </MenuItem>
        )}
        <Divider />
        {generateTableManagementMenuItems(
          handleCloseMenuDropdown,
          apiRef,
          defaultTableState,
          dispatch,
          "AllNodes"
        )}
      </Menu>
      <TagDeregistrationModal />
    </>
  );
};

export default AllNodesTable;
