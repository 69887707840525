import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { analysisTemplateActions } from "../../Analysis/slices/LaunchAnalysisSlices";
import { allNodesTagActions } from "../../AllNodes/slices/AllNodesSlices";
import TagDeregistrationModal from "../../AllNodes/components/TagDeregistrationModal";

const NodeOptionsMenu = ({ nodeData, isLoading, anchorEl, open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector((state) => state.user.current_role);
  const deregisterNodesModal = useSelector(
    (state) => state.nodeTags.tagDeregistrationModalVisible
  );

  const handleNewAnalysis = () => {
    onClose();
    dispatch(analysisTemplateActions.resetAnalyisForm());
    dispatch(analysisTemplateActions.setSelectedNodes([nodeData]));
    navigate("/newAnalysis");
  };

  const handleEditTags = () => {
    onClose();
    dispatch(allNodesTagActions.setSelectedNodes([nodeData]));
    dispatch(allNodesTagActions.setSelectedNodesPreviewState([]));
    dispatch(allNodesTagActions.setTagEditorNodesTags([]));
    navigate("/editNodeTags");
  };

  const handleDeregisterNode = () => {
    onClose();
    dispatch(allNodesTagActions.setSelectedNodes([nodeData]));
    dispatch(
      allNodesTagActions.setTagDeregistrationModalVisible(!deregisterNodesModal)
    );
  };

  return (
    <>
      <Menu
        id="screen-header-dropdown-options"
        MenuListProps={{
          "aria-labelledby": "screen-header-dropdown-button"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <MenuItem
          onClick={handleNewAnalysis}
          disabled={isLoading || nodeData.ping_status === "Inactive"}
          display={role.hasPermission("canCreateAnalyses") ? "default" : "none"}
        >
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText>Configure new analysis</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleEditTags()}
          display={role.hasPermission("canEditNodeTags") ? "default" : "none"}
        >
          <ListItemIcon>
            <EditNoteIcon />
          </ListItemIcon>
          <ListItemText>Edit Tags</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleDeregisterNode()}
          display={
            role.hasPermission("canDeregisterNodes") ? "default" : "none"
          }
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Deregister node</ListItemText>
        </MenuItem>
      </Menu>
      <TagDeregistrationModal />
    </>
  );
};

export default NodeOptionsMenu;
