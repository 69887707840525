import { customAnalysisFormsActions } from "../../../components/AnalysisTypes/Slices/CustomAnalysisForms";
import { analysisTemplateActions } from "../slices/LaunchAnalysisSlices";

const noValidateIds = ["app_params", "app_working_dir"];

export const isItANumber = (value) => {
  return !!(
    (typeof value === "number" || typeof value === "string") &&
    !Number.isNaN(Number(value))
  );
};

export const isItNotLinuxName = (
  dispatch,
  value,
  id,
  action,
  message = "Must not contain '/', please change."
) => {
  if (!/[/]/g.test(value)) {
    dispatch(
      action({
        key: id,
        isValid: true,
        message: null
      })
    );
    return false;
  }
  dispatch(
    action({
      key: id,
      isValid: false,
      message
    })
  );
  return true;
};

export const isItEmpty = (
  dispatch,
  value,
  id,
  action,
  message = "Cannot be empty, please fill out."
) => {
  if (noValidateIds.includes(id)) return false;
  const isValueAnArray = Array.isArray(value);
  if ((isValueAnArray && value.length > 0) || (!isValueAnArray && value)) {
    dispatch(
      action({
        key: id,
        isValid: true,
        message: null
      })
    );
    return false;
  }

  dispatch(
    action({
      key: id,
      isValid: false,
      message
    })
  );
  return true;
};

export const isItNegative = (
  dispatch,
  value,
  id,
  action,
  message = "Must be positive, please change."
) => {
  const isValueANumber = isItANumber(value);
  if (!isValueANumber && isItEmpty(dispatch, value, id, action, message))
    return false;
  if (isValueANumber && Number(value) > -1) {
    dispatch(
      action({
        key: id,
        isValid: true,
        message: null
      })
    );
    return false;
  }
  dispatch(
    action({
      key: id,
      isValid: false,
      message
    })
  );
  return true;
};

export const isItInRange = (
  dispatch,
  value,
  id,
  action,
  min,
  max,
  message = "Invalid input."
) => {
  const isValueANumber = isItANumber(value);
  if (!isValueANumber && isItEmpty(dispatch, value, id, action, message))
    return false;
  if (isValueANumber && max >= Number(value) && Number(value) >= min) {
    dispatch(
      action({
        key: id,
        isValid: true,
        message: null
      })
    );
    return true;
  }
  dispatch(
    action({
      key: id,
      isValid: false,
      message
    })
  );
  return false;
};

export const setCardValidation = (dispatch, errors) => {
  Object.keys(errors).forEach((id) => {
    if (errors[id] > 0) {
      dispatch(
        analysisTemplateActions.setValidation({
          keyToUpdate: id,
          isValid: false,
          message: "This card is missing information"
        })
      );
    } else {
      dispatch(
        analysisTemplateActions.setValidation({
          keyToUpdate: id,
          isValid: true,
          message: null
        })
      );
    }
  });
};

export const validateCmdLineIsEqual = (dispatch, cmdValue, formValue) => {
  if (cmdValue !== formValue) {
    dispatch(
      analysisTemplateActions.setValidation({
        keyToUpdate: "vTuneCommandLine",
        isValid: false,
        message:
          "This command is reflecting any changes you make in the form. This is no longer identical to the original command passed by VTune."
      })
    );
    return false;
  }
  dispatch(
    analysisTemplateActions.setValidation({
      keyToUpdate: "vTuneCommandLine",
      isValid: true,
      message: null
    })
  );
  return true;
};

const checkForCard = {
  selectedTemplate: "how",
  selectedNodes: "where",
  analysisTarget: "what",
  iptAnalysisName: "how",
  iptAnalysisDuration: "how"
};

export const isCombinedCardsValid = (dispatch, inputForm) => {
  if (
    inputForm.analysisTarget.value !== null &&
    inputForm.selectedTemplate.value.type === "ebpf" &&
    inputForm.analysisTarget.value.id === "start_new_process"
  ) {
    dispatch(
      analysisTemplateActions.setValidation({
        key: "selectedTemplate",
        isValid: false,
        message: "Analysis Template is incompatible with Profile Target (What)"
      })
    );
    dispatch(
      analysisTemplateActions.setValidation({
        key: "analysisTarget",
        isValid: false,
        message: "Profile Target is incompatible with Analysis Template (How)"
      })
    );
    dispatch(
      analysisTemplateActions.setValidation({
        keyToUpdate: ["how", "what"],
        isValid: false,
        message: "There are some mistakes that need to be corrected"
      })
    );
    return false;
  }
  return true;
};
export const checkValidation = async (
  analysis,
  customFormInputs,
  cmdObj,
  dispatch
) => {
  const errors = { how: 0, where: 0, what: 0 };
  const dataIds = [
    "selectedTemplate",
    "selectedNodes",
    "analysisTarget",
    "iptAnalysisName"
  ];

  if (Object.keys(customFormInputs.value).length !== 0) {
    Object.keys(customFormInputs.value).forEach((id) => {
      if (
        customFormInputs.required[id] &&
        isItEmpty(
          dispatch,
          customFormInputs.value[id],
          id,
          customAnalysisFormsActions.setValidation
        )
      ) {
        errors.how += 1;
      }
    });
  }

  if (
    analysis.iptAnalysisDuration.value &&
    analysis.iptAnalysisDuration.isValid === false
  ) {
    errors.how += 1;
  }
  const targetParametersToValidate = Object.fromEntries(
    Object.entries(analysis.targetParameters)
      .filter(([, value]) => value.isEnabled)
      .map(([key, value]) => {
        return [key, value.value];
      })
  );
  Object.keys(targetParametersToValidate).forEach((id) => {
    if (
      isItEmpty(
        dispatch,
        targetParametersToValidate[id],
        id,
        analysisTemplateActions.setTargetParameterValidation
      )
    ) {
      errors.what += 1;
    } else if (
      id === "iptTargetProcessType" &&
      !analysis.targetParameters.checkProcessPID?.value
    ) {
      if (
        isItNotLinuxName(
          dispatch,
          targetParametersToValidate[id],
          id,
          analysisTemplateActions.setTargetParameterValidation
        )
      )
        errors.what += 1;
    } else if (
      id === "iptTargetProcessType" &&
      analysis.targetParameters.checkProcessPID?.value
    ) {
      if (
        isItNegative(
          dispatch,
          targetParametersToValidate[id],
          id,
          analysisTemplateActions.setTargetParameterValidation
        )
      )
        errors.what += 1;
    }
  });
  dataIds.forEach((id) => {
    if (
      isItEmpty(
        dispatch,
        analysis[id].value,
        id,
        analysisTemplateActions.setValidation
      )
    )
      errors[checkForCard[id]] += 1;
    if (
      isItANumber(analysis[id].value) &&
      isItNegative(
        dispatch,
        analysis[id].value,
        id,
        analysisTemplateActions.setValidation
      )
    )
      errors[checkForCard[id]] += 1;
  });

  const noEmptyInputs = errors.how + errors.what + errors.where === 0;
  setCardValidation(dispatch, errors);
  return noEmptyInputs && isCombinedCardsValid(dispatch, analysis);
};
