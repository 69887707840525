import React from "react";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import { useSelector } from "react-redux";
import WTPKSwitch from "./ReusableFormComponents/WTPKSwitch";
import WTPKSelect from "./ReusableFormComponents/WTPKSelect";
import WTPKInput from "./ReusableFormComponents/WTPKInput";
import { isItInRange } from "../../screens/Analysis/LaunchAnalysis/LaunchAnalysisValidation";

const MicroarchitectureExploration = () => {
  const formInputs = useSelector((state) => state.customAnalysisForms.value);
  const validationSamplingInterval = (e, dispatch, action) => {
    isItInRange(
      dispatch,
      e.target.value,
      e.target.id,
      action,
      1,
      1000,
      "Invalid value, please pick a value between 1 and 1000."
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <WTPKInput
          id="sampling_interval"
          label="CPU sampling interval, ms"
          initialValue="1"
          min="1"
          max="1000"
          inputType="number"
          validation={validationSamplingInterval}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel component="legend">
            Extend granularity for the top-level metrics:
          </FormLabel>
          <FormGroup>
            <WTPKSwitch
              id="collect_frontend_bound"
              label="Front-End Bound"
              defaultChecked={formInputs.collect_frontend_bound || true}
            />
            <WTPKSwitch
              id="collect_bad_speculation"
              label="Bad Speculation"
              defaultChecked={formInputs.collect_bad_speculation || true}
            />
            <WTPKSwitch
              id="collect_memory_bound"
              label="Memory Bound"
              defaultChecked={formInputs.collect_memory_bound || true}
            />
            <WTPKSwitch
              id="collect_core_bound"
              label="Core Bound"
              defaultChecked={formInputs.collect_core_bound || true}
            />
            <WTPKSwitch
              id="collect_retiring"
              label="Retiring"
              defaultChecked={formInputs.collect_retiring || true}
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <WTPKSelect
          id="pmu_collection_mode"
          label="Collection Mode"
          options={[
            { optionText: "Detailed", optionValue: "detailed" },
            { optionText: "Summary", optionValue: "summary" }
          ]}
        />
      </Grid>

      {/* eslint-disable-next-line dot-notation */}
      {formInputs["pmu_collection_mode"] !== "summary" && (
        <Grid item xs={12}>
          <FormGroup>
            <WTPKSwitch
              id="collect_memory_bandwidth"
              label="Analyze memory bandwidth"
              defaultChecked={formInputs.collect_memory_bandwidth || false}
            />

            <WTPKSwitch
              id="dram_bandwidth_limits"
              label="Evaluate max DRAM bandwidth"
              defaultChecked={formInputs.dram_bandwidth_limits || true}
            />
          </FormGroup>
        </Grid>
      )}
    </Grid>
  );
};

export default MicroarchitectureExploration;
