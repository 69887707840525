import React from "react";
import { Box, Typography, Link } from "@mui/material";

const DCCText = () => {
  return (
    <Box mx={4}>
      <Typography paragraph>
        The Software collects information (<strong>“Information”</strong>) that
        is transmitted to Intel as part of the Performance Profiler service that
        you have signed up for separately. Consent to collection of the
        Information is required before you can download the Software. The
        Information includes, but is not limited to, your username, the file
        locations of the application and related working directory directories,
        operating system version, hostname, hypervisor, and detailed host system
        related data to include, processor type(s), number of packages and
        cores, reference frequency and power management unit capabilities.
      </Typography>
      <Typography paragraph>
        The collected information will be retained for 10 years to support
        providing you with the Performance Profiler service. The information
        will not be shared outside of Intel or its wholly-owned subsidiaries,
        however information may be anonymized and aggregated with information
        from other users of the software for statistical and product improvement
        purposes. This anonymized and aggregated information may be used
        publicly.
      </Typography>
      <Typography paragraph>
        For more information on Intel’s privacy policy, please click{" "}
        <Link
          href="https://www.intel.com/content/www/us/en/privacy/intel-privacy-notice.html"
          target="_blank"
          rel="noreferrer"
          component="a"
          underline="none"
          color="primary"
        >
          here
        </Link>
        .
      </Typography>
      <Typography paragraph>
        If you do not wish to consent to the collection of the Information but
        you would still like to download and install similar software, then you
        may be interested in using Intel® VTune™ Profiler available{" "}
        <Link
          href="https://www.intel.com/content/www/us/en/developer/tools/oneapi/vtune-profiler.html"
          target="_blank"
          rel="noreferrer"
          component="a"
          underline="none"
          color="primary"
        >
          here
        </Link>
        .
      </Typography>
    </Box>
  );
};

export default DCCText;
