import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography, Button, Alert, Collapse } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SpokeIcon from "@mui/icons-material/Spoke";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";
import Dashboard from "./Dashboard";
import AlertGradientBackground from "../../images/alert-gradient-background.png";
import { userActions } from "../../store/UserSlice";
import { analysisTemplateActions } from "../Analysis/slices/LaunchAnalysisSlices";

const Home = () => {
  const dispatch = useDispatch();
  const showTourBanner = useSelector((state) => state.user.showTourBanner);

  return (
    <>
      <Collapse in={showTourBanner}>
        <Alert
          onClose={() => {
            dispatch(userActions.dismissTourBanner());
          }}
          color="null"
          icon={false}
          sx={{
            backgroundImage: `url(${AlertGradientBackground})`,
            backgroundSize: "cover",
            borderRadius: "0px",
            minHeight: "52px",
            color: "white",
            alignItems: "center",
            "& .MuiAlert-message": {
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              gap: "24px"
            },
            "& .MuiAlert-action": {
              alignItems: "center",
              pt: "0"
            }
          }}
        >
          <Typography>
            Take a quick tour to get familiar with our platform and learn more
            about the features.
          </Typography>
          <Button
            variant="outlined"
            sx={{
              color: "white",
              borderColor: "white",
              minHeight: "22px",
              py: "4px",
              fontSize: "13px",
              lineHeight: "22px"
            }}
            onClick={() => dispatch(userActions.startTour())}
            id="btnRunTour"
          >
            Start the Quick Tour
          </Button>
        </Alert>
      </Collapse>
      <Stack
        spacing={4}
        sx={{
          px: "40px",
          py: "24px"
        }}
      >
        <ScreenHeader title="Welcome to Performance Profiler Early Access" />
        <Stack direction="row" spacing="32px" height="64px">
          <Button
            id="btnCreateAnalysis"
            variant="contained"
            startIcon={<PlayArrowIcon />}
            color="darkerSecondary"
            href="/newAnalysis"
            onClick={() => {
              dispatch(analysisTemplateActions.resetAnalyisForm());
            }}
            sx={{
              px: "32px",
              fontWeight: "medium",
              fontSize: "15px",
              textColor: "white"
            }}
          >
            Configure new analysis
          </Button>
          <Button
            id="btnRegisterNodes"
            variant="contained"
            startIcon={<SpokeIcon />}
            href="/registerNodes"
            color="darkerPrimary"
            sx={{ px: "32px", fontWeight: "medium", fontSize: "15px" }}
          >
            Register a new node
          </Button>
        </Stack>
        <Dashboard />
      </Stack>
    </>
  );
};
export default Home;
