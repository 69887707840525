import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip } from "@mui/material";

const STATUS_CONFIG = {
  COMPLETED: {
    ICON: CheckCircleIcon,
    COLOR: "success",
    NAME: "Complete"
  },
  RUNNING: { ICON: InfoIcon, COLOR: "info", NAME: "Running" },
  PENDING: { ICON: InfoIcon, COLOR: "info", NAME: "Pending" },
  "COMPLETED WITH FAILURES": {
    COLOR: "warning",
    NAME: "Completed with Failures"
  },
  FAILED: { ICON: WarningRoundedIcon, COLOR: "error", NAME: "Failed" },
  Online: { ICON: CheckCircleIcon, COLOR: "success", NAME: "Online" },
  Inactive: { ICON: ErrorIcon, COLOR: "black", NAME: "Inactive" },
  Enabled: {
    ICON: CheckCircleIcon,
    COLOR: "success",
    NAME: "Enabled"
  },
  "Not enabled": {
    ICON: WarningRoundedIcon,
    COLOR: "error",
    NAME: "Not enabled"
  }
};

export const generateIconForStatus = (
  status,
  showTooltip = true,
  size = "medium"
) => {
  let result;
  switch (status) {
    case "RUNNING":
    case "PENDING":
      result = <InfoIcon color="info" fontSize={size} />;
      break;
    case "FAILED":
    case "Not enabled":
      result = <WarningRoundedIcon color="error" fontSize={size} />;
      break;
    case "COMPLETED":
    case "Enabled":
      result = <CheckCircleIcon color="success" fontSize={size} />;
      break;
    case "COMPLETED WITH FAILURES":
      result = <ErrorIcon color="warning" fontSize={size} />;
      break;
    case "Online":
      result = <CheckCircleIcon color="success" fontSize={size} />;
      break;
    case "Inactive":
      result = (
        <ErrorIcon color="black" sx={{ opacity: 0.56 }} fontSize={size} />
      );
      break;
    default:
      return null;
  }
  return (
    <Tooltip
      title={showTooltip ? status : ""}
      slotProps={{
        popper: {
          modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
        }
      }}
    >
      {result}
    </Tooltip>
  );
};

export const generateStatusIconWithLabel = (status) => {
  const statusObj = STATUS_CONFIG[status];
  const { COLOR, NAME } = statusObj;
  return (
    <Box
      variant="subtitle2"
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: "medium",
        color: `${COLOR}.main`,
        gap: "5px"
      }}
    >
      {generateIconForStatus(status, false)}
      {` ${NAME}`}
    </Box>
  );
};

export default generateIconForStatus;
