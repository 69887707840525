import React from "react";
import { MenuItem, Select, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useGridApiContext } from "@mui/x-data-grid-premium";

const DataGridSelectCell = (props) => {
  const {
    id,
    field,
    value,
    isEditable,
    cellMode,
    colDef: { valueOptions }
  } = props;

  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value
    });
    apiRef.current.stopCellEditMode({ id, field });
  };

  const handleClose = async () => {
    apiRef.current.stopCellEditMode({ id, field });
  };

  return cellMode === "edit" ? (
    <Select
      value={value}
      onChange={handleChange}
      onClose={handleClose}
      sx={{
        width: "100%",
        borderRadius: "0",
        background: "none",
        fontSize: "14px",
        height: 1
      }}
      autoFocus
      defaultOpen
    >
      {valueOptions.map((option) => (
        <MenuItem value={option} sx={{ fontSize: "14px" }}>
          {option}
        </MenuItem>
      ))}
    </Select>
  ) : (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Typography fontSize="14px">{value}</Typography>
      {isEditable && <ArrowDropDownIcon />}
    </Stack>
  );
};

export default DataGridSelectCell;
