import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from "@mui/material";
import { analysisTemplateActions } from "../../slices/LaunchAnalysisSlices";
import {
  availableFormInputs,
  availableFormInputToolTipContent
} from "../utils/AnalysisFormCardUtils";
import InfoTip from "../../../../components/InfoTip/InfoTip";
import {
  isItANumber,
  isItEmpty,
  isItNegative,
  validateCmdLineIsEqual
} from "../LaunchAnalysisValidation";
import { ParseKeyToCmdLine } from "../utils/AnalysisVtuneParser";

const AnalysisTargetParametersForm = () => {
  const vTuneCommandLine = useSelector(
    (state) => state.analysis.vTuneCommandLine
  );
  const cmdObj = useSelector((state) => state.analysis.cmdObj);
  const analysisTarget = useSelector((state) => state.analysis.analysisTarget);
  const targetParameters = useSelector(
    (state) => state.analysis.targetParameters
  );

  const dispatch = useDispatch();

  const targetProcessEnabled = analysisTarget.value
    ? analysisTarget.value.defaults.includes("iptTargetProcessType")
    : false;

  const onChangeFormInputHandler = (event) => {
    isItEmpty(
      dispatch,
      event.target.value,
      event.target.id,
      analysisTemplateActions.setTargetParameterValidation
    );
    if (
      isItANumber(event.target.value) &&
      event.target.id === "iptTargetProcessType" &&
      targetParameters.checkProcessPID.value
    )
      isItNegative(
        dispatch,
        event.target.value,
        event.target.id,
        analysisTemplateActions.setTargetParameterValidation
      );
    if (vTuneCommandLine?.value !== null && cmdObj !== null) {
      switch (event.target.id) {
        case "iptTargetProcessType":
          if (targetParameters.checkProcessPID.value) {
            if (
              ParseKeyToCmdLine(
                vTuneCommandLine.value,
                "target_pid",
                event.target.value,
                dispatch
              ) !== -1
            )
              validateCmdLineIsEqual(
                dispatch,
                cmdObj?.target?.target_pid,
                event.target.value
              );
          }
          if (
            !targetParameters.checkProcessPID.value &&
            ParseKeyToCmdLine(
              vTuneCommandLine.value,
              "target_process",
              event.target.value,
              dispatch
            ) !== -1
          )
            validateCmdLineIsEqual(
              dispatch,
              cmdObj?.target?.target_process,
              event.target.value
            );
          break;
        default:
          if (
            ParseKeyToCmdLine(
              vTuneCommandLine.value,
              event.target.id,
              event.target.value,
              dispatch
            ) !== -1
          )
            validateCmdLineIsEqual(
              dispatch,
              cmdObj?.target[event.target.id],
              event.target.value
            );
          break;
      }
    }
    dispatch(
      analysisTemplateActions.setTargetParameterValue({
        id: event.target.id,
        valueReceived: event.target.value
      })
    );
  };

  const onChangeSwitchProcess = (_event, value) => {
    dispatch(
      analysisTemplateActions.setTargetParameterValue({
        id: "checkProcessPID",
        valueReceived: value === "ID"
      })
    );
  };

  return (
    <>
      {targetProcessEnabled && (
        <Box
          id="boxWhatProcessNameID"
          sx={{
            width: "100%",
            display: "flex",
            maxHeight: "40px"
          }}
        >
          <InfoTip
            content={
              <>
                <Typography variant="h6" color="inherit">
                  Process Name
                </Typography>
                Works best when there is only one process with that name running
                on the node(s).
                <Typography variant="h6" color="inherit">
                  Process ID
                </Typography>
                Since an application&apos;s PID varies from node to node, this
                option should be used on one node at a time.
              </>
            }
            placement="top"
            size="sm"
          />
          <ToggleButtonGroup
            size="small"
            color="primary"
            sx={{ ml: 2, mr: 2 }}
            value={targetParameters.checkProcessPID.value ? "ID" : "Name"}
            exclusive
            onChange={onChangeSwitchProcess}
            aria-label="Platform"
          >
            <ToggleButton value="Name">Name</ToggleButton>
            <ToggleButton value="ID">ID</ToggleButton>
          </ToggleButtonGroup>

          <TextField
            required
            sx={{ width: "100%" }}
            error={
              targetParameters.iptTargetProcessType.isValid !== null &&
              !targetParameters.iptTargetProcessType.isValid
            }
            helperText={
              targetParameters.iptTargetProcessType.isValid !== null &&
              !targetParameters.iptTargetProcessType.isValid
                ? targetParameters.iptTargetProcessType.statusMessage ??
                  "Invalid Input"
                : ""
            }
            size="small"
            name="TargetProcessType"
            id="iptTargetProcessType"
            onChange={onChangeFormInputHandler}
            value={targetParameters.iptTargetProcessType.value}
            type={targetParameters.checkProcessPID.value ? "Number" : "text"}
            label={
              targetParameters.checkProcessPID.value
                ? "Process ID"
                : "Process Name"
            }
            placeholder={
              targetParameters.checkProcessPID.value
                ? "Process ID"
                : "Process Name"
            }
          />
        </Box>
      )}
      {analysisTarget.value !== null &&
        analysisTarget.value.defaults.length > 1 && (
          <Box id="boxWhatInputs" width="70%">
            <Grid container spacing={2}>
              {analysisTarget.value.defaults
                .filter(
                  (id) =>
                    !["iptTargetProcessType", "checkProcessPID"].includes(id)
                )
                .map((id) => {
                  return (
                    <Tooltip
                      title={availableFormInputToolTipContent[id]}
                      arrow
                      key={id}
                      placement="top"
                    >
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          size="small"
                          required={
                            !["app_params", "app_working_dir"].includes(id)
                          }
                          name={id}
                          type="text"
                          id={id}
                          error={
                            targetParameters[id].isValid !== null &&
                            !targetParameters[id].isValid
                          }
                          helperText={
                            targetParameters[id].isValid !== null &&
                            !targetParameters[id].isValid
                              ? targetParameters[id].statusMessage ??
                                "Invalid Input"
                              : ""
                          }
                          label={
                            !availableFormInputs[id]
                              ? id
                                  .substring(3)
                                  .split(/(?=[A-Z])/)
                                  .join(" ")
                              : availableFormInputs[id]
                          }
                          value={
                            targetParameters !== null
                              ? targetParameters[id].value
                              : ""
                          }
                          onChange={onChangeFormInputHandler}
                        />
                      </Grid>
                    </Tooltip>
                  );
                })}
            </Grid>
          </Box>
        )}
    </>
  );
};

export default AnalysisTargetParametersForm;
