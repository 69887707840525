import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import AuthContext from "../../../../contexts/AuthContext";
import { ApiGET } from "../../../../appServices/axiosService";

const HtmlResult = ({ result }) => {
  const apiBaseUrl = `${window.runtime.REACT_APP_API_URL}`;
  const auth = useContext(AuthContext);
  const [resultUrl, setResultUrl] = useState();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchResultUrl() {
      const response = await ApiGET(
        `${apiBaseUrl}/nodeExecution/${result.node_execution_id}/result/result.html/link`,
        auth.user
      );
      if (response instanceof Error) {
        enqueueSnackbar(
          `Could not fetch the log details: ${response.message}`,
          { variant: "error" }
        );
      }
      if (!(response instanceof Error)) {
        setResultUrl(response?.result_url);
      }
    }

    fetchResultUrl();
  }, [auth, apiBaseUrl, result.node_execution_id, dispatch, enqueueSnackbar]);

  return (
    <object
      type="text/html"
      data={resultUrl}
      aria-labelledby="html-visual-object"
      style={{ width: "100%", minHeight: "500px" }}
      id="obj-html-ebpf-result"
      data-testid="ebpfHTMLresults"
    />
  );
};

export default HtmlResult;
