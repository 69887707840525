/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from "react";
import { Form, json, useSubmit, useLocation, Navigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CardActions,
  CardHeader,
  Button,
  Grid
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { ApiPut } from "../../appServices/axiosService";
import { termsAndConditionsActions } from "./TermsAndConditionsSlice";
import DCCText from "./DCCText";
import { logout } from "../../utils/broadcaster";
import { useGetCustomerInfoQuery, wtpkAPI } from "../../store/wtpkApi";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";

const apiBaseUrl = window.runtime.REACT_APP_API_URL;

// eslint-disable-next-line consistent-return
export const action = async (request, dispatch, auth) => {
  const formData = await request.formData();
  const acceptDataCollection = formData.get("acceptDataCollection");
  const acceptTermsAndConditions = formData.get("acceptTermsAndConditions");
  if (acceptDataCollection === "Accept") {
    const input = {
      preferences: {
        terms_accepted:
          acceptTermsAndConditions === "" ? false : acceptTermsAndConditions,
        data_collection_accepted: true
      }
    };
    const response = await ApiPut(
      `${apiBaseUrl}/user/${auth.user.profile["custom:userId"]}`,
      auth.user,
      input,
      true
    );
    if (response && response.name !== "AxiosError") {
      dispatch(
        termsAndConditionsActions.setDataCollection(
          response.user.preferences.data_collection_accepted
        )
      );
      dispatch(
        wtpkAPI.endpoints.getCustomerInfo.initiate(
          auth?.user?.profile["custom:userId"],
          { subscribe: false, forceRefetch: true }
        )
      );
    } else
      throw json(
        {
          didNotAccept: true,
          message:
            "There was an error sending your Data Collection Consent. Please log out and sign back in."
        },
        {
          status: 403,
          statusText: "You must re-login, to accept the Data Collection Consent"
        }
      );
    return response;
  }
  try {
    logout(auth, dispatch);
  } catch (error) {
    throw json(
      {
        didNotAccept: true,
        message:
          "There was an error determining if your Data Collection Consent is valid. Please log out and sign back in."
      },
      {
        status: 403,
        statusText: "You must re-login, to accept the Data Collection Consent"
      }
    );
  }
};

const DataCollectionConsent = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const auth = useAuth();
  const formRef = useRef();
  const submit = useSubmit();
  const dispatch = useDispatch();
  const location = useLocation();

  const { data: customerInfo } = useGetCustomerInfoQuery(
    auth?.user?.profile["custom:userId"]
  );
  const dataConsented =
    customerInfo?.user?.preferences?.data_collection_accepted;

  const hasAcceptedTermsAndConditions = useSelector(
    (state) => state.termsAndConditions.hasAcceptedTermsAndConditions
  );

  const handleChange = (e, value) => {
    setIsAccepted(value);
  };

  if (
    dataConsented &&
    location.pathname !== "/information/data-collection-consent"
  ) {
    return <Navigate to="/home" replace />;
  }
  return (
    <>
      {dataConsented && (
        <ScreenHeader
          title="Data Collection Consent"
          crumbDefs={[{ label: "Information" }]}
        />
      )}
      <Box
        sx={{
          background: "#F5F5F5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: dataConsented ? "100%" : "100vh"
        }}
      >
        <Card
          sx={{
            margin: dataConsented ? "auto" : "52px",
            flexDirection: "column",
            border: "none",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
            minWidth: "496px", // min supported window size of 600, minus two 52px margins = 496px
            maxWidth: "800px",
            maxHeight: "600px"
          }}
        >
          <CardHeader
            titleTypographyProps={{
              padding: 2,
              textAlign: "center",
              textTransform: "none !important",
              variant: "h5",
              color: "#000000 !important",
              fontSize: "1.5rem !important"
            }}
            title="Data Collection Consent for Performance Profiler Agent (“Software”)"
          />
          <CardContent
            sx={{
              maxHeight: dataConsented ? "536px" : "400px",
              overflow: "auto",
              // scrollbox shadows:
              background:
                "linear-gradient(white 30%,rgba(255, 255, 255, 0)),linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,radial-gradient(farthest-side at 50% 0,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0)),radial-gradient(farthest-side at 50% 100%,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0)) 0 100%",
              backgroundRepeat: "no-repeat",
              backgroundColor: "white",
              backgroundSize: "100% 40px, 100% 40px, 100% 5px, 100% 5px",
              backgroundAttachment: "local, local, scroll, scroll"
            }}
          >
            <DCCText />
          </CardContent>
          {!dataConsented && (
            <CardActions
              data-testid="dcc-actions"
              sx={{ justifyContent: "space-around" }}
            >
              <Form
                method="post"
                ref={formRef}
                style={{ width: "100%" }}
                replace
              >
                <FormGroup
                  sx={{
                    flexDirection: "row",
                    gap: 2
                  }}
                >
                  <input
                    type="hidden"
                    readOnly
                    value={hasAcceptedTermsAndConditions}
                    name="acceptTermsAndConditions"
                  />
                  <Grid container flexDirection="row">
                    <Grid item xs={8}>
                      <FormControlLabel
                        sx={{
                          maxWidth: "784px",
                          minHeight: "42px",
                          alignItems: "start"
                        }}
                        slotProps={{ typography: { sx: { mt: "5px" } } }}
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="acceptDataCollection"
                            id="acceptDataCollection"
                            value="Accept"
                            sx={{ padding: "5px 12px" }}
                          />
                        }
                        label="I consent to the collection of the Information and would like
                to download the software."
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="start"
                    >
                      <Button
                        variant="outlined"
                        name="Cancel"
                        onClick={() => {
                          logout(auth, dispatch);
                        }}
                        sx={{
                          marginLeft: "10px",
                          minWidth: "65px",
                          height: "33px",
                          padding: "16px 6px",
                          borderWidth: "1px"
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        disabled={!isAccepted}
                        onClick={() => {
                          submit(formRef.current);
                        }}
                        sx={{
                          marginLeft: "10px",
                          minWidth: "83px",
                          height: "33px",
                          padding: "16px 6px"
                        }}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Form>
            </CardActions>
          )}
        </Card>
      </Box>
    </>
  );
};

export default DataCollectionConsent;
