import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import { generateColumnVisibilityDef } from "../../../appServices/resuableMethods";
import generateIconForStatus from "../../../utils/statusIconGeneration";

const generateIconOrProgressBarForStatus = (params) => {
  switch (params.value) {
    case "RUNNING":
    case "PENDING":
      return (
        <LinearProgressWithLabel
          currentStepCount={params.row.currentStepCount}
          currentStepName={params.row.currentStepName}
          totalStepCount={params.row.totalStepCount}
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
          }}
          size={30}
          thickness={4}
          value={100}
        />
      );
    default:
      return generateIconForStatus(params.value);
  }
};

export const columnDef = [
  {
    field: "name_object",
    headerName: "Node",
    width: 300,
    editable: false,
    hideable: false,
    valueGetter: (_, row) => {
      return `${row.name_object?.primary} ${row.name_object?.secondary1} ${
        row.name_object?.secondary2
      } ${row.nodeStatus === "Inactive" ? "Deregistered" : ""}`;
    },

    renderCell: (params) => (
      <Box sx={{ overflow: "hidden" }}>
        {params?.row?.name_object?.secondary1 !== "" && (
          <Tooltip
            title={
              <Box>
                {params?.row?.name_object?.secondary1 !== "" ? (
                  <strong>Additional Identifiers:</strong>
                ) : (
                  ""
                )}{" "}
                <br />
                {params.row.name_object.secondary1} <br />{" "}
                {params.row.name_object.secondary2}
              </Box>
            }
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2">
                {params.row.name_object.primary}
              </Typography>

              {params.row.nodeStatus === "Inactive" && (
                <Chip
                  label="Deregistered"
                  color="error"
                  size="small"
                  sx={{ marginTop: 1 }}
                />
              )}
            </Stack>
          </Tooltip>
        )}
        {params?.row?.name_object?.secondary1 === "" && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">
              {params.row.name_object.primary}
            </Typography>
            {params.row.nodeStatus === "Inactive" && (
              <Chip label="Deregistered" color="error" size="small" />
            )}
          </Stack>
        )}
      </Box>
    ),
    sortComparator: (v1, v2) => {
      const keys1 = Object.keys(v1).map((key) => key.toLowerCase());
      const keys2 = Object.keys(v2).map((key) => key.toLowerCase());

      if (keys1.length === 0) return 1;
      if (keys2.length === 0) return -1;

      return keys1.join("").localeCompare(keys2.join(""));
    }
  },
  {
    field: "node_execution_id",
    headerName: "Node Result ID",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.node_execution_id ?? "Unknown";
    }
  },
  {
    field: "node_id",
    headerName: "Node ID",
    editable: false,
    minWidth: 200,
    valueGetter: (_, row) => {
      return row.node_id ?? "Unknown";
    }
  },
  {
    field: "execution_id",
    headerName: "Associated Analysis ID",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.execution_id ?? "Unknown";
    }
  },
  {
    field: "template_id",
    headerName: "Analysis Type",
    editable: false,
    minWidth: 270,
    valueGetter: (_, row) =>
      row.template_id === "19275d0a-a413-4440-ba25-88f1ceaebf33"
        ? "Custom VTune Template"
        : row.template_id ?? "Unknown"
  },
  {
    field: "definition.load_drivers",
    headerName: "Sampling Drivers",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      let value = row.definition?.load_drivers;
      if (value == null) {
        value = "Unknown";
      }
      return value;
    }
  },
  {
    field: "lifecycle.duration",
    headerName: "Duration",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.lifecycle?.duration ?? "Unknown";
    }
  },
  {
    field: "target.app",
    headerName: "App Path",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.target?.app ?? "NA";
    }
  },
  {
    field: "start_timestamp",
    headerName: "Date Started",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.start_timestamp ?? "Unknown";
    }
  },
  {
    field: "end_timestamp",
    headerName: "Date Completed",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.end_timestamp ?? "N/A";
    }
  },
  {
    field: "started_by",
    headerName: "Started By",
    editable: false,
    minWidth: 200,
    valueGetter: (_, row) => {
      let userID = row.started_by;
      if (userID != null && userID.startsWith("IntelSSO_")) {
        userID = userID.substring(9);
      }
      return userID ?? "Unknown";
    }
  },
  {
    field: "node.computer_name",
    headerName: "Hostname",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.node?.computer_name ?? "Unknown";
    }
  },
  {
    field: "node.ip_address",
    headerName: "IP Address",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.node?.ip_address ?? "Unknown";
    }
  },
  {
    field: "node.inventory.cpu_brand",
    headerName: "CPU",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.node?.inventory?.cpu_brand ?? "Unknown";
    }
  },
  {
    field: "node.inventory.kernel_release",
    headerName: "Kernel",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.node?.inventory?.kernel_release ?? "Unknown";
    }
  },
  {
    field: "node.platform_type",
    headerName: "OS Type",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.node?.platform_type ?? "Unknown";
    }
  },
  {
    field: "node.inventory.distro_name",
    headerName: "OS Distribution",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.node?.inventory?.distro_name ?? "Unknown";
    }
  },
  {
    field: "node.inventory.distro_version",
    headerName: "OS Version",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.node?.inventory?.distro_version ?? "Unknown";
    }
  },
  {
    field: "tags",
    headerName: "Tags",
    flex: 1,
    minWidth: 140,
    valueGetter: (_, row) => {
      let { tags } = row;
      if (typeof tags === "object" && tags !== null) {
        tags = Object.entries(tags).flat();
      } else {
        tags = ["N/A"];
      }
      tags = tags.join(" ");
      return tags;
    },
    editable: false,
    wrapText: true,
    sortComparator: (v1, v2) => {
      const keys1 = Object.keys(v1).map((key) => key.toLowerCase());
      const keys2 = Object.keys(v2).map((key) => key.toLowerCase());

      if (keys1.length === 0) return 1;
      if (keys2.length === 0) return -1;

      return keys1.join("").localeCompare(keys2.join(""));
    },
    renderCell: (params) => {
      const { tags } = params.row;
      if (typeof tags === "object" && tags !== null) {
        const items = Object.entries(tags).map(([key]) => {
          return (
            <Chip
              size="small"
              sx={{
                marginRight: (theme) => theme.spacing(1),
                marginTop: (theme) => theme.spacing(1)
              }}
              label={<Box>{key}</Box>}
              color={
                params.row.nodeStatus === "Inactive" ? "default" : "secondary"
              }
              key={`${params.row.node_id}-${key}`}
            />
          );
        });

        return (
          <Box
            sx={{
              overflow: "hidden",
              marginY: (theme) => theme.spacing(1)
            }}
          >
            {items}
          </Box>
        );
      }
      return <Chip color="primary" size="small" />;
    }
  },
  {
    field: "node_registration_date",
    headerName: "Registration Date",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.node_registration_date ?? "Unknown";
    }
  },
  {
    field: "node.ping_status",
    headerName: "Node Status",
    width: 120,
    editable: false,
    renderCell: (params) =>
      params.row.node?.ping_status !== undefined ? (
        <Chip
          size="small"
          label={params.row.node?.ping_status}
          color={
            params.row.node?.ping_status === "Inactive" ? "error" : "success"
          }
        />
      ) : null
  },
  {
    field: "status",
    headerName: "Status",
    width: 310,
    editable: false,
    wrapText: true,
    align: "center",
    headerAlign: "center",
    sortComparator: (v1, v2) => {
      const keys1 = Object.keys(v1).map((key) => key.toLowerCase());
      const keys2 = Object.keys(v2).map((key) => key.toLowerCase());

      if (keys1.length === 0) return 1;
      if (keys2.length === 0) return -1;

      return keys1.join("").localeCompare(keys2.join(""));
    },
    renderCell: (params) => {
      return generateIconOrProgressBarForStatus(params);
    }
  }
];

const fieldsVisibleByDefault = ["name_object", "tags", "status", "actions"];

export const columnVisibilityDef = generateColumnVisibilityDef(
  columnDef,
  fieldsVisibleByDefault
);
