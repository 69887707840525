/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  nodeExecutionId: null,
  nodeName: null,
  templateName: null,
  timeStamp: null,
  templateID: "",
  analysisExecutionID: null,
  analysisExecutionName: null,
  templateDetails: [],
  analysisStatus: "",
  executionStatus: "",
  analysisTimestamp: "",
  analysisResultsTableData: [],
  deletedAnalysis: [],
  isAPSReportAvailable: false,
  aps_report_id: null,
  aps_report_status: null
};

export const analysisStatusSlice = createSlice({
  name: "analysisStatus",
  initialState,
  reducers: {
    resetToInitialState: () => initialState,
    setSliceKeys: (state, action) => {
      if (
        typeof action.payload === "object" &&
        Object.entries(action.payload).length
      ) {
        Object.entries(action.payload).forEach(([id, value]) => {
          state[id] = value;
        });
      }
    }
  }
});

export const analysisStatusActions = analysisStatusSlice.actions;

export default analysisStatusSlice;
