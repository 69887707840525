import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { generateColumnVisibilityDef } from "../../../../appServices/resuableMethods";
import generateIconForStatus from "../../../../utils/statusIconGeneration";

export const columnDef = [
  {
    field: "name_object",
    headerName: "Node",
    minWidth: 150,
    flex: 0.75,
    editable: false,
    hideable: false,
    getApplyQuickFilterFn: (filterValue) => {
      return (_, row) => {
        const nodeDetails = `${row.name_object?.primary} ${
          row.name_object?.secondary1
        } ${row.name_object?.secondary2} ${
          row.nodeStatus === "Inactive" ? "Deregistered" : ""
        }`;
        return nodeDetails.toLowerCase().includes(filterValue.toLowerCase());
      };
    },
    valueGetter: (_, row) => {
      return row.name_object.primary;
    },

    renderCell: (params) => {
      const row = params?.row;
      if (!row) return null;
      const { name_object: nameObject, nodeStatus } = row;
      return (
        <Box sx={{ overflow: "hidden" }}>
          {nameObject?.secondary1 !== "" && (
            <Tooltip
              title={
                <Box>
                  <strong>Additional Identifiers:</strong> <br />
                  {nameObject?.secondary1} <br /> {nameObject?.secondary2}
                </Box>
              }
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2">{nameObject?.primary}</Typography>

                {nodeStatus === "Inactive" && (
                  <Chip
                    label="Deregistered"
                    color="error"
                    size="small"
                    sx={{ marginTop: 1 }}
                  />
                )}
              </Stack>
            </Tooltip>
          )}
          {nameObject?.secondary1 === "" && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2">{nameObject?.primary}</Typography>
              {nodeStatus === "Inactive" && (
                <Chip label="Deregistered" color="error" size="small" />
              )}
            </Stack>
          )}
        </Box>
      );
    }
  },
  {
    field: "node_tags",
    headerName: "Tags",
    minWidth: 150,
    flex: 1,
    editable: false,
    wrapText: true,
    sortComparator: (v1, v2) => {
      const keys1 = Object.entries(v1).map(([key]) => `${key.toLowerCase()}`);
      const keys2 = Object.entries(v2).map(([key]) => `${key.toLowerCase()}`);

      if (keys1.length === 0) return 1;
      if (keys2.length === 0) return -1;

      return keys1.join("").localeCompare(keys2.join(""));
    },
    getApplyQuickFilterFn: (filterValue) => {
      return (_, row) => {
        const nodeDetails = row.node_tags;
        return Object.keys(nodeDetails)
          .toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      };
    },
    renderCell: (params) => {
      const tags = params.row.node_tags;
      if (typeof tags === "object" && tags !== null) {
        const items = Object.entries(tags).map(([key]) => {
          return (
            <Chip
              size="small"
              sx={{
                marginRight: (theme) => theme.spacing(1),
                marginTop: (theme) => theme.spacing(1)
              }}
              label={<Box>{key}</Box>}
              color="secondary"
              key={`${params.row.node_id}-${key}`}
            />
          );
        });

        return (
          <Box
            sx={{
              marginY: (theme) => theme.spacing(1),
              overflow: "hidden"
            }}
          >
            {items}
          </Box>
        );
      }
      return null;
    }
  },
  {
    field: "registration_date",
    headerName: "Registered On",
    width: 170,
    editable: false,
    type: "dateTime"
  },
  {
    field: "cpu_model",
    headerName: "CPU",
    width: 80,
    editable: false,
    renderCell: (params) => {
      <Box sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        {params.row.cpu_model}
      </Box>;
    }
  },
  {
    field: "node_type",
    headerName: "Type",
    width: 60,
    editable: false
  },
  {
    field: "platform_name",
    headerName: "Operating System",
    minWidth: 100,
    editable: false
  },
  {
    field: "last_ping_time",
    headerName: "Last Online",
    minWidth: 150,
    editable: false
  },
  {
    field: "pmu",
    headerName: "PMU",
    editable: false,
    minWidth: 100,
    valueGetter: (_, row) => {
      return row.pmu ? "enabled" : "disabled";
    }
  },
  {
    field: "ping_status",
    headerName: "Status",
    width: 70,
    editable: false,
    align: "center",
    renderCell: (params) => generateIconForStatus(params.row.ping_status)
  },
  {
    field: "computer_name",
    headerName: "Hostname",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.computer_name ?? "Unknown";
    }
  },
  {
    field: "hypervisor",
    headerName: "Hypervisor",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.hypervisor ?? "Unknown";
    }
  },
  {
    field: "vtsspp_driver_installed",
    headerName: "VTSSPP Driver Installed",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.vtsspp_driver_installed ?? "Unknown";
    }
  },
  {
    field: "socwatch_driver_installed",
    headerName: "Socwatch Driver Installed",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.socwatch_driver_installed ?? "Unknown";
    }
  },
  {
    field: "ip_address",
    headerName: "IP Address",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.ip_address ?? "Unknown";
    }
  },
  {
    field: "cpu_arch",
    headerName: "CPU Architecture",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.cpu_arch ?? "Unknown";
    }
  },
  {
    field: "kernel",
    headerName: "Kernel",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.kernel ?? "Unknown";
    }
  },
  {
    field: "platform_type",
    headerName: "OS Type",
    editable: false,
    minWidth: 150,
    valueGetter: (_, row) => {
      return row.platform_type ?? "Unknown";
    }
  }
];

const fieldsVisibleByDefault = [
  "name_object",
  "node_tags",
  "registration_date",
  "cpu_model",
  "node_type",
  "pmu",
  "platform_name",
  "ping_status"
];

export const columnVisibilityDef = generateColumnVisibilityDef(
  columnDef,
  fieldsVisibleByDefault
);
