/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Role } from "../appServices/role";
import tourSteps from "../components/Tour/TourSteps";

const MAX_VIEWED_ANLYSIS_HISTORY_SIZE = 20;

export const initialState = {
  runTour: false,
  tourStepIndex: 0,
  memberships: {},
  authUser: null,
  current_tenant_id: null,
  current_role: new Role("No Role", -1, []),
  viewedAnalysisHistory: [],
  showTourBanner: true,
  dashboardShowOnlyMine: false
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetToInitialState: () => initialState,
    startTour: (state) => {
      state.runTour = true;
      state.tourStepIndex = 0;
    },
    stopTour: (state) => {
      state.runTour = false;
    },
    tourNextStep: (state) => {
      if (state.tourStepIndex < tourSteps.length - 1) {
        state.tourStepIndex += 1;
      } else {
        state.runTour = false;
      }
    },
    tourPrevStep: (state) => {
      if (state.tourStepIndex > 0) {
        state.tourStepIndex -= -1;
      }
    },
    setAuthUser: (state, action) => {
      state.authUser = action.payload;
    },
    setMemberships: (state, action) => {
      state.memberships = action.payload;
    },
    updateTenantIdAndRole: (state, action) => {
      const data = action.payload;
      state.current_tenant_id = data.currentTenantId;
      state.current_role = Role.getRoleByName(data.currentRoleName);
    },
    dismissTourBanner: (state) => {
      state.showTourBanner = false;
    },
    setDashboardShowOnlyMine: (state, action) => {
      state.dashboardShowOnlyMine = action.payload;
    },
    userViewedAnalysis: (state, action) => {
      // relies on the fact that a Set's iteration order is insertion order
      // the most recent analysis will be the last one in the set
      const viewedAnalysisId = action.payload;
      if (state.viewedAnalysisHistory.includes(viewedAnalysisId)) {
        state.viewedAnalysisHistory.splice(
          state.viewedAnalysisHistory.indexOf(viewedAnalysisId),
          1
        );
      }
      if (
        state.viewedAnalysisHistory.length > MAX_VIEWED_ANLYSIS_HISTORY_SIZE
      ) {
        state.viewedAnalysisHistory.pop();
      }
      state.viewedAnalysisHistory.unshift(viewedAnalysisId);
    }
  }
});

export const userActions = userSlice.actions;

export default userSlice;
