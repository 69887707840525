/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import { CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import ErrorScreen from "../screens/Error/ErrorScreen";
import { logout } from "../utils/broadcaster";

const ProtectedRoute = ({ children }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    auth.events.addAccessTokenExpiring(() => {
      if (
        alert(
          "You're about to be signed out due to inactivity. Press Ok to stay signed in."
        )
      ) {
        auth.signinSilent();
      }
    });
    auth.events.addAccessTokenExpired(() => {
      logout(auth, dispatch);
    });
  }, [auth, auth.events, auth.signinSilent, dispatch]);

  if (auth.isLoading) {
    return (
      <Stack
        sx={{ width: "100vw", height: "100vh" }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2">Loading...</Typography>
        <CircularProgress color="inherit" size="14rem" />
      </Stack>
    );
  }

  if (!auth.isAuthenticated) {
    return (
      <ErrorScreen
        status={401}
        message="Not Authorized"
        statusText="Your login has expired.  Please login again by clicking the 'Login' button."
      />
    );
  }

  if (auth?.isLoading !== true && auth?.user && auth?.user?.expired === true) {
    logout(auth, dispatch);
  }
  return children;
};

export default ProtectedRoute;
