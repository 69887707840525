/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import YourProfilePane from "./components/YourProfilePane";
import UsersPane from "./components/UsersPane";
import * as userManagement from "../../appServices/UserManagement.service";
import { reduxStore } from "../../store/ReduxStore";
import { logout } from "../../utils/broadcaster";
import InviteUserDialog from "../../components/InviteUserDialog/InviteUserDialog";
import { useGetTenantQuery } from "../../store/wtpkApi";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ flex: "1 1 250px", overflow: "hidden" }}
    >
      {value === index && <Box display="block">{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
};

export const accountLoader = async (auth) => {
  const accountData = {};
  if (
    reduxStore
      .getState()
      .user.current_role.hasPermission("canAccessAdminContent")
  ) {
    accountData.members = await userManagement.getMembers(auth);
    accountData.invites = await userManagement.getInvitations(auth);
  }
  return accountData;
};

const Account = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const auth = useAuth();
  const dispatch = useDispatch();
  const email = auth?.user?.profile?.email;
  const role = useSelector((state) => state.user.current_role);
  const userCanAccessAdmin = role.hasPermission("canAccessAdminContent");
  const tenantId = useSelector((state) => state.user.current_tenant_id);
  const { data: tenantData, isSuccess: gotTenantData } = useGetTenantQuery(
    tenantId,
    { refetchOnMountOrArgChange: true, skip: !tenantId }
  );

  const allowedEmailDomains = gotTenantData
    ? tenantData.tenant.allowed_email_domains
    : [];

  const tenantName = gotTenantData ? tenantData.tenant.tenant_name : "";

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const [inviteUserDialogIsOpen, setInviteUserDialogIsOpen] = useState(false);
  const handleOpenInviteUser = () => {
    setInviteUserDialogIsOpen(true);
  };
  const handleCloseInviteUser = () => {
    setInviteUserDialogIsOpen(false);
  };

  const logoutButtonDef = {
    label: "Log out",
    onClick: () => {
      logout(auth, dispatch);
    }
  };

  const inviteUserButtonDef = {
    label: "Invite a user",
    onClick: () => {
      handleOpenInviteUser();
    }
  };

  return (
    <>
      <ScreenHeader
        title="Account"
        heroButtonDef={currentTab === 1 ? inviteUserButtonDef : logoutButtonDef}
      />
      <Box height="100%" padding={0} margin={0}>
        <Grid
          container
          item
          sx={{ width: "100%", overflow: "hidden", height: "100%" }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              height: "auto",
              overflow: "hidden",
              alignItems: "self-start"
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={currentTab}
              onChange={handleTabChange}
              aria-label="Vertical tabs example"
              sx={{
                width: "175px",
                alignItems: "self-start",
                "& .MuiTab-root.Mui-selected": {
                  color: "#000000",
                  fontWeight: "medium"
                },
                "& .MuiTabs-root>.MuiBox-root": { padding: 0 }
              }}
              TabIndicatorProps={{ hidden: true }}
            >
              <Tab
                sx={{
                  minHeight: "22px",
                  fontSize: "14px",
                  width: "175px",
                  justifyContent: "flex-start",
                  py: (theme) => theme.spacing(1),
                  px: 0
                }}
                icon={<PersonIcon />}
                iconPosition="start"
                label={
                  <Typography
                    textTransform="none"
                    variant="body2"
                    color="disabled"
                  >
                    Profile
                  </Typography>
                }
                {...a11yProps(0)}
              />
              {userCanAccessAdmin && (
                <Tab
                  sx={{
                    minHeight: "22px",
                    fontSize: "14px",
                    width: "175px",
                    justifyContent: "flex-start",
                    py: (theme) => theme.spacing(1),
                    px: 0
                  }}
                  icon={<PeopleIcon />}
                  iconPosition="start"
                  label={
                    <Typography
                      textTransform="none"
                      variant="body2"
                      color="disabled"
                    >
                      Users
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
              )}
            </Tabs>
            <TabPanel value={currentTab} index={0}>
              <YourProfilePane email={email} tenantName={tenantName} />
            </TabPanel>
            {userCanAccessAdmin && (
              <TabPanel value={currentTab} index={1}>
                <UsersPane />
              </TabPanel>
            )}
          </Box>
        </Grid>
        {inviteUserDialogIsOpen && (
          <InviteUserDialog
            open={inviteUserDialogIsOpen}
            close={handleCloseInviteUser}
            allowedDomains={allowedEmailDomains}
          />
        )}
      </Box>
    </>
  );
};

export default Account;
