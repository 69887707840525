import { ApiGET, ApiPost, ApiPut, ApiDELETE } from "./axiosService";
import { reduxStore } from "../store/ReduxStore";

const apiBaseUrl = `${window.runtime.REACT_APP_API_URL}`;

export const getMemberInformation = async (auth) => {
  if (!auth || !auth?.isAuthenticated) return null;
  const response = await ApiGET(
    `${apiBaseUrl}/membership_list`,
    auth.user,
    true
  );
  return response;
};

export const getTenantInfo = async (auth, tenantId) => {
  const tenantResponse = await ApiGET(
    `${apiBaseUrl}/tenant/${tenantId}`,
    auth.user,
    false,
    tenantId
  );
  return tenantResponse?.tenant;
};

export const getCurrentTenantInfo = async (auth) => {
  const tenantResponse = await ApiGET(
    `${apiBaseUrl}/tenant/${reduxStore.getState().user.current_tenant_id}`,
    auth.user
  );
  return tenantResponse?.tenant;
};

export const getMembers = async (auth) => {
  const memberResponse = await ApiGET(
    `${apiBaseUrl}/tenant/${
      reduxStore.getState().user.current_tenant_id
    }/member`,
    auth.user
  );
  return memberResponse;
};

export const getInvitations = async (auth) => {
  const invitationResponse = await ApiGET(
    `${apiBaseUrl}/tenant/${
      reduxStore.getState().user.current_tenant_id
    }/invitation`,
    auth.user
  );
  return invitationResponse;
};

export const getInvitationsByEmail = async (auth) => {
  const invitationByEmailResponse = await ApiGET(
    `${apiBaseUrl}/invitation_list`,
    auth.user
  );
  return invitationByEmailResponse;
};

export const inviteUser = async (auth, emailAddress, roleId) => {
  const createInviteResponse = await ApiPost(
    `${apiBaseUrl}/tenant/${
      reduxStore.getState().user.current_tenant_id
    }/invitation`,
    auth.user,
    { member_email: emailAddress, member_role_id: roleId }
  );
  return createInviteResponse;
};

export const updateRole = async (auth, userId, roleId) => {
  const updateMemberResponse = await ApiPut(
    `${apiBaseUrl}/tenant/${
      reduxStore.getState().user.current_tenant_id
    }/member/${userId}`,
    auth.user,
    { member_role_id: roleId }
  );
  return updateMemberResponse;
};

export const revokeInvitation = async (auth, emailAddress) => {
  const revokeInviteResponse = await ApiDELETE(
    `${apiBaseUrl}/tenant/${
      reduxStore.getState().user.current_tenant_id
    }/invitation/${emailAddress}`,
    auth.user
  );
  return revokeInviteResponse;
};

export const revokeUserAccess = async (auth, userId) => {
  const reovkeUserAccessResponse = await ApiDELETE(
    `${apiBaseUrl}/tenant/${
      reduxStore.getState().user.current_tenant_id
    }/member/${userId}`,
    auth.user
  );
  return reovkeUserAccessResponse;
};

export const parseRoleList = (roleListReponse) => {
  const roleList = roleListReponse.roles.map(JSON.parse);
  return roleList;
};

export const getRoleList = async (auth) => {
  const listRoleReponse = await ApiGET(
    `${apiBaseUrl}/platform_role_list`,
    auth.user
  );
  if (!(listRoleReponse instanceof Error)) {
    const roleList = listRoleReponse.roles.map(JSON.parse);
    return roleList;
  }
  return listRoleReponse;
};
