const styles = {
  landingButton: {
    color: "white",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.1)",
    transition:
      "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    backgroundColor: "#e96115",
    minWidth: "calc(67px + 8vw)",
    minHeight: "calc(18px + 4vh)",
    textTransform: "none",
    ":hover": {
      backgroundColor: "#a3430e"
    },
    ":active": {
      backgroundColor: "#a3430e"
    },
    ":focus": {
      backgroundColor: "#a3430e"
    }
  },
  titleSize: {
    fontSize: "3.5rem"
  },
  subTitleSize: {
    fontSize: "2.4rem"
  },

  textSize: {
    fontSize: "1.2rem"
  },
  boldTitle: {
    fontWeight: "bold"
  },

  lightTitle: {
    fontWeight: "light"
  },
  boldText: {
    fontWeight: "bold"
  },
  intelOrange: {
    color: "#e96115"
  },
  textColor: {
    color: "#262626"
  },

  a: {
    ":hover": {
      color: "#a3430e"
    },
    ":active": {
      color: "#a3430e"
    },
    ":focus": {
      color: "#a3430e"
    }
  }
};

export default styles;
