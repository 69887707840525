/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect, useMemo } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { useSnackbar } from "notistack";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useDispatch, useSelector } from "react-redux";
import { useLoaderData, useRevalidator } from "react-router-dom";
import ParseNodeData from "../../../../utils/TableUtils";
import * as APIServices from "../../../../appServices/axiosService";
import { analysisTemplateActions } from "../../slices/LaunchAnalysisSlices";
import { tableDataActions } from "../../../../store/TableDataSlice";
import { columnDef, columnVisibilityDef } from "./SelectNodesColumnDef";
import { dataTableHeaderToolbarGetter } from "../../../../components/DataTableElements/DataTableHeaderToolbar";
import PaginationButtons from "../../../../components/DataTableElements/PaginationButtons";

const apiBaseUrl = window.runtime.REACT_APP_API_URL;
export const loader = async (auth) => {
  const response = await APIServices.ApiGET(
    `${apiBaseUrl}/node?page_size=200`,
    auth.user
  );
  return response;
};

const untoggleableFields = ["__check__"];

const getTogglableColumns = (columns) => {
  return columns
    .filter((column) => !untoggleableFields.includes(column.field))
    .map((column) => column.field);
};

const SelectNodesTable = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loadedNodes] = useLoaderData();
  const revalidateLoader = useRevalidator();
  const memoColumns = useMemo(() => columnDef);
  const selectedNodes = useSelector((state) => state.analysis.selectedNodes);
  const [rowData, setRowData] = useState(ParseNodeData(loadedNodes, "Online"));
  const [visibleColumns, setVisibleColumns] = useState(columnVisibilityDef);
  const [panelAnchorEl, setPanelAnchorEl] = useState(null);

  const setTableData = async () => {
    const nodeData = ParseNodeData(loadedNodes, "Online");
    if (nodeData instanceof Error) {
      enqueueSnackbar(
        `Could not fetch most recent table data. Current table data may be outdated: ${loadedNodes}`,
        { variant: "error" }
      );
    }
    setRowData(nodeData);
    dispatch(tableDataActions.setNodeList(ParseNodeData(loadedNodes)));
  };

  useEffect(() => {
    setTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedNodes]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (revalidateLoader.state === "idle") {
        revalidateLoader.revalidate();
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [revalidateLoader]);

  return (
    <Stack width="100%" justifyContent="center" sx={{ overflow: "hidden" }}>
      <DataGridPremium
        rows={rowData}
        getRowId={(row) => row.node_id}
        columns={memoColumns}
        sx={{
          "& .MuiDataGrid-overlayWrapper": {
            minHeight: "100px"
          },
          "& .MuiDataGrid-toolbarQuickFilter": {
            height: "32px"
          },
          "&>.MuiDataGrid-main": {
            border: 0
          },
          "&>.MuiDataGrid-main .MuiDataGrid-columnHeader": {
            background: "#FAFAFA"
          },
          "&>.MuiDataGrid-main .MuiDataGrid-scrollbarFiller": {
            background: "#FAFAFA"
          },
          "&>.MuiDataGrid-footerContainer": {
            border: 0,
            minHeight: "44px"
          },
          "& .MuiTablePagination-toolbar": {
            minHeight: "44px"
          },
          "& .MuiPaginationItem-root": {
            height: "24px",
            minWidth: "24px"
          }
        }}
        onColumnVisibilityModelChange={(newModel) => {
          if (
            Object.keys(newModel).length === 1 &&
            newModel.name_object === true
          ) {
            const updatedColumns = { ...visibleColumns };
            Object.keys(updatedColumns).forEach((key) => {
              updatedColumns[key] = true;
            });
            setVisibleColumns(updatedColumns);
          } else {
            setVisibleColumns({ ...visibleColumns, ...newModel });
          }
        }}
        slots={{
          loadingOverlay: LinearProgress,
          toolbar: dataTableHeaderToolbarGetter,
          pagination: PaginationButtons
        }}
        checkboxSelection
        disableVirtualization
        disableRowSelectionOnClick
        onRowSelectionModelChange={(ids) => {
          dispatch(
            analysisTemplateActions.setSelectedNodes(
              rowData.filter((row) => ids.includes(row.node_id))
            )
          );
        }}
        slotProps={{
          toolbar: {
            setPanelAnchorEl
          },
          columnsManagement: {
            getTogglableColumns
          },
          panel: {
            anchorEl: panelAnchorEl
          }
        }}
        rowSelectionModel={selectedNodes.value.map((node) => node.node_id)}
        initialState={{
          sorting: {
            sortModel: [{ field: "start_timestamp", sort: "desc" }]
          },
          columns: {
            columnVisibilityModel: columnVisibilityDef
          },
          density: "compact",
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          }
        }}
      />
    </Stack>
  );
};

export default SelectNodesTable;
