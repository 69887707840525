export const ConvertDateTime = (startDate) => {
  try {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    }).format(startDate);
  } catch (e) {
    return null;
  }
};

export const convertDateTime24Hr = (startDate) => {
  try {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    }).format(startDate);
  } catch (e) {
    return null;
  }
};

export const customSort = (a, b) =>
  new Date(b.start_timestamp).getTime() - new Date(a.start_timestamp).getTime();

export const dateComparator = (date1, date2) => {
  const a = date1;
  const b = date2;
  const adt = new Date(a).getTime();
  const bdt = new Date(b).getTime();
  if (bdt > adt) return 1;
  if (bdt < adt) return -1;
  return 0;
};

export const sortTags = (listOfTags) => {
  if (listOfTags === null) {
    return {};
  }
  let tags = Object.entries(listOfTags);
  tags.sort((a, b) => {
    return a[0].localeCompare(b[0]);
  });
  tags = tags.reduce((acc, [key, value]) => {
    acc[key] = value; // eslint-disable-line no-param-reassign
    return acc;
  }, {});
  return tags;
};

export const tagComparator = (
  valueA1,
  valueB1,
  nodeA,
  nodeB,
  sortDirection
) => {
  const entriesA = Object.entries(nodeA.data.node_tags);
  const entriesB = Object.entries(nodeB.data.node_tags);

  if (entriesA.length === 0 && entriesB.length === 0) {
    return 0;
  }
  if (entriesA.length === 0) {
    return 1;
  }
  if (entriesB.length === 0) {
    return -1;
  }

  let index = 0;
  const shorterEntryLength =
    entriesA.length >= entriesB.length ? entriesB.length : entriesA.length;

  if (!sortDirection) {
    while (index < shorterEntryLength) {
      if (entriesA[index][0] !== entriesB[index][0]) {
        return entriesA[index][0].localeCompare(entriesB[index][0]);
      }

      if (entriesA[index][1] !== entriesB[index][1]) {
        return entriesA[index][1].localeCompare(entriesB[index][1]);
      }

      index += 1;
    }
  } else {
    index = 1;
    while (shorterEntryLength - index > -1) {
      if (
        entriesA[entriesA.length - index][0] !==
        entriesB[entriesB.length - index][0]
      ) {
        return entriesA[entriesA.length - index][0].localeCompare(
          entriesB[entriesB.length - index][0]
        );
      }

      if (
        entriesA[entriesA.length - index][1] !==
        entriesB[entriesB.length - index][1]
      ) {
        return entriesA[entriesA.length - index][1].localeCompare(
          entriesB[entriesB.length - index][1]
        );
      }

      index += 1;
    }
  }

  if (entriesA > entriesB) {
    return sortDirection ? 1 : -1;
  }
  if (entriesA < entriesB) {
    return sortDirection ? -1 : 1;
  }
  return 0;
};

export const parseNodeType = (nodeInformation) => {
  switch (nodeInformation) {
    case "None":
      return "HW";
    default:
      return "VM";
  }
};

export const parseModelType = (nodeInformation) => {
  const splitString = nodeInformation.split(" ");
  splitString[1] = splitString[1].replace("(R)", "\u00ae");
  splitString[1] = splitString[1].replace("(TM)", "\u2122");
  return splitString[3] !== undefined
    ? splitString[1].concat(" ", splitString[3])
    : splitString[1];
};

export const displayConsistentNodeName = (nodeInformation) => {
  let nodeName = { primary: "", secondary1: "", secondary2: "" };
  switch (true) {
    case nodeInformation?.name !== null:
      nodeName = {
        ...nodeName,
        primary: nodeInformation.name,
        secondary1:
          nodeInformation.computer_name !== null
            ? nodeInformation.computer_name
            : "",
        secondary2:
          nodeInformation.node_id !== null ? nodeInformation.node_id : ""
      };
      break;
    case nodeInformation.computer_name !== null:
      nodeName = {
        ...nodeName,
        primary: nodeInformation.computer_name,
        secondary1:
          nodeInformation.node_id !== null ? nodeInformation.node_id : "",
        secondary2: ""
      };
      break;
    case nodeInformation.node_id !== null:
      nodeName = {
        ...nodeName,
        primary: nodeInformation.node_id,
        secondary1: "",
        secondary2: ""
      };
      break;
    default:
      break;
  }
  return nodeName;
};

export const customNodeNameSearch = (params) => {
  const joinedNameString = `${params?.data.name_object?.primary} ${params?.data.name_object?.secondary1} ${params?.data.name_object?.secondary2}`;
  return joinedNameString;
};

export const filterRowData = (
  filteredValue,
  selectedDataGridSearchbarFilter,
  rowData
) => {
  if (selectedDataGridSearchbarFilter === "All") {
    return rowData.filter((row) =>
      JSON.stringify(row).toLowerCase().includes(filteredValue)
    );
  }
  return rowData.filter((row) => {
    return JSON.stringify(row[selectedDataGridSearchbarFilter])
      ?.toLowerCase()
      .includes(filteredValue);
  });
};

export const filterAnalysisStatusRowData = (
  selectValueParsed,
  filteredValue,
  rowData,
  selectedDataGridSearchbarFilter
) => {
  return rowData.filter((row) => {
    const modifiedRow = { ...row };
    if (modifiedRow.nodeStatus === "Inactive") {
      modifiedRow.name_object = {
        ...modifiedRow.name_object,
        deregistered: true
      };
    }
    switch (true) {
      case selectedDataGridSearchbarFilter === "All":
        return JSON.stringify(modifiedRow)
          .toLowerCase()
          .includes(filteredValue);
      case modifiedRow[selectValueParsed[0]] &&
        modifiedRow[selectValueParsed[0]][selectValueParsed[1]] !== undefined &&
        selectValueParsed.length === 2:
        return JSON.stringify(
          modifiedRow[selectValueParsed[0]][selectValueParsed[1]]
        )
          .toLowerCase()
          .includes(filteredValue);
      case modifiedRow[selectValueParsed[0]] &&
        modifiedRow[selectValueParsed[0]][selectValueParsed[1]] &&
        modifiedRow[selectValueParsed[0]][selectValueParsed[1]][
          selectValueParsed[2]
        ] !== undefined &&
        selectValueParsed.length === 3:
        return JSON.stringify(
          modifiedRow[selectValueParsed[0]][selectValueParsed[1]][
            selectValueParsed[2]
          ]
        )
          .toLowerCase()
          .includes(filteredValue);
      case selectValueParsed.length > 1:
        return null;
      default:
        return JSON.stringify(modifiedRow[selectedDataGridSearchbarFilter])
          ?.toLowerCase()
          .includes(filteredValue);
    }
  });
};

/**
 * Creates a MUI DataGrid column visibility definition, where only the fields
 * in the list visibleFields will be visible by default and the rest hidden.
 *
 * Inputs:
 *   - column definition object
 *   - list of fields which should be visible by default
 * Outputs:
 *   - column visibility object hiding the non-visible columns
 */
export const generateColumnVisibilityDef = (columnDef, visibleFields) =>
  columnDef.reduce((obj, colDef) => {
    return { ...obj, [colDef.field]: visibleFields.includes(colDef.field) };
  }, {});

export const invertKeysAndValues = (obj) => {
  const retobj = {};
  Object.keys(obj).forEach((key) => {
    retobj[obj[key]] = key;
  });
  return retobj;
};
