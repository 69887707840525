/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from "react";
import {
  Form,
  Navigate,
  json,
  useActionData,
  useSubmit,
  redirect
} from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CardActions,
  CardHeader,
  Button,
  Grid
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useAuth } from "react-oidc-context";
import { ApiGET, ApiPut } from "../../appServices/axiosService";
import { termsAndConditionsActions } from "./TermsAndConditionsSlice";
import TCLegalText from "./TCLegalText";
import { logout } from "../../utils/broadcaster";
import { useGetCustomerInfoQuery, wtpkAPI } from "../../store/wtpkApi";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";

const apiBaseUrl = window.runtime.REACT_APP_API_URL;

export const fetchCustomerInformation = async (auth) => {
  if (!auth || !auth?.isAuthenticated) return null;
  const response = await ApiGET(
    `${apiBaseUrl}/user/${auth?.user?.profile["custom:userId"]}`,
    auth.user,
    true
  );
  return response;
};

// eslint-disable-next-line consistent-return
export const action = async (request, dispatch, auth) => {
  const formData = await request.formData();
  const acceptTermsAndConditions = formData.get("acceptTermsAndConditions");
  const acceptDataCollection = formData.get("acceptDataCollection");
  if (acceptTermsAndConditions === "Accept") {
    const input = {
      preferences: {
        terms_accepted: true,
        data_collection_accepted:
          acceptDataCollection === "" ? false : acceptDataCollection
      }
    };
    const response = await ApiPut(
      `${apiBaseUrl}/user/${auth.user.profile["custom:userId"]}`,
      auth.user,
      input,
      true
    );
    if (response && response.name !== "AxiosError") {
      dispatch(
        termsAndConditionsActions.setTermsAndConditions(
          response.user.preferences.terms_accepted
        )
      );
      dispatch(
        wtpkAPI.endpoints.getCustomerInfo.initiate(
          auth?.user?.profile["custom:userId"],
          { subscribe: false, forceRefetch: true }
        )
      );
      if (response && response?.user?.preferences?.terms_accepted) {
        return redirect("/data-collection-consent");
      }
    } else
      throw json(
        {
          didNotAccept: true,
          message:
            "There was an error in sending your data for your Terms and Conditions. Please log out and sign back in."
        },
        {
          status: 403,
          statusText: "You must re-login, to accept the terms and conditions"
        }
      );
    return response;
  }
  try {
    logout(auth, dispatch);
  } catch (error) {
    throw json(
      {
        didNotAccept: true,
        message:
          "There was an error determining if your Terms and Conditions is valid. Please log out and sign back in."
      },
      {
        status: 403,
        statusText: "You must re-login, to accept the terms and conditions"
      }
    );
  }
};

const TermsAndConditions = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const auth = useAuth();
  const formRef = useRef();
  const submit = useSubmit();
  const dispatch = useDispatch();
  const actionResponse = useActionData();
  const { data: customerInfo } = useGetCustomerInfoQuery(
    auth?.user?.profile["custom:userId"]
  );
  const termsAccepted = customerInfo?.user?.preferences?.terms_accepted;

  const hasAcceptedDataCollection =
    customerInfo?.user?.preferences?.data_collection_accepted;

  if (termsAccepted && !actionResponse) {
    dispatch(termsAndConditionsActions.setTermsAndConditions(termsAccepted));
  }
  if (
    (termsAccepted && !hasAcceptedDataCollection) ||
    (actionResponse && actionResponse?.user?.preferences?.terms_accepted)
  ) {
    return <Navigate to="/data-collection-consent" replace />;
  }

  const handleChange = (e, value) => {
    setIsAccepted(value);
  };

  return (
    <>
      {termsAccepted && (
        <ScreenHeader
          title="Terms and Conditions"
          crumbDefs={[{ label: "Information" }]}
        />
      )}
      <Box
        sx={{
          background: "#F5F5F5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: termsAccepted ? "100%" : "100vh"
        }}
      >
        <Card
          sx={{
            margin: termsAccepted ? "auto" : "52px",
            flexDirection: "column",
            border: "none",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
            minWidth: "496px", // min supported window size of 600, minus two 52px margins = 496px
            maxWidth: "800px",
            maxHeight: "600px"
          }}
        >
          <CardHeader
            titleTypographyProps={{
              padding: 2,
              textAlign: "center",
              textTransform: "none !important",
              variant: "h5",
              color: "#000000 !important",
              fontSize: "1.5rem !important"
            }}
            title="Performance Profiler Early Access Terms of Service Agreement"
          />
          <CardContent
            sx={{
              maxHeight: termsAccepted ? "496px" : "450px",
              overflow: "auto",
              // scrollbox shadows:
              background:
                "linear-gradient(white 30%,rgba(255, 255, 255, 0)),linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,radial-gradient(farthest-side at 50% 0,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0)),radial-gradient(farthest-side at 50% 100%,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0)) 0 100%",
              backgroundRepeat: "no-repeat",
              backgroundColor: "white",
              backgroundSize: "100% 40px, 100% 40px, 100% 5px, 100% 5px",
              backgroundAttachment: "local, local, scroll, scroll"
            }}
          >
            <TCLegalText />
          </CardContent>
          {!termsAccepted && (
            <CardActions
              data-testid="tnc-actions"
              sx={{ justifyContent: "space-around" }}
            >
              <Form
                method="post"
                style={{ width: "100%" }}
                ref={formRef}
                replace
              >
                <FormGroup
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2
                  }}
                >
                  <input
                    type="hidden"
                    readOnly
                    value={hasAcceptedDataCollection}
                    name="acceptDataCollection"
                  />
                  <Grid container>
                    <Grid item xs={8}>
                      <FormControlLabel
                        sx={{
                          maxWidth: "784px",
                          minHeight: "42px",
                          alignItems: "start"
                        }}
                        slotProps={{ typography: { sx: { mt: "5px" } } }}
                        control={
                          <Checkbox
                            name="acceptTermsAndConditions"
                            value="Accept"
                            id="acceptTermsAndConditions"
                            className="form-check-input"
                            type="checkbox"
                            onChange={handleChange}
                            sx={{ padding: "5px 12px" }}
                          />
                        }
                        label="I have read and agree to the Terms of Services Agreement"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="start"
                    >
                      <Button
                        variant="outlined"
                        name="Cancel"
                        size="medium"
                        ml={2}
                        onClick={() => {
                          logout(auth, dispatch);
                        }}
                        sx={{
                          marginLeft: "10px",
                          minWidth: "65px",
                          height: "33px",
                          padding: "16px 6px",
                          borderWidth: "1px"
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        ml={2}
                        disabled={!isAccepted}
                        onClick={() => {
                          submit(formRef.current);
                        }}
                        sx={{
                          marginLeft: "10px",
                          minWidth: "83px",
                          height: "33px",
                          padding: "16px 6px"
                        }}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Form>
            </CardActions>
          )}
        </Card>
      </Box>
    </>
  );
};

export default TermsAndConditions;
