import { invertKeysAndValues } from "./resuableMethods";

export const ROLE_NAME_TO_ROLE_ID_MAP = {
  Admin: 0,
  Manager: 1,
  Editor: 2,
  Viewer: 3
};

const ROLE_NAME_TO_PERMISSION_MAP = {
  Admin: [
    "canAccessAdminContent",
    "canCreateAnalyses",
    "canDeleteAnalyses",
    "canRenameAnalyses",
    "canRegisterNodes",
    "canDeregisterNodes",
    "canEditNodeTags"
  ],
  Editor: [
    "canCreateAnalyses",
    "canDeleteAnalyses",
    "canRenameAnalyses",
    "canRegisterNodes",
    "canDeregisterNodes",
    "canEditNodeTags"
  ],
  Viewer: []
};

export class Role {
  constructor(roleName, roleId, permissions) {
    this.name = roleName;
    this.id = roleId;
    permissions.forEach((permission) => {
      this[permission] = true;
    });
    this.hasPermission = (permission) => {
      return permission in this ? this[permission] : false;
    };
  }

  // map of role name to role
  static SAVED_ROLES = {};

  static NAME_TO_ID_MAP = ROLE_NAME_TO_ROLE_ID_MAP;

  static ID_TO_NAME_MAP = invertKeysAndValues(this.NAME_TO_ID_MAP);

  static NAME_TO_PERMISSION_MAP = ROLE_NAME_TO_PERMISSION_MAP;

  static areSameRole(role1, role2) {
    return role1.id === role2.id;
  }

  static getRoleById(id) {
    const name = this.ID_TO_NAME_MAP[id];
    return this.getRoleByName(name);
  }

  static getRoleByName(name) {
    if (!(name in this.SAVED_ROLES)) {
      this.SAVED_ROLES[name] = new Role(
        name,
        this.NAME_TO_ID_MAP[name],
        this.NAME_TO_PERMISSION_MAP[name] ?? []
      );
    }
    return this.SAVED_ROLES[name];
  }

  static ROLES_UPDATED = false;

  static wereRolesUpdated = () => {
    return this.ROLES_UPDATED;
  };

  static updateRoleDefinitions(roleData) {
    const newSavedRoles = {};
    const newNameToIDMap = {};
    const newIDToNameMap = {};

    roleData.forEach((roleDef) => {
      const name = roleDef.properties.role_name.default;
      const id = roleDef.properties.role_id.default;
      newNameToIDMap[name] = id;
      newIDToNameMap[id] = name;
    });
    this.SAVED_ROLES = newSavedRoles;
    this.NAME_TO_ID_MAP = newNameToIDMap;
    this.ID_TO_NAME_MAP = newIDToNameMap;
    this.ROLES_UPDATED = true;
  }
}

/// For converting between role name and role id

export const getRoleNameOfId = (roleId) => {
  return Object.keys(ROLE_NAME_TO_ROLE_ID_MAP).find(
    (key) => ROLE_NAME_TO_ROLE_ID_MAP[key] === roleId
  );
};

export const getRoleIdOfName = (roleName) => {
  return ROLE_NAME_TO_ROLE_ID_MAP[roleName];
};
